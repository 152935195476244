import { Alert, AlertTitle, Button, Dialog, DialogContent, Slide, Snackbar, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { APIUserClient } from '../Api/ApiUserClient';
import MuiDialogHeader from '../Shared/MuiDialogHeader'

interface ChangePasswordDialogProps {
    isOpen: boolean;
    onClose: (changed: boolean) => void;
}
export default function ChangePasswordDialog(props: ChangePasswordDialogProps) {
    const { isOpen, onClose } = props;
    const [oldPass, setOldPass] = useState<string>('');
    const [newPass, setNewPass] = useState<string>('');
    const [newPassCheck, setNewPassCheck] = useState<string>('');
    const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);

    useEffect(() => {
        setOldPass('');
        setNewPass('');
        setNewPassCheck('');
    }, []);

    function changePassword()
    {
        if (oldPass.length !== 0 && newPass.length !== 0 && newPassCheck.length !== 0 && newPass === newPassCheck)
        {
            setButtonDisabled(true);
            new APIUserClient().ChangePasswordUser(oldPass, newPass).then((d) => {   
                setButtonDisabled(false);
                
                if (d.ok === true)
                    onClose(true);
                else
                    setSnackbarOpen(true);
            }); 
        }  
    }
    
    return (
        <Dialog onClose={() => onClose(false)} open={isOpen}>
            <MuiDialogHeader showCloseText title='ZMĚNA HESLA' onClose={() => onClose(false)}/>
            <DialogContent>
                <div>
                    <div>
                        <div>
                            <TextField sx={{ margin: '12px', marginLeft: '24px', width: '350px' }} label={'Staré heslo'}
                                type={"password"}
                                size='small'
                                name='old-pass'
                                variant='outlined'
                                value={oldPass}
                                onChange={(e) => { setOldPass(e.currentTarget.value); }} />
                        </div>
                        <div>
                            <TextField sx={{ margin: '12px', marginLeft: '24px', width: '350px' }} label={'Nové heslo'}
                                type={"password"}
                                size='small'
                                name='new-pass'
                                variant='outlined'
                                value={newPass}
                                onChange={(e) => { setNewPass(e.currentTarget.value); }} />
                        </div>
                        <div>
                            <TextField sx={{ margin: '12px', marginLeft: '24px', width: '350px' }} label={'Nové heslo znovu'}
                                type={"password"}
                                size='small'
                                name='new-pass-check'
                                variant='outlined'
                                value={newPassCheck}
                                onChange={(e) => { setNewPassCheck(e.currentTarget.value); }} />
                        </div>
                    </div>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
                        <Button variant='contained' sx={{ margin: 'auto' }} onClick={changePassword}
                        disabled={oldPass.length === 0 || newPass.length === 0 || newPassCheck.length === 0 || newPass !== newPassCheck || buttonDisabled}
                        >ZMĚNIT HESLO</Button>
                    </div>
                </div>
                <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={() => setSnackbarOpen(false)}>
                    <Alert onClose={() => setSnackbarOpen(false)} severity="error" sx={{ width: '100%' }}>
                        Špatné původní heslo
                    </Alert>
                </Snackbar>
            </DialogContent>
        </Dialog>
    )
}
