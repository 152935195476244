import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export const handleCapture = (input: HTMLDivElement) => {
    // const input = captureRef.current; // Reference to the element to capture
    if (input === null) return;
    html2canvas(input, { useCORS: true })
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4");

        // Calculate the image dimensions for the PDF
        const imgWidth = 210; // A4 width in mm
        const pageHeight = 297; // A4 height in mm
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;

        let position = 0;

        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        // If the content overflows to another page, create more pages in PDF
        while (heightLeft > 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }

        // Save the generated PDF
        pdf.save("download.pdf");
      })
      .catch((error) => {
        console.error("Error capturing the screenshot: ", error);
      });
  };