import { Dialog, DialogContent, DialogActions, Box, Typography, Button, IconButton, Tooltip } from '@mui/material';
import Home from '@mui/icons-material/Home';
import { FilterAltOff, Launch } from '@mui/icons-material';
import React from 'react';
import { ProjectWiseProjectModel } from './Models/ProjectWiseProjectModel';

export interface IProjectWiseBuildingSceneProps {
    projectData: ProjectWiseProjectModel[];
    selectedRowIndex: number | null;
    isDialog: boolean;
    handleRowClickWithHighlight: (row: any, rowIndex: number) => void;
    unselectBuilding: () => void;
}

export default function ProjectWiseBuildingsScene(props: IProjectWiseBuildingSceneProps) {
    const { projectData, selectedRowIndex, isDialog, handleRowClickWithHighlight, unselectBuilding } = props;
    const [open, setOpen] = React.useState(false);
    const [dialogWidth, setDialogWidth] = React.useState('90vw');
    const [dialogHeight, setDialogHeight] = React.useState('90vh');

    React.useEffect(() => {
        window.addEventListener('resize', updateDialogWidth);
        return () => {
          window.removeEventListener('resize', updateDialogWidth);
        };
    }, []);

    React.useEffect(() => {
        updateDialogWidth(); // Set initial width
    }, [projectData]);
    
    const updateDialogWidth = () => {
        setDialogWidth(`${window.innerWidth * 0.8}px`);
        setDialogHeight(`${window.innerHeight * 0.8}px`);
    };

    const columns = [
        { field: 'PROJECT_PW_STAVBA_NAZEV', flex: 1 }
    ];

    const rows = projectData.map((project, index) => ({
        id: index,
        instanceId: project.instanceId,
        PROJECT_PW_STAVBA_NAZEV: project.properties.PROJECT_PW_STAVBA_NAZEV
    }));
  
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box>
            <Dialog open={open} onClose={handleClose} PaperProps={{ sx: { minWidth: dialogWidth, width: dialogWidth, minHeight: dialogHeight, height: dialogHeight } }}>
                <DialogContent>
                    <ProjectWiseBuildingsScene projectData={projectData} selectedRowIndex={selectedRowIndex} isDialog={true} handleRowClickWithHighlight={handleRowClickWithHighlight} unselectBuilding={unselectBuilding} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Zavřít
                    </Button>
                </DialogActions>
            </Dialog>
            <Box sx={{ display: 'flex', flexDirection: 'row', minWidth: '20%', alignItems: 'center', marginTop: '16px', borderBottom: '1px solid lightgray', paddingBottom: '16px' }}>
                <Box sx={{ backgroundColor: 'lightgray', padding: '4px', display: 'inline-block', borderRadius: '8px', mx: 2 }}>
                    <Home sx={{ color: 'white', mt: 0.5, mx: .5 }} />
                </Box>
                <Typography variant="h6" sx={{ color: 'black', fontWeight: 'bold' }}>Stavby</Typography>
                {selectedRowIndex !== null && <Tooltip title="Zrušit filtr">
                    <IconButton sx={{ alignSelf: 'center', marginLeft: '20px' }} onClick={() => { unselectBuilding(); }}>
                        <FilterAltOff />
                    </IconButton>
                </Tooltip>}
                {!isDialog && <IconButton sx={{ marginLeft: 'auto', alignSelf: 'center', marginRight: '16px' }} onClick={() => { setOpen(true) }}><Launch /></IconButton>}
            </Box>
            <Box sx={{ height: isDialog ? `calc(${dialogHeight} - 170px)` : '365px', overflowY: 'auto' }}>
                {rows.map((row, rowIndex) => (
                    <Box
                        key={rowIndex}
                        sx={{
                            display: 'flex',
                            borderBottom: '1px solid #E7E7E7',
                            cursor: 'pointer',
                            backgroundColor: selectedRowIndex === rowIndex ? '#f0f0f0' : 'transparent',
                            '&:hover': {
                                backgroundColor: '#e0e0e0',
                            },
                        }}
                        onClick={() => handleRowClickWithHighlight(row, rowIndex)}
                    >
                        {columns.map((column, colIndex) => (
                            <Box
                                key={colIndex}
                                sx={{
                                    flex: 1,
                                    padding: '8px',
                                    borderRight: colIndex < columns.length - 1 ? '1px solid #E7E7E7' : 'none',
                                    textAlign: 'left',
                                    marginLeft: '10px'
                                }}
                            >
                                {row[column.field]}
                            </Box>
                        ))}
                    </Box>
                ))}
            </Box>
        </Box>
    );
}
