import { Box, Button, Checkbox, CircularProgress, Grid, Menu, MenuItem, Typography, useTheme } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { csCZ } from '@mui/x-data-grid/locales';
import { ProjectWiseProjectModel } from './Models/ProjectWiseProjectModel';
import ProjectWiseProjectDetail from './ProjectWiseProjectDetail';
import { DataGridPremium, GridColDef, GridRowModesModel, GridRowSelectionModel } from '@mui/x-data-grid-premium';
import ProjectTileScene from './Components/ProjectTileScene';
import CustomToolBar from './CustomToolBar';
import { ProjectWiseProjectInfoModel } from './Models/ProjectWiseProjectInfoModel';

interface ProjectWiseProjectsBottomSceneProps {
  data: ProjectWiseProjectModel[];
  showTiles: boolean;
  projectInfoData: ProjectWiseProjectInfoModel[];
}

export default function ProjectWiseProjectsBottomScene(props: ProjectWiseProjectsBottomSceneProps) {
  const { data, showTiles, projectInfoData } = props;
  const theme = useTheme();
  const [selected, setSelected] = React.useState<string>();
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>();
  const [selectedProject, setSelectedProject] = useState<ProjectWiseProjectModel>();

  useEffect(() => {
  }, [selected])

  const columns: GridColDef[] = [
    {
      field: 'properties.PROJECT_PW_STAVBA_SKOD',
      headerName: 'Značka projektu',
      minWidth: 120,
      valueGetter: (params) => {
        if (params.row.properties.PROJECT_PW_STAVBA_SKOD) {
          return params.row.properties.PROJECT_PW_STAVBA_SKOD;
        } else {
          return '-----';
        }
      }
    },
    {
      field: 'properties.PROJECT_PW_STAVBA_NAZEV',
      headerName: 'Název projektu',
      flex: 1,
      minWidth: 100,
      valueGetter: (params) => {
        if (params.row.properties.PROJECT_PW_STAVBA_NAZEV) {
          return params.row.properties.PROJECT_PW_STAVBA_NAZEV;
        } else {
          return '-----';
        }
      }
    },
    {
      field: 'properties.PROJECT_PW_STAVBA_STADIUM',
      headerName: 'Stádium',
      minWidth: 100,
      valueGetter: (params) => {
        if (params.row.properties.PROJECT_PW_STAVBA_STADIUM) {
          return params.row.properties.PROJECT_PW_STAVBA_STADIUM;
        } else {
          return '-----';
        }
      }
    },
    {
      field: 'properties.PROJECT_PW_STAVBA_STATUS',
      headerName: 'Status',
      minWidth: 120,
      valueGetter: (params) => {
        if (params.row.properties.PROJECT_PW_STAVBA_STATUS) {
          return params.row.properties.PROJECT_PW_STAVBA_STATUS;
        } else {
          return '-----';
        }
      }
    },
    {
      field: 'properties.PROJECT_PW_STAVBA_SPRAVCA',
      headerName: 'Správa',
      minWidth: 150,
      valueGetter: (params) => {
        if (params.row.properties.PROJECT_PW_STAVBA_SPRAVCA) {
          return params.row.properties.PROJECT_PW_STAVBA_SPRAVCA;
        } else {
          return '-----';
        }
      }
    },
    {
      field: 'properties.PROJECT_PW_STAVBA_KRAJ',
      headerName: 'Kraj',
      minWidth: 120,
      valueGetter: (params) => {
        if (params.row.properties.PROJECT_PW_STAVBA_KRAJ) {
          return params.row.properties.PROJECT_PW_STAVBA_KRAJ;
        } else {
          return '-----';
        }
      }
    },
    {
      field: 'properties.PROJECT_PW_STAVBA_TU',
      headerName: 'TU',
      minWidth: 100,
      valueGetter: (params) => {
        if (params.row.properties.PROJECT_PW_STAVBA_TU) {
          return params.row.properties.PROJECT_PW_STAVBA_TU;
        } else {
          return '-----';
        }
      }
    },
    {
      field: 'properties.PROJECT_PW_STAVBA_HIS',
      headerName: 'HIS',
      minWidth: 120,
      valueGetter: (params) => {
        if (params.row.properties.PROJECT_PW_STAVBA_HIS) {
          return params.row.properties.PROJECT_PW_STAVBA_HIS;
        } else {
          return '-----';
        }
      }
    },
    {
      field: 'properties.PROJECT_PW_STAVBA_PLANNAKLADY_1',
      headerName: 'Plánované náklady',
      minWidth: 150,
      valueGetter: (params) => {
        if (params.row.properties.PROJECT_PW_STAVBA_PLANNAKLADY_1) {
          return params.row.properties.PROJECT_PW_STAVBA_PLANNAKLADY_1;
        } else {
          return '-----';
        }
      }
    }
  ];

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  return <Grid item xs={12} lg={12}>
    {!showTiles && <DataGridPremium
      slots={{
        toolbar: (props) => <CustomToolBar {...props} rows={data || []} columns={columns} isProject={true} />,
      }}
      getRowId={(row) => row.instanceId}
      columns={columns}
      rows={data || []}
      rowHeight={35}
      sx={{ minWidth: '500px', border: '1px solid #C3C3C3', marginLeft: '36px' }}
      localeText={csCZ.components.MuiDataGrid.defaultProps.localeText}
      pagination={true}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 10,
          },
        },
      }}
      pageSizeOptions={[10, 20, 30, 50, 100]}
      //rowModesModel={rowModesModel}
      onRowSelectionModelChange={(ids) => {
        setSelectionModel(ids);
      }}
      onRowDoubleClick={(row) => { setSelectedProject(row.row as ProjectWiseProjectModel) }}
      //onRowModesModelChange={handleRowModesModelChange}
      slotProps={{
        toolbar: {
          showQuickFilter: true,
          quickFilterProps: { debounceMs: 500 },
        },
      }}
    />}
    {showTiles && <Box>
      <Typography variant="h4" component="div" sx={{ height: '50%', flexGrow: 1, ml: 2, color: 'black' }}>
        <Grid container spacing={4} style={{ padding: '10px' }}>
          {data.map((project, index) => (
            <ProjectTileScene projectData={project} onSelectProject={(e) => setSelectedProject(e)} />
          ))}
        </Grid>
      </Typography>
    </Box>}
    {selectedProject && <ProjectWiseProjectDetail
      projectInfoData={projectInfoData}
      open={selectedProject !== undefined} onClose={() => { setSelectedProject(undefined) }} project={selectedProject} />}
  </Grid>;
}
