import React from 'react'
import Login from '../Auth/Login';


declare global {
    interface Window {
        sendValidationsDataSlope: (data: any) => void;
        // loginExternal: (accessToken: string, refreshToken: string, expiresAt: Date) => void;
    }
}
interface Props {
    loginDirect?: boolean;
}
export default function SlopePlugin(props: Readonly<Props>) {
    window.sendValidationsDataSlope = sendValidationsDataSlope;
    const [loggedIn, setLoggedIn] = React.useState(false);
    function sendValidationsDataSlope(data: any) {
        // setModelData(data.ModelData);
        // new APIAuthClient().IsAuthed().then((r) => {
        //     if (r === true) {
        //         setLoggedIn(true);
        //     }
        //     else setLoggedIn(false);

        // }).catch((err) => {
        //     setLoggedIn(false);
        // });

    }
    return (
        <div>{loggedIn && <div>Úspěšně přihlášení</div>}
            {!loggedIn && <Login loginDirect={props.loginDirect} onLogin={(acc, refr) => {
                //@ts-ignore
                console.log(window.chrome.webview);
                //@ts-ignore
                if (window.chrome.webview)
                    //@ts-ignore
                    window.chrome.webview.postMessage({ MessageType: 'login', AccessToken: acc, RefreshToken: refr });
            }} />}
        </div>
    )
}
