import { useMsal } from '@azure/msal-react';
import { Alert, Button, CircularProgress, Snackbar, TextField, Typography, useTheme } from '@mui/material';
import React, { useEffect } from 'react'
import { useMediaPredicate } from 'react-media-hook';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { ApiDataClient } from '../Api/ApiDataClient';
import EtcsDataUploadScene from './EtcsDataUploadScene';
import EtcsDataTableScene from './EtcsDataTableScene';
import { OutputRowDTO } from '../Api/ApiServer';
//import { importEtcsExcel } from './ParseEtscsExcel';

interface EtcsDataMainSceneProps {
}

export default function EtcsDataMainScene(props: EtcsDataMainSceneProps) {
    const params = useParams();
    
    const theme = useTheme();
    const [ outputData, setOutputData] = React.useState<OutputRowDTO[] | undefined>();
    const navigate = useNavigate();

    useEffect(() => {
    }, []);    

    return (
        <div className='container'>
            <div style={{ width: '100%', background: '#F5F5F5', position: 'absolute', height: '52px', top: '0', textAlign: 'center', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}>
                <div style={{ display: 'flex', textAlign: 'center', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography variant='h5' fontWeight='bold' color='primary' margin={1}>ETCS</Typography>
                    <Typography variant='h5' fontWeight='bold' >Plugin</Typography>
                </div>
            </div>
            {!outputData && <EtcsDataUploadScene dataUploaded={(d) => { setOutputData(d) }} />}
            {outputData && <EtcsDataTableScene data={outputData} />}
        </div>)
}
