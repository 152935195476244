import React, { useCallback, useMemo, useRef } from 'react';
import { Stack, Box, Switch, Typography, IconButton, useMediaQuery, useTheme } from '@mui/material';
import ProjectWiseUpperMenu from './ProjectWiseUpperMenu';
import ProjectWiseSideMenu from './ProjectWiseSideMenu';
import ProjectWiseProjectScene from './ProjectWiseProjectsScene';
import { ProjectWiseModules } from './ProjectWiseModules';
import { getModuleName } from './Helpers/getModuleName';
import ProjectWiseDashboardScene from './ProjectWiseDashboardScene';
import { getProjects } from './Helpers/getProjects';
import { ProjectWiseProjectModel } from './Models/ProjectWiseProjectModel';
import { ProjectWiseProjectInfoModel } from './Models/ProjectWiseProjectInfoModel';
import { PictureAsPdf } from '@mui/icons-material';
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import delay from '../Shared/delay';

const ProjectWiseLayoutScene: React.FC = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.only('md'));
  const isSm = useMediaQuery(theme.breakpoints.only('sm'));
  const [leftMenuVisible, setLeftMenuVisible] = React.useState(true);
  const [data, setData] = React.useState<ProjectWiseProjectModel[]>([]);
  const [dataProjectInfo, setDataProjectInfo] = React.useState<ProjectWiseProjectInfoModel[]>([]);
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [activeModule, setActiveModule] = React.useState<ProjectWiseModules>(ProjectWiseModules.Projects);
  const [printingPdf, setPrintingPdf] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [isOnlyUser, setIsOnlyUser] = React.useState(false);

  const isMdOrSm = isMd || isSm;

  const getWidthMainPanel = () => {
    if (leftMenuVisible)
      return menuOpen ? 'calc(100vw - 240px)' : 'calc(100vw - 78px)';
    else return  'calc(100vw - 88px)';
  }
  const getHeightMainPanel = () => {
    if (leftMenuVisible)
      return 'calc(100vh - 40px)';
    else return 'calc(100vh - 196px)';
  }
  const getModuleNameCallback = useCallback(() => { return getModuleName(activeModule) }, [activeModule]);


  React.useEffect(() => {
    setLoading(true);
    getProjects()
      .then((projects) => {
        setLoading(false);
        setData(projects ? projects.projects.filter(p => p.properties.PROJECT_PW_STAVBA_SKOD) : []);
        setDataProjectInfo(projects ? projects.projectsinfos : []);
      })
  }, []);

  return (
    <Stack sx={{ height: '100vh', overflow: 'hidden' }}>
      <Box sx={{ position: 'fixed', top: 0, width: '100%', maxHeight: '40px' }}>
        <ProjectWiseUpperMenu drawerOpen={menuOpen} onOpenDrawer={() => setMenuOpen(p => !p)} />
      </Box>
      <Stack direction={'row'} sx={{ flex: 1, marginTop: leftMenuVisible ? '40px' : '100px', marginLeft: '20px', overflow: 'hidden' }}>
        {leftMenuVisible && <ProjectWiseSideMenu selectedModule={activeModule} onModuleChange={(m) => { setActiveModule(m); }} isDrawerOpen={menuOpen} setDrawerOpen={setMenuOpen} />}
          <Stack mb={4} pr={8} sx={{ overflow: 'auto', maxHeight: getHeightMainPanel(), minHeight: getHeightMainPanel(), maxWidth: getWidthMainPanel(), minWidth: getWidthMainPanel() }}>
            <Box display='flex' alignItems='center' sx={{ height: '40px' }} width='100%'>
              <Typography textAlign={'left'} variant='h4' sx={{ color: '#0E457D', marginLeft: '36px', marginTop: '12px' }}>{getModuleNameCallback()}</Typography>
              <IconButton sx={{ mt: 2, ml: 2 }} onClick={() => { setPrintingPdf(true); delay(500).then(() => { setPrintingPdf(false) }) }}><PictureAsPdf /></IconButton>
              {activeModule === ProjectWiseModules.Tasks && (
                <Stack sx={{ mt: 2, ml: 'auto', mr: '30px' }} direction="row" spacing={1} alignItems="center" justifyContent="flex-end">
                  <Typography textAlign={'left'} variant='h6' sx={{ color: !isOnlyUser ? '#0E457D' : '#A9A9A9' , marginTop: '12px' }}>Vše</Typography>
                  <Switch size='small' checked={isOnlyUser} onChange={(event) => { setIsOnlyUser(event.target.checked) }} inputProps={{ 'aria-label': 'ant design' }} />
                  <Typography textAlign={'left'} variant='h6' sx={{ color: isOnlyUser ? '#0E457D' : '#A9A9A9', marginLeft: '36px', marginTop: '12px' }}>Pouze pro uživatele</Typography>
                </Stack>
              )}
            </Box>
            {activeModule === ProjectWiseModules.Projects && <ProjectWiseProjectScene print={printingPdf} data={data} loading={loading} projectInfoData={dataProjectInfo} />}
            {activeModule === ProjectWiseModules.Tasks && <ProjectWiseDashboardScene isOnlyUser={isOnlyUser} print={printingPdf} projectData={data} projectInfoData={dataProjectInfo} />}
          </Stack>
      </Stack>
    </Stack>
  );
};

export default ProjectWiseLayoutScene;