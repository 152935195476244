import { CircularProgress } from '@mui/material';
import React, { useCallback, useEffect } from 'react'

export default function ToolLogin() {
    const fireMessage = useCallback(async () => {
        if (localStorage.getItem('token') !== null && localStorage.getItem('refresh_token') !== null) {
            //@ts-ignore
            if (window.chrome.webview)
                //@ts-ignore
                window.chrome.webview.postMessage({ MessageType: 'login', AccessToken: localStorage.getItem('token')!, RefreshToken: localStorage.getItem('refresh_token')! });
        }
        else {
            setTimeout(() => {
                fireMessage();
            }, 1000);
        }
    },[]);

    useEffect(() => {
        fireMessage();
    }, [fireMessage]);

    return (
        <div>
            <div>
                <CircularProgress />
            </div>
        </div>
    );
}
