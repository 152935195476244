import { ProjectWiseProjectModel } from '../Models/ProjectWiseProjectModel';
import { ProjectWiseProjectInfoModel } from '../Models/ProjectWiseProjectInfoModel';

export const getProjects = async () => {
    try {
        // setLoading(true); // Start the loading spinner
        const response = await fetch('https://pwproxy.azurewebsites.net/aggregate'); // Replace with your URL

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);            }

        const jsonData = await response.json(); // Assuming the data is an array of ProjectWiseProjectModel arrays
        const flattenedData = jsonData.flat(); // Flatten the array of arrays into a single array
        
        return ({projects: [...flattenedData[0].instances] as ProjectWiseProjectModel[], projectsinfos: [...flattenedData[1].instances] as ProjectWiseProjectInfoModel[] }); // Update the state with the fetched data
        // return ([...flattenedData[0].instances, ...flattenedData[1].instances] as ProjectWiseProjectModel[]); // Update the state with the fetched data
    } catch (err: any) {
        // setError(err.message);
    } finally {
        // setLoading(false); // Stop the loading spinner
    }
};