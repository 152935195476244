import { Alert, AlertTitle, Button, Dialog, DialogContent, Slide, Snackbar, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { TenantDTO } from '../Api/ApiServer';
import MuiDialogHeader from '../Shared/MuiDialogHeader';
import { APIUserClient } from '../Api/ApiUserClient';

interface CreateTenantDialogProps {
    isOpen: boolean;
    onClose: (refresh: boolean, id?: number) => void;
    tenant?: TenantDTO;
}
export default function CreateTenantDialog(props: CreateTenantDialogProps) {
    const{isOpen, onClose, tenant} = props;
    const [ buttonDisabled, setButtonDisabled] = useState(false);
    const [ refresh, setRefresh] = useState(false);
    const [ newName, setNewName] = useState('');
    const [ openCreateError, setOpenCreateError] = useState(false);

    useEffect(() => {
        if (tenant && tenant.name)
            setNewName(tenant.name);
        else
            setNewName('');

        setButtonDisabled(false);
        setRefresh(false);
    }, []);
    
    useEffect(() => {
        if (tenant && tenant.name)
            setNewName(tenant.name);
        else
            setNewName('');
    }, [tenant]);

    function TransitionLeft(props: any) {
        return <Slide {...props} direction='left' />;
    }

    const handleClose = (event: any) => {
        setOpenCreateError(false);
    };
    
    return (
        <Dialog onClose={() => onClose(refresh)} open={isOpen}>
            {tenant && <MuiDialogHeader showCloseText title='ZMĚNA NÁZVU TENANTU' onClose={() => onClose(refresh)}/>}
            {!tenant && <MuiDialogHeader showCloseText title='PŘIDÁNÍ TENANTU' onClose={() => onClose(refresh)}/>}
            <DialogContent>
                <div>
                    <div>
                        <TextField
                            sx={{ margin: '12px', marginLeft: '24px', width: '350px' }}
                            label={'Jméno Tenantu'}
                            size='small'
                            variant='outlined'
                            value={newName}
                            onChange={(e) => { setNewName(e.target.value); }} />
                    </div>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
                        <Button variant='contained' sx={{ margin: 'auto' }} disabled={!newName || buttonDisabled} onClick={() => {
                            setButtonDisabled(true);
                            if (tenant && tenant.id){
                                new APIUserClient().RenameTenant(tenant.id, newName).then((e) => {
                                    if (e.ok) {
                                        setRefresh(true);
                                        onClose(true, tenant.id);
                                    }
                                    else {
                                        setOpenCreateError(true);
                                    }
                                    setButtonDisabled(false);
                                });
                            }
                            else {
                                new APIUserClient().CreateTenant(newName).then((e) => {
                                    if (e.ok) {
                                        setRefresh(true);
                                        onClose(true, e.tenantId);
                                    }
                                    else {
                                        setOpenCreateError(true);
                                    }
                                    setButtonDisabled(false);
                                });
                            }
                        }}>ULOŽIT</Button>
                    </div>
                    
                    <div className="create-form-popup">
                        <Snackbar
                            TransitionComponent={TransitionLeft}
                            open={openCreateError}
                            autoHideDuration={3000}
                            onClose={handleClose}
                            anchorOrigin={{ vertical: "top", horizontal: "right" }} >
                            <Alert onClose={handleClose} severity="warning">
                                <AlertTitle>{'Chyba zakládání'}</AlertTitle>
                                {'Tenant již existuje'}
                            </Alert>
                        </Snackbar>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}
