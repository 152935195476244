import { useMsal } from '@azure/msal-react';
import { Button, CircularProgress, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { APIUserClient } from '../Api/ApiUserClient';
import { GetSignupUserDataResponse } from '../Api/ApiServer';

interface UserResetPasswordProps {
}
export default function UserResetPasswordScene(props: UserResetPasswordProps) {
    const [ buttonDisabled, setButtonDisabled] = useState(false);
    const [token, setToken] = useState<string>();
    const [ userData, setUserData] = useState<GetSignupUserDataResponse>();
    
    const [newPass, setNewPass] = useState<string>('');
    const [newPassCheck, setNewPassCheck] = useState<string>('');

    const location = useLocation();
    const { instance } = useMsal();
    const navigate = useNavigate();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        let tok = queryParams.get('token');
        setToken(tok !== null ? tok : undefined);
      }, [location.search]);

    useEffect(() => {
        if (token) {
            new APIUserClient().GetResetPasswordUserData(token).then((res) => {
                setUserData(res);
            });
        }
        else setUserData(undefined);
    }, [token]);

    function changePassword()
    {
        if (newPass.length !== 0 && newPassCheck.length !== 0 && newPass === newPassCheck && userData && userData.userId && token)
        {
            setButtonDisabled(true);
            new APIUserClient().SetUserPassword(userData.userId, newPass, token).then((d) => {   
                setButtonDisabled(false);                
                if (d.ok === true)
                    navigate('/?email=' + d.email);
            }); 
        }  
    }
    
    if (userData)
        return (
            <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <div>
                    <div>
                        <div>
                            <div>
                                <TextField sx={{ margin: '12px', marginLeft: '24px', width: '350px' }} label={'Nové heslo'}
                                    type={"password"}
                                    size='small'
                                    name='new-pass'
                                    variant='outlined'
                                    value={newPass}
                                    onChange={(e) => { setNewPass(e.currentTarget.value); }} />
                            </div>
                            <div>
                                <TextField sx={{ margin: '12px', marginLeft: '24px', width: '350px' }} label={'Nové heslo znovu'}
                                    type={"password"}
                                    size='small'
                                    name='new-pass-check'
                                    variant='outlined'
                                    value={newPassCheck}
                                    onChange={(e) => { setNewPassCheck(e.currentTarget.value); }} />
                            </div>
                        </div>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
                            <Button variant='contained' sx={{ margin: 'auto' }} onClick={changePassword}
                            disabled={newPass.length === 0 || newPassCheck.length === 0 || newPass !== newPassCheck || !userData || !userData.userId || !token || buttonDisabled}
                            >ZMĚNIT HESLO</Button>
                        </div>
                    </div>
                </div>            
            </div>
        )
    else 
        return <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}><CircularProgress/></div>
}
