import { Button, IconButton, TextField } from '@mui/material'
import React, { useState } from 'react'
import { Oblouk } from './Models/ObloukModelAsociative';
import SolverField from './SolverField';
import { Close, Info } from '@mui/icons-material';
import { GetColorForState } from './Helpers/GetColorForStateHelper';
import DetailGadget from '../Shared/DetailGadget';
interface CurveSolverMainDetailProps {
    obloukInput: Oblouk;
    previousObloukInput: Oblouk | null;
    nextObloukInput: Oblouk | null;
    onChangedDetail: (changed: Oblouk, nextObloukInput?: Oblouk) => void;
    closeDetail: () => void;
}
export default function CurveSolverMainDetail(props: CurveSolverMainDetailProps) {

    const { obloukInput, onChangedDetail, previousObloukInput, nextObloukInput } = props;
    const [showDetail, setShowDetail] = useState<boolean>(false);
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { name, value } = event.target;

        // setobloukInput((prevState) => {

        // const newState = new Oblouk(0, -2);
        // newState.copyFrom(obloukInput);
        // newState.getItem(name)!.value = value;
        // let newOblouk = new Oblouk(0, -3);
        // newOblouk.copyFrom(newState as Oblouk);
        // newOblouk.calculate(previousObloukInput, nextObloukInput);
        // onChangedDetail(newOblouk);




        const newState = new Oblouk(0, -14);
        newState.copyFrom(obloukInput);
        newState.getItem(name)!.value = value;
        let newOblouk = new Oblouk(0, -15);
        newOblouk.copyFrom(newState as Oblouk);
        newOblouk.hasMezilehla = obloukInput.hasMezilehla
        // newOblouk.horniOblouk = previousObloukInput;
        newOblouk.calculate(previousObloukInput, nextObloukInput);
        console.log(newOblouk);
        if (nextObloukInput)
            onChangedDetail(newOblouk, nextObloukInput);
        else onChangedDetail(newOblouk);
        // return newOblouk;
        // return newOblouk;
        // });
    };
    return (
        <div style={{ overflow: 'auto' }}>
            <div style={{ width: '100%', height: '32px', display: 'flex', justifyContent: 'space-between' }}>
                <IconButton onClick={() => setShowDetail(!showDetail)}><Info style={{ width: '14px', height: '14px' }} /></IconButton>
                <div >
                    <Button onClick={props.closeDetail} endIcon={<Close />}>
                        Zavřít
                    </Button>
                </div>
            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <DetailGadget title='R & D'>
                    <TextField
                        label='ID'
                        variant='filled'
                        style={{ width: '80px', margin: '4px' }}
                        sx={{ '& .MuiInputBase-root': { paddingLeft: '8px', height: '40px' }, '& .MuiInputLabel-root': { color: '#404040', top: '-4px', left: '-4px' } }}
                        value={obloukInput.getId()}
                        InputProps={{
                            inputProps: {
                                style: { textAlign: "right", fontWeight: 'normal', color: '#404040' },
                            }
                        }}
                    />

                    <SolverField name='R' label='R' model={obloukInput} handleChange={handleChange} />
                    {/* <SolverField name='Dstav' model={obloukInput} handleChange={handleChange} /> */}
                    <SolverField name='D' label='D' model={obloukInput} handleChange={handleChange} />
                    <SolverField name='DN' label='D<sub>N</sub>' model={obloukInput} handleChange={handleChange} />
                </DetailGadget>
                <DetailGadget title='V'>
                    <SolverField name='V' label='V' model={obloukInput} handleChange={handleChange} />
                    <SolverField name='V130' label='V<sub>130</sub>' model={obloukInput} handleChange={handleChange} />
                    <SolverField name='V150' label='V<sub>150</sub>' model={obloukInput} handleChange={handleChange} />
                    <SolverField name='Vk' label='V<sub>k</sub>' model={obloukInput} handleChange={handleChange} />
                </DetailGadget>
                {/*zatím není VPOM */}
                <DetailGadget title='I/E'>
                    <SolverField name='I' label='I' model={obloukInput} handleChange={handleChange} />
                    <SolverField name='I130' label='I<sub>130</sub>' model={obloukInput} handleChange={handleChange} />
                    <SolverField name='I150' label='I<sub>150</sub>' model={obloukInput} handleChange={handleChange} />
                    <SolverField name='Ik' label='I<sub>k</sub>' model={obloukInput} handleChange={handleChange} />
                </DetailGadget>
                {/*zatím není EPOM */}
                {/*zatím není Lk1Stav */}
                <DetailGadget title='L & I (V)'>
                    <SolverField name='Lk1' label='L<sub>k,1</sub>' model={obloukInput} handleChange={handleChange} />
                    <SolverField name='n1' label='n<sub>1</sub>' model={obloukInput} handleChange={handleChange} />
                    <SolverField name='nI1' label='n<sub>I,1</sub>' model={obloukInput} handleChange={handleChange} />
                    <SolverField name='Lk2' label='L<sub>k,2</sub>' model={obloukInput} handleChange={handleChange} />
                    <SolverField name='n2' label='n<sub>2</sub>' model={obloukInput} handleChange={handleChange} />
                    <SolverField name='nI2' label='n<sub>I,2</sub>' model={obloukInput} handleChange={handleChange} />
                </DetailGadget>
                <DetailGadget title='L & I (V<sub>130</sub>)'>
                    <SolverField name='Lk1130' label='L<sub>k,1,130</sub>' model={obloukInput} handleChange={handleChange} />
                    <SolverField name='n1130' label='n<sub>1,130</sub>' model={obloukInput} handleChange={handleChange} />
                    <SolverField name='nI1130' label='n<sub>I,1,130</sub>' model={obloukInput} handleChange={handleChange} />
                    <SolverField name='Lk2130' label='L<sub>k,2,130</sub>' model={obloukInput} handleChange={handleChange} />
                    <SolverField name='n2130' label='n<sub>2,130</sub>' model={obloukInput} handleChange={handleChange} />
                    <SolverField name='nI2130' label='n<sub>I,2,130</sub>' model={obloukInput} handleChange={handleChange} />
                </DetailGadget>
                <DetailGadget title='L & I (V<sub>150</sub>)'>
                    <SolverField name='Lk1150' label='L<sub>k,1,150<sub>' model={obloukInput} handleChange={handleChange} />
                    <SolverField name='n1150' label='n<sub>1,150</sub>' model={obloukInput} handleChange={handleChange} />
                    <SolverField name='nI1150' label='n<sub>I,1,150</sub>' model={obloukInput} handleChange={handleChange} />
                    <SolverField name='Lk2150' label='L<sub>k,2,150</sub>' model={obloukInput} handleChange={handleChange} />
                    <SolverField name='n2150' label='n<sub>2,150</sub>' model={obloukInput} handleChange={handleChange} />
                    <SolverField name='nI2150' label='n<sub>I,2,150<sub>' model={obloukInput} handleChange={handleChange} />
                </DetailGadget>
                <DetailGadget title='L & I (V<sub>k</sub>)'>
                    <SolverField name='Lk1k' label='L<sub>k,1,k</sub>' model={obloukInput} handleChange={handleChange} />
                    <SolverField name='n1k' label='n<sub>1,k</sub>' model={obloukInput} handleChange={handleChange} />

                    <SolverField name='Lk2k' label='L<sub>k,2,k<sub>' model={obloukInput} handleChange={handleChange} />
                    <SolverField name='n2k' label='n<sub>2,k</sub>' model={obloukInput} handleChange={handleChange} />
                </DetailGadget>
            </div>


            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {showDetail && obloukInput.getAllItemsAsString().map((item, i) => {
                    if (item !== null && item !== undefined)
                        return <div key={'out' + i} style={{ width: '70px ' }}>
                            <div>{item.key}: {item.value}</div>

                            <div style={{ width: '30px', height: '30px', background: GetColorForState(item.state) }}></div>
                        </div>
                })}
            </div>
        </div>
    )
}
