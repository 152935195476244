import { Button, useTheme } from '@mui/material'
import { Error, Warning } from '@mui/icons-material';
import React from 'react'

interface TiltValidatorInteractiveNumberMenuProps {
    totalErrors: number,
    totalWarnings: number,
    leftErrors: number,
    leftWarnings: number,
    rightErrors: number,
    rightWarnings: number,
    isColorized: boolean,
    errorsClicked: (total: boolean, left: boolean, right: boolean) => void,
    warningsClicked: (total: boolean, left: boolean, right: boolean) => void,
    unColorizeClicked: () => void
}

export default function TiltValidatorInteractiveNumberMenu(props: TiltValidatorInteractiveNumberMenuProps) {
    const { totalErrors, totalWarnings, leftErrors, leftWarnings, rightErrors, rightWarnings, isColorized, errorsClicked, warningsClicked, unColorizeClicked } = props;
    const theme = useTheme();
    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '4px' }}>
            <div style={{ display: 'flex', flexDirection: 'column', cursor: 'pointer' }}>
                <div className='error-div' style={{ display: 'flex', marginLeft: '10px', alignItems: 'center', fontSize: '14px', fontWeight: 'bold' }}>
                    <div style={{ color: '#777777' }}>CELKEM:</div>
                    <div className='number-forefront' onClick={() => { errorsClicked(true, false, false) }} style={{ marginLeft: '8px', alignItems:'center', display: 'flex', color: theme.palette.error.main }}><div style={{ marginTop: '4px', marginRight: '2px' }}><Error color='error' style={{ width: '16px', height: '16px' }} /></div>{totalErrors} chyb</div>
                    <div className='number-forefront' onClick={() => { warningsClicked(true, false, false) }} style={{ marginLeft: '20px', alignItems:'center', display: 'flex', color: theme.palette.warning.main }}><div style={{ marginTop: '4px', marginRight: '2px' }}><Warning color='warning' style={{ width: '16px', height: '16px' }} /></div>{totalWarnings} upozornění</div>
                    {isColorized && <Button size='small' sx={{marginLeft:'12px'}} onClick={()=>{unColorizeClicked()}} variant='contained'>ODBARVIT</Button>}
                </div>
                <div style={{ marginLeft: '20px', marginTop: '4px', marginBottom: '-8px', zIndex: 10000, fontSize: '18px', fontWeight: 'bold', alignSelf:'start' }}>Validace navržených sklonů</div>
            </div>
            <div className='error-div' style={{ display: 'flex', marginTop: '20px', alignItems: 'end', marginBottom: '-8px', zIndex: 10000, fontWeight: 'bold', fontSize: '14px', cursor: 'pointer', borderRadius: '10px' }}>
                <div style={{ color: '#404040', fontSize: '18px' }}>LEVÁ</div>
                <div className='number-forefront' onClick={() => { errorsClicked(false, true, false) }} style={{ marginLeft: '8px', display: 'flex', color: theme.palette.error.main }}><div style={{ marginTop: '2px', marginRight: '2px' }}><Error color='error' style={{ width: '16px', height: '16px' }} /></div>{leftErrors} chyb</div>
                <div className='number-forefront' onClick={() => { warningsClicked(false, true, false) }} style={{ marginLeft: '20px', display: 'flex', color: theme.palette.warning.main }}><div style={{ marginTop: '2px', marginRight: '2px' }}><Warning color='warning' style={{ width: '16px', height: '16px' }} /></div>{leftWarnings} upozornění</div>
            </div>
            <div className='error-div' style={{ display: 'flex', marginTop: '20px', alignItems: 'end', marginBottom: '-8px', zIndex: 10000, fontWeight: 'bold', marginRight: '20px', fontSize: '14px', cursor: 'pointer', borderRadius: '10px' }}>
                <div style={{ color: '#404040', fontSize: '18px' }}>PRAVÁ</div>
                <div className='number-forefront' onClick={() => { errorsClicked(false, false, true) }} style={{ marginLeft: '8px', display: 'flex', color: theme.palette.error.main }}><div style={{ marginTop: '2px', marginRight: '2px' }}><Error color='error' style={{ width: '16px', height: '16px' }} /></div>{rightErrors} chyb</div>
                <div className='number-forefront' onClick={() => { warningsClicked(false, false, true) }} style={{ marginLeft: '20px', display: 'flex', color: theme.palette.warning.main }}><div style={{ marginTop: '2px', marginRight: '2px' }}><Warning color='warning' style={{ width: '16px', height: '16px' }} /></div>{rightWarnings} upozornění</div>
            </div>
            {/* <div>Total chyby {totalErrors}</div>
    <div style={{marginLeft:'20px'}}>Total varování {totalWarnings}</div>
    <div>levé chyby {leftErrors}</div>
    <div>levé warning {leftWarnings}</div>
    <div>pravé chyby {rightErrors}</div>
    <div>pravé warning {rightWarnings}</div> */}
        </div>
    )
}
