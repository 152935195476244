import { ObloukItemState } from "../Enums/ObloukItemState";

export function GetColorForState(state: ObloukItemState) {
    switch (state) {
        case ObloukItemState.OkInput:
            return '#b3ea8f';//'#58D34E';//'#5CBF54';
            /* return '#4CB944'; */
        case ObloukItemState.OkReadOnly:
            return '#c2eda5';//'#A7f000';
        case ObloukItemState.Error:
            return '#D16666';//'#E46550';
        case ObloukItemState.Oranzove:
            return '#ffdb69';//'#FFB20F'; //
        case ObloukItemState.Zlute:
            return '#fefb8b';//'#FFD270';
        case ObloukItemState.OranzoveSrafovane:
            //console.log('OranzoveSrafovane');
            return 'repeating-linear-gradient(45deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1) 5px, #FFB20F 5px, #FFB20F 10px)';
        case ObloukItemState.ZeleneSeVzorkem:
            //console.log('ZeleneSeVzorkem');
            return 'repeating-linear-gradient(45deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1) 5px, #4CB944 5px, #4CB944 10px)';
        default:
           /*  console.log(state);
            console.log('default'); */
            return 'repeating-linear-gradient(45deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1) 5px, transparent 5px, transparent 10px)';
        // return '#linear-gradient(45deg, #000 25%, transparent 25%, transparent 50%, #000 50%, #000 75%, transparent 75%, #fff);';

    }
}