import React, { useEffect, useRef, useState } from 'react'
import { Oblouk } from '../Models/ObloukModelAsociative';
import CurveSolverDetailScene from '../CurveSolverDetailScene';
import { Button } from '@mui/material';
import CurveSolveTableCell from './CurveSolverTableCell';
import CurveSolverTableRow from './CurveSolverTableRow';
import CurveSolverTableHeader from './CurveSolverTableHeader';
import DeleteDialog from '../../Shared/DeleteDialog';
interface CurveSolverTableProps {
    deleteOutside: boolean;
    addOutside: boolean | null;
    addMezilehlaOutside: boolean | null;
    deleteMezilehlaOutside: boolean | null;
    copyOutside: boolean | null;
    deletedOutside: () => void;
    // addedOutside: () => void;
    selected: (selected: Oblouk | null, canAddMezilehla: boolean, canDeleteMezilehla: boolean, previousObloukInput: Oblouk | null, nextObloukInput: Oblouk | null) => void;
    inputHeight?: string;
    onChanged: (changed: Oblouk) => void;
    inputObloukChanged: Oblouk | null;
    onObloukAdded: (canAddMezilehla: boolean) => void;
    onObloukyListChanged: (oblouky: Oblouk[]) => void;
    obloukyLoad: Oblouk[] | undefined;
    onRowDelete: (id: number) => void;
}

export default function CurveSolverTable(props: CurveSolverTableProps) {
    const { deleteOutside, addOutside, deletedOutside, selected, inputHeight, onChanged, inputObloukChanged, copyOutside, onObloukAdded, addMezilehlaOutside, deleteMezilehlaOutside, onObloukyListChanged, obloukyLoad, onRowDelete } = props;
    useEffect(() => {
        if (deleteOutside) {
            onRemoveLine(selectedRowId);
            deletedOutside();
        }
    }, [deleteOutside]);
    useEffect(() => {
        if (addOutside !== null)
            addObloukToEnd();
        // addedOutside();
        // }
    }, [addOutside]);
    useEffect(() => {
        if (deleteMezilehlaOutside !== null && deleteMezilehlaOutside === true)
            setDeletingSelectedMezilehla(true);
        // addedOutside();
        // }
    }, [deleteMezilehlaOutside]);
    useEffect(() => {
        if (addMezilehlaOutside !== null)
            addMezilehla();
        // addedOutside();
        // }
    }, [addMezilehlaOutside]);
    useEffect(() => {
        if (copyOutside !== null && selectedRowId !== 0)
            onCopyRow(selectedRowId);
        // addedOutside();
        // }
    }, [copyOutside]);

    useEffect(() => {
        setOblouky(obloukyLoad ? obloukyLoad : []);
        // TODO tady nějak refreshovat řádky

    }, [obloukyLoad]);
    useEffect(() => {
        if (inputObloukChanged !== null) {
            if (selectedRowId === inputObloukChanged.getId()) {
                let q = [...oblouky];
                q.splice(inputObloukChanged.getId() - 1, 1, inputObloukChanged);
                calculateWithMezilehla(q, inputObloukChanged, (inputObloukChanged.getId() + 1));
                setOblouky(q);
                onObloukyListChanged(q);








                // let alreadyChangedOblouk = changedOblouky.current.find(ob => ob.getId() === inputObloukChanged?.getId());
                // if (alreadyChangedOblouk !== undefined) {
                //     alreadyChangedOblouk.copyFrom(inputObloukChanged);
                //     calculateWithMezilehla(q, alreadyChangedOblouk);
                //     // alreadyChangedOblouk.calculate();
                // }
                // else {
                //     changedOblouky.current.push(inputObloukChanged);

                // }

                // if (inputObloukChanged.getId() > 1)
                //     calculateWithMezilehla(q, q[inputObloukChanged.getId() - 1], inputObloukChanged.getId() - 1);
                // else calculateWithMezilehla(q, q[inputObloukChanged.getId() - 1]);
                // // .calculate();
                // setOblouky(q);
                // onObloukyListChanged(q);
            }

        }


    }, [inputObloukChanged]);

    const [oblouky, setOblouky] = React.useState<Oblouk[]>([]);
    const [deletingSelectedMezilehla, setDeletingSelectedMezilehla] = React.useState<boolean>(false);
    const [selectedRowId, setSelectedRowId] = React.useState<number>(0);
    const canAddMezilehla = (fromAdd?: boolean): boolean => {
        if (selectedRowId > 1 && (selectedRowId <= oblouky.length || fromAdd === true)) {
            let obs = getObloukyWithChanged();
            let ob = obs.find(ob => ob.getId() === selectedRowId)!;
            if (ob && ob.hasMezilehla) return false;
            // && selectedRowId < oblouky.length
            return true;
        }
        return false;
    }
    const canDeleteMezilehla = () => {
        let obs = getObloukyWithChanged();
        let ob = obs.find(ob => ob.getId() === selectedRowId)!;
        if (ob)
            return ob.hasMezilehla;
        else return false;
    }
    const selectEvent = () => {
        let obs = getObloukyWithChanged();
        let previous = selectedRowId !== 0 && selectedRowId < obs.length ? (obs.find(ob => ob.getId() === selectedRowId - 1)!) : null;
        let next = selectedRowId < obs.length - 1 ? obs.find(ob => ob.getId() === selectedRowId + 1)! : null;
        selected(selectedRowId !== 0 ? (getObloukyWithChanged().find(ob => ob.getId() === selectedRowId)!) : null, canAddMezilehla(), canDeleteMezilehla(), previous, next);
    }
    useEffect(() => {
        selectEvent();
    }, [selectedRowId]);
    const getNextPrevOblouky = (obs: Oblouk[], index: number) => {
        let previousOblouk = obs.find(ob => ob.getId() === index - 1);
        let nextOblouk = obs.find(ob => ob.getId() === index + 1);
        return ({ previousOblouk: previousOblouk ? previousOblouk : null, nextOblouk: nextOblouk ? nextOblouk : null });
    }
    const addMezilehla = () => {
        if (selectedRowId > 1 && selectedRowId <= oblouky.length) {
            let obs = getObloukyWithChanged();
            let ob = obs.find(ob => ob.getId() === selectedRowId)!;
            ob.hasMezilehla = true;
            let prevNext = getNextPrevOblouky(obs, selectedRowId);
            ob.calculate(prevNext.previousOblouk, prevNext.nextOblouk);
            // recalculateIds(obs);
            setOblouky(obs);
            onObloukyListChanged(obs);
            changedOblouky.current = [];
            onObloukAdded(false);
        }

    }
    const deleteMezilehla = () => {
        // if (selectedRowId > 1 && selectedRowId <= oblouky.length) {
        let obs = getObloukyWithChanged();
        let ob = obs.find(ob => ob.getId() === selectedRowId)!;
        if (ob.hasMezilehla) {
            ob.hasMezilehla = false;
            let prevNext = getNextPrevOblouky(obs, selectedRowId);
            ob.calculate(prevNext.previousOblouk, prevNext.nextOblouk);
            // recalculateIds(obs);
            setOblouky(obs);
            onObloukyListChanged(obs);
            changedOblouky.current = [];
            onObloukAdded(true);
        }
        // }

    }
    const onAddLineAfter = (id: number) => {
        let obsNew = getObloukyWithChanged();
        let obs: Oblouk[] = [];
        for (let i = 0; i < obsNew.length; i++) {
            if (i === id) {
                obs.push(new Oblouk(i + 2, -8));
            }
            obs.push(obsNew[i]);
        }
        recalculateIds(obs);
        setOblouky([...obs]);
        onObloukyListChanged([...obs]);
        changedOblouky.current = [];
        onObloukAdded(canAddMezilehla());
    }
    const onAddLineBefore = (id: number) => {
        let obsNew = getObloukyWithChanged();
        let obs: Oblouk[] = [];
        for (let i = 0; i < obsNew.length; i++) {
            if ((i + 1) === id) {
                obs.push(new Oblouk(i + 1, -9));
            }
            obs.push(obsNew[i]);
        }
        recalculateIds(obs);
        setOblouky([...obs]);
        onObloukyListChanged([...obs]);
        changedOblouky.current = [];
        onObloukAdded(canAddMezilehla());
    }
    const recalculateIds = (oblouky: Oblouk[]) => {
        for (let i = 0; i < oblouky.length; i++) {
            oblouky[i].setId(i + 1);
        }
    }
    const addObloukToEnd = () => {
        // setOblouky(prevOblouky => [
        //     ...prevOblouky,
        //     createNewOblouk(prevOblouky.length + 1)
        // ]);
        onObloukyListChanged([...oblouky, new Oblouk(oblouky.length + 1, -10)]);
        setOblouky(prevOblouky => [
            ...prevOblouky,
            new Oblouk(prevOblouky.length + 1, -11)
        ]);
        onObloukAdded(canAddMezilehla(true));
    };
    // const createNewOblouk = React.useMemo(() => {
    //     return (id: number) => new Oblouk(id);
    // }, []);
    const onRemoveLine = (id: number) => {
        let oblNew = getObloukyWithChanged();
        setSelectedRowId(0);
        let obs: Oblouk[] = [];
        for (let i = 0; i < oblNew.length; i++) {
            if (oblNew[i].getId() !== id) {
                if (oblNew[i].getId() >= id)
                    oblNew[i].setId(oblouky[i].getId() - 1);
                obs.push(oblNew[i]);
            }
        }
        setOblouky(obs);
        onObloukyListChanged(obs);
        changedOblouky.current = [];
        onObloukAdded(false);
        if (oblNew.find(p => p.getId() === id)?.getDbId()! > 0)
            onRowDelete(oblNew.find(p => p.getId() === id)?.getDbId()!);
    }
    const changedOblouky = useRef<Oblouk[]>([]);
    const lastChangedOblouk = useRef<Oblouk | null>(null);
    const onObloukChanged = (oblouk: Oblouk, nextInputOblouk?: Oblouk) => {
        let currId = oblouk.getId();
        var obloukHasChanged = changedOblouky.current.find(ob => ob.getId() === currId);
        if (obloukHasChanged === undefined) {
            changedOblouky.current.push(oblouk);
            lastChangedOblouk.current = oblouk;
        }
        else {
            obloukHasChanged.copyFrom(oblouk);
            lastChangedOblouk.current = obloukHasChanged;
        }
        let nextObloukHasChanged = changedOblouky.current.find(ob => ob.getId() === currId + 1);
        var nextOblouk = getObloukyWithChanged().find(ob => ob.getId() === currId + 1);
        if (nextOblouk) {
            if (nextObloukHasChanged === undefined) {
                changedOblouky.current.push(nextOblouk);
            }
            else {
                nextObloukHasChanged.copyFrom(nextOblouk);
                nextObloukHasChanged.calculate(obloukHasChanged ?? null, null);
            }
        }

        let prevObloukHasChanged = changedOblouky.current.find(ob => ob.getId() === currId - 1);
        var prevOblouk = getObloukyWithChanged().find(ob => ob.getId() === currId - 1);
        if (prevOblouk) {
            if (prevObloukHasChanged === undefined) {
                changedOblouky.current.push(prevOblouk);
            }
            else {
                prevObloukHasChanged.copyFrom(prevOblouk);
                prevObloukHasChanged.calculate(obloukHasChanged ?? null, null);
            }
        }


        lostFocus(oblouk.getId());
        // if (nextOblouk)
        //     lostFocus(oblouk.getId() + 1);
        // if (prevOblouk)
        //     lostFocus(oblouk.getId() - 1);
    }
    const getObloukyWithChanged = () => {
        let obs: Oblouk[] = [];
        for (let i = 0; i < oblouky.length; i++) {
            let obloukHasChanged = changedOblouky.current.find(ob => ob.getId() === oblouky[i].getId());
            if (obloukHasChanged === undefined) {
                obs.push(oblouky[i]);
            }
            else {
                obs.push(obloukHasChanged);
            }
        }
        return obs;
    }
    const moveUp = (id: number) => {
        let obs = moveUpOblouky(id, [...getObloukyWithChanged()]);
        recalculateIds(obs);
        setOblouky(obs);
        onObloukyListChanged(obs);
        changedOblouky.current = [];
        onObloukAdded(canAddMezilehla());
    }
    const moveUpOblouky = (id: number, obs: Oblouk[]) => {

        const index = obs.findIndex((item) => item.getId() === id);

        if (index <= 0) {
            // Element is already at the top or not found, no change needed
            return obs;
        }

        // Swap the elements
        const temp = obs[index];
        obs[index] = obs[index - 1];
        obs[index - 1] = temp;
        let prev: Oblouk | null = null;
        let next: Oblouk | null = null;
        if (index - 2 > -1)
            prev = obs[index - 2];
        if (index < obs.length)
            next = obs[index];
        obs[index - 1].calculate(prev, next);
        return obs;
    }
    const moveDown = (id: number) => {
        let obs = moveDownOblouky(id, [...getObloukyWithChanged()]);
        recalculateIds(obs);
        setOblouky(obs);
        onObloukyListChanged(obs);
        changedOblouky.current = [];
        onObloukAdded(canAddMezilehla());
    }
    const moveDownOblouky = (id: number, obs: Oblouk[]) => {
        const index = obs.findIndex((item) => item.getId() === id);

        if (index === -1 || index === obs.length - 1) {
            // Element is already at the bottom or not found, no change needed
            return obs;
        }

        // Swap the elements
        const temp = obs[index];
        obs[index] = obs[index + 1];
        obs[index + 1] = temp;
        let prev: Oblouk | null = null;
        let next: Oblouk | null = null;
        if (index < obs.length)
            prev = obs[index];
        if (index + 2 < obs.length)
            next = obs[index + 2];
        // calculateWithMezilehla(temp,  )
        obs[index + 1].calculate(prev, next);
        return obs;
    }
    const onCopyRow = (id: number) => {
        let changedObs = getObloukyWithChanged();
        let index = changedObs.findIndex(ob => ob.getId() === id);

        let newOb = new Oblouk(oblouky.length + 1, -12);
        newOb.copyFrom(changedObs[index]);
        newOb.setId(oblouky.length + 1);
        newOb.calculate(null, null);

        // Insert the new row behind the original row
        let updatedOblouky = [...oblouky];
        updatedOblouky.splice(index + 1, 0, newOb);
        recalculateIds(updatedOblouky);
        setOblouky(updatedOblouky);
        onObloukyListChanged(updatedOblouky);
        onObloukAdded(canAddMezilehla());
    }
    const lostFocus = (id: number) => {
        let obs = getObloukyWithChanged();
        let ob = obs.find(ob => ob.getId() === id)!;
        // if (ob === undefined) {
        //     ob = oblouky.find(ob => ob.getId() === id)!;
        // }
        calculateWithMezilehla(obs, ob, id + 1);
        // ob.calculate();
        // if (selectedRowId !== 0 && ob.getId() === selectedRowId) {
        //onChanged(ob);
        onObloukyListChanged(oblouky);
        setOblouky(obs);
        changedOblouky.current = [];
        // }
    }
    const calculateWithMezilehla = (oblouky: Oblouk[], oblouk: Oblouk, nextId?: number) => {
        // if (oblouk.hasMezilehla) {
        // let ob = oblouky.find(ob => ob.getId() === nextId);
        // if (ob)
        if (nextId !== undefined) {
            let prevNext = getNextPrevOblouky(oblouky, nextId - 1);
            if (oblouk.hasMezilehla)
                oblouk.calculate(prevNext.previousOblouk, prevNext.nextOblouk);
            if (prevNext.nextOblouk?.hasMezilehla)
                prevNext.nextOblouk.calculate(oblouk, null);
        }
        else oblouk.calculate(null, null);
        // }
        // else oblouk.calculate(null, null);
    }
    const getPreviousOblouk = (i: number) => {
        let obs = getObloukyWithChanged();
        if (i > 0 && obs.length > 1 && i < obs.length)
            return obs[i - 1];
        else return null;

    }
    const getNextOblouk = (i: number): Oblouk | null => {
        let obs = getObloukyWithChanged();
        if ((i + 1) < obs.length)
            return obs[i + 1];
        else return null;

    }

    const [selectedPosition, setSelectedPosition] = useState<{row: number, column: number}>();
    const [move, setMove] = useState<string>();

    const onCellSelected = (row: number, column: number) => {
        console.log('Cell selected:', row, column);
        setSelectedPosition({row: row, column: column});
    }

    useEffect(() => {
        const handleKeyDown = (e) => {
           if (e.shiftKey){                
               //handleKeyMove(e.key);
               setMove(e.key);
           }
          // Handle keyboard events here
          // e.key contains the pressed key
          // You may want to update focus or perform some action based on the key
        };
      
        document.addEventListener('keydown', handleKeyDown);
      
        return () => {
          document.removeEventListener('keydown', handleKeyDown);
        };
      }, []);

      useEffect(() => {
        if (move && selectedPosition) {
            handleKeyMove(move);
            setMove(undefined);
        }
      }, [move]);

      const columnMap = [0, 1, 3, 4, 5, 6, 11, 13];
      const handleKeyMove = (key: string) => {
        let tempPos = { row: selectedPosition?.row!, column: selectedPosition?.column! };
        
        if (key === 'ArrowDown'){
            if (tempPos.row! < oblouky.length)
                tempPos = {row: selectedPosition?.row! + 1, column: selectedPosition?.column!}
        }
        else if (key === 'ArrowRight'){
            var ind = columnMap.indexOf(tempPos.column!);
            if (ind >= 0 && ind < 7)
                tempPos = { row: selectedPosition?.row!, column: columnMap[ind + 1] }
        }
        else if (key === 'ArrowLeft'){
            var ind = columnMap.indexOf(tempPos.column!);
            if (ind > 0 && ind <= 7)
                tempPos = { row: selectedPosition?.row!, column: columnMap[ind - 1] }
        }
        else if (key === 'ArrowUp'){
            if (tempPos.row! > 0)
                tempPos = {row: selectedPosition?.row! -1, column: selectedPosition?.column!}
        }
        else if (key === 'Delete'){
            if (selectedRowId){
                onRemoveLine(selectedRowId);
                //deletedOutside();
            }
        }        

        if (tempPos.column !== undefined &&tempPos.row !== undefined)
            setSelectedPosition(tempPos);

        let selInput = document.getElementById('row' + tempPos?.row + '_column' + tempPos?.column);
        if (selInput)
            selInput.focus();
      }

    return (
        <div id='parent' style={{ minHeight: inputHeight, maxHeight: inputHeight, overflow: 'auto' }} >
            <DeleteDialog open={deletingSelectedMezilehla} onClose={() => { selectEvent(); setDeletingSelectedMezilehla(false); }} data={null} onDeleteAgree={(data) => {
                // if (deleteMezilehlaOutside !== null)
                deleteMezilehla();
                setDeletingSelectedMezilehla(false);
            }} />
            {/* <Button onClick={addObloukToEnd}>přidat</Button> */}
            <CurveSolverTableHeader />
            <div >{oblouky.map((oblouk, i) => <CurveSolverTableRow
                rowIndex={i}
                nextObloukInput={getNextOblouk(i)}
                previousObloukInput={getPreviousOblouk(i)}
                hasMezilehla={oblouk.hasMezilehla}
                onCellBlur={() => { lostFocus(oblouk.getId()); }}
                // onCellBlur={() => { lostFocus(oblouk.getId()); }}
                key={'row' + i}
                onCopyRow={onCopyRow}
                onMoveDown={moveDown}
                onMoveUp={moveUp}
                onObloukChanged={onObloukChanged}
                onRemoveLine={onRemoveLine}
                onAddLineEnd={addObloukToEnd}
                onAddLineBefore={onAddLineBefore}
                onAddLineAfter={onAddLineAfter}
                onRowSelected={setSelectedRowId}
                isSelected={oblouk !== undefined && selectedRowId !== 0 && oblouk.getId() === selectedRowId}
                isFirst={i === 0}
                isLast={i === oblouky.length - 1}
                obloukInput={oblouk}
                onCellSelected={onCellSelected}
            />)}</div>

        </div >
    )
}
