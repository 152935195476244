import React, { useEffect, useRef } from 'react'
import CurveSolverUpperMenu from './CurveSolverUpperMenu'
import CurveSolverTable from './CurveSolverTable/CurveSolverTable'
import CurveSolverDetailScene from './CurveSolverDetailScene';
import { Oblouk } from './Models/ObloukModelAsociative';
import { useSettingsStore } from './Stores/SettingsStore';
import { Split } from 'ts-react-splitter'
import { useUserInfoStore } from '../Stores/UserInfoStore';
import { UpperMenuType } from '../CurveSolver/Enums/UpperMenuTypes';
import { useNavigate, useParams } from 'react-router';
import { ApiDataClient } from '../Api/ApiDataClient';
import { CurveDTO } from '../Api/ApiServer';
import NavigationPanel, { NavigationItem } from '../Shared/NavigationPanel';
import { Button, useTheme } from '@mui/material';
import { Add, ContentCopy, DeleteOutlined, Help, Save, SettingsOutlined, Upload } from '@mui/icons-material';
import CurveSolverBaseSettingsDialog from './CurveSolverBaseSettingsDialog';
import { usePluginStore } from './Stores/PluginStore';


declare global {
  interface Window {
    reloadDataExternal: (data: any) => void;
    // loginExternal: (accessToken: string, refreshToken: string, expiresAt: Date) => void;
  }
}
export default function CurveSolverMainScene() {
  // const [externalReloaded, setExternalReloaded] = React.useState(false);
  window.reloadDataExternal = reloadDataExternal;
  function reloadDataExternal(data: string) {
    let mappedCurves = JSON.parse(data).map((singleRow, i) => {
      try {
        return new CurveDTO({
          r: singleRow.r,
          d: singleRow.d,
          v: singleRow.v,
          v130: singleRow.v130,
          v150: singleRow.v150,
          vk: singleRow.vk,
          lk1: singleRow.lk1,
          lk2: singleRow.lk2,
          lkm: singleRow.lkm,
          inflexWithNext: singleRow.inflexWithNext,
          inflexWithPrevious: singleRow.inflexWithPrevious,
          hasMezilehla: singleRow.hasMezilehla,
          id: i
        })
      }
      catch
      {

      }

    });
    let tmp: Oblouk[] = [];

    mappedCurves.forEach((vv, i) => {
      if (vv)
        setDatatAfterLoad(i, vv, tmp, mappedCurves);
    });

    setOblouky(tmp);
    setObloukyStore(tmp);
  }
  const [obloukAdd, setObloukAdd] = React.useState<boolean | null>(null);
  const [obloukAddMezilehla, setObloukAddMezilehla] = React.useState<boolean | null>(null);
  const [obloukDeleteMezilehla, setObloukDeleteMezilehla] = React.useState<boolean | null>(null);
  const [obloukCopy, setObloukCopy] = React.useState<boolean | null>(null);
  const [deleteOblouk, setDeleteOblouk] = React.useState<boolean>(false);
  const [canAddMezilehle, setCanAddMezilehla] = React.useState<boolean>(false);
  const [canDeleteMezilehle, setCanDeleteMezilehle] = React.useState<boolean>(false);
  const [outsideDetailChanged, setOutsideDetailChanged] = React.useState<boolean>(false);
  const { initSettings, cantShowDetail, showHideDetail } = useSettingsStore();
  // const [canOpenDetail, setCanOpenDetail] = React.useState<boolean>(true);
  const [selectedOblouk, setSelectedOblouk] = React.useState<Oblouk | null>(null);
  const [previousSelectedOblouk, setPreviousSelectedOblouk] = React.useState<Oblouk | null>(null);
  const [nextSelectedOblouk, setNextSelectedOblouk] = React.useState<Oblouk | null>(null);
  const [splitPercent, setSplitPercent] = React.useState<number>(40);
  const [routeId, setRouteId] = React.useState<number>();
  const [obloukyStore, setObloukyStore] = React.useState<Oblouk[]>();
  const [oblouky, setOblouky] = React.useState<Oblouk[]>();
  const [deletedRows, setDeletedRows] = React.useState<number[]>([]);
  const [navigationItems, setNavigationItems] = React.useState<NavigationItem[]>();

  const [settingsOpen, setSettingsOpen] = React.useState<boolean>(false);

  //const { userId, tenantId, initUserStore } = useUserInfoStore();

  const params = useParams();
  const theme = useTheme();

  useEffect(() => {
    initSettings();

    if (params['id'])
      setRouteId(parseFloat(params['id']));
    else { setRouteId(undefined); }

    // Attach the event listener to the document
    document.addEventListener('keydown', handleKeyDown);

    // // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    reloadData();
  }, [routeId]);

  const closeDetail = () => {
    showHideDetail(false);
  }

  const convertOblouk = (i: number, vv: CurveDTO) => {
    let tmpp = new Oblouk(i + 1, vv.id!);
    tmpp.hasMezilehla = vv.hasMezilehla !== undefined ? vv.hasMezilehla : false;

    tmpp.inflexWithNext = vv.inflexWithNext === true;
    tmpp.inflexWithPrevious = vv.inflexWithPrevious === true;

    let d = tmpp.getItem('D');
    if (d && vv.d) d.setValueByNumericValue(vv.d);

    let dstav = tmpp.getItem('Dstav');
    if (dstav && vv.dstav) dstav.setValueByNumericValue(vv.dstav);

    let lkm = tmpp.getItem('Lkm');
    if (lkm && vv.lkm) lkm.setValueByNumericValue(vv.lkm);

    let r = tmpp.getItem('R');
    if (r && vv.r) r.setValueByNumericValue(vv.r);

    let vvv = tmpp.getItem('V');
    if (vvv && vv.v) vvv.setValueByNumericValue(vv.v);

    let vvv130 = tmpp.getItem('V130');
    if (vvv130 && vv.v130) vvv130.setValueByNumericValue(vv.v130);

    let vvv150 = tmpp.getItem('V150');
    if (vvv150 && vv.v150) vvv150.setValueByNumericValue(vv.v150);

    let vk = tmpp.getItem('Vk');
    if (vk && vv.vk) vk.setValueByNumericValue(vv.vk);

    let vnakl = tmpp.getItem('Vnakl');
    if (vnakl && vv.vnakl) vnakl.setValueByNumericValue(vv.vnakl);

    let lk1 = tmpp.getItem('Lk1');
    if (lk1 && vv.lk1) lk1.setValueByNumericValue(vv.lk1);

    let lk2 = tmpp.getItem('Lk2');
    if (lk2 && vv.lk2) lk2.setValueByNumericValue(vv.lk2);
    return tmpp;
  }
  const setDatatAfterLoad = (i: number, vv: CurveDTO, tmp: Oblouk[], allOblouks: CurveDTO[]) => {

    var tmpp = convertOblouk(i, vv);
    let prev: Oblouk | null = null;
    let next: Oblouk | null = null;
    if (i > 0) {
      prev = tmp[i - 1];

    }
    if (i < allOblouks.length - 1)
      next = convertOblouk(i + 1, allOblouks[i + 1]);
    tmpp.calculate(prev, next);
    tmp.push(tmpp);
  }
  const reloadData = () => {
    setDeletedRows([]);
    if (routeId)
      new ApiDataClient().GetCurves(routeId).then((v) => {
        let tmp: Oblouk[] = [];

        if (v.curves) {
          v.curves.forEach((vv, i) => {
            setDatatAfterLoad(i, vv, tmp, v.curves!);
          });
        }

        setOblouky(tmp);
        setObloukyStore(tmp);
        setNavigationItems([new NavigationItem(v.projectId!, v.projectName!, UpperMenuType.Projects), new NavigationItem(v.routeId!, v.routeName!, UpperMenuType.Routes)]);
      });
    else {
      setOblouky(undefined);
      setObloukyStore(undefined);
      setNavigationItems(undefined);
    }
  }

  const saveCurves = () => {
    if (routeId) {
      let curves: CurveDTO[] = [];
      if (obloukyStore) {
        obloukyStore.forEach((vv, i) => {
          let tmpp = new CurveDTO();
          tmpp.id = vv.getDbId();

          tmpp.hasMezilehla = vv.hasMezilehla;

          if (vv.getItem('D')?.value && vv.getItem('D')?.value !== '')
            tmpp.d = (parseFloat(vv.getItem('D')?.value!));

          if (vv.getItem('Dstav')?.value && vv.getItem('Dstav')?.value !== '')
            tmpp.dstav = parseFloat(vv.getItem('Dstav')?.value!);

          if (vv.getItem('Lkm')?.value && vv.getItem('Lkm')?.value !== '')
            tmpp.lkm = vv.hasMezilehla ? parseFloat(vv.getItem('Lkm')?.value!) : 0; //vv.getItem('Lkm')?.numericValue;

          if (vv.getItem('R')?.value && vv.getItem('R')?.value !== '')
            tmpp.r = parseFloat(vv.getItem('R')?.value!);

          if (vv.getItem('V')?.value && vv.getItem('V')?.value !== '')
            tmpp.v = parseFloat(vv.getItem('V')?.value!);

          if (vv.getItem('V130')?.value && vv.getItem('V130')?.value !== '')
            tmpp.v130 = parseFloat(vv.getItem('V130')?.value!);

          if (vv.getItem('V150')?.value && vv.getItem('V150')?.value !== '')
            tmpp.v150 = parseFloat(vv.getItem('V150')?.value!);

          if (vv.getItem('Vk')?.value && vv.getItem('Vk')?.value !== '')
            tmpp.vk = parseFloat(vv.getItem('Vk')?.value!);

          if (vv.getItem('Vnakl')?.value && vv.getItem('Vnakl')?.value !== '')
            tmpp.vnakl = parseFloat(vv.getItem('Vnakl')?.value!);

          if (vv.getItem('Lk1')?.value && vv.getItem('Lk1')?.value !== '')
            tmpp.lk1 = parseFloat(vv.getItem('Lk1')?.value!);

          if (vv.getItem('Lk2')?.value && vv.getItem('Lk2')?.value !== '')
            tmpp.lk2 = parseFloat(vv.getItem('Lk2')?.value!);

          curves.push(tmpp);
        });
      }

      new ApiDataClient().CreateOrUpdateCurves(routeId, curves, deletedRows).then((e) => { if (e.ok) reloadData(); });
    }
  }

  const onAddMezilehla = () => {
    if (obloukAddMezilehla !== null)
      setObloukAddMezilehla(!obloukAddMezilehla);
    else setObloukAddMezilehla(true);
    setCanDeleteMezilehle(true);
    setCanAddMezilehla(false);
    let sel = selectedOblouk;
    if (sel !== null) {
      sel.hasMezilehla = true;
      setSelectedOblouk(sel);
    }
  }

  const onCopyOblouk = () => {
    if (obloukCopy !== null)
      setObloukCopy(!obloukCopy);
    else setObloukCopy(true);

  }

  const onDeleteMezilehla = () => {
    setCanDeleteMezilehle(false);
    // setCanAddMezilehla(true);
    if (obloukDeleteMezilehla)
      setObloukDeleteMezilehla(!obloukDeleteMezilehla);
    else setObloukDeleteMezilehla(true);

  }

  const handleKeyDown = (event: any) => {
    if (event.shiftKey) {
      if (event.key === '+') {
        setSetterAdd(true);
      }

      if (event.key === 'M') {
        setSetterAddMezilehla(true);
      }
    }
  };


  const [setterAddMezilehla, setSetterAddMezilehla] = React.useState<boolean>(false);
  const [setterAdd, setSetterAdd] = React.useState<boolean>(false);

  useEffect(() => {
    if (setterAddMezilehla) {
      if (selectedOblouk && !selectedOblouk.hasMezilehla) {

        onAddMezilehla();
      }
      setSetterAddMezilehla(false);
    }
  }, [setterAddMezilehla]);


  useEffect(() => {
    if (setterAdd) {
      if (obloukAdd !== null) {
        setObloukAdd(!obloukAdd);
      }
      else {
        setObloukAdd(true);
      }
      setSetterAdd(false);
    }
  }, [setterAdd]);
  const { fromPlugin } = usePluginStore();
  const navigate = useNavigate();
  return (
    <div onKeyDown={handleKeyDown} style={{ overflow: 'hidden', minHeight: '100vh', background: '#F5F5F5' }}>
      <CurveSolverUpperMenu
        navigationEnabled={fromPlugin === false}
        showChangeTenantDialog={() => { }}
        onAddProject={() => { }}
        menuType={UpperMenuType.Curves}
        navigationItems={navigationItems} />
      <div style={{ display: 'flex', maxHeight: '30px', textAlign: 'center', width: '80%', justifyContent: 'left', alignItems: 'left', marginTop: '80px' }}>
        <Button sx={{ minWidth: '150px', margin: '0px 8px', marginLeft: '32px' }} onClick={saveCurves} size='small' variant='contained' startIcon={<Save />} >Uložit</Button>
        <Button sx={{ minWidth: '150px', margin: '0px 8px' }} onClick={() => {
          if (obloukAdd !== null)
            setObloukAdd(!obloukAdd);
          else setObloukAdd(true);
        }} size='small' variant='contained' startIcon={<Add />} >Přidat OBL</Button>
        {!canDeleteMezilehle && (!(canDeleteMezilehle === false && canAddMezilehle === false)) && <Button sx={{ minWidth: '150px', margin: '0px 8px' }} onClick={onAddMezilehla} disabled={!canAddMezilehle} size='small' variant='contained' startIcon={<Add />}>Přidat MZL</Button>}
        {!canAddMezilehle && (!(canDeleteMezilehle === false && canAddMezilehle === false)) && <Button sx={{ minWidth: '150px', margin: '0px 8px' }} onClick={onDeleteMezilehla} disabled={!canDeleteMezilehle} size='small' variant='contained'>Smazat MZL</Button>}
        <Button sx={{ minWidth: '120px', margin: '0px 8px' }} onClick={onCopyOblouk} disabled={!(selectedOblouk !== null)} startIcon={<ContentCopy />} size='small' variant='outlined'>Kopírovat</Button>
        <Button sx={{ minWidth: '120px', margin: '0px 8px' }} disabled={!(selectedOblouk !== null)} startIcon={<DeleteOutlined />} onClick={() => { setDeleteOblouk(true); }} size='small' variant='outlined'>Smazat</Button>
        {/* <Button sx={{ minWidth: '120px' }} startIcon={<GetApp />} size='small' variant='outlined'>Import</Button> */}
        <Button sx={{ minWidth: '120px', margin: '0px 8px' }} disabled={!(selectedOblouk !== null)} startIcon={<Upload />} size='small' variant='outlined'>Export</Button>
        <Button sx={{ minWidth: '120px', margin: '0px 8px' }} size='small' variant='outlined' startIcon={<SettingsOutlined />} onClick={() => { setSettingsOpen(true) }}>Nastavení</Button>
        <Button sx={{ minWidth: '120px', margin: '0px 8px' }} size='small' variant='outlined' startIcon={<Help />} onClick={() => { window.open('/help', '_blank');/* navigate('/help') */ }}>Nápověda</Button>
      </div>

      <div style={{ marginTop: '15px', overflow: 'auto' }}>
        <Split splitterSize='3px' horizontal percent={(cantShowDetail === undefined || cantShowDetail === false) && selectedOblouk !== null ? splitPercent : 100} setPercent={(e) => { if (e) setSplitPercent(e); }} >
          <CurveSolverTable
            deleteMezilehlaOutside={obloukDeleteMezilehla}
            onObloukAdded={(mezi) => { setCanAddMezilehla(mezi) }}
            copyOutside={obloukCopy}
            inputObloukChanged={selectedOblouk}
            onChanged={(o) => { if (o.getId() === selectedOblouk?.getId()) setSelectedOblouk(o); setOutsideDetailChanged(!outsideDetailChanged); }}
            inputHeight={(selectedOblouk === null || cantShowDetail === true) ? '88vh' : 'calc((100vh - 95px) * ' + splitPercent / 100 + ')'/* splitPercent - 6 + 'vh' */}
            selected={(e, mezi, canDelete, prev) => {
              setSelectedOblouk(e);
              setCanDeleteMezilehle(canDelete);
              // console.log(canDelete);
              setPreviousSelectedOblouk(prev);
              setCanAddMezilehla(mezi);
            }}
            addOutside={obloukAdd}
            addMezilehlaOutside={obloukAddMezilehla}
            deleteOutside={deleteOblouk}
            deletedOutside={() => { setDeleteOblouk(false) }}
            onObloukyListChanged={(v) => { setObloukyStore(v);  }}
            obloukyLoad={oblouky}
            onRowDelete={(id) => setDeletedRows([...deletedRows, id])}
          />
          {(cantShowDetail === undefined || cantShowDetail === false) && selectedOblouk !== null &&
            <div>
              <CurveSolverDetailScene
                nextObloukInput={nextSelectedOblouk}
                inputHeight={'calc((100vh - 95px) * ' + (100 - splitPercent) / 100 + ')'/* (90 - splitPercent) + 'vh' */}
                previousObloukInput={previousSelectedOblouk}
                closeDetail={closeDetail}
                outsideChanged={outsideDetailChanged}
                onChangedDetail={(o) => { setSelectedOblouk(o) }}
                obloukInput={selectedOblouk}
              />
            </div>}
        </Split>
      </div>
      <CurveSolverBaseSettingsDialog isOpen={settingsOpen} onClose={() => { setSettingsOpen(false) }} />
    </div>
  )
}
