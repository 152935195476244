import { Close, CloseFullscreen, Fullscreen } from '@mui/icons-material';
import { DialogTitle, IconButton, Typography } from '@mui/material';
import React from 'react'

interface MuiDialogHeaderProps {
    title: string;
    fullScreen?: boolean;
    setFullScreen?: () => void;
    onClose: () => void;
    showCloseText?: boolean;
    
}
export default function MuiDialogHeader(props: MuiDialogHeaderProps) {
    const { title, fullScreen, setFullScreen, onClose, showCloseText } = props;
    return (
        <DialogTitle>
            <div style={{background:'#F5F5F5', borderBottom:'1px solid #C3C3C3', margin:'-16px -24px 0px -24px', height:'56px'}}>
                <div style={{ float: 'left', display: 'flex' }} >
                    <Typography variant='h1' fontWeight='bold' sx={{marginLeft:'12px'}} lineHeight={'48px'} fontSize={20}>{title} </Typography>
            
                </div>
                <div style={{ float: 'right', display: 'flex', alignContent: 'flex-end' }}>

                    {/* {biggerThan600 && task!.date && <div>
            <Typography variant='body1' sx={{ marginRight: '12px' }} fontSize={11}>{(task.id! > 0) ? t('Created') + " " + task!.date!.toLocaleString() : ""} </Typography>
        </div>} */}
                    {/* <div style={{ marginTop: '-14px' }}> */}
                        {setFullScreen && <IconButton onClick={() => {
                            setFullScreen();
                        }} >
                            {fullScreen ? <CloseFullscreen /> : <Fullscreen />}
                        </IconButton>}
                        {showCloseText && <Typography variant='h1' color='primary'  lineHeight={'42px'} fontSize={16}>ZAVŘÍT</Typography>}
                        <IconButton  onClick={() => { onClose() }} >
                            <Close color='primary' />
                        </IconButton>
                    {/* </div> */}
                </div>
            </div>
        </DialogTitle>
    )
}
