import React, { useState } from 'react'
import { ApiUpdaterClient } from './ApiUpdaterClient'
import { handleBlobFileUpload } from './UploadBlobSas';
import { BentleyProductType, CreateNewUpdateRequest, FileParameter, SGProductType } from '../Api/ApiServer';
import { Autocomplete, Button, FormControlLabel, Switch, TextField } from '@mui/material';
import isNullOrWhiteSpace from '../Shared/isNullOrWhiteSpace';
import Passwordbox from '../Shared/PasswordBox';
// const products = [
//     'SG BIM Loader',
//     'SG Power Tools',
//     'SG Open Tools',
// ]

// const pluginToProducts = [
//     'Microstation',
//     'SG Open Rail',
//     'SG Open Roads',
// ];
export interface AutoCompleteOption<T> {
    label: string;
    value: T;
};
export const productsOptions: AutoCompleteOption<SGProductType>[] = [
    { label: 'BIMLoader', value: SGProductType.Bimloader },
    { label: 'SG Open tools', value: SGProductType.OpenTools },
    { label: 'SGPowerTools', value: SGProductType.PowerTools },

];

export const productPluginsOptions: AutoCompleteOption<BentleyProductType>[] = [
    { label: 'Microstation', value: BentleyProductType.Microstation },
    { label: 'Openrail', value: BentleyProductType.Openrail },
    { label: 'Openroads', value: BentleyProductType.Openroads },

];

export default function UpdaterScene() {
    const [zipFileEvent, setZipFileEvent] = useState<any>(null);
    const [updaterFileEvent, setUpdaterFileEvent] = useState<any>(null);
    const [updatingUpdater, setUpdatingUpdater] = useState<boolean>(false);
    const [wasSuccesUpdated, setWasSuccessUpdated] = useState<boolean | null>(null);
    const [selectedProduct, setSelectedProduct] = useState<AutoCompleteOption<SGProductType> | undefined>();
    const [selectedPlugin, setSelectedPlugin] = useState<AutoCompleteOption<BentleyProductType> | undefined>();
    const saveNewInstaller = () => {
        if (updatingUpdater) {
            if (newUpdateData.password)
                new ApiUpdaterClient().GetUploadUriToUpdater({ data: updaterFileEvent.target.files[0], fileName: updaterFileEvent.target.files[0].name } as FileParameter, newUpdateData.password)
                    .then((uploadData) => {
                        if (uploadData.uri)
                            handleBlobFileUpload(zipFileEvent, uploadData.uri).then((BlobName) => {
                                if (uploadData.updateId)
                                    new ApiUpdaterClient().FinishUploadUpdater(uploadData.updateId).then(() => {
                                        setWasSuccessUpdated(true);
                                    })

                            }).catch(() => {
                                setWasSuccessUpdated(false);
                            });
                    }).catch(() => {
                        setWasSuccessUpdated(false);
                    });
        }
        else {
            if (newUpdateData.version && newUpdateData.minimumUpdateVersion && newUpdateData.product !== undefined && newUpdateData.productPlugin !== undefined && newUpdateData.password)

                new ApiUpdaterClient().GetUploadUri(newUpdateData.version, newUpdateData.minimumUpdateVersion, newUpdateData.product, newUpdateData.productPlugin, newUpdateData.password)
                    .then((uploadData) => {
                        if (uploadData.uri)
                            handleBlobFileUpload(zipFileEvent, uploadData.uri).then((BlobName) => {
                                if (uploadData.updateId)
                                    new ApiUpdaterClient().FinishUploadUpdater(uploadData.updateId).then(() => {
                                        setWasSuccessUpdated(true);
                                    })

                            }).catch(() => {
                                setWasSuccessUpdated(false);
                            });
                    }).catch(() => {
                        setWasSuccessUpdated(false);
                    });
        }
    }
    const continueDisabled = () => {
        if (updatingUpdater) {
            return isNullOrWhiteSpace(newUpdateData.password) || updaterFileEvent === null || zipFileEvent === null;
        }
        else return isNullOrWhiteSpace(newUpdateData.version) || newUpdateData.productPlugin === undefined
            || newUpdateData.product === undefined || isNullOrWhiteSpace(newUpdateData.password) || zipFileEvent === null;
    }

    const [newUpdateData, setNewUpdateData] = useState<Partial<CreateNewUpdateRequest>>(new CreateNewUpdateRequest());
    const handleProductChange = (event: any, newValue: AutoCompleteOption<SGProductType> | null) => {
        if (newValue !== null) {
            setSelectedProduct(newValue);
            setNewUpdateData({ ...newUpdateData, product: newValue.value });
            // setLocalRule({ ...localRule, condition: '', valueCondition: undefined, hasCondition: false });
        }
    };
    const handlePluginChange = (event: any, newValue: AutoCompleteOption<BentleyProductType> | null) => {
        if (newValue !== null) {
            setSelectedPlugin(newValue);
            setNewUpdateData({ ...newUpdateData, productPlugin: newValue.value });
        }
    };
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {/* <Button variant='contained' sx={{ marginTop: '80px' }} onClick={() => {
                const protocol = 'FirstDigitalSigner'; // Match the custom protocol you registered
                const token = 'GxlQ6CiKvvODMQhnlVAGgIe7LyMxKJzfRLVpOds7En4';
                const id = 43;
                const url = `${protocol}:${encodeURIComponent(token!)}:${id}`;
                window.open(url);

            }}>Buildary Signatures Plugin</Button> */}
            <FormControlLabel control={<Switch checked={updatingUpdater} onChange={(e, ch) => { setUpdatingUpdater(ch) }} defaultChecked />} labelPlacement='top' label="Update updateru" />
            <div style={{ border: '1px solid #C3C3C3', borderRadius: '16px', padding: '12px' }}>
                <div style={{ fontWeight: 'bold' }}>{'Vyberte zip s knihovnami nebo aplikací'}</div>
                <input type='file'
                    style={{ margin: '16px', width: 300 }}
                    accept=".zip"
                    // placeholder=
                    // style={{alignSelf:'center'}}
                    onChange={(e) => {
                        setZipFileEvent(e)

                    }}></input>

            </div>
            {updatingUpdater && <div style={{ border: '1px solid #C3C3C3', borderRadius: '16px', padding: '12px', marginTop: '20px' }}>
                <div style={{ fontWeight: 'bold' }}>{'Vyberte zip s knihovnami nebo aplikací'}</div>
                <input type='file'
                    style={{ margin: '16px', width: 300 }}
                    accept=".exe"
                    // placeholder=
                    // style={{alignSelf:'center'}}
                    onChange={(e) => {
                        setUpdaterFileEvent(e)
                    }}></input>

            </div>}

            {!updatingUpdater && <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={productsOptions}
                getOptionLabel={o => o !== undefined ? o.label : ""}
                value={selectedProduct}
                onChange={handleProductChange}
                sx={{ width: 300, margin: '16px' }}
                renderInput={(params) => <TextField {...params} label="Produkt" />}
            />}
            {!updatingUpdater && <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={productPluginsOptions}
                sx={{ width: 300, margin: '16px' }}
                // getOptionLabel={o => o !== null ? o!.name! : ""}
                value={selectedPlugin}
                onChange={handlePluginChange}
                renderInput={(params) => <TextField {...params} label="Do produktu" />}
            />}
            {!updatingUpdater && <TextField label={'Číslo verze'}
                sx={{ width: 300, margin: '16px' }}
                value={newUpdateData.version}
                onChange={(e) => { setNewUpdateData({ ...newUpdateData, version: e.currentTarget.value }) }}></TextField>}
            {!updatingUpdater && <TextField
                sx={{ width: 300, margin: '16px' }}
                label={'Minimální kompatibilní verze'}
                value={newUpdateData.minimumUpdateVersion}
                onChange={(e) => { setNewUpdateData({ ...newUpdateData, minimumUpdateVersion: e.currentTarget.value }) }}></TextField>}
            <div style={{ margin: '16px', width: 300 }}>
                <Passwordbox password={newUpdateData.password ?? ''} onPasswordChange={(e) => { setNewUpdateData({ ...newUpdateData, password: e }) }} />
            </div>
            <Button variant='contained'
                disabled={continueDisabled()}
                onClick={saveNewInstaller}
            >Přidat</Button>
        </div>
    )
}
