import { IssueItemWithProject } from './Models/ProjectWiseIssuesModel';
import { Dialog, DialogContent, MenuItem, Select, TextField, Box, FormControl, InputLabel, Typography, TableContainer, Table, TableHead, TableRow, TableCell, Paper, TableBody } from '@mui/material';
import { CardType } from './Enums/CardType';
import MuiDialogHeader from '../Shared/MuiDialogHeader';

export interface IProjectWiseTaskDetailProps {
  isOpen: boolean;
  detailData: IssueItemWithProject;
  cardType: CardType;
  onClose: () => void;
}

export default function ProjectWiseTaskDetail (props: IProjectWiseTaskDetailProps) {
  const { isOpen, detailData, cardType, onClose } = props;
  
  const getTextByCardType = () => {
    switch (cardType) {
      case CardType.TASKS:
        return 'Úkol';
      case CardType.PROCESSES:
        return 'Proces';
      default:
        return 'Poslední aktivita';
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  return (
    <Dialog PaperProps={{ sx: { minWidth: '1000px', width:'100%', minHeight: '400px', height: '100%' } }} onClose={() => onClose()} open={isOpen}>
      <MuiDialogHeader title={getTextByCardType()} onClose={() => onClose()} />
      <DialogContent>
      <TextField
        label="Název"
        value={detailData?.displayName}
        fullWidth
        variant="outlined"
        sx={{ my:2 }}
      />
      <Box style={{ display: 'flex', gap: '16px' }}>
        <FormControl sx={{my:2}} fullWidth variant="outlined">
          <InputLabel>Status</InputLabel>
          <Select
            label="Status"
            value={detailData?.detail.status}
            fullWidth
            variant="outlined"
          >
            <MenuItem value="Návrh">Návrh</MenuItem>
            <MenuItem value="Otevřeno">Otevřeno</MenuItem>
            <MenuItem value="Předběžný souhlas">Předběžný souhlas</MenuItem>
            <MenuItem value="Připomínka odsouhlasena">Připomínka odsouhlasena</MenuItem>
            <MenuItem value="Schváleno">Schváleno</MenuItem>
            <MenuItem value="Uzavřeno">Uzavřeno</MenuItem>
          </Select>
        </FormControl>
        <TextField
          label="Deadline"
          type="date"
          value={detailData?.detail.dueDate ? formatDate(detailData.detail.dueDate) : ''}
          fullWidth
          sx={{my:2}}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Box>
      <TextField
        label="Popis"
        
        value={detailData?.detail.description}
        fullWidth
        margin="normal"
        variant="outlined"
        multiline
        rows={3}
        sx={{ height: '100px',my:2}}
      />
      <Box>
        <Typography variant="h6" sx={{ color: 'black', fontWeight: 'bold',m:1 }}>Vlastnosti</Typography>
        <Box sx={{ maxHeight: '400px', overflowY: 'auto', overflowX: 'auto' }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold', width: '50%', borderBottom: '1px solid lightgray' }}>Vlastnost</TableCell>
                  <TableCell sx={{ fontWeight: 'bold', width: '50%', borderBottom: '1px solid lightgray' }}>Hodnota</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {detailData?.detail?.properties && Object.entries(detailData.detail.properties).map(([key, value], index) => (
                  <TableRow key={index}>
                    <TableCell sx={{ width: '50%', borderBottom: '1px solid lightgray' }}>{key.replace('__x0020_', '')}</TableCell>
                    <TableCell sx={{ width: '50%', borderBottom: '1px solid lightgray' }}>{value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </DialogContent>
    </Dialog>
  );
}
