import { ProjectWiseModules } from "../ProjectWiseModules";

export const getModuleName = (module: ProjectWiseModules) => {
    switch (module) {
        case ProjectWiseModules.Projects:
            return 'Stavby';
        case ProjectWiseModules.Tasks:
            return 'Můj přehled';
        default:
            return '';
    }
}