import { Accordion, AccordionDetails, AccordionSummary, Button, Checkbox, Divider, Tooltip, Typography, useTheme } from '@mui/material';
import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { BaseLineDTO, CorridorBaseLineDataDTO, CorridorDTO, ProjectDTO, RouteDTO, ValidateTiltTreeDataResponse } from '../Api/ApiServer';
import { ApiBaseClient } from '../Api/ApiBaseClient';
import { ApiDataClient } from '../Api/ApiDataClient';
import { DataGridPremium, GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium';
import { Add, CheckBox, Delete, Edit, ExpandMore, NavigateNext } from '@mui/icons-material';
import NavigationPanel, { NavigationItem } from '../Shared/NavigationPanel';
import { useValidatorStore } from '../CurveSolver/Stores/ValidatorStore';
import { ValidatorsEnum } from '../CurveSolver/Enums/ValidatorsEnum';
import TreeView, { flattenTree, INode, ITreeViewOnNodeSelectProps, NodeId } from "react-accessible-treeview";
import './CorridorScene.css'
import { format } from 'date-fns';

interface CorridorSceneProps {
    projectId: number;
}

enum TreeLevel {
    Corridor = 0,
    BaseLine = 1
}

export default function CorridorScene(props: CorridorSceneProps) {
    const { projectId } = props;
    const theme = useTheme();
    // console.log(theme);
    const [treeData, setTreeData] = React.useState<INode[] | null>(null);
    const [datagridData, setDatagridData ] = React.useState<CorridorBaseLineDataDTO[] | null>(null);
    const [expandedIds, setExpandedIds] = React.useState<NodeId[]>();

    const [ dataBackup, setDataBackup ] = React.useState<ValidateTiltTreeDataResponse | null>();

    const navigate = useNavigate();
    const params = useParams();
    const { initValidator, setValidator, validator } = useValidatorStore();

    useEffect(() => {
        if (validator === ValidatorsEnum.SlopeGuard)
            refreshTree();
    }, []);

    const refreshTree = () => {        
        getTreeData().then((data) => {
            setDataBackup(data);
            if (data !== null && data.corridors != null && data.corridors.length > 0) {
                let d = flattenTree(parseCorridors(data.corridors));
                setTreeData(d);
                setExpandedIds(d.filter(p => p.metadata && p.metadata.typ === TreeLevel.Corridor).map(p => p.id));
            }
            else {
                setTreeData(null);
                setExpandedIds(undefined);
            } 
        });
    }

    const parseCorridors = (corridors: CorridorDTO[]) => {
        let array: any[] = [];
        for (let cor of corridors) {
            array.push({ name: cor.name, children: parseBaselines(cor.baseLines!), metadata: { typ: TreeLevel.Corridor, data: cor.baseLines? JSON.stringify(cor.baseLines.filter(p => p.data).map(p => p.data)) : null } });
        }
        
        return { name: "Koridory", children: array };
    };

    const parseBaselines = (data: BaseLineDTO[]) => {
        if (data.length !== undefined) {
            let array: any[] = [];
            for (let item of data) {
                array.push({
                    name: item.name,
                    metadata: { typ: TreeLevel.BaseLine, data: JSON.stringify(item.data) }
                });
            }
            if (array.length === 0)
                return null;
            return array;
        }
        else {
            return [];
        }
    };

    const getTreeData = async () => {
        if (projectId) {
            return await new ApiDataClient().ValidateTiltTreeData(projectId);     
        }
        else {
            return null;
        }
    };
    
    const ArrowIcon = ({ isOpen }) => {
        if (isOpen) return <ExpandMore sx={{ marginTop: '4px' }} />;
        else return <NavigateNext sx={{ marginTop: '4px' }} />;
    };

    function handleNodeSelect(props: ITreeViewOnNodeSelectProps): void {
        if (props.element.metadata && props.element.metadata.typ === TreeLevel.BaseLine && props.element.metadata.data){
            //console.log('JSON: ',JSON.parse(props.element.metadata.data + '') );
            setDatagridData(JSON.parse(props.element.metadata.data + ''));
        }
        else if (props.element.metadata && props.element.metadata.typ === TreeLevel.Corridor && props.element.metadata.data !== null){
            //console.log('children:', JSON.parse(props.element.metadata.data + '').flatMap(p => p));
            setDatagridData(JSON.parse(props.element.metadata.data + '').flatMap((p: any) => p));
        }
        else setDatagridData(null);
    }

    const columns: GridColDef[] = [
        { 
            field: 'datum', width: 500, headerName: 'Datum', flex: 0,
            valueGetter: ({ row }) => {              
              return format(new Date(row.datum), 'dd.MM.yyyy hh:mm');
            },
        },
        {
            field: 'autor', headerName: 'Autor', flex: 0, renderCell: (params: GridRenderCellParams) => (
                <div>
                    {params.row.autor}
                </div>
            ),
        },
        {
            field: 'isActual', headerName: 'Stav', flex: 0, renderCell: (params: GridRenderCellParams) => (
                <div>
                    <Checkbox checked={params.row.isActual}/>
                </div>
            ),
        },
    ];

    return (
        <div style={{ background: '#F5F5F5', display: 'flex' }}>       
            <div style={{ background: '#F5F5F5',width: '100%', marginTop: '5px' }}>
                {validator === ValidatorsEnum.SlopeGuard && <div style={{ display: 'flex', justifyContent: 'left', marginTop: '32px', color: theme.palette.primary.main, fontSize: '22px', marginLeft: '32px', marginRight: 'auto', textAlign: 'left' }}>
                    <div>Koridory a jejich základny</div>
                </div>}
                {validator === ValidatorsEnum.SlopeGuard && treeData && <div style={{ margin: '16px', marginTop: '0px', paddingLeft: '0px', display: 'flex' }}>
                    <div className='ide' style={{ padding: '8px', paddingTop: '2px', border: '1px solid lightgray', background: 'white'}} onContextMenu={(e)=>e.preventDefault()}>
                        <TreeView                            
                            data={treeData}
                            onNodeSelect={handleNodeSelect}
                            aria-label="basic example tree"
                            expandedIds={expandedIds}
                            nodeRenderer={({ element, getNodeProps, level, handleSelect, isBranch, isSelected, isExpanded, handleExpand, isHalfSelected }) => (
                                <div {...getNodeProps()} style={{ padding: 0, paddingLeft: 20 * (level - 1), background: isSelected ? '' : '' }} onContextMenu={(e) => {e.preventDefault()}}>
                                    <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                        <div style={{ display: 'flex', margin: '5px' }}>
                                            {isBranch && <ArrowIcon isOpen={isExpanded} />}
                                            <div>{element.name} </div>
                                        </div>
                                    </div>
                                    <div style={{ height: '1px', background: '#C3C3C3', width: '100%', marginBottom: '-2px' }}></div>
                                </ div>
                            )}
                        />
                    </div>
                    {datagridData && <div style={{ margin: '16px', marginTop: '12px', paddingLeft: '16px' }}>
                        <DataGridPremium hideFooterRowCount rows={datagridData} columns={columns} hideFooterSelectedRowCount
                            onRowDoubleClick={(params) => {
                                if (validator === ValidatorsEnum.SlopeGuard)
                                    navigate('/slopevalidation/' + params.row.id);
                            }}
                            sx={{
                                '& .MuiDataGrid-cell': {
                                    background: 'white',
                                },
                            }}
                        />
                    </div>}
                </div>}
            </div>
        </div>)
}
