import { Close } from '@mui/icons-material';
import { Button, Dialog, DialogContent, DialogTitle, FormControl, IconButton, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import React, { useEffect } from 'react'
import { ApiDataClient } from '../../Api/ApiDataClient';

interface CreateProjectDialogProps {
    open: boolean;
    onClose: () => void;
    onRefresh?: () => void;
}
export default function CreateProjectDialog(props: CreateProjectDialogProps) {
    const { open, onClose, onRefresh } = props;

    const [name, setName] = React.useState<string>();
    const [type, setType] = React.useState<string>('0');
    const [buttonDisabled, setButtonDisabled] = React.useState<boolean>(false);

    useEffect(() => {
    }, []);

    return (
        <Dialog
            open={open}
            onClose={() => { onClose() }}
            aria-labelledby="create-dialog-title"
            aria-describedby="create-dialog-description"
        >
            <DialogTitle id="create-project-dialog-title" sx={{ background: '#F5F5F5'}}>
                <div  >
                    <div style={{ marginRight: '20px', minWidth: '200px' }}>Založení projektu</div>
                    <IconButton sx={{ width: '30px', height: '30px', right: '20px', top: '18px', float: 'right', position: 'absolute' }}
                        onClick={() => { setName(undefined); setType('0'); onClose(); }}><Close /></IconButton>
                </div>
            </DialogTitle>
            <DialogContent>
                    <div>
                        <TextField sx={{ margin: '12px', width: '526px' }} label={'Název projektu'}
                            size='small'
                            name='userName'
                            variant='outlined'
                            value={name}
                            error={!name}
                            onChange={(e) => { setName(e.target.value) }} />
                        <div style={{ margin: '12px', width: '526px' }}>
                            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                                <InputLabel id="demo-simple-select-standard-label">Typ</InputLabel>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={type}
                                    onChange={(v) => { if (v.target.value) setType(v.target.value) }  }
                                    label="Typ"
                                >
                                    <MenuItem value="0">Neurčeno</MenuItem>
                                    <MenuItem value="1">Silnice</MenuItem>
                                    <MenuItem value="2">Železnice</MenuItem>
                                    <MenuItem value="3">Silniční most</MenuItem>
                                    <MenuItem value="4">Železniční most</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
                            <Button variant='contained' sx={{ margin: 'auto' }} disabled={name === undefined || buttonDisabled} onClick={() => {
                                if (name) {
                                    setButtonDisabled(true);
                                    new ApiDataClient().CreateProject(name, parseInt(type)).then((v) => {
                                        setButtonDisabled(false);

                                        if (onRefresh)
                                            onRefresh();

                                        onClose();
                                    });
                                }
                            }}>Přidat</Button>
                        </div>
                    </div>
            </DialogContent>
        </Dialog>
    )
}
