import React, { useState } from 'react'
import { Oblouk } from '../Models/ObloukModelAsociative';
import { Tooltip } from '@mui/material';
import { ObloukItemState } from '../Enums/ObloukItemState';
import { IsFieldEditable } from '../Helpers/IsFieldEditableHelper';
import { GetColorForState } from '../Helpers/GetColorForStateHelper';
import TableContextMenu from './TableContextMenu';
import DeleteDialog from '../../Shared/DeleteDialog';
import { SignalCellular4Bar } from '@mui/icons-material';

interface CurveSolverTableCellProps {
    name: string;
    label: string;
    model: Oblouk;
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    isInDouble?: boolean;
    isUpperInDouble?: boolean;
    width?: string | number;
    margin?: string;
    onAddLineBefore?: () => void;
    onAddLineAfter?: () => void;
    onAddLineEnd?: () => void;
    onRemoveLine?: () => void;
    onMoveUp?: () => void;
    onMoveDown?: () => void;
    onCopyRow?: () => void;
    onCellBlur?: () => void;
    onCellFocused?: (index: number) => void;
    isMezilehla?: boolean;
    isWhiteBg?: boolean;
    columnIndex?: number;
}
export default function CurveSolverTableCell(props: CurveSolverTableCellProps) {
    const { handleChange, model, name, label, isInDouble, width, margin, isUpperInDouble, onAddLineAfter, onAddLineBefore, onRemoveLine, onAddLineEnd, onCopyRow, isMezilehla, isWhiteBg, columnIndex } = props;
    const [contextMenuAnchorEl, setContextMenuAnchorEl] = useState<HTMLElement | null>(null);
    const [deletingOpen, setDeletingOpen] = useState(false);
    const handleContextMenu = (event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        setContextMenuAnchorEl(event.currentTarget);
    };
    const menuItems = [
        { label: 'Přidat před', onClick: () => { if (onAddLineBefore) onAddLineBefore() } },
        { label: 'Přidat za', onClick: () => { if (onAddLineAfter) onAddLineAfter() } },
        { label: 'Přidat nakonec', onClick: () => { if (onAddLineEnd) onAddLineEnd() } },
        { label: 'Odebrat', onClick: () => { if (onRemoveLine) setDeletingOpen(true) } },
        { label: 'Nahoru', onClick: () => { if (props.onMoveUp) props.onMoveUp() } },
        { label: 'Dolu', onClick: () => { if (props.onMoveDown) props.onMoveDown() } },
        { label: 'Duplikovat', onClick: () => { if (onCopyRow) onCopyRow() } },
        // Add more items as needed
    ];
    const keyMap = [
        'ArrowLeft',
        'ArrowUp',
        'ArrowRight',
        'ArrowDown',
        'Delete'
    ];
    if (model !== undefined && model.getItem(name) !== undefined) {
        return (
            <div onContextMenu={handleContextMenu}
                style={{
                    maxWidth: width,
                    height: isMezilehla === true ? '69px' : (isInDouble ? '16px' : (IsFieldEditable(name) ? '36px' : '36px')),
                    margin: margin,
                    background: IsFieldEditable(name) ? '#0086CA' : 'white',
                    //  borderBottom: isUpperInDouble ? '0px' : (IsFieldEditable(name) ? '2px solid #0086CA' : '1px solid white')
                }}>
                <DeleteDialog open={deletingOpen} onClose={() => { setDeletingOpen(false) }} onDeleteAgree={() => { if (onRemoveLine) onRemoveLine() }}></DeleteDialog>
                {onAddLineBefore !== undefined && <TableContextMenu
                    menuItems={menuItems}
                    anchorEl={contextMenuAnchorEl}
                    onClose={() => setContextMenuAnchorEl(null)}
                    open={Boolean(contextMenuAnchorEl)}
                />}

                <Tooltip title={model.getItem(name)!.toolTip}>
                    <div style={{ margin: IsFieldEditable(name) ? '2px' : '1px', background: 'white' }}>
                        {isMezilehla === true && <div style={{
                            fontSize: '9px', height: '33px', textAlign: 'center',
                            justifyContent: 'center',
                            alignItems: 'center',
                            display: 'flex',
                            background: '#F5F5F5',
                            color: '#777'
                        }}>
                            {/* label */}
                            {<div dangerouslySetInnerHTML={{ __html: label ? label : '' }}>
                            </div>}
                        </div>}
                        <input
                            onKeyDown={(e) => {
                                if (e.shiftKey && keyMap.includes(e.key)) {
                                    e.preventDefault();
                                }
                            }}
                            //autoComplete='off'
                            name={name}
                            id={columnIndex !== undefined ? 'row' + model.getId() + '_column' + columnIndex : undefined}
                            className='cell-input'
                            value={model.getItem(name)!.value}
                            type={IsFieldEditable(name) ? 'number' : 'text'}
                            readOnly={!IsFieldEditable(name)}
                            tabIndex={IsFieldEditable(name) ? 0 : -1}
                            onChange={handleChange}
                            onBlur={props.onCellBlur}
                            onFocus={() => { if (props.onCellFocused && columnIndex !== undefined) props.onCellFocused(columnIndex) }}
                            // placeholder={model.getItem(name)!.whisperText}
                            style={{
                                background: isWhiteBg ? 'white' : GetColorForState(model.getItem(name)!.okState),
                                padding: 0,
                                // margin: IsFieldEditable(name) ? '2px' : '1px',
                                border: '0px',
                                // border: IsFieldEditable(name) ? '.1rem solid #0086CA' : '.1px solid white',
                                maxWidth: width === undefined ? (isInDouble ? '52px' : '') : 'calc(' + width + ' - 10px)',
                                textAlign: 'right',
                                fontFamily: 'Arial',
                                fontSize: IsFieldEditable(name) ? (isInDouble ? '12px' : (isMezilehla ? '14px' : '16px')) : (isInDouble ? '10px' : (isMezilehla ? (model.getItem(name)!.value !== 'RŮZNÉ' ? '12px' : '10px') : '14px')),
                                paddingRight: '6px',
                                // marginTop:isInDouble?'-6px':'0px',
                                position: 'relative',
                                top: isInDouble ? '-4px' : (IsFieldEditable(name) ? '0px' : '0px'),
                                fontWeight: IsFieldEditable(name) ? '750' : 'normal',
                                maxHeight: isInDouble ? '17px' : (IsFieldEditable(name) ? '32px' : '34px'),
                                minHeight: isInDouble ? '17px' : (IsFieldEditable(name) ? '32px' : '34px'),




                            }}
                        />


                    </div>
                </Tooltip >
                {model.getItem(name)!.toolTip && !isMezilehla && <div><SignalCellular4Bar className='rotate' sx={{ position: 'relative', height: '8px', width: '8px', margin: 0, padding: 0, zIndex: '900', left: '-12px', top: IsFieldEditable(name) ? '-35px' : '-36px', color: 'red' }} /></div>}
                {model.getItem(name)!.toolTip && isMezilehla && <div><SignalCellular4Bar className='rotate' sx={{ position: 'relative', height: '8px', width: '8px', margin: 0, padding: 0, zIndex: '900', left: '-12px', top: '-59px', color: 'red' }} /></div>}
                <div>
                    {model.getItem(name) && model.getItem(name)!.value === '' && <div style={{ marginTop: model.getItem(name)?.whisperTexts?.length! === 3 ? '-33px' : (model.getItem(name)?.whisperTexts?.length! === 2 ? '-28px' : '-26px'), marginLeft: '6px', color: '#444', zIndex: 20, position: 'relative', pointerEvents: 'none', fontSize: model.getItem(name)?.whisperTexts?.length! > 1 ? '8.3px' : '13px' }}> {model.getItem(name)?.whisperTexts?.map((whisperText, index) => {
                        return <div key={index} style={{ marginTop: index > 0 ? '-1px' : '0px' }}>{whisperText}</div>;
                    })}</div>}
                </div>
            </div >
        )
    }
    else return <div>nevalid</div>;

}
