import React from 'react'
import { Oblouk } from './Models/ObloukModelAsociative';
import { InputLabel, TextField, Tooltip, Typography, styled } from '@mui/material';
import { GetColorForState } from './Helpers/GetColorForStateHelper';
import { roundNumber } from '../Shared/roundNumber';

interface SolverFieldProps {
  name: string;
  label: string;
  model: Oblouk;
  handleChange: (event: any) => void;
  width?: string;
  roundOnThree?: boolean;
}
const HideNumberArrowsTextField = styled(TextField)`
  & input::-webkit-outer-spin-button,
  & input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  & input[type='number'] {
    -moz-appearance: textfield;
  }
`;

export default function SolverField(props: SolverFieldProps) {
  const { name, model, handleChange, width, roundOnThree, label } = props;
  return (
    <Tooltip title={model.getItem(name)!.toolTip}>
      <div style={{ marginTop: model.getItem(name)!.readOnly ? '0px' : 0, marginBottom: model.getItem(name)!.readOnly ? '-18px' : 0}}>
        <HideNumberArrowsTextField
          size='medium'
          type="text"
          variant='filled'
         /*  label={model.getItem(name)!.readOnly || model.getItem(name)!.value === '' ? '' : label} */
         label={
          <div dangerouslySetInnerHTML={{ __html: model.getItem(name)!.readOnly || model.getItem(name)!.value === '' ? '' : label }}>
          </div>
          }
          placeholder={name}
          sx={{ '& .MuiInputBase-root': { width: '100%', background: GetColorForState(model.getItem(name)!.okState), paddingLeft: '8px', height: '40px'},
              '& .MuiInputLabel-root': { color: '#404040', top: '-4px', left: '-4px'}, '& .MuiInputBase-input.Mui-disabled': {
                WebkitTextFillColor: "rgba(0, 0, 0, 0.7)",
              } }}
          disabled={model.getItem(name)!.readOnly}
          // sx={{ '& .MuiInputBase-root': { background: 'repeating-linear-gradient(45deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1) 10px, transparent 10px, transparent 20px)' } }}
          name={name}
          InputProps={{
            inputProps: {
                style: { textAlign: "right", fontWeight: model.getItem(name)!.readOnly ? 'normal' : '750', color: '#404040' },
            }
          }}
          value={roundOnThree ? model.getItem(name)!.numericValue.toFixed(3) : model.getItem(name)!.value}
          style={{ width: width ?? '85px', margin: '4px' }}
          // onBlur={handleChange}
          onChange={handleChange} >
            {/* <InputLabel>
              <Typography>
                {label}
              </Typography>
            </InputLabel> */}
          </HideNumberArrowsTextField>
        {model.getItem(name)!.readOnly && <div style={{ position: 'relative', color: '#404040', top: '-42px', left: '12px', textAlign: 'start', fontSize: '12px'}}  dangerouslySetInnerHTML={{ __html: label }}>
          {/* {label} */}
        </div>}
      </div>
    </Tooltip>
  )
}
