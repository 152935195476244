import { ObloukItemState } from "../Enums/ObloukItemState";
import { ObloukItem } from "../Models/ObloukItem";

export function whisperDAndSetStates(items: { [key: string]: ObloukItem }): void {
    items['Dmax'].setValueByNumericValue(180);
    if (items['R'].numericValue < 290) {
      items['Dmax'].setValueByNumericValue(Math.floor((items['R'].numericValue - 50) / 1.5));
    }
    if (items['D'].numericValue > items['Dmax'].numericValue) {
      items['D'].okState = ObloukItemState.Error;
    }
    //vyměnit konstanty
    else if (items['D'].numericValue > 170) {
      items['D'].okState = ObloukItemState.OranzoveSrafovane;
      items['D'].toolTip = 'Lze jen při návrhu PJD a výhradně osobní dopravy.';
    }
    else if (items['D'].numericValue > 160) {
      items['D'].okState = ObloukItemState.OranzoveSrafovane;
      items['D'].toolTip = 'Lze jen při návrhu PJD.';
      //přidat tooltip
    }
    else if (items['D'].numericValue > 150) {
      items['D'].okState = ObloukItemState.Oranzove;

      //přidat tooltip
    }
    else if (items['D'].numericValue > 120) {
      items['D'].okState = ObloukItemState.ZeleneSeVzorkem;
      items['D'].toolTip = 'Při provozním zatížení nad 20 mil.hrt/rok jde o vyšší než mezní hodnotu.';
      //přidat tooltip
    }

  }