import { Alert, AlertTitle, Button, Dialog, DialogContent, Slide, Snackbar, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import MuiDialogHeader from '../Shared/MuiDialogHeader';
import { APIUserClient } from '../Api/ApiUserClient';

interface AddUserToTenantDialogProps {
    tenantId?: number;
    isOpen: boolean;
    onClose: (refresh: boolean, id?: number, exists?: boolean, email?: string, alreadyAdded?: boolean) => void;
}
export default function AddUserToTenantDialog(props: AddUserToTenantDialogProps) {
    const { isOpen, onClose, tenantId } = props;
    const [ buttonDisabled, setButtonDisabled] = useState(false);
    const [ newEmail, setNewEmail] = useState('');
    const [ openCreateError, setOpenCreateError] = useState(false);

    useEffect(() => {
        setNewEmail('');
        setButtonDisabled(false);
    }, []);

    function TransitionLeft(props: any) {
        return <Slide {...props} direction='left' />;
    }

    const handleClose = (event: any) => {
        setOpenCreateError(false);
    };
    
    function isEmail(email: string): boolean {
        const regexp = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
        return regexp.test(email);
    }
    
    return (
        <Dialog onClose={() => onClose(false)} open={isOpen}>
            <MuiDialogHeader showCloseText title='PŘIDÁNÍ UŽIVATELE' onClose={() => onClose(false)}/>
            <DialogContent>
                <div>
                    <div>
                        <TextField
                            sx={{ margin: '12px', marginLeft: '24px', width: '350px' }}
                            label={'Email'}
                            size='small'
                            variant='outlined'
                            value={newEmail}
                            onChange={(e) => { setNewEmail(e.target.value); }} />
                    </div>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
                        <Button variant='contained' sx={{ margin: 'auto' }} disabled={!newEmail || !isEmail(newEmail) || buttonDisabled} onClick={() => {
                            setButtonDisabled(true);
                            new APIUserClient().AddUserToTenant(newEmail, tenantId).then((e) => {
                                if (e.ok) {
                                    onClose(true, e.userId, e.ok, undefined, e.alreadyAdded);
                                }
                                else {
                                    onClose(false, undefined, false, newEmail)
                                }
                                setButtonDisabled(false);
                                setNewEmail('');
                            });
                        }}>PŘIDAT</Button>
                    </div>
                    
                    {/* <div className="create-form-popup">
                        <Snackbar
                            TransitionComponent={TransitionLeft}
                            open={openCreateError}
                            autoHideDuration={3000}
                            onClose={handleClose}
                            anchorOrigin={{ vertical: "top", horizontal: "right" }} >
                            <Alert onClose={handleClose} severity="warning">
                                <AlertTitle>{'Chyba zakládání'}</AlertTitle>
                                {'Tenant již existuje'}
                            </Alert>
                        </Snackbar>
                    </div> */}
                </div>
            </DialogContent>
        </Dialog>
    )
}
