import React from 'react'
import DetailGadget from '../Shared/DetailGadget'
import SolverField from './SolverField'
import { Oblouk } from './Models/ObloukModelAsociative';
import { Button, TextField } from '@mui/material';
import { Close } from '@mui/icons-material';
interface CurveSolverVytycovaciDetailProps {
    obloukInput: Oblouk;
    previousObloukInput: Oblouk | null;
    nextObloukInput: Oblouk | null;
    onChangedDetail: (changed: Oblouk) => void;
    closeDetail: () => void;
}
export default function CurveSolverVytycovaciDetail(props: CurveSolverVytycovaciDetailProps) {
    const { obloukInput, onChangedDetail, previousObloukInput, nextObloukInput } = props;
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { name, value } = event.target;

        // setobloukInput((prevState) => {

        const newState = new Oblouk(0, -17);
        newState.copyFrom(obloukInput);
        newState.getItem(name)!.value = value;
        let newOblouk = new Oblouk(0, -18);
        newOblouk.copyFrom(newState as Oblouk);
        newOblouk.calculate(previousObloukInput, nextObloukInput);
        onChangedDetail(newOblouk);
        // return newOblouk;
        // });
    };
    return (
        <div>
            <div style={{ width: '100%', height: '32px', display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ width: '1px', height: '1px', background: 'white' }}></div>
                <div >
                    <Button onClick={props.closeDetail} endIcon={<Close />}>
                        Zavřít
                    </Button>
                </div>
            </div>
            <div style={{ display: 'flex' }}>
                <DetailGadget title='Prvky'>
                    <TextField
                        label='ID'
                        style={{ width: '80px', margin: '4px' }}
                        sx={{ '& .MuiInputBase-root': { paddingLeft: '8px', height: '40px' } }}
                        value={obloukInput.getId()}
                    />

                    {/* <SolverField name='alfa' label='alfa' handleChange={handleChange} model={obloukInput} />
                    <SolverField name='kolej' label='kolej' handleChange={handleChange} model={obloukInput} /> */}
                </DetailGadget>
                <DetailGadget title='Tečny zaoblení'>
                    <SolverField name='tz1' label='t<sub>z,1</sub>' handleChange={handleChange} model={obloukInput} />
                    <SolverField name='tz2' label='t<sub>z,2</sub>' handleChange={handleChange} model={obloukInput} />
                    {(obloukInput.hasMezilehla) ? <SolverField name='tzm' label='t<sub>z,m</sub>' roundOnThree handleChange={handleChange} model={obloukInput} /> : <div></div>}
                </DetailGadget>
                {obloukInput.hasMezilehla && <DetailGadget title='Vytyčovací prvky'>
                    <SolverField name='dRx' label='R<sub>x</sub>' roundOnThree width='150px' handleChange={handleChange} model={obloukInput} />
                    <SolverField name='dR1' label='R<sub>1</sub>' roundOnThree width='150px' handleChange={handleChange} model={obloukInput} />
                    <SolverField name='dR2' label='R<sub>2</sub>' roundOnThree width='150px' handleChange={handleChange} model={obloukInput} />
                    <SolverField name='dA' label='A' roundOnThree width='150px' handleChange={handleChange} model={obloukInput} />
                    <SolverField name='dL1' label='L<sub>1</sub>' roundOnThree width='150px' handleChange={handleChange} model={obloukInput} />
                    <SolverField name='dL2' label='L<sub>2</sub>' roundOnThree width='150px' handleChange={handleChange} model={obloukInput} />
                    <SolverField name='dXs1' label='X<sub>s1</sub>' roundOnThree width='150px' handleChange={handleChange} model={obloukInput} />
                    <SolverField name='dXs2' label='X<sub>s2</sub>' roundOnThree width='150px' handleChange={handleChange} model={obloukInput} />
                    <SolverField name='dYs1' label='Y<sub>s1</sub>' roundOnThree width='150px' handleChange={handleChange} model={obloukInput} />
                    <SolverField name='dYs2' label='Y<sub>s2</sub>' roundOnThree width='150px' handleChange={handleChange} model={obloukInput} />
                    <SolverField name='omega' label='ω' roundOnThree width='150px' handleChange={handleChange} model={obloukInput} />
                    {/* <SolverField name='tau1' roundOnThree width='150px' handleChange={handleChange} model={obloukInput} />
                    <SolverField name='tau2' roundOnThree width='150px' handleChange={handleChange} model={obloukInput} />
                    <SolverField name='taum1' roundOnThree width='150px' handleChange={handleChange} model={obloukInput} />
                    <SolverField name='taum2' roundOnThree width='150px' handleChange={handleChange} model={obloukInput} /> */}
                    <SolverField name='ddn' label='n' roundOnThree width='150px' handleChange={handleChange} model={obloukInput} />
                    <SolverField name='dmm' label='m<sub>m</sub>' roundOnThree  width='150px' handleChange={handleChange} model={obloukInput} />

                </DetailGadget>}

               
            </div>
        </div>

    )
}
