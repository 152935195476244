import * as React from 'react';
import { Box, Stack, Switch, Grid, Typography, Dialog, DialogContent, IconButton, DialogActions, Button } from '@mui/material';
import { PieChart } from '@mui/x-charts';
import { IssueItemWithProject } from './Models/ProjectWiseIssuesModel';
import { PieChartOutlined, Reorder, Work, Notifications, Launch } from '@mui/icons-material';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import { format } from 'date-fns';
import { csCZ } from '@mui/x-data-grid/locales';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import CustomToolBar from './CustomToolBar';
import { CardType } from './Enums/CardType';
import ProjectWiseTaskDetail from './ProjectWiseTaskDetail';
import NoDataPlaceholder from '../Shared/NoDataPlaceHolder';

export interface IProjectWiseTasksSceneProps {
    data: IssueItemWithProject[];
    cardType: CardType;
    isDialog: boolean;
}

export default function ProjectWiseTasksScene(props: IProjectWiseTasksSceneProps) {
    const { data, cardType, isDialog } = props;
    const [isGrid, setIsGrid] = React.useState(false);
    const [isOpenDetail, setIsOpenDetail] = React.useState<boolean>(false);
    const [gridData, setGridData] = React.useState<IssueItemWithProject[] | null>(null);
    const [selectedRowData, setSelectedRowData] = React.useState<IssueItemWithProject | null>(null);
    const [itemData, setItemData] = React.useState<string | undefined>();
    const [open, setOpen] = React.useState(false);
    const [boxWidth, setBoxWidth] = React.useState(0);
    const [dialogWidth, setDialogWidth] = React.useState('90vw');
    const [dialogHeight, setDialogHeight] = React.useState('90vh');
    const boxRef = React.useRef(null);

    React.useEffect(() => {
        window.addEventListener('resize', updateDialogAndBoxWidth);
        return () => {
          window.removeEventListener('resize', updateDialogAndBoxWidth);
        };
    }, []);

    React.useEffect(() => {
        if (itemData)
            setGridData(data.filter((item) => item.detail.status === itemData));
        else
            setGridData(data);

        updateDialogAndBoxWidth(); // Set initial width
    }, [data, itemData]);

    const updateDialogAndBoxWidth = () => {
        setDialogWidth(`${window.innerWidth * 0.8}px`);
        setDialogHeight(`${window.innerHeight * 0.8}px`);

        if (boxRef.current) {
          setBoxWidth((boxRef.current as HTMLElement).clientWidth);
        }
    };

    const renderDateCell = (params) => {
        return (params.value ?? '') !== '' ? format(new Date(params.value), 'dd.MM.yyyy') : '';
    };

    const renderState = (params) => {
        return params.value === 'Open' ? 'Aktivní' : (params.value === 'Closed' ? 'Dokončené' : params.value);
      };

    const columns = [
        { field: 'displayName', headerName: 'Název', flex: 1 },
        { field: 'projectName', headerName: 'Projekt', flex: 1 },
        { field: 'status', headerName: 'Status', flex: 1, renderCell: renderState },
        { field: 'dueDate', headerName: 'Deadline', flex: 1, renderCell: renderDateCell }
    ];
    
    if (cardType === CardType.PROCESSES) {
        columns.splice(2, 0, { field: 'type', headerName: 'Typ', flex: 1 });
    }

    const rows = data.map((issues, index) => ({
        id: index,
        displayName: issues.displayName,
        projectName: issues.projectName,
        type: issues.type,
        status: issues.detail.status,
        dueDate: issues.detail?.dueDate ?? '',
    }));

    const pieChartData = React.useMemo(() => {
        const statusCounts = data.reduce((acc, item) => {
            acc[item.detail.status] = (acc[item.detail.status] || 0) + 1;
            return acc;
        }, {} as Record<string, number>);
    
        const statusColors = data.reduce((acc, item) => {
            if (item.detail.statusColor) {
                acc[item.detail.status] = item.detail.statusColor;
            }
            return acc;
        }, {} as Record<string, string>);
    
        return Object.entries(statusCounts)
            .sort(([statusA], [statusB]) => statusB.localeCompare(statusA))
            .map(([status, count]) => ({
                name: status,
                label: status,
                color: statusColors[status] || '#FFFFFF', // Default color if not found
                value: count,
            }));
    }, [data]);

    const getTextByCardType = () => {
        switch (cardType) {
          case CardType.TASKS:
            return 'Úkoly';
          case CardType.PROCESSES:
            return 'Procesy';
          default:
            return 'Poslední aktivita';
        }
    };

    const handleRowDoubleClick = (params) => {
        const rowData = data.find((item, index) => index === params.id);
        if (rowData) {
            setSelectedRowData(rowData);
            setIsOpenDetail(true);
        }
    };

    const handleDoubleClick = (item) => {
        setSelectedRowData(item);
        setIsOpenDetail(true);
    };

    const onCloseDetail = () => {
        setIsOpenDetail(false);
    };
  
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box>
            <ProjectWiseTaskDetail
                isOpen={isOpenDetail}
                detailData={selectedRowData!}
                cardType={selectedRowData?.type === 'Úkoly' ? CardType.TASKS : CardType.PROCESSES}
                onClose={onCloseDetail} />
            <Dialog open={open} onClose={handleClose} PaperProps={{ sx: { minWidth: dialogWidth, width: dialogWidth, minHeight: dialogHeight, height: dialogHeight } }}>
                <DialogContent>
                    <ProjectWiseTasksScene data={data} cardType={cardType} isDialog={true} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Zavřít
                    </Button>
                </DialogActions>
            </Dialog>
            <Box sx={{ display: 'flex', flexDirection: 'row', minWidth: '20%', alignItems:'center', marginTop: '16px', borderBottom: '1px solid lightgray', paddingBottom: '16px' }}>
                <Box sx={{ backgroundColor: 'lightgray', padding: '4px', display: 'inline-block', borderRadius: '8px', mx:2 }}>
                    {cardType === CardType.TASKS && <TextSnippetOutlinedIcon sx={{ color: 'white', mt:0.5, mx:.5 }} />}
                    {cardType === CardType.PROCESSES && <Work sx={{ color: 'white', mt:0.5, mx:.5 }} />}
                    {cardType === CardType.LAST_ACTIVITY && <Notifications sx={{ color: 'white', mt:0.5, mx:.5 }} />}
                </Box>
                <Typography variant="h6" sx={{ color: 'black', fontWeight: 'bold' }}>{getTextByCardType()}</Typography>
                <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start" marginLeft={'30px'}>
                    <PieChartOutlined fontSize='small' color={isGrid ? 'secondary' : 'primary'} />
                    <Switch size='small' checked={isGrid} onChange={(event) => { setIsGrid(event.target.checked) }} inputProps={{ 'aria-label': 'ant design' }} />
                    <Reorder fontSize='small' color={isGrid ? 'primary' : 'secondary'} />
                </Stack>
                {!isDialog && <IconButton sx={{ marginLeft: 'auto', alignSelf: 'center', marginRight: '16px' }} onClick={() => { setOpen(true) }}><Launch /></IconButton>}
            </Box>
            {rows.length === 0 && <Box style={{ margin: '64px', marginLeft: '96px'}}><NoDataPlaceholder title={'Nejsou zadány žádné ' + getTextByCardType().toLowerCase() + '.'} paragraph='' /></Box>}
            {rows.length !== 0 && isGrid && <DataGridPremium
                slots={{
                    toolbar: (props) => <CustomToolBar {...props} rows={rows} columns={columns} isProject={false} />,
                }}
                sx={{ border: '1px solid #E7E7E7', overflow: 'auto', height: isDialog ? `calc(${dialogHeight} - 180px)` : '380px' }}
                rows={rows} 
                rowHeight={35}
                columns={columns}
                localeText={csCZ.components.MuiDataGrid.defaultProps.localeText}
                pagination={true}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 10,
                        },
                    },
                }}
                pageSizeOptions={[10, 20, 30, 50, 100]}
                onRowDoubleClick={handleRowDoubleClick} />}
            {rows.length !== 0 && !isGrid && 
                <Box sx={{ display: 'flex', flexDirection: 'row', minWidth: '20%', alignItems:'center' }}>
                    <Box ref={boxRef} sx={{ height: isDialog ? `calc(${dialogHeight} - 230px)` : '245px', flex: 1 }}>
                        <PieChart
                            colors={pieChartData.map(item => item.color)}
                            series={[
                            {
                                data: pieChartData,
                                innerRadius: boxWidth / 7,
                                outerRadius: boxWidth / 3,
                                paddingAngle: 2,
                                cornerRadius: 5,
                                startAngle: -90,
                                endAngle: 90,
                                cx: boxWidth / 2.1,
                                cy: boxWidth / (isDialog ? 2.5 : 3.1),
                            }]}
                            slotProps={{
                                legend: {
                                    direction: 'row',
                                    position: { vertical: 'bottom', horizontal: 'middle' },
                                    padding: 5,
                                },
                            }}
                            onItemClick={(event, d) => setItemData(pieChartData[d.dataIndex].name)}
                        />
                        {itemData && <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                            <Button onClick={() => { setItemData(undefined); }} variant="text" size='small' color="primary">
                                Zrušit filtr
                            </Button>
                        </Box>}
                    </Box>
                    <Box sx={{ flex: 1, height: isDialog ? `calc(${dialogHeight} - 180px)` : '355px', overflowY: 'auto', width: '50%', marginTop: '10px' }}>
                        <Grid container direction="column" alignItems="flex-start">
                            {gridData && gridData.map((item) => (
                                <Box key={item.id} 
                                    marginBottom={'15px'} 
                                    onClick={() => handleDoubleClick(item)}
                                    sx={{
                                        padding: '4px',
                                        width: '100%',
                                        px: 2,
                                        '&:hover': {
                                            backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                            cursor: 'pointer',
                                            '.hover-underline': {
                                                textDecoration: 'underline',
                                            },
                                        },
                                    }}>
                                    <Typography 
                                        variant="body1" 
                                        className="hover-underline" 
                                        style={{ 
                                            textAlign: 'left', 
                                            color: item.detail.statusColor, 
                                            fontWeight: 'bold' 
                                        }}>
                                        {item.displayName + ((item.detail?.dueDate ?? '') === '' ? '' : ' (' + new Date(item.detail?.dueDate ?? '').toLocaleDateString('cs-CZ') + ')')}
                                    </Typography>
                                    <Typography variant="body2" style={{ textAlign: 'left' }}>{item.projectName.trim()}</Typography>
                                </Box>
                            ))}
                        </Grid>
                    </Box>
                </Box>
            }
        </Box>
    );
}