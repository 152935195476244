import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import React from 'react'
export interface InfoDialogProps {
    onClose: () => void;
    open: boolean;
    label: string;
    text: string;
}

export default function InfoDialog(props: InfoDialogProps) {
    const { onClose, open, text, label } = props;
    const handleClose = (e: any) => {
        onClose();
    };
    return (
        <Dialog
            open={open}
            onClose={(e) => { handleClose(e) }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {label}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {text}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={(e) => { handleClose(e) }}>OK</Button>
            </DialogActions>
        </Dialog>
    )
}
