import { ceilWithMinus } from "../../Shared/ceilWithMinus";
import { ObloukItemState } from "../Enums/ObloukItemState";
import { ObloukItem } from "../Models/ObloukItem";

export function calculateI150(items: { [key: string]: ObloukItem }): void {
  if (items['V150'].numericValue > 0) {
    if (items['V150'].numericValue <= 230) {
      items['Inn150'].setValueByNumericValue(80);
    } else if (items['V150'].numericValue <= 300) {
      items['Inn150'].setValueByNumericValue(70);
    } else {
      items['Inn150'].setValueByNumericValue(60);
    }
    if (items['V150'].numericValue <= 250) {
      items['Ilim150'].setValueByNumericValue(100);
    }
    else if (items['V150'].numericValue <= 300) {
      items['Ilim150'].setValueByNumericValue(80);
    }
    else {
      items['Ilim150'].setValueByNumericValue(65);
    }
    if (items['V150'].numericValue < 80) {
      items['Imax150'].setValueByNumericValue(130);

    }
    else if (items['V150'].numericValue <= 230) {
      // items['Ilim150'].setValueByNumericValue(100);
      items['Imax150'].setValueByNumericValue(150);

    }
    else if (items['V150'].numericValue <= 250) {
      
      items['Imax150'].setValueByNumericValue(130);

    }

    else if (items['V130'].numericValue <= 300) {
      // items['Ilim130'].setValueByNumericValue(80);
      items['Imax130'].setValueByNumericValue(100);
    }
    else {
      // items['Inn130'].setValueByNumericValue(65);
      items['Imax130'].setValueByNumericValue(90);
    }

    items['I150'].setValueByNumericValue(11.8 * Math.pow(items['V150'].numericValue, 2) / items['R'].numericValue - items['D'].numericValue);
    items['I150'].setValueByNumericValue(ceilWithMinus(items['I150'].numericValue));
    items['I_E150'].setValueByNumericValue(Math.abs(items['I150'].numericValue));

    if (items['I150'].numericValue < -110) items['I150'].okState = ObloukItemState.Error;
    else if (items['I150'].numericValue < -80) items['I150'].okState = ObloukItemState.Zlute;
    else if (items['I150'].numericValue > items['Imax150'].numericValue) items['I150'].okState = ObloukItemState.Error;
    else if (items['I150'].numericValue > items['Ilim150'].numericValue) {
      if (items['V150'].numericValue < 80 && items['R'].numericValue < 250) {
        items['I150'].okState = ObloukItemState.OranzoveSrafovane;
        items['I150'].toolTip = "Lze pouze pro vozidla s hmotností na nápravu do 18 t.";
      }
      else if (items['V150'].numericValue >= 80 && items['V150'].numericValue <= 230 && items['I150'].numericValue > 130) {
        items['I150'].okState = ObloukItemState.OranzoveSrafovane;
        items['I150'].toolTip = "Lze pouze pro vozidla osobní dopravy.";
      }
      else if (items['V150'].numericValue > 300 && items['I150'].numericValue > 80) {
        items['I150'].okState = ObloukItemState.OranzoveSrafovane;
        items['I150'].toolTip = "Lze jen při návrhu PJD.";
      }
      else items['I150'].okState = ObloukItemState.Oranzove;
    }
    else if (items['I150'].numericValue > items['Inn150'].numericValue) items['I150'].okState = ObloukItemState.Zlute;
    //rewrite bellow to ts
    if (items['I150'].numericValue >= 0) items['I150'].setValueByNumericValue(Math.round(items['I150'].numericValue));

    //co tohle znamená??
    else items['I150'].value = "E=" + Math.abs(Math.round(items['I150'].numericValue));

  }
}

