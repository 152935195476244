import React, { useEffect } from 'react'
import { ProjectDTO } from '../../Api/ApiServer';
import { useProjectsStore } from '../../Base/Stores/ProjectsStore';
import { Autocomplete, TextField } from '@mui/material';

interface ProjectSelectProps {
    selectedProjectInput: ProjectDTO | null;
    onChange: (event: ProjectDTO | null) => void;
}

export default function ProjectSelect(props: ProjectSelectProps) {
    const { selectedProjectInput, onChange } = props;
    const { projects, initProjects } = useProjectsStore();
    // const [selectedProject, setSelectedProject] = React.useState<ProjectDTO | null>(null);
    useEffect(() => {
        if (projects === undefined || projects.length === 0)
            initProjects();
    }, []);
    if (projects)
        return (
            <div>

                {projects && <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    size={'small'}
                    onChange={(e, v) => {
                        onChange(v);
                        // onChange(v);
                    }}
                    getOptionLabel={o => o !== null ? o!.name! : ""}
                    value={selectedProjectInput}
                    options={projects}

                    // fullWidth={biggerThan700}
                    sx={{ minWidth: 288, margin:'20px' }}
                    renderInput={(params) => <TextField {...params} label={selectedProjectInput === null ? 'Vyberte projekt' : 'Projekt'}
                    // renderInput={(params) => <TextField error={isMandatory === true && selectedStandard === null}  {...params} label={'Datový standard'}

                    />}
                />}
                {/* {selectedStandard === null && isMandatory && <p style={{ marginBottom: '0px', marginLeft: '-10px', marginTop: '4px', color: theme.palette.error.main }}>{'Vlastnost je povinná'}</p>} */}
            </div>
        );
    else return <div>loading</div>;
}
