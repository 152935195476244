import { useMsal } from '@azure/msal-react';
import { Alert, Button, Snackbar, TextField, Typography, useTheme } from '@mui/material';
import React, { useEffect } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { APIAuthClient } from '../Api/ApiAuthClient';
import { loginRequest } from '../MsAuth/MsAuthConfig';
import Passwordbox from '../Shared/PasswordBox';
import { useCompanyLogoStore } from '../Stores/CompanyLogoStore';
import './Login.css';
import { ApiException } from '../Api/ApiServer';
import ChooseTenantDialog from './ChooseTenantDialog';
import ChangePasswordDialog from '../Users/ChangePasswordScene';
import { useMediaPredicate } from 'react-media-hook';
import SendResetPasswordDialog from '../Users/SendResetPasswordScene';

interface LoginProps {
    onLogin?: (accessToken: string, refreshToken: string, expiresAt: Date) => void;
    loginDirect?: boolean;
    // redirectPath?: string;
}

export default function Login(props: Readonly<LoginProps>) {
    // const { redirectPath } = props;
    const params = useParams();
    const location = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const mail = queryParams.get('email');

        if (mail)
            setUserName(mail);
    }, [location]);

    const { instance } = useMsal();

    const theme = useTheme();
    // console.log(theme);
    const [name, setName] = React.useState("");
    const { initLogoStore, logoBase64 } = useCompanyLogoStore();
    const [userName, setUserName] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [userId, setUserId] = React.useState<number>(0);
    const [loginError, setLoginError] = React.useState("");
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [dialogReset, setDialogResetOpen] = React.useState(false);
    const [dialogPassOpen, setDialogPassOpen] = React.useState(false);
    const [microsoftLogin, setMicrosoftLogin] = React.useState(false);
    const [accessTokenMS, setAccessTokenMS] = React.useState<string>();

    const [changePassAlertOpen, setChangePassAlertOpen] = React.useState(false);

    const [redirectPath, setRedirectPath] = React.useState<string | null>(null);
    // const history = uns();
    const navigate = useNavigate();

    useEffect(() => {
        if (params !== undefined && params.redirect) {
            setRedirectPath(decodeURIComponent(params.redirect));
            window.history.replaceState(null, "SlopeGuard", "/");

        }

    }, []);
    const loginLocal = async (tenantId?: number) => {
        new APIAuthClient().Login(userName, password, tenantId).then((v) => {
            if (v.id !== undefined && v.tenantId !== undefined) {
                if (v.resetPassword === true)
                    setDialogPassOpen(true);
                else {
                    if (props.onLogin === undefined) {
                        if (redirectPath !== null)
                            navigate('/' + redirectPath);
                        else
                            navigate('validators');
                    }
                    else props.onLogin(v.token!, v.refreshToken!, v.tokenExpiration!);
                }

            }
            else if (v.id !== undefined) {
                setUserId(v.id);
                setDialogOpen(true);
            }
        }).catch((e: ApiException) => {
            setPassword("");
            //@ts-ignore
            setLoginError(e.response.Reason);
        });
    }
    const loginMS = async (accessToken: string, tenantId?: number) => {
        new APIAuthClient().LoginMicrosoft(accessToken, tenantId).then((v) => {
            if (v.id !== undefined && v.tenantId !== undefined) {
                // if (props.onLogin === undefined)
                if (props.onLogin === undefined) {
                    if (redirectPath !== null)
                        navigate('/' + redirectPath);
                    else
                        navigate('validators');
                }
                else props.onLogin(v.token!, v.refreshToken!, v.tokenExpiration!);
            }
            else if (v.id !== undefined) {
                setUserId(v.id);
                setDialogOpen(true);
            }
        }).catch((e: ApiException) => {
            //@ts-ignore
            setLoginError(e.response.Reason);
        });
    }

    useEffect(() => {
        initLogoStore();
    }, []);
    const higherThan600 = useMediaPredicate("(min-height: 550px)");
    return (
        <div className='container'>
            <Snackbar open={loginError !== ""} autoHideDuration={3000} onClose={() => setLoginError("")}>

                <Alert onClose={() => setLoginError("")} severity="error" sx={{ width: '100%' }}>
                    Přihlášení se nezdařilo: {loginError}
                </Alert>

            </Snackbar>
            <Snackbar open={changePassAlertOpen} autoHideDuration={3000} onClose={() => setChangePassAlertOpen(false)}>

                <Alert onClose={() => setChangePassAlertOpen(false)} severity="warning" sx={{ width: '100%' }}>
                    Pro pokračování je nutné změnit heslo
                </Alert>

            </Snackbar>


            {higherThan600 && <video style={{ width: '100%', height: '100vh', minHeight: '100vh', objectFit: 'fill' }} autoPlay loop muted>
                <source src={'https://res.cloudinary.com/duffqpbsu/video/upload/v1617831108/forestry/header.mp4#t=0.001'} type='video/mp4' />
            </video>}

            <div style={{ width: '100%', background: '#F5F5F5', position: 'absolute', height: '52px', top: '0', textAlign: 'center', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}>
                <div style={{ display: 'flex', textAlign: 'center', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography variant='h5' fontWeight='bold' color='primary' margin={1}>SG</Typography>
                    <Typography variant='h5' fontWeight='bold' >Validátory</Typography>
                    <img src={logoBase64} style={{ height: '32px', width: 'auto', minWidth: '92px', maxWidth: '260px', marginLeft: '28px' }} />
                </div>
            </div>
            <div className='centered-div' style={{ boxShadow: higherThan600 ? '0px 4px 16px rgba(255, 255, 255, 0.86)' : '0px 4px 16px rgba(0, 0, 0, 0.6)' }}>
                <Typography variant='h4' color='primary' sx={{ margin: '8px', fontWeight: 'bolder' }} >{'Přihlášení'}</Typography>

                <TextField
                    id="outlined-basic"
                    label={'Zadejte email'}
                    className="text-field"
                    sx={{ marginBottom: '12px', marginTop: '16px', height: '58px' }}
                    value={userName}
                    onChange={(e) => { setUserName(e.currentTarget.value) }}
                    variant="outlined" />




                <Passwordbox password={password}
                    onEnter={loginLocal}
                    onPasswordChange={(e) => { setPassword(e) }}></Passwordbox>
                <div style={{ position: 'absolute', bottom: '30px', left: '0', width: '100%' }}>
                    {/* <Button onClick={()=>{
                        const protocol = 'deksigner'; // Match the custom protocol you registered
                        const token = 'token';
                        const id = 4;
                        const url = `${protocol}:${encodeURIComponent(token!)}:${id}`;
                        window.open(url);
                       
                    }}>open app</Button> */}
                    <Button
                        disabled={password === "" || userName === ""}
                        variant={'contained'}
                        size='large'
                        style={{ width: '88%', height: '52px', marginBottom: '12px' }}
                        onClick={() => { setMicrosoftLogin(false); loginLocal(); }}>
                        <Typography sx={{ marginLeft: '12px', marginTop: '8px', fontWeight: 'bold' }}>Login validátor</Typography>
                    </Button>
                    <a href='' onClick={(e) => { e.preventDefault(); setDialogResetOpen(true) }} style={{ marginTop: '0px' }}>zapomněli jste heslo?</a>

                    <Typography
                        sx={{ marginLeft: '12px', marginTop: '24px', marginBottom: '14px' }}
                        color='secondary'
                        fontSize={13}>nebo využijte</Typography>

                    <div style={{}}>
                        <Button variant='outlined' style={{ width: '88%', height: '52px' }} onClick={() => {
                            localStorage.setItem('ms-state', 'login');
                            setMicrosoftLogin(true);
                            if (props.onLogin === undefined)
                                navigate('/auth-callback/validators');
                            else {
                                if (props.loginDirect === undefined)
                                    navigate('/auth-callback' + location.pathname);
                                else navigate('/auth-callback/tool-login');
                            }
                        }}><div style={{ display: 'flex' }}>
                                <img width={32} height={32} src={'https://upload.wikimedia.org/wikipedia/commons/thumb/4/44/Microsoft_logo.svg/2048px-Microsoft_logo.svg.png'} />
                                <Typography sx={{ marginLeft: '12px', marginTop: '4px', fontWeight: 'bold' }}>Microsoft přihlášení</Typography>
                            </div></Button>
                    </div>
                </div>

            </div>
            <ChooseTenantDialog userId={userId} isOpen={dialogOpen} onClose={(r) => { setDialogOpen(false); if (r) { microsoftLogin ? loginMS(accessTokenMS!, r) : loginLocal(r); } }} />
            <ChangePasswordDialog isOpen={dialogPassOpen} onClose={(res) => {
                if (res) {
                    setDialogPassOpen(false);
                    navigate('/projects');
                }
                else
                    setChangePassAlertOpen(true);
            }
            }
            />
            <SendResetPasswordDialog isOpen={dialogReset} onClose={() => { setDialogResetOpen(false) }} />
        </div>)
}
