import { APIAuthClient } from '../Api/ApiAuthClient';
import { create } from 'zustand'

interface UserInfoStoreState {
    userId: number;
    tenantId: number;
    userName: string;
    userSurname: string;
    tenantName: string;
    initials: string;
    canCreateUsers: boolean;
    canCreateTenants: boolean;
    userEmail: string;
    isAdmin: boolean;
    validatingTreeProps: boolean;
    // load: (perms: DataStandardDTO[]) => void
    initUserStore: () => void
}

export const useUserInfoStore = create<UserInfoStoreState>()((set) => ({
    userId: 0,
    tenantId: 0,
    userName: '',
    userSurname: '',
    tenantName: '',
    initials: '',
    canCreateUsers: false,
    canCreateTenants: false,
    userEmail: '',
    isAdmin: false,
    validatingTreeProps: false,
    // load: (by) => set((state) => ({ standardsData: by })),
    initUserStore: () => {
        new APIAuthClient().GetUserInfo().then((res) => {
            set((state) => ({
                userId: res.userId,
                tenantId: res.tenantId,
                userName: res.username,
                userSurname: res.userSurname,
                tenantName: res.tenantName,
                canCreateUsers: res.canCreateUsers,
                canCreateTenants: res.canCreateTenant,
                initials: res.initials,
                userEmail: res.userEmail,
                isAdmin: res.isAdmin,
                validatingTreeProps: res.validatingTreeProps
            }))
        });
    }
}))