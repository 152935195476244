import React, { useEffect, useState } from 'react'
import { Oblouk } from '../Models/ObloukModelAsociative';
import CurveSolverTableCell from './CurveSolverTableCell';
import CurveSolverTableDoubleCell from './CurveSolverTableDoubleCell';

interface CurveSolverMezilehlaRowProps {
    obloukInput: Oblouk;
    onObloukChanged: (oblouk: Oblouk) => void;
    previousObloukInput: Oblouk | null;
    nextObloukInput: Oblouk | null;
    onCellBlur: () => void;
    isSelected: boolean;
}

export default function CurveSolverMezilehlaRow(props: CurveSolverMezilehlaRowProps) {
    const [solverModel, setSolverModel] = useState<Oblouk>(props.obloukInput);
    useEffect(() => {
        // if (solverModel.isSame(props.obloukInput)) return;
        setSolverModel(props.obloukInput);
        // solverModel.setId(obloukInput.getId());
    }, [props.obloukInput]);
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { name, value } = event.target;
        setSolverModel((prevState) => {

            const newState = new Oblouk(0, -6);
            newState.copyFrom(solverModel);
            newState.getItem(name)!.value = value;
            let newOblouk = new Oblouk(0, -7);
            newOblouk.copyFrom(newState as Oblouk);
            newOblouk.calculate(props.previousObloukInput, props.nextObloukInput);
            props.onObloukChanged(newOblouk);
            

            return newOblouk;
        });
    };
    return (

        <div style={{ display: 'flex', minHeight: '52px'}}>
            <div style={{ minWidth: '52px' }}></div>
            <div style={{
                minWidth: '68px',
                fontSize: '10px',
                marginTop: '1px',
                marginRight: '4px',
                marginLeft: '8px',
                height: '25px',
                background: '#C3C3C3',
                textAlign: 'center',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                fontWeight: props.isSelected ? 'bold' : 'normal',

            }}><div style={{margin:'1px',background:'rgb(245, 245, 245)', width:'100%', height:'calc(100% - 2px)', display:'flex', alignItems:'center', justifyContent:'center'}}>mezilehlá</div></div>

            <CurveSolverTableCell width={'54px'} isMezilehla handleChange={handleChange} model={solverModel} name='deltaD' label='ΔD' onCellBlur={props.onCellBlur} />
            <CurveSolverTableCell width={'48px'} isMezilehla handleChange={handleChange} model={solverModel} name='VkdeltaD' label='V<sub>k</sub>ΔD' onCellBlur={props.onCellBlur} />
            <div style={{ minWidth: '4px' }}></div>
            <CurveSolverTableCell width={'47px'} isMezilehla handleChange={handleChange} model={solverModel} name='Vmezi' label='V<sub>mezi</sub>' onCellBlur={props.onCellBlur} />
            <CurveSolverTableCell width={'47px'} isMezilehla handleChange={handleChange} model={solverModel} name='V130m' label='V<sub>130,m</sub>' onCellBlur={props.onCellBlur} />
            <CurveSolverTableCell width={'47px'} isMezilehla handleChange={handleChange} model={solverModel} name='V150m' label='V<sub>150,m</sub>' onCellBlur={props.onCellBlur} />
            <CurveSolverTableCell width={'47px'} isMezilehla handleChange={handleChange} model={solverModel} name='Vkm' label='V<sub>k,m</sub>' onCellBlur={props.onCellBlur} />
            <div style={{ minWidth: '4px' }}></div>
            <CurveSolverTableCell width={'52px'} isMezilehla handleChange={handleChange} model={solverModel} name='deltaI' label='ΔI' onCellBlur={props.onCellBlur} />
            <CurveSolverTableCell width={'52px'} isMezilehla handleChange={handleChange} model={solverModel} name='deltaI130' label='ΔI<sub>130</sub>' onCellBlur={props.onCellBlur} />
            <CurveSolverTableCell width={'52px'} isMezilehla handleChange={handleChange} model={solverModel} name='deltaI150' label='ΔI<sub>150</sub>' onCellBlur={props.onCellBlur} />
            <CurveSolverTableCell width={'52px'} isMezilehla handleChange={handleChange} model={solverModel} name='deltaIk' label='ΔI<sub>k</sub>' onCellBlur={props.onCellBlur} />
            <div style={{ minWidth: '4px' }}></div>
            <CurveSolverTableCell margin='0px 32px 0px 1px' width={'52px'} isMezilehla handleChange={handleChange} model={solverModel} name='Lkm' label='L<sub>k,m</sub>' onCellBlur={props.onCellBlur} />
            <CurveSolverTableDoubleCell isMezilehla nameUp='nm' nameDown='nIm' labelUp='n<sub>m</sub>' labelDown='n<sub>I,m</sub>' handleChange={handleChange} model={solverModel} onCellBlur={props.onCellBlur} />

            {/* <CurveSolverTableCell width={'44px'} isMezilehla handleChange={handleChange} model={solverModel} name='nm' onCellBlur={props.onCellBlur} />
            <CurveSolverTableCell width={'44px'} isMezilehla handleChange={handleChange} model={solverModel} name='nIm' onCellBlur={props.onCellBlur} /> */}
            <div style={{ minWidth: '116px' }}></div>


            <CurveSolverTableCell width={'52px'} margin='0px 0px 0px 32px' isMezilehla handleChange={handleChange} model={solverModel} name='Lkm130' label='L<sub>k,m,130</sub>' onCellBlur={props.onCellBlur} />
            <CurveSolverTableDoubleCell isMezilehla nameUp='nm130' nameDown='nIm130' labelUp='n<sub>m,130</sub>' labelDown='n<sub>I,m,130</sub>' handleChange={handleChange} model={solverModel} onCellBlur={props.onCellBlur} />
            {/* <CurveSolverTableCell width={'44px'} isMezilehla handleChange={handleChange} model={solverModel} name='nm130' onCellBlur={props.onCellBlur} />
            <CurveSolverTableCell width={'44px'} isMezilehla handleChange={handleChange} model={solverModel} name='nIm130' onCellBlur={props.onCellBlur} /> */}
            <div style={{ minWidth: '114px' }}></div>


            <CurveSolverTableCell width={'52px'} isMezilehla handleChange={handleChange} model={solverModel} name='Lkm150' label='L<sub>k,m,150</sub>' onCellBlur={props.onCellBlur} />
            <CurveSolverTableDoubleCell isMezilehla nameUp='nm150' nameDown='nIm150' labelUp='n<sub>m,150</sub>' labelDown='n<sub>I,m,150</sub>' handleChange={handleChange} model={solverModel} onCellBlur={props.onCellBlur} />
            {/* <CurveSolverTableCell width={'44px'} isMezilehla handleChange={handleChange} model={solverModel} name='nm150' onCellBlur={props.onCellBlur} />
            <CurveSolverTableCell width={'44px'} isMezilehla handleChange={handleChange} model={solverModel} name='nIm150' onCellBlur={props.onCellBlur} /> */}
            <div style={{ minWidth: '114px' }}></div>


            <CurveSolverTableCell width={'54px'} isMezilehla handleChange={handleChange} model={solverModel} name='LkmVk' label='L<sub>k,m,Vk' onCellBlur={props.onCellBlur} />

            <CurveSolverTableCell width={'54px'} isMezilehla handleChange={handleChange} model={solverModel} name='nmk' label='n<sub>m,k</sub>' onCellBlur={props.onCellBlur} />
        </div>

    )
}
