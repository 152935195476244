import { ApiBaseClient } from "./ApiBaseClient";
import { CreateOrUpdateCurvesRequest, CreateOrUpdateCurvesResponse, CreateProjectRequest, CreateProjectResponse, CreateRouteRequest, CreateRouteResponse, CurveDTO, DeleteProjectRequest, DeleteProjectResponse, DeleteRouteRequest, DeleteRouteResponse, FileParameter, GetCurvesForRouteResponse, GetInitDataResponse, GetProjectsForTenantResponse, GetRoutesForProjectResponse, ImportMultiCurvesNewRequest, TiltValidationVersionResponse, UpdateProjectDataRequest, UpdateProjectDataResponse, UpdateProjectRequest, UpdateProjectResponse, UpdateRouteRequest, UpdateRouteResponse, ValidateTiltTreeDataRequest, ValidateTiltTreeDataResponse } from "./ApiServer";

export class ApiDataClient extends ApiBaseClient {

    public async GetProjects(): Promise<GetProjectsForTenantResponse> {
        return (await this.GetAdressedAuthRefreshableClient().getProjectsForTenant());
    }

    public async CreateProject(name: string, typ: number): Promise<CreateProjectResponse> {
        return (await this.GetAdressedAuthRefreshableClient().createProject(new CreateProjectRequest({ name: name, type: typ })));
    }

    public async UpdateProject(name: string, typ: number, id: number): Promise<UpdateProjectResponse> {
        return (await this.GetAdressedAuthRefreshableClient().updateProject(new UpdateProjectRequest({ name: name, type: typ, id: id })));
    }

    public async DeleteProject(projectId: number): Promise<DeleteProjectResponse> {
        return (await this.GetAdressedAuthRefreshableClient().deleteProject(new DeleteProjectRequest({ id: projectId })));
    }

    public async GetRoutes(projectId: number): Promise<GetRoutesForProjectResponse> {
        return (await this.GetAdressedAuthRefreshableClient().getRoutesForProject(projectId));
    }

    public async CreateRoute(name: string, state: number, projectId: number): Promise<CreateRouteResponse> {
        return (await this.GetAdressedAuthRefreshableClient().createRoute(new CreateRouteRequest({ name: name, state: state, projectId: projectId })));
    }

    public async UpdateRoute(name: string, state: number, id: number): Promise<UpdateRouteResponse> {
        return (await this.GetAdressedAuthRefreshableClient().updateRoute(new UpdateRouteRequest({ name: name, state: state, id: id })));
    }

    public async UpdateProjectData(name: string, id: number, description: string, projectManager: string, authorizedPersons: string, otherFeatures: string): Promise<UpdateProjectDataResponse> {
        return (await this.GetAdressedAuthRefreshableClient().updateProjectData(new UpdateProjectDataRequest({ projectName: name, projectId: id, description: description, projectManager: projectManager, authorizedPersons: authorizedPersons, otherFeatures: otherFeatures })));
    }

    public async DeleteRoute(routeId: number): Promise<DeleteRouteResponse> {
        return (await this.GetAdressedAuthRefreshableClient().deleteRoute(new DeleteRouteRequest({ id: routeId })));
    }

    public async GetCurves(routeId: number): Promise<GetCurvesForRouteResponse> {
        return (await this.GetAdressedAuthRefreshableClient().getCurvesForRoute(routeId));
    }

    public async CreateOrUpdateCurves(routeId: number, curves: CurveDTO[], deleted: number[]): Promise<CreateOrUpdateCurvesResponse> {
        console.log(routeId, curves, deleted);
        return (await this.GetAdressedAuthRefreshableClient().createOrUpdateCurves(new CreateOrUpdateCurvesRequest({ routeId: routeId, curves: curves, deleted: deleted })));
    }

    public async ValidateTiltTreeData(projectId: number):Promise<ValidateTiltTreeDataResponse>{
        return (await this.GetAdressedAuthRefreshableClient().validateTiltTreeData(new ValidateTiltTreeDataRequest({projectId: projectId})));
    }
    public async ValidateVersion(id: number): Promise<TiltValidationVersionResponse> {
        return (await this.GetAdressedAuthRefreshableClient().validationVersion(id));
    }

    public async ValidateTreeData(id: number): Promise<ValidateTiltTreeDataResponse> {
        return (await this.GetAdressedAuthRefreshableClient().validateTiltTreeData(new ValidateTiltTreeDataRequest({ projectId: id })));
    }
    public async ImportMultiCurves(data: any, projectId: number, routeId?: number, toExisting?: boolean, replace?: boolean, routeName?: string): Promise<number | undefined> {
        return (await this.GetAdressedAuthRefreshableClient().importMultiCurvesNew(new ImportMultiCurvesNewRequest({
            curves: JSON.parse(data).map((x, i) => new CurveDTO({
                d: x.d,
                r: x.r,
                id: -i,
                v: x.v,
                v130: x.v130,
                v150: x.v150,
                vk: x.vk,
                lk1: x.lk1,
                lk2: x.lk2,
                lkm: x.lkm,
                hasMezilehla: x.hasMezilehla,
                inflexWithNext: x.inflexWithNext,
                inflexWithPrevious: x.inflexWithPrevious,
            })), routeId: routeId, projectId: projectId,
            toExisting: toExisting, replace: replace, routeName: routeName
        }))).routeId;
    } 
    public async IsLoggedIn(): Promise<boolean> {
        return (await this.GetAdressedAuthRefreshableClient().isAuthed());
    }

    public async GetInitData(rawData: FileParameter/* RawDataDTO[] */, xmls: FileParameter[]): Promise<GetInitDataResponse> {
        return (await this.GetAdressedAuthRefreshableClient().getInitData(rawData, xmls));        
    }
}