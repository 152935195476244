import React, { useEffect } from 'react'
import LeafletMap, { Hectometer, Lines } from '../Shared/LeafletMap/LeafletMap'
import { Button, Checkbox, FormControlLabel, Menu, MenuItem, TextField, Tooltip, alpha, styled, useTheme } from '@mui/material'
import { convertToGPS } from '../Shared/LeafletMap/GpsSJTSKConverter';
import { LatLng } from 'leaflet';
import { DataGridPremium, GridColDef, GridFilterModel, GridRowSelectionModel, csCZ, useGridApiRef, gridClasses, GridLogicOperator } from '@mui/x-data-grid-premium';
import { ApiBaseClient } from '../Api/ApiBaseClient';
import { TestVideoValidationSlopeRequest, ValidationDesignDataGridSectionDTO, ValidationDesignHectoServer, ValidationSlopeState } from '../Api/ApiServer';
import { Split } from 'ts-react-splitter';
import { Error, ErrorOutline, Warning } from '@mui/icons-material';
import TiltValidatorInteractiveNumberMenu from './TiltValidatorInteractiveNumberMenu';
import { gridColumns } from './TiltTableColumns';
import { useNavigate, useParams } from 'react-router';
import { ApiDataClient } from '../Api/ApiDataClient';
import { fetchSlopeValidationData } from './FetchValidationData';
import CurveSolverUpperMenu from '../CurveSolver/CurveSolverUpperMenu';
import { UpperMenuType } from '../CurveSolver/Enums/UpperMenuTypes';
import { NavigationItem } from '../Shared/NavigationPanel';
const ODD_OPACITY = 0.2;
const StripedDataGrid = styled(DataGridPremium)(({ theme }) => ({

    [`& .${gridClasses.row}.even`]: {
        backgroundColor: '#F8F8F8',
        '&:hover, &.Mui-hovered': {
            backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
        '&.Mui-selected': {
            backgroundColor: alpha(
                theme.palette.primary.main,
                ODD_OPACITY + theme.palette.action.selectedOpacity,
            ),
            '&:hover, &.Mui-hovered': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    ODD_OPACITY +
                    theme.palette.action.selectedOpacity +
                    theme.palette.action.hoverOpacity,
                ),
                // Reset on touch devices, it doesn't add specificity
                '@media (hover: none)': {
                    backgroundColor: alpha(
                        theme.palette.primary.main,
                        ODD_OPACITY + theme.palette.action.selectedOpacity,
                    ),
                },
            },
        },
    },
    [`& .${gridClasses.row}.odd`]: {
        backgroundColor: '#FFFFFF',
        '&:hover, &.Mui-hovered': {
            backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
        '&.Mui-selected': {
            backgroundColor: alpha(
                theme.palette.primary.main,
                ODD_OPACITY + theme.palette.action.selectedOpacity,
            ),
            '&:hover, &.Mui-hovered': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    ODD_OPACITY +
                    theme.palette.action.selectedOpacity +
                    theme.palette.action.hoverOpacity,
                ),
                // Reset on touch devices, it doesn't add specificity
                '@media (hover: none)': {
                    backgroundColor: alpha(
                        theme.palette.primary.main,
                        ODD_OPACITY + theme.palette.action.selectedOpacity,
                    ),
                },
            },
        },
    },
}));
// interface TiltValidationSceneProps {
//     validationDataId?: number;
// }
export default function TiltValidationScene() {

    const [position, setPosition] = React.useState<LatLng | undefined>();
    const [lines, setLines] = React.useState<Lines[]>([]);
    const [hectos, setHectos] = React.useState<Hectometer[]>([]);
    const [rowSelectionModel, setRowSelectionModel] = React.useState<GridRowSelectionModel>([]);
    const [filterModel, setFilterModel] = React.useState<GridFilterModel | null>(null);
    const [rows, setRows] = React.useState<ValidationDesignDataGridSectionDTO[]>([]);
    const [splitPercent, setSplitPercent] = React.useState<number>(60);
    const [totalErrors, setTotalErrors] = React.useState<number>(0);
    const [totalWarnings, setTotalWarnings] = React.useState<number>(0);
    const [leftErrors, setLeftErrors] = React.useState<number>(0);
    const [leftWarnings, setLeftWarnings] = React.useState<number>(0);
    const [rightErrors, setRightErrors] = React.useState<number>(0);
    const [rightWarnings, setRightWarnings] = React.useState<number>(0);
    const [isColorized, setIsColorized] = React.useState<boolean>(false);
    const [navigationItems, setNavigationItems] = React.useState<NavigationItem[]>();
    const navigate = useNavigate();
    const gridRef = useGridApiRef();
    const params = useParams();
    useEffect(() => {
        if (params.id) {
            fetchSlopeValidationData(Number(params.id))
                .then(data => {
                    setPosition(data.position);
                    setTotalErrors(data.totalErrors);
                    setTotalWarnings(data.totalWarnings);
                    setLeftErrors(data.leftErrors);
                    setLeftWarnings(data.leftWarnings);
                    setRightErrors(data.rightErrors);
                    setRightWarnings(data.rightWarnings);
                    setLines(data.lines);
                    setHectos(data.hectos);
                    setRows(data.rows);
                    setNavigationItems([new NavigationItem(data.projectData.projectId!, data.projectData.projectName!, UpperMenuType.Projects), new NavigationItem(data.projectData.tiltId!, data.projectData.tiltName!, UpperMenuType.Tilts)]);
                })
                .catch(() => {
                    navigate('/login/' + encodeURIComponent('slopevalidation/' + params.id));
                });
        } else {
            fetchSlopeValidationData(null)
                .then(data => {
                    setPosition(data.position);
                    setTotalErrors(data.totalErrors);
                    setTotalWarnings(data.totalWarnings);
                    setLeftErrors(data.leftErrors);
                    setLeftWarnings(data.leftWarnings);
                    setRightErrors(data.rightErrors);
                    setRightWarnings(data.rightWarnings);
                    setLines(data.lines);
                    setHectos(data.hectos);
                    setRows(data.rows);
                    setNavigationItems([new NavigationItem(data.projectData.projectId!, data.projectData.projectName!, UpperMenuType.Projects), new NavigationItem(data.projectData.tiltId!, data.projectData.tiltName!, UpperMenuType.Tilts)]);
                });
        }

    }, []);
    const unColorLines = () => {
        let newLines = [...lines];
        var last = newLines.pop();
        setFilterModel(({ items: [], logicOperator: GridLogicOperator.And }));
        last?.lines.forEach(line => {
            line.state = undefined;
        });
        setIsColorized(false);
        setLines([...newLines, last!]);
    }
    const colorizeAllLines = (total: boolean, left: boolean, right: boolean, error: boolean) => {
        //I want to remove last element from lines state array and add it again to trigger rerender
        let newLines = [...lines];
        var last = newLines.pop();
        let rowIds: any[] = [];
        last?.lines.forEach(line => {
            var row = rows.find(x => x.id === line.crossId);
            if (row) {
                if (total) {
                    if (error) {
                        line.state = row.finalSlopeLeftCorrectState === ValidationSlopeState.Error
                            || row.finalSlopeRightCorrectState === ValidationSlopeState.Error
                            || row.isCrossSlopeLeftInCurveCorrect === false
                            || row.isCrossSlopeRightInCurveCorrect === false
                            || row.spminLeftCorrectState === ValidationSlopeState.Error
                            || row.spminRightCorrectState === ValidationSlopeState.Error
                            || row.deltaSLeftCorrectState === ValidationSlopeState.Error
                            || row.deltaSRightCorrectState === ValidationSlopeState.Error
                            ? ValidationSlopeState.Error : ValidationSlopeState.Correct;
                        if (line.state === ValidationSlopeState.Error) rowIds.push(row.id);
                    }
                    else {
                        line.state = row.finalSlopeLeftCorrectState === ValidationSlopeState.Warning
                            || row.finalSlopeRightCorrectState === ValidationSlopeState.Warning
                            || row.spminLeftCorrectState === ValidationSlopeState.Warning
                            || row.spminRightCorrectState === ValidationSlopeState.Warning
                            ? ValidationSlopeState.Warning : ValidationSlopeState.Correct;
                        if (line.state === ValidationSlopeState.Warning) rowIds.push(row.id);
                    }

                }
                else if (left) {
                    if (error) {
                        line.state = row.finalSlopeLeftCorrectState === ValidationSlopeState.Error
                            || row.isCrossSlopeLeftInCurveCorrect === false
                            || row.deltaSLeftCorrectState === ValidationSlopeState.Error
                            || row.spminLeftCorrectState === ValidationSlopeState.Error
                            ? ValidationSlopeState.Error : ValidationSlopeState.Correct;
                        if (line.state === ValidationSlopeState.Error) rowIds.push(row.id);
                    }
                    else {
                        line.state = row.finalSlopeLeftCorrectState === ValidationSlopeState.Warning || row.spminLeftCorrectState === ValidationSlopeState.Warning
                            ? ValidationSlopeState.Warning : ValidationSlopeState.Correct;
                        if (line.state === ValidationSlopeState.Warning) rowIds.push(row.id);
                    }

                }
                else if (right) {
                    if (error) {
                        line.state = row.finalSlopeRightCorrectState === ValidationSlopeState.Error
                            || row.isCrossSlopeRightInCurveCorrect === false
                            || row.deltaSRightCorrectState === ValidationSlopeState.Error
                            || row.spminRightCorrectState === ValidationSlopeState.Error
                            ? ValidationSlopeState.Error : ValidationSlopeState.Correct;
                        if (line.state === ValidationSlopeState.Error) rowIds.push(row.id);
                    }
                    else {
                        line.state = row.finalSlopeRightCorrectState === ValidationSlopeState.Warning || row.spminRightCorrectState === ValidationSlopeState.Warning
                            ? ValidationSlopeState.Warning : ValidationSlopeState.Correct;
                        if (line.state === ValidationSlopeState.Warning) rowIds.push(row.id);
                    }
                }
                else line.state = ValidationSlopeState.Correct;


                // line.state = row.finalSlopeLeftCorrectState === ValidationSlopeState.Correct && row.finalSlopeRightCorrectState === ValidationSlopeState.Correct ? ValidationSlopeState.Correct : ValidationSlopeState.Error;
            }
            else line.state = ValidationSlopeState.Correct;
        });
        // console.log(({ items: [{ field: 'crossId', operator: 'isAnyOf', value: rowIds }], logicOperator: GridLogicOperator.And }));

        setIsColorized(true);
        console.log([...newLines, last!]);
        setLines([...newLines, last!]);
        if (rowIds.length > 0) {
            let filter = ({ items: rowIds.map(x => ({ field: 'id', operator: 'equals', value: x })), logicOperator: GridLogicOperator.Or });
            // console.log(filter);
            setFilterModel(filter);
            // setRowSelectionModel(rowIds);
        }
        // setFilterModel(({ items: [{ field: 'crossId', operator: 'isAnyOf', value: rowIds }], logicOperator: GridLogicOperator.And }));
    }

    const [contextMenu, setContextMenu] = React.useState<{
        mouseX: number;
        mouseY: number;
    } | null>(null);
    const handleRowContextMenu = (event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault();

        if (!event.currentTarget || rows === null) {
            return;
        }
        const rowId = Number((event.currentTarget as HTMLDivElement).getAttribute('data-id'));
        setContextMenu(
            contextMenu === null ? { mouseX: event.clientX, mouseY: event.clientY } : null
        );

    };
    const handleClose = () => {
        setContextMenu(null);
    };
    const [inputSelectedLinesId, setInputSelectedLinesId] = React.useState<any[]>([]);
    return (
        <div style={{ overflow: 'hidden', minHeight: '100vh', background: '#F5F5F5' }}>
            <CurveSolverUpperMenu
                navigationEnabled
                showChangeTenantDialog={() => { }}
                onAddProject={() => { }}
                menuType={UpperMenuType.Curves}
                navigationItems={navigationItems} />
            <Menu
                open={contextMenu !== null}
                onClose={handleClose}
                onAuxClick={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={
                    contextMenu !== null
                        ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                        : undefined
                }
            >
                <MenuItem onClick={() => setInputSelectedLinesId(rowSelectionModel)} disabled={rowSelectionModel.length === 0}>Vybrat v mapě</MenuItem>

            </Menu>
            <div style={{ marginTop: '72px', overflow: 'auto' }}>
                <Split horizontal percent={splitPercent} setPercent={(e) => { if (e) setSplitPercent(e); }} >
                    <div style={{ height: 'calc((100vh - 72px) * ' + splitPercent / 100 + ')' /* (splitPercent - 4) + 'vh' */ }}>
                        <LeafletMap lineClicked={(line) => {
                            setRowSelectionModel([line.crossId]);
                            setInputSelectedLinesId([line.crossId]);
                            if (gridRef.current) {
                                try {
                                    let rowIndex = gridRef.current.getRowIndexRelativeToVisibleRows(line.crossId);
                                    gridRef.current.scrollToIndexes({ rowIndex: rowIndex, colIndex: 0 });
                                }
                                catch {

                                }
                            }
                        }} height={(splitPercent) + 'vh'}
                            inputSelectedLinesId={inputSelectedLinesId}
                            inputCenter={position} linesInput={lines} hectosInput={hectos} />
                    </div>
                    <div style={{ height: 'calc((100vh - 72px) * ' + (100 - splitPercent) / 100 + ')' /* (100 - splitPercent - 4) + 'vh' */ }}>
                        <TiltValidatorInteractiveNumberMenu
                            isColorized={isColorized}
                            unColorizeClicked={() => { setIsColorized(false); unColorLines(); }}
                            leftErrors={leftErrors}
                            leftWarnings={leftWarnings}
                            rightErrors={rightErrors}
                            rightWarnings={rightWarnings}
                            totalErrors={totalErrors}
                            totalWarnings={totalWarnings}
                            errorsClicked={(total, left, right) => {
                                colorizeAllLines(total, left, right, true);
                            }}
                            warningsClicked={(total, left, right) => { colorizeAllLines(total, left, right, false); }}
                        />

                        <StripedDataGrid localeText={csCZ.components.MuiDataGrid.defaultProps.localeText}
                            apiRef={gridRef}
                            onRowSelectionModelChange={(newRowSelectionModel) => {
                                console.log(newRowSelectionModel);
                                setRowSelectionModel(newRowSelectionModel);
                            }}
                            // getCellClassName={(params) => {
                            //     console.log(params);
                            //     return 'tilt-grid-cell';
                            // }}
                            rowSelectionModel={rowSelectionModel}
                            filterModel={filterModel !== null ? filterModel : undefined}
                            onFilterModelChange={(e) => { setFilterModel(e); console.log(e); }}
                            style={{ width: '100%', padding: '12px' }}
                            slotProps={{
                                row: {
                                    onContextMenu: (e) => handleRowContextMenu(e),
                                    style: { cursor: 'context-menu' },
                                },
                            }}
                            onRowDoubleClick={(e) => {

                                // let a: any[] = [];
                                // gridRef.current.getSelectedRows().forEach(row => {
                                //     a.push(row.crossId);
                                // });
                                setInputSelectedLinesId(rowSelectionModel);
                                //if (rowDoubleClicked) rowDoubleClicked(e.row.id, e.row.modelId);
                            }}
                            getRowClassName={(params) =>
                                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                            }
                            // gethea={(p)=>{
                            //     console.log(p);
                            //     if(p.field === 'pica1') return 'MuiDataGrid-columnHeader-mini';
                            //     else return 'MuiDataGrid-columnHeader';
                            // }}
                            rowHeight={32}
                            columnVisibilityModel={{ id: false }}
                            // getRowClassName={(params) => 'datagrid-row'}
                            columns={gridColumns} rows={rows!} />
                    </div>
                </Split>
            </div>
        </div>
    )
}
