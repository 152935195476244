import { Card, Grid, Box, Typography } from '@mui/material';
import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { ProjectWiseProjectModel } from '../Models/ProjectWiseProjectModel';
import { Circle } from '@mui/icons-material';
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts';

export interface ProjectTileSceneProps {
    projectData: ProjectWiseProjectModel;
    onSelectProject: (project: ProjectWiseProjectModel) => void;
}

export default function ProjectTileScene(props: ProjectTileSceneProps) {
    const { projectData, onSelectProject } = props;
    const [selectedProject, setSelectedProject] = React.useState<ProjectWiseProjectModel>();
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        setSelectedProject(projectData);
    }, [projectData]);

    const getColor = (status: string) => {
        switch (status) {
            case 'projektová příprava': return '#FF5200';
            case 'realizace': return '#0E457D';
            case 'realizace - výběrové řízení': return '#0E457D';
            case 'ukončení realizace': return '#31AFD4';
            default: return 'gray';
        }
    }

    const getSoD = () => {
        return Number(selectedProject?.properties.PROJECT_PW_STAVBA_PLANNAKLADY_1.substring(0, selectedProject?.properties.PROJECT_PW_STAVBA_PLANNAKLADY_1.lastIndexOf(' ')).replace(/\s+/g, ''));
    }

    const getCerpano = () => {
        return Number(selectedProject?.properties.PROJECT_PW_STAVBA_NAKLADY_CERPANI.substring(0, selectedProject?.properties.PROJECT_PW_STAVBA_NAKLADY_CERPANI.lastIndexOf(' ')).replace(/\s+/g, ''));
    }

    const getZbv = () => {
        return Number(selectedProject?.properties.PROJECT_PW_STAVBA.substring(0, selectedProject?.properties.PROJECT_PW_STAVBA.lastIndexOf(' ')).replace(/\s+/g, '')) - Number(selectedProject?.properties.PROJECT_PW_STAVBA_PLANNAKLADY_1.substring(0, selectedProject?.properties.PROJECT_PW_STAVBA_PLANNAKLADY_1.lastIndexOf(' ')).replace(/\s+/g, '')) ;
    }

    if (selectedProject)
        return (
            <Grid item key={selectedProject?.instanceId} xs={12} sm={12} md={12} lg={6} xl={4} style={{ display: 'flex', justifyContent: 'center' }}>
                <div onClick={() => { onSelectProject(selectedProject) }}>
                    <Box
                        alignContent={'left'}
                        alignItems={'left'}
                        sx={{
                            padding: '4px',
                            position: 'relative',
                            cursor: 'pointer',
                            border: '1px solid lightgray',
                            borderRadius: '8px',
                            boxShadow: 3,
                            width:'100%'
                           
                        }}
                    >
                        <Box display='flex' pb={2} flexDirection='column' justifyContent='space-between' minHeight='300px'  minWidth='420px' maxWidth='420px' height='100%'>
                            <Grid container spacing={1} style={{ padding: '4px' }} >
                                <Grid item xs={2} >
                                    <Circle sx={{ color: getColor(selectedProject.properties.PROJECT_PW_STAVBA_STATUS), marginTop: '24px' }} />
                                </Grid>
                                <Grid item xs={10}>
                                    <Typography textAlign={'left'} variant='h6' sx={{ marginTop: '6px', marginLeft: '-16px', fontWeight: 'bold' }}>{selectedProject.properties.PROJECT_PW_STAVBA_NAZEV}</Typography>
                                    <Typography textAlign={'left'} variant='body1' sx={{ marginTop: '6px', marginLeft: '-16px' }}>{selectedProject.properties.PROJECT_PW_STAVBA_SKOD}</Typography>
                                </Grid>
                            </Grid>
                            <Box>
                                <BarChart
                                    borderRadius={5}
                                    layout="horizontal"
                                    yAxis={[{
                                        scaleType: 'band', data: ['SoD', 'Čerpáno', 'ZBV'], colorMap: {
                                            colors: ['#2E7D32', '#FF5200', '#0E457D'],
                                            type: 'ordinal'
                                        }
                                    }]}
                                    margin={{ top: 20, right: 20, bottom: 20, left: 120 }}
                                    xAxis={[
                                        {
                                            valueFormatter: (value) => `${(value / 1000000).toLocaleString()} mil. kč`
                                        },
                                    ]}
                                    series={[{ data: [getSoD(), getCerpano(), getZbv()] }]}
                                    sx={{
                                        [`.${axisClasses.left} .${axisClasses.label}`]: {
                                            // Move the y-axis label with CSS
                                            visibility: 'hidden',
                                        },
                                    }
                                    }
                                    width={350}
                                    height={150}
                                />
                            </Box>
                        </Box>


                    </Box>
                </div>
            </Grid >
        );
    else return <div></div>;
}
