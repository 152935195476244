import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';
import { isTemplateExpression } from 'typescript';

interface BimValidatorFrameProps {

}

function BimValidatorFrame(props: BimValidatorFrameProps) {
    const iframeRef = useRef<any | null>(null);
    const navigate = useNavigate();

    const receiveMessageFromIframe = (event) => {
    // Handle the data received from the iframe
        console.log('Received message from iframe:', event.data);
        localStorage.removeItem('token')
        navigate('/');
    };

    useEffect(() => {
        window.addEventListener('message', receiveMessageFromIframe);
    
        // Cleanup the event listener when the component unmounts
        return () => {
          window.removeEventListener('message', receiveMessageFromIframe);
        };
      }, []); 

    /* useEffect(() => {
        if (iframeRef && iframeRef.current)
            iframeRef.current.addEventListener('message', receiveMessageFromIframe);
            
    }, [iframeRef]); */

    if(localStorage.getItem('token') == null)
        return (
            <div>chyba přihlášení</div>
        );
    return (
        
        <iframe ref={iframeRef} style={{ width:'100%', height:'100%',minHeight:'100vh' }} height="100%" width="100%" frameBorder="0" src={process.env.REACT_APP_BIMVALIDATOR_URL + '/router/' + localStorage.getItem('token')}></iframe>
    );
}

export default BimValidatorFrame

