import React, { useEffect, useRef, useState } from 'react'
import { Oblouk } from '../Models/ObloukModelAsociative';
import { Button } from '@mui/material';
import { GetColorForState } from '../Helpers/GetColorForStateHelper';
import CurveSolverTableCell from './CurveSolverTableCell';
import CurveSolverTableDoubleCell from './CurveSolverTableDoubleCell';
import TableContextMenu from './TableContextMenu';
import delay from '../../Shared/delay';
import CurveSolverMezilehlaRow from './CurveSolverMezilehlaRow';
import CurveSolverCheckboxCell from './CurveSolverCheckboxCell';
interface CurveSolverTableRowProps {
    obloukInput: Oblouk;
    previousObloukInput: Oblouk | null;
    nextObloukInput: Oblouk | null;
    // solverModel: Oblouk;
    onObloukChanged: (oblouk: Oblouk, nextObloukInput?: Oblouk) => void;
    onRowSelected: (rowId: number) => void;
    onCellSelected: (rowId: number, columnId: number) => void;
    isSelected: boolean;
    onAddLineBefore?: (id: number) => void;
    onAddLineAfter?: (id: number) => void;
    onAddLineEnd?: () => void;
    onRemoveLine?: (id: number) => void;
    onMoveUp?: (id: number) => void;
    onMoveDown?: (id: number) => void;
    onCopyRow?: (id: number) => void;
    isFirst?: boolean;
    isLast?: boolean;
    onCellBlur: () => void;
    hasMezilehla: boolean;
    rowIndex: number;
}
export default function CurveSolverTableRow(props: CurveSolverTableRowProps) {
    const { obloukInput, onRowSelected, isSelected, onObloukChanged, onAddLineAfter, onAddLineBefore, onAddLineEnd, onRemoveLine, isFirst, isLast, onCellBlur, hasMezilehla, previousObloukInput, nextObloukInput, rowIndex, onCellSelected } = props;

    const [solverModel, setSolverModel] = useState<Oblouk>(obloukInput);

    useEffect(() => {
        if (obloukInput.isSame(solverModel)) return;
        setSolverModel(obloukInput);
        // solverModel.setId(obloukInput.getId());
    }, [obloukInput]);
    useEffect(() => {
        console.log(nextObloukInput);
        // solverModel.setId(obloukInput.getId());
    }, [nextObloukInput]);
    const handleInflexChange = (next: boolean, value: boolean) => {
        setSolverModel((prevState) => {

            const newState = new Oblouk(0, -14);
            newState.copyFrom(solverModel);
            if (next)
                newState.inflexWithNext = value;
            else newState.inflexWithPrevious = value;
            // newState.getItem(name)!.value = value;
            let newOblouk = new Oblouk(0, -15);
            newOblouk.copyFrom(newState as Oblouk);
            newOblouk.hasMezilehla = solverModel.hasMezilehla;
            // newOblouk.inflexWithNext = solverModel.in
            // newOblouk.horniOblouk = previousObloukInput;
            newOblouk.calculate(previousObloukInput, nextObloukInput);
            if (next === false && previousObloukInput)
                previousObloukInput.inflexWithNext = value;
            if (next && nextObloukInput)
                nextObloukInput.inflexWithPrevious = value;
            console.log(newOblouk);
            if (nextObloukInput)
                onObloukChanged(newOblouk, nextObloukInput);
            else onObloukChanged(newOblouk);
            return newOblouk;
        });
    }
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { name, value } = event.target;

        setSolverModel((prevState) => {

            const newState = new Oblouk(0, -14);
            newState.copyFrom(solverModel);
            newState.getItem(name)!.value = value;
            let newOblouk = new Oblouk(0, -15);
            newOblouk.copyFrom(newState as Oblouk);
            newOblouk.hasMezilehla = solverModel.hasMezilehla
            // newOblouk.horniOblouk = previousObloukInput;
            newOblouk.calculate(previousObloukInput, nextObloukInput);
            console.log(newOblouk);
            if (nextObloukInput)
                onObloukChanged(newOblouk, nextObloukInput);
            else onObloukChanged(newOblouk);
            return newOblouk;
        });
    };
    const onChangeFromMezilehla = (newState: Oblouk) => {
        setSolverModel((prevState) => {

            // const newState = new Oblouk(0);
            // newState.copyFrom(solverModel);
            // // newState.getItem(name)!.value = value;
            let newOblouk = new Oblouk(0, -16);
            newOblouk.copyFrom(newState);
            newOblouk.hasMezilehla = true;
            // newOblouk.horniOblouk = previousObloukInput;
            newOblouk.calculate(previousObloukInput, nextObloukInput);
            console.log(newOblouk);
            onObloukChanged(newOblouk);
            return newOblouk;
        });
    }
    const rowRef = useRef<HTMLDivElement>(null);
    const overlayRef = useRef<HTMLDivElement>(null);

    const onAddAfter = () => {
        if (onAddLineAfter)
            onAddLineAfter(solverModel.getId());
    }
    const onAddBefore = () => {
        if (onAddLineBefore)
            onAddLineBefore(solverModel.getId());
    }
    const onAddEnd = () => {
        if (onAddLineEnd)
            onAddLineEnd();
    }
    const onRemove = () => {
        if (onRemoveLine)
            onRemoveLine(solverModel.getId());
    }
    const onMoveUp = () => {
        if (props.onMoveUp)
            props.onMoveUp(solverModel.getId());
    }
    const onMoveDown = () => {
        if (props.onMoveDown)
            props.onMoveDown(solverModel.getId());
    }
    const onCopyRow = () => {
        if (props.onCopyRow)
            props.onCopyRow(solverModel.getId());
    }
    // const lastSelectedRowId = useState<number>(0);
    const select = (e: any) => {
        if (isSelected) {
            if (e.ctrlKey) {
                onRowSelected(0);
            }
        }

        else onRowSelected(solverModel.getId());

    }
    const onCellFocused = (i: number) => {
        if (!isSelected) onRowSelected(solverModel.getId());

        onCellSelected(solverModel.getId(), i);
    }
    return (
        <div >
            {hasMezilehla && previousObloukInput !== null && <CurveSolverMezilehlaRow
                isSelected={isSelected}
                obloukInput={solverModel}
                onCellBlur={onCellBlur}
                onObloukChanged={onChangeFromMezilehla}
                previousObloukInput={previousObloukInput}
                nextObloukInput={nextObloukInput}
            />}
            <div style={{ display: 'relative' }} onMouseDown={select}>
                {/* <div onMouseDown={(e) => { onRowSelected(solverModel.getId()) }}> */}
                <div id='row' ref={rowRef} style={{ display: 'flex', margin: '1px', position: 'relative', top: 0, left: 0 }}>
                    <div style={{
                        minWidth: '52px',
                        fontSize: '12px',
                        height: '31px',
                        // borderLeft: '2px solid #C3C3C3',
                        borderRadius: isFirst ? '6px 0px 0px 0px' : (isLast ? '0px 0px 0px 6px' : '0px'),
                        borderRight: '2px solid #C3C3C3', borderTop: '1px solid #C3C3C3', borderBottom: '1px solid #C3C3C3',
                        // margin: (isLast === undefined || isLast === false) ? '0px -2px -2px 4px' : '0px -2px -0px 4px',
                        textAlign: 'center',
                        lineHeight: '34px',
                        fontWeight: 'bold',
                        background: 'white',
                        // display: solverModel.getId() === 1 && isFirst !== true ? 'none' : 'block',
                    }}>{solverModel.getId() === 1 && isFirst !== true ? '' : solverModel.getId()}</div>
                    <CurveSolverTableCell columnIndex={0} onCellFocused={onCellFocused} margin='0px 4px 0px 5px' width={'68px'} name='R' label='R' model={solverModel} handleChange={handleChange} onAddLineAfter={onAddAfter} onAddLineBefore={onAddBefore} onAddLineEnd={onAddEnd} onRemoveLine={onRemove} onMoveDown={onMoveDown} onMoveUp={onMoveUp} onCopyRow={onCopyRow} onCellBlur={onCellBlur} isWhiteBg={true} />
                    {/* <CurveSolverTableCell name='Dstav' model={solverModel} handleChange={handleChange} /> */}
                    <CurveSolverTableCell columnIndex={1} width={'52px'} onCellFocused={onCellFocused} name='D' label='D' model={solverModel} handleChange={handleChange} onAddLineAfter={onAddAfter} onAddLineBefore={onAddBefore} onAddLineEnd={onAddEnd} onRemoveLine={onRemove} onMoveDown={onMoveDown} onMoveUp={onMoveUp} onCopyRow={onCopyRow} onCellBlur={onCellBlur} />
                    <CurveSolverTableCell columnIndex={2} width={'48px'} name='DN' label='DN' margin='0px 4px 0px 0px' model={solverModel} handleChange={handleChange} onAddLineAfter={onAddAfter} onAddLineBefore={onAddBefore} onAddLineEnd={onAddEnd} onRemoveLine={onRemove} onMoveDown={onMoveDown} onMoveUp={onMoveUp} onCopyRow={onCopyRow} onCellBlur={onCellBlur} />
                    <CurveSolverTableCell columnIndex={3} width={'45px'} onCellFocused={onCellFocused} name='V' label='V' model={solverModel} handleChange={handleChange} onAddLineAfter={onAddAfter} onAddLineBefore={onAddBefore} onAddLineEnd={onAddEnd} onRemoveLine={onRemove} onMoveDown={onMoveDown} onMoveUp={onMoveUp} onCopyRow={onCopyRow} onCellBlur={onCellBlur} />
                    <CurveSolverTableCell columnIndex={4} width={'45px'} onCellFocused={onCellFocused} name='V130' label='V<sub>130</sub>' model={solverModel} handleChange={handleChange} onAddLineAfter={onAddAfter} onAddLineBefore={onAddBefore} onAddLineEnd={onAddEnd} onRemoveLine={onRemove} onMoveDown={onMoveDown} onMoveUp={onMoveUp} onCopyRow={onCopyRow} onCellBlur={onCellBlur} />
                    <CurveSolverTableCell columnIndex={5} width={'45px'} onCellFocused={onCellFocused} name='V150' label='V<sub>150</sub>' model={solverModel} handleChange={handleChange} onAddLineAfter={onAddAfter} onAddLineBefore={onAddBefore} onAddLineEnd={onAddEnd} onRemoveLine={onRemove} onMoveDown={onMoveDown} onMoveUp={onMoveUp} onCopyRow={onCopyRow} onCellBlur={onCellBlur} />
                    <CurveSolverTableCell columnIndex={6} width={'45px'} onCellFocused={onCellFocused} name='Vk' label='V<sub>k</sub>' margin='0px 4px 0px 0px' model={solverModel} handleChange={handleChange} onAddLineAfter={onAddAfter} onAddLineBefore={onAddBefore} onAddLineEnd={onAddEnd} onRemoveLine={onRemove} onMoveDown={onMoveDown} onMoveUp={onMoveUp} onCopyRow={onCopyRow} onCellBlur={onCellBlur} />
                    {/*zatím není VPOM */}
                    <CurveSolverTableCell columnIndex={7} width={'52px'} name='I' label='I' model={solverModel} handleChange={handleChange} onAddLineAfter={onAddAfter} onAddLineBefore={onAddBefore} onAddLineEnd={onAddEnd} onRemoveLine={onRemove} onMoveDown={onMoveDown} onMoveUp={onMoveUp} onCopyRow={onCopyRow} onCellBlur={onCellBlur} />
                    <CurveSolverTableCell columnIndex={8} width={'52px'} name='I130' label='I<sub>130</sub>' model={solverModel} handleChange={handleChange} onAddLineAfter={onAddAfter} onAddLineBefore={onAddBefore} onAddLineEnd={onAddEnd} onRemoveLine={onRemove} onMoveDown={onMoveDown} onMoveUp={onMoveUp} onCopyRow={onCopyRow} onCellBlur={onCellBlur} />
                    <CurveSolverTableCell columnIndex={9} width={'52px'} name='I150' label='I<sub>150</sub>' model={solverModel} handleChange={handleChange} onAddLineAfter={onAddAfter} onAddLineBefore={onAddBefore} onAddLineEnd={onAddEnd} onRemoveLine={onRemove} onMoveDown={onMoveDown} onMoveUp={onMoveUp} onCopyRow={onCopyRow} onCellBlur={onCellBlur} />
                    <CurveSolverTableCell columnIndex={10} width={'53px'} name='Ik' label='I<sub>k</sub>' margin='0px 4px 0px 0px' model={solverModel} handleChange={handleChange} onAddLineAfter={onAddAfter} onAddLineBefore={onAddBefore} onAddLineEnd={onAddEnd} onRemoveLine={onRemove} onMoveDown={onMoveDown} onMoveUp={onMoveUp} onCopyRow={onCopyRow} onCellBlur={onCellBlur} />
                    {/*zatím není EPOM */}
                    {/*zatím není Lk1Stav */}
                    <CurveSolverTableCell columnIndex={11} width={'52px'} onCellFocused={onCellFocused} name='Lk1' label='L<sub>k,1</sub>' model={solverModel} handleChange={handleChange} onAddLineAfter={onAddAfter} onAddLineBefore={onAddBefore} onAddLineEnd={onAddEnd} onRemoveLine={onRemove} onMoveDown={onMoveDown} onMoveUp={onMoveUp} onCopyRow={onCopyRow} onCellBlur={onCellBlur} />
                    <CurveSolverCheckboxCell isCheckboxVisible={!isFirst} isChecked={solverModel.inflexWithPrevious} onCheckedChange={(ch) => { handleInflexChange(false, ch); }} />
                    <CurveSolverTableDoubleCell columnIndex={12} nameUp='n1' nameDown='nI1' labelUp='n<sub>1</sub>' labelDown='n<sub>I,1</sub>' model={solverModel} handleChange={handleChange} onAddLineAfter={onAddAfter} onAddLineBefore={onAddBefore} onAddLineEnd={onAddEnd} onRemoveLine={onRemove} onMoveDown={onMoveDown} onMoveUp={onMoveUp} onCopyRow={onCopyRow} onCellBlur={onCellBlur} />
                    <CurveSolverTableCell columnIndex={13} width={'52px'} onCellFocused={onCellFocused} name='Lk2' label='L<sub>k,2</sub>' model={solverModel} handleChange={handleChange} onAddLineAfter={onAddAfter} onAddLineBefore={onAddBefore} onAddLineEnd={onAddEnd} onRemoveLine={onRemove} onMoveDown={onMoveDown} onMoveUp={onMoveUp} onCopyRow={onCopyRow} onCellBlur={onCellBlur} />
                    <CurveSolverCheckboxCell isCheckboxVisible={!isLast} isChecked={solverModel.inflexWithNext} onCheckedChange={(ch) => { handleInflexChange(true, ch); }} />
                    <CurveSolverTableDoubleCell columnIndex={14} nameUp='n2' nameDown='nI2' labelUp='n<sub>2</sub>' labelDown='n<sub>I,2</sub>' margin='0px 4px 0px 0px' model={solverModel} handleChange={handleChange} onAddLineAfter={onAddAfter} onAddLineBefore={onAddBefore} onAddLineEnd={onAddEnd} onRemoveLine={onRemove} onMoveDown={onMoveDown} onMoveUp={onMoveUp} onCopyRow={onCopyRow} onCellBlur={onCellBlur} />

                    <CurveSolverTableCell columnIndex={15} width={'52px'} name='Lk1130' label='L<sub>k,1,130</sub>' model={solverModel} handleChange={handleChange} onAddLineAfter={onAddAfter} onAddLineBefore={onAddBefore} onAddLineEnd={onAddEnd} onRemoveLine={onRemove} onMoveDown={onMoveDown} onMoveUp={onMoveUp} onCopyRow={onCopyRow} onCellBlur={onCellBlur} />
                    <CurveSolverTableDoubleCell columnIndex={16} nameUp='n1130' nameDown='nI1130' labelUp='n<sub>1,130</sub>' labelDown='n<sub>I,1,130</sub>' model={solverModel} handleChange={handleChange} onAddLineAfter={onAddAfter} onAddLineBefore={onAddBefore} onAddLineEnd={onAddEnd} onRemoveLine={onRemove} onMoveDown={onMoveDown} onMoveUp={onMoveUp} onCopyRow={onCopyRow} onCellBlur={onCellBlur} />

                    <CurveSolverTableCell columnIndex={17} width={'52px'} name='Lk2130' label='L<sub>k,2,130</sub>' model={solverModel} handleChange={handleChange} onAddLineAfter={onAddAfter} onAddLineBefore={onAddBefore} onAddLineEnd={onAddEnd} onRemoveLine={onRemove} onMoveDown={onMoveDown} onMoveUp={onMoveUp} onCopyRow={onCopyRow} onCellBlur={onCellBlur} />
                    <CurveSolverTableDoubleCell columnIndex={18} margin='0px 4px 0px 0px' nameUp='n2130' nameDown='nI2130' labelUp='n<sub>2,130</sub>' labelDown='n<sub>I,2,130</sub>' model={solverModel} handleChange={handleChange} onAddLineAfter={onAddAfter} onAddLineBefore={onAddBefore} onAddLineEnd={onAddEnd} onRemoveLine={onRemove} onMoveDown={onMoveDown} onMoveUp={onMoveUp} onCopyRow={onCopyRow} onCellBlur={onCellBlur} />

                    <CurveSolverTableCell columnIndex={19} width={'52px'} name='Lk1150' label='L<sub>k,1,150</sub>' model={solverModel} handleChange={handleChange} onAddLineAfter={onAddAfter} onAddLineBefore={onAddBefore} onAddLineEnd={onAddEnd} onRemoveLine={onRemove} onMoveDown={onMoveDown} onMoveUp={onMoveUp} onCopyRow={onCopyRow} onCellBlur={onCellBlur} />
                    <CurveSolverTableDoubleCell columnIndex={20} nameUp='n1150' nameDown='nI1150' labelUp='n<sub>1,150</sub>' labelDown='n<sub>I,1,150</sub>' model={solverModel} handleChange={handleChange} onAddLineAfter={onAddAfter} onAddLineBefore={onAddBefore} onAddLineEnd={onAddEnd} onRemoveLine={onRemove} onMoveDown={onMoveDown} onMoveUp={onMoveUp} onCopyRow={onCopyRow} onCellBlur={onCellBlur} />

                    <CurveSolverTableCell columnIndex={21} width={'52px'} name='Lk2150' label='L<sub>k,2,150</sub>' model={solverModel} handleChange={handleChange} onAddLineAfter={onAddAfter} onAddLineBefore={onAddBefore} onAddLineEnd={onAddEnd} onRemoveLine={onRemove} onMoveDown={onMoveDown} onMoveUp={onMoveUp} onCopyRow={onCopyRow} onCellBlur={onCellBlur} />
                    <CurveSolverTableDoubleCell columnIndex={22} margin='0px 4px 0px 0px' nameUp='n2150' nameDown='nI2150' labelUp='n<sub>2,150</sub>' labelDown='n<sub>I,2,150</sub>' model={solverModel} handleChange={handleChange} onAddLineAfter={onAddAfter} onAddLineBefore={onAddBefore} onAddLineEnd={onAddEnd} onRemoveLine={onRemove} onMoveDown={onMoveDown} onMoveUp={onMoveUp} onCopyRow={onCopyRow} onCellBlur={onCellBlur} />

                    <CurveSolverTableCell columnIndex={23} width={'54px'} name='Lk1k' label='L<sub>k,1,k</sub>' model={solverModel} handleChange={handleChange} onAddLineAfter={onAddAfter} onAddLineBefore={onAddBefore} onAddLineEnd={onAddEnd} onRemoveLine={onRemove} onMoveDown={onMoveDown} onMoveUp={onMoveUp} onCopyRow={onCopyRow} onCellBlur={onCellBlur} />
                    <CurveSolverTableCell columnIndex={24} width={'54px'} name='n1k' label='n<sub>1,k</sub>' model={solverModel} handleChange={handleChange} onAddLineAfter={onAddAfter} onAddLineBefore={onAddBefore} onAddLineEnd={onAddEnd} onRemoveLine={onRemove} onMoveDown={onMoveDown} onMoveUp={onMoveUp} onCopyRow={onCopyRow} onCellBlur={onCellBlur} />

                    <CurveSolverTableCell columnIndex={25} width={'54px'} name='Lk2k' label='L<sub>k,2,k</sub>' model={solverModel} handleChange={handleChange} onAddLineAfter={onAddAfter} onAddLineBefore={onAddBefore} onAddLineEnd={onAddEnd} onRemoveLine={onRemove} onMoveDown={onMoveDown} onMoveUp={onMoveUp} onCopyRow={onCopyRow} onCellBlur={onCellBlur} />
                    <CurveSolverTableCell columnIndex={26} width={'54px'} name='n2k' label='n<sub>2,k</sub>' model={solverModel} handleChange={handleChange} onAddLineAfter={onAddAfter} onAddLineBefore={onAddBefore} onAddLineEnd={onAddEnd} onRemoveLine={onRemove} onMoveDown={onMoveDown} onMoveUp={onMoveUp} onCopyRow={onCopyRow} onCellBlur={onCellBlur} />
                    {/* </div> */}
                </div>
                {isSelected && <div id='overlay' ref={overlayRef} style={{
                    position: 'relative',
                    top: 0,
                    left: 0,
                    zIndex: 3,
                    width: '1580px',
                    height: '37px',
                    marginTop: '-35px',
                    background: 'rgba(0, 0, 0, 0.2)',
                    pointerEvents: 'none'
                }} />}


            </div>
            {/* <Button onClick={() => setShowDetail(!showDetail)}>DETAIL</Button> */}
            {/* <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {showDetail && solverModel.getAllItemsAsString().map((item, i) => {
                    if (item !== null && item !== undefined)
                        return <div key={'out' + i} style={{ width: '70px ' }}>
                            <div>{item.key}: {item.value}</div>

                            <div style={{ width: '30px', height: '30px', background: GetColorForState(item.state) }}></div>
                        </div>
                })}
            </div> */}
        </div>)
}
