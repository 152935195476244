import React from 'react'
import { UpperMenuType } from '../CurveSolver/Enums/UpperMenuTypes';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material';
import { useValidatorStore } from '../CurveSolver/Stores/ValidatorStore';
import { ValidatorsEnum } from '../CurveSolver/Enums/ValidatorsEnum';


interface NavigationPanelProps {
    items?: NavigationItem[];
    navigationEnabled: boolean;
}
export default function NavigationPane(props: NavigationPanelProps) {
    const { items, navigationEnabled } = props;

    const { initValidator, setValidator, validator } = useValidatorStore();

    const navigate = useNavigate();
    const theme = useTheme();

    function getItemNavigationPath(item: NavigationItem): string {
        if (!item)
            return '';
        if (item.type === UpperMenuType.Projects) {
            return '/routes/' + item.id;
        }
        else if (item.type === UpperMenuType.Routes) {
            return '/solverMain/' + item.id;
        }
        else if (item.type === UpperMenuType.Curves) {
            // tady asi nic .. tak jen pro jistotu přesměruju na projekty třeba
            return '/projects';
        }
        //default?
        return '/projects';
    }
    // onClick={(e) => {
    //     if (!navigationEnabled) {
    //         e.preventDefault();
    //         e.stopPropagation();
    //     }
    // }}
    return (
        <div style={{ display: 'flex', fontSize: '16px', fontWeight: '600' }}>
            {/* {validator !== ValidatorsEnum.None && */} <div style={{ cursor: 'pointer', marginRight: 10 }} onClick={(e) => { if (navigationEnabled) { setValidator(ValidatorsEnum.None); navigate('/validators') } }}>{'Validátory'}</div> {/* } */}
            <div style={{ marginRight: 12 }} >{'>'}</div> {/* } */}
            <div style={{ cursor: 'pointer', marginRight: 12 }} onClick={(e) => { if (navigationEnabled) navigate('/projects') }}>Projekty</div>
            {items && items.map((v, i) => {
                return <div key={i} style={{ display: 'flex', color: i + 1 < items.length ? '#404040' : theme.palette.primary.main, fontWeight: i + 1 < items.length ? '600' : '750', marginBottom: i + 1 < items.length ? '-2px' : '2px' }}>{/* ' → ' */ '>'}<div style={{ cursor: 'pointer', marginRight: 10, marginLeft: 10, marginTop: 0 }} onClick={(e) => { if (navigationEnabled) navigate(getItemNavigationPath(v)) }}>{v.text}</div></div>;
            })
            }
        </div>
    )
}

export class NavigationItem {
    id: number;
    text: string;
    type: UpperMenuType

    constructor(_id: number, _text: string, _type: UpperMenuType) {
        this.id = _id;
        this.text = _text;
        this.type = _type;
    }
}