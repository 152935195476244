import { create } from "zustand";
import { ApiDataClient } from "../../Api/ApiDataClient";
import { ProjectDTO } from "../../Api/ApiServer";

interface ProjectsStoreState {
    projects?: ProjectDTO[];
    // load: (perms: DataStandardDTO[]) => void
    initProjects: () => Promise<ProjectDTO[] | undefined>;
}


export const useProjectsStore = create<ProjectsStoreState>()((set) => ({
    projects: [],
    // load: (by) => set((state) => ({ standardsData: by })),
    initProjects: async() => {
        let projects = (await new ApiDataClient().GetProjects()).projects;
        set((state) => ({ projects: projects }));
        return projects;
    },
   
}))