import * as React from 'react';
import { ProjectWiseProjectModel } from './Models/ProjectWiseProjectModel';
import { ProjectWiseProjectInfoModel } from './Models/ProjectWiseProjectInfoModel';
import { Box, CircularProgress, Grid, Stack, Switch, Typography } from '@mui/material';
import ProjectWiseProjectsUpperScene from './ProjectWiseProjectsUpperScene';
import { getProjects } from './Helpers/getProjects';
import ProjectWiseProjectsBottomScene from './ProjectWiseProjectsBottomScene';
import { GridView, Reorder } from '@mui/icons-material';
import { handleCapture } from './Helpers/printPdf';
import delay from '../Shared/delay';

export interface IProjectWiseProjectSceneProps {
    data: ProjectWiseProjectModel[];
    projectInfoData: ProjectWiseProjectInfoModel[];
    loading: boolean;
    print: boolean;
}

export default function ProjectWiseProjectScene(props: IProjectWiseProjectSceneProps) {
    const { data, print, projectInfoData } = props;
    const [selectedProject, setSelectedProject] = React.useState<ProjectWiseProjectModel | null>(null);
    const [loading, setLoading] = React.useState(false);
    const [showTiles, setShowTiles] = React.useState(true);
    const [printing, setPrinting] = React.useState(false);
    const captureRef = React.useRef<HTMLDivElement>(null);
    
    React.useEffect(() => { if (printing && captureRef.current) handleCapture(captureRef.current); }, [printing]);
    React.useEffect(() => { if (print && captureRef.current) setPrinting(true); delay(2000).then(() => setPrinting(false)) }, [print]);
    React.useEffect(() => { setLoading(props.loading); }, [props.loading]);
    // Call the function to fetch data
    return (
        <Box ref={captureRef} display="flex" flexDirection="column" mr={4} >
            <Box mt={2} overflow={'auto'} display="flex" flexDirection="column">
                <Box alignContent='left'>
                    {loading && <CircularProgress />}
                    {!loading && <ProjectWiseProjectsUpperScene isColored={!printing} data={data} />}
                </Box>
            </Box>
            <Box   display="flex" flexDirection="column">
                <Box alignContent='left'>
                    {loading && <CircularProgress />}
                    {!loading && <Stack direction="row" spacing={1} sx={{ alignItems: 'center', margin: '0px 36px', marginBottom: '12px' }}>
                        <Reorder fontSize='small' color='primary' />
                        <Switch size='small' checked={showTiles} onChange={(event) => { setShowTiles(event.target.checked) }} inputProps={{ 'aria-label': 'ant design' }} />
                        <GridView fontSize='small' color='primary' />
                    </Stack>}
                    {!loading && <ProjectWiseProjectsBottomScene projectInfoData={projectInfoData} data={data} showTiles={showTiles} />}
                </Box>
            </Box>
        </Box>);
}
