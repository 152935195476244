import React from 'react'
import CurveSolverTableCell from './CurveSolverTableCell'
import { Oblouk } from '../Models/ObloukModelAsociative';

interface CurveSolverTableDoubleCellProps {
    nameUp: string;
    nameDown: string;
    labelUp: string;
    labelDown: string;
    model: Oblouk;
    isMezilehla?: boolean;
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    margin?: string;
    onAddLineBefore?: () => void;
    onAddLineAfter?: () => void;
    onAddLineEnd?: () => void;
    onRemoveLine?: () => void;
    onMoveUp?: () => void;
    onMoveDown?: () => void;
    onCopyRow?: () => void;
    onCellBlur?: () => void;
    columnIndex?: number;
}

export default function CurveSolverTableDoubleCell(props: CurveSolverTableDoubleCellProps) {
    const { handleChange, model, nameUp, nameDown, margin, onMoveDown, onMoveUp, onAddLineAfter,
        onAddLineBefore, onAddLineEnd, onRemoveLine, onCopyRow, onCellBlur, isMezilehla, columnIndex, labelUp, labelDown } = props;
    return (
        <div style={{ display: 'flex', flexDirection: 'column', margin: margin }}>
            {isMezilehla === true && <div style={{ /* border: '1px solid #C3C3C3', */color:'#777', borderBottom: '0px solid white' }}>
                <div style={{ fontSize: '9px', marginLeft:'4px', paddingTop:'2px' }} dangerouslySetInnerHTML={{ __html: labelUp ? labelUp : '' }}>{/* nameUp */}</div>
                <div style={{ width: '100%', height: '1px', background: '#C3C3C3' }}></div>
                <div style={{ fontSize: '9px', marginLeft:'4px' }} dangerouslySetInnerHTML={{ __html: labelDown ? labelDown : '' }}>{/* nameDown */}</div>
            </div>}
            <div style={{marginTop:'-1px'}}>
                <CurveSolverTableCell columnIndex={columnIndex}
                    onCellBlur={onCellBlur}
                    onCopyRow={onCopyRow}
                    onMoveDown={onMoveDown}
                    onMoveUp={onMoveUp}
                    onAddLineAfter={onAddLineAfter}
                    onAddLineBefore={onAddLineBefore}
                    onAddLineEnd={onAddLineEnd}
                    onRemoveLine={onRemoveLine}
                    margin='0px'
                    isUpperInDouble
                    isInDouble
                    handleChange={handleChange}
                    model={model}
                    name={nameUp}
                    label={labelUp} />
            </div>
            <div >
                <CurveSolverTableCell columnIndex={columnIndex}
                    onCellBlur={onCellBlur}
                    onCopyRow={onCopyRow}
                    onMoveDown={onMoveDown}
                    onMoveUp={onMoveUp}
                    onAddLineAfter={onAddLineAfter}
                    onAddLineBefore={onAddLineBefore}
                    onAddLineEnd={onAddLineEnd}
                    onRemoveLine={onRemoveLine}
                    margin='0px'
                    isInDouble
                    handleChange={handleChange}
                    model={model}
                    name={nameDown} 
                    label={labelDown}/>
            </div>
        </div>
    )
}
