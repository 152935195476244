import React, { useEffect } from 'react'
import logo from './Assets/SG_Logo.png';
import { Button, IconButton, InputAdornment, Popover, TextField, Tooltip, Typography, useTheme } from '@mui/material';
import { Add, ContentCopy, DeleteOutlined, GetApp, Save, Search, SettingsOutlined, Upload } from '@mui/icons-material';
import CurveSolverBaseSettingsDialog from './CurveSolverBaseSettingsDialog';
import { useMediaPredicate } from 'react-media-hook'
import { Oblouk } from './Models/ObloukModelAsociative';
import { useUserInfoStore } from '../Stores/UserInfoStore';
import UsersPopup from '../Users/UsersPopup';
import './CurveSolverUpperMenu.css'
import { UpperMenuType } from './Enums/UpperMenuTypes';
import NavigationPanel, { NavigationItem } from '../Shared/NavigationPanel';
import { useValidatorStore } from './Stores/ValidatorStore';
import { ValidatorsEnum } from './Enums/ValidatorsEnum';
import ChooseTenantDialog from '../Auth/ChooseTenantDialog';
import { APIAuthClient } from '../Api/ApiAuthClient';

interface CurveSolverUpperMenuProps {
    onAddProject: () => void;
    navigationEnabled: boolean;
    // selectedOblouk: Oblouk | null;
    showChangeTenantDialog: () => void;
    menuType: UpperMenuType;
    navigationItems?: NavigationItem[]
}

export default function CurveSolverUpperMenu(props: CurveSolverUpperMenuProps) {
    const { showChangeTenantDialog, menuType, onAddProject, navigationItems, navigationEnabled } = props;
    const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
    const openDropDownUserMenu = Boolean(anchorEl);
    const [dropDownOpen, setDropDownOpen] = React.useState(false);
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const dropDownId = dropDownOpen ? 'simple-popover' : undefined;

    const biggerThan1200 = useMediaPredicate("(min-width: 1200px)");
    const { initUserStore, userName, userSurname, tenantName, initials, tenantId, userId } = useUserInfoStore();
    const { initValidator, validator } = useValidatorStore();

    const theme = useTheme();
    useEffect(() => {
        initUserStore();

        if (!validator)
            initValidator();
    }, []);

    function showTenants() {
        setAnchorEl(null);
        //showChangeTenantDialog();

        setDialogOpen(true);
    }

    const changeTenant = (r: number) => {
        new APIAuthClient().ChangeUserTenant(r).then((res) => {
            if (res) {
                initUserStore();
                setDialogOpen(false);
            }
        });
    }

    return (
        <div style={{ position: 'fixed', width: '100%', height: '60px', left: 0, top: 0, boxShadow: '0px 4px 4px 0px #00000040', zIndex: 999, background: '#F5F5F5' }}>
            <div style={{ display: 'flex', height: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ display: 'flex', width: '75%' }} >
                    <img src={logo} style={{ height: '30px', marginLeft: '32px', marginTop: '4px' }} alt='logo' />
                    <div style={{ display: 'flex', textAlign: 'center', justifyContent: 'left', alignItems: 'left', pointerEvents: 'none', marginLeft: 32, marginTop: 5 }}>
                        {/* <Typography variant='h5' fontSize={16} fontWeight='bold' color='primary' margin={1}>SG</Typography>         */}
                        {validator === ValidatorsEnum.None && <Typography variant='h5' fontSize={20} fontWeight='bold' color={theme.palette.primary.main}>Validátory</Typography>
                        }
                        {validator === ValidatorsEnum.BimValidator && <Typography variant='h5' fontSize={20} fontWeight='bold' color={theme.palette.primary.main} sx={{ display: 'flex' }}>IFC <b style={{ marginLeft: '6px', color: '#404040' }}>Validátor</b></Typography>
                        }
                        {validator === ValidatorsEnum.CurveSolver && <Typography variant='h5' fontSize={20} fontWeight='bold' color={theme.palette.primary.main} sx={{ display: 'flex' }}>GPK <b style={{ marginLeft: '6px', color: '#404040' }}>Validátor</b></Typography>
                        }
                        {validator === ValidatorsEnum.SlopeGuard && <Typography variant='h5' fontSize={20} fontWeight='bold' color={theme.palette.primary.main}>SlopeGuard</Typography>
                        }

                    </div>
                    {menuType === UpperMenuType.Curves && <div style={{ display: 'flex', maxHeight: '30px', textAlign: 'center', width: '80%', justifyContent: 'space-evenly', alignItems: 'left', marginTop: '4px' }}>
                        {navigationItems && <div style={{ width: '100%', marginTop: '4px', marginLeft: '64px', marginRight: 'auto', textAlign: 'left' }}><NavigationPanel navigationEnabled={navigationEnabled} items={navigationItems} /></div>}
                    </div>
                    }
                    {menuType === UpperMenuType.Tilts && <div style={{ display: 'flex', maxHeight: '30px', textAlign: 'center', width: '80%', justifyContent: 'space-evenly', alignItems: 'left', marginTop: '4px' }}>
                        {navigationItems && <div style={{ width: '100%', marginTop: '4px', marginLeft: '64px', marginRight: 'auto', textAlign: 'left' }}><NavigationPanel navigationEnabled={navigationEnabled} items={navigationItems} /></div>}
                    </div>
                    }
                    {menuType === UpperMenuType.Projects && /* biggerThan1200 && */ <div style={{ display: 'flex', maxHeight: '30px', textAlign: 'left', width: '80%', justifyContent: 'left', alignItems: 'left', marginTop: '4px' }}>
                        <Button sx={{ minWidth: '160px', marginLeft: '32px', marginRight: '32px' }} onClick={onAddProject} size='small' variant='contained' startIcon={<Add />} >Přidat Projekt</Button>
                        <TextField onKeyDown={() => { }} onChange={(e) => { }}
                            placeholder={'Hledání ...'} variant='outlined' size='small' sx={{ borderRadius: '20px' }} InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton onClick={() => { }}>
                                            <Search />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                                style: {
                                    height: '30px',
                                    padding: '0 8px',
                                    borderRadius: '20px'
                                },
                            }} />
                    </div>
                    }
                    {menuType === UpperMenuType.Routes && /* biggerThan1200 && */ <div style={{ display: 'flex', maxHeight: '30px', textAlign: 'center', width: '80%', justifyContent: 'space-evenly', alignItems: 'left', marginTop: '4px' }}>
                        {navigationItems && <div style={{ width: '100%', marginTop: '4px', marginLeft: '64px', marginRight: 'auto', textAlign: 'left' }}><NavigationPanel  navigationEnabled={navigationEnabled} items={navigationItems} /></div>}
                        {/* <Button sx={{ minWidth: '160px', marginLeft: '32px', marginRight: 'auto' }} onClick={onAddRoute} size='small' variant='outlined' startIcon={<Add />} >Přidat trasu</Button> */}
                    </div>
                    }
                    {menuType === UpperMenuType.Validators && /* biggerThan1200 && */ <div style={{ display: 'flex', maxHeight: '30px', textAlign: 'center', width: '80%', justifyContent: 'space-evenly', alignItems: 'left', marginTop: '4px' }}>

                    </div>
                    }
                    {/* {menuType === UpperMenuType.Projects && !biggerThan1200 && <div style={{ display: 'flex', maxHeight: '30px', textAlign: 'center', width: '80%', justifyContent: 'space-evenly', alignItems: 'center', marginTop: '4px' }}>
                        <Button sx={{ minWidth: '50px' }} onClick={onAddOblouk} size='small' variant='contained' ><Add /></Button>
                    </div>
                    } */}
                </div>

                <div style={{ position: 'absolute', right: '20px', display: 'flex', top: 2 }}>
                    <div style={{ fontSize: '16px', textAlign: 'right', marginTop: '8px' }}>
                        <div style={{ color: theme.palette.secondary.main }}>{userName}{' '}{userSurname}</div>
                        <div style={{ fontWeight: 'bold', marginTop: '-4px', color: theme.palette.primary.main }}>{tenantName}</div>
                    </div>
                    <Tooltip title={'Profil uživatele ' + userName + ' ' + userSurname}>
                        <div className='user-button'
                            onClick={(e) => { setAnchorEl(e.currentTarget); }}
                        >{initials}</div>
                    </Tooltip>
                    <Popover
                        id={dropDownId}
                        PaperProps={{ sx: { border: '1px solid #C3C3C3', borderRadius: '16px', background: '#F5F5F5' } }}
                        sx={{ zIndex: 50002 }}
                        open={openDropDownUserMenu}
                        anchorEl={anchorEl}
                        onClose={() => { setAnchorEl(null) }}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                    >
                        <div className="popup-content-div" >
                            <UsersPopup showChangeTenantDialog={showTenants} />
                        </div>
                    </Popover>
                </div>
            </div>
            <ChooseTenantDialog selectedTenantId={tenantId} userId={userId} isOpen={dialogOpen} onClose={(r) => { if (r) changeTenant(r); else setDialogOpen(false); }} />
        </div >
    )
}

