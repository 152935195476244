export enum ObloukItemState {
    OkReadOnly = 0,
    OkInput = 1,
    //TODO: lepší název
    Zlute = 2, //3 - original
    Oranzove = 3, //4 - original
    OranzoveSrafovane = 4, //5- original
    Whisper = 5,
    //TODO: lepší název
    ZeleneSeVzorkem = 6, //14 original
    Error = 99 //99 original

}