import axios from "axios";
import { BIMDataValidatorClient, RefreshTokenRequest } from "./ApiServer";

export class SingletonRefreshClient {
    public static isRefreshing: boolean;
    private constructor() {
    }
    public static setRefresh() {
        this.isRefreshing = true;
    }
    public static completeRefresh() {
        this.isRefreshing = false;
    }
    public static async refreshToken(): Promise<void> {

        let tokens = (await this.GetClientToAuth().refreshToken(new RefreshTokenRequest({
            accessToken: localStorage.getItem('token')!,
            refreshToken: localStorage.getItem('refresh_token')!
        })));
        if (tokens.accessToken === undefined) throw new Error('token is undefined');
        localStorage.setItem('token', tokens.accessToken);
        localStorage.setItem('token_expires', tokens.expireAt?.toString()!);
        localStorage.setItem('refresh_token', tokens.refreshToken!);

    }
    private static GetClientToAuth(): BIMDataValidatorClient {
        const axiosApiInstance = axios.create();

        // Request interceptor for API calls
        axiosApiInstance.interceptors.request.use(
            async config => {
                // let culture = localStorage.getItem('i18nextLng');
                config.headers = {
                    // 'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'TimeZone': Intl.DateTimeFormat().resolvedOptions().timeZone
                }
                return config;
            },
            error => {
                Promise.reject(error)
            });
        axiosApiInstance.interceptors.response.use((response) => {
            return response
        }, async function (error) {
            console.log('error', error);
            // const originalRequest = error.config;
            // if (error.response.status === 401) {
            //     console.log('401.1', window.location.href);
            //     var items = window.location.href.split("/");
            //     window.location.href = window.location.origin + '/' + items[1] + '/' + items[2];
            // }
            // if (error.response.status === 401 && !originalRequest._retry) {
            //     try {
            //         if (error.response.headers['is-token-expired'] === 'true') {
            //             console.log('refresh token', error);
            //             originalRequest._retry = true;
            //             await new APIUsersHelper().RefreshToken();
            //             axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('bearer');
            //             return axiosApiInstance(originalRequest);
            //         }
            //         else throw new Error('unathorized but refresh token not expires');
            //     }
            //     catch (e) {
            //         throw new Error('unable to refresh token');
            //     }
            // }
            return Promise.reject(error);
        });
        return new BIMDataValidatorClient(this.GetApiAddress(), axiosApiInstance);
    }
    protected static GetApiAddress(): string {
        // console.log(process.env.REACT_APP_API_URL);
        // if (process.env.REACT_APP_API_URL === 'NOT')
        //     return '';
        // else return process.env.REACT_APP_API_URL!;
        return process.env.REACT_APP_API_URL!;
    }

}