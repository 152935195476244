import { Button, IconButton, TextField, ToggleButton, ToggleButtonGroup, styled } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Oblouk } from './Models/ObloukModelAsociative';
import SolverField from './SolverField';
import { GetColorForState } from './Helpers/GetColorForStateHelper';
import { Close, Info, PreviewSharp } from '@mui/icons-material';
import delay from '../Shared/delay';
import CurveSolverMainDetail from './CurveSolverMainDetail';
import CurveSolverVytycovaciDetail from './CurveSolverVytycovaciDetail';

interface CurveSolverDetailSceneProps {
    obloukInput: Oblouk;
    previousObloukInput: Oblouk | null;
    nextObloukInput: Oblouk | null;
    onChangedDetail: (changed: Oblouk) => void;
    outsideChanged: boolean;
    closeDetail: () => void;
    inputHeight: string;
}
const TabHeaderContainer = styled(ToggleButtonGroup)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '48px', // Set the desired height for the tab header
    backgroundColor: theme.palette.grey[100], // Add a background color
    borderRadius: '4px', // Add rounded corners
    '& .MuiToggleButton-root': {
        textTransform: 'none', // Use the default text casing
        fontSize: '16px', // Set the font size as needed
        fontWeight: 500, // Set the font weight as needed
        padding: '0 20px', // Add padding to create space between the toggle buttons
        color: theme.palette.text.primary, // Use primary text color
        border: 'none', // Remove the default button border
        '&.Mui-selected': {
            color: theme.palette.primary.main, // Set the text color when the button is selected
            backgroundColor: theme.palette.grey[200], // Add a background color when selected
            '&:hover': {
                backgroundColor: theme.palette.grey[200], // Add a background color when hovering over the selected button
            },
            fontWeight: '800',
        },
        '&:hover': {
            backgroundColor: theme.palette.grey[50], // Add a background color when hovering over the unselected button
        },
    },
}));
export default function CurveSolverDetailScene(props: CurveSolverDetailSceneProps) {
    const { obloukInput, onChangedDetail, outsideChanged, closeDetail, previousObloukInput, inputHeight, nextObloukInput } = props;
    const [tabSelected, setTabSelected] = React.useState<number | null>(0);
    const handleTabSelection = (
        event: React.MouseEvent<HTMLElement>,
        newIndex: number | null,
    ) => {
        setTabSelected(newIndex);
    };
    return (
        <div style={{ overflow: 'auto', height: inputHeight }}>
            <div style={{ textAlign: 'left', display: 'flex', marginLeft: '12px' }}>
                <TabHeaderContainer
                    value={tabSelected}
                    exclusive
                    onChange={handleTabSelection}
                    aria-label="text alignment"
                >
                    <ToggleButton value={0} aria-label="left aligned" sx={{ marginLeft: '8px' }}>
                        HLAVNÍ
                    </ToggleButton>
                    <ToggleButton value={1} aria-label="left aligned">
                        VYTYČOVACÍ
                    </ToggleButton>
                </TabHeaderContainer>
            </div>
            {tabSelected === 0 &&
                <CurveSolverMainDetail
                    nextObloukInput={nextObloukInput}
                    previousObloukInput={previousObloukInput}
                    closeDetail={closeDetail}
                    obloukInput={obloukInput}
                    onChangedDetail={onChangedDetail}
                />}
            {tabSelected === 1 && <CurveSolverVytycovaciDetail
                previousObloukInput={previousObloukInput}
                nextObloukInput={nextObloukInput}
                closeDetail={closeDetail}
                obloukInput={obloukInput}
                onChangedDetail={onChangedDetail} />}
        </div>
    );
};
