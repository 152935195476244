import { LayersOutlined, SettingsOutlined } from '@mui/icons-material';
import { Button, Checkbox, FormControlLabel, IconButton, TextField, Typography } from '@mui/material'
import React from 'react'
interface LeafletMapMenuProps {
    showOrto: boolean;
    showZtm: boolean;
    showKatastr: boolean;
    showPerpendicular: boolean;
    showArrows: boolean;
    showHectos: boolean;
    lineWidth: number;
    perpendicularWidth: number;
    setShowOrto: (show: boolean) => void;
    setShowZtm: (show: boolean) => void;
    setShowKatastr: (show: boolean) => void;
    setShowPerpendicular: (show: boolean) => void;
    setShowHectos: (show: boolean) => void;
    setLineWidth: (width: number) => void;
    setPerpendicularWidth: (width: number) => void;
    setShowArrows: (show: boolean) => void;

}

export default function LeafletMapMenu(props: LeafletMapMenuProps) {
    const { showOrto, showZtm, showKatastr, lineWidth, setShowOrto, setShowZtm, setShowKatastr, setLineWidth, perpendicularWidth,
        setPerpendicularWidth, setShowPerpendicular, showPerpendicular, setShowArrows, showArrows, setShowHectos, showHectos } = props;
    const [showMenu, setShowMenu] = React.useState(false);
    const [showLayers, setShowLayers] = React.useState(false);

    return (
        <div style={{ position: 'absolute', top: '40px', zIndex: 100000, right: '40px', background: 'transparent', display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
            <div style={{ position: 'relative', background: 'white', zIndex: 100001, border: '1px solid #C3C3C3', borderRadius: '16px', boxShadow: 'rgb(0 0 0 / 20%) 0px 4px 4px' }}>
                {!showMenu && <div style={{ position: 'relative', right: '0px' }}><IconButton onClick={() => { setShowMenu(!showMenu) }}><SettingsOutlined /></IconButton></div>}
                {showMenu && <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}><div style={{ fontSize: '14px', fontWeight: 'bold', lineHeight: '40px', color: '#777777', marginLeft: '20px' }}>NASTAVENÍ</div><IconButton onClick={() => { setShowMenu(!showMenu) }}><SettingsOutlined /></IconButton></div>}
                {showMenu && <div style={{ marginTop: '20px', width: '200px', marginLeft: '20px', marginRight: '20px', display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', fontSize:'16px' }}>
                        <div>Šířka trasy</div>
                        <input type='number' style={{ border: '0px solid white', borderBottom: '1px solid #777777', width: '32px', textAlign: 'right', marginLeft: '16px' }} placeholder='Šířka trasy' value={lineWidth} onChange={(e) => { setLineWidth(parseInt(e.currentTarget.value)) }} />
                    </div>
                    <FormControlLabel control={<Checkbox onChange={(e, ch) => { setShowArrows(ch) }} checked={showArrows}></Checkbox>} label={<Typography fontWeight={showArrows ? 'bold' : 'normal'} color={showArrows ? 'primary' : 'action'}>Šipky</Typography>} />
                    <FormControlLabel control={<Checkbox onChange={(e, ch) => { setShowHectos(ch) }} checked={showHectos}></Checkbox>} label={<Typography fontWeight={showHectos ? 'bold' : 'normal'} color={showHectos ? 'primary' : 'action'}>Hektometry</Typography>} />
                    <FormControlLabel control={<Checkbox onChange={(e, ch) => { setShowPerpendicular(ch) }} checked={showPerpendicular}></Checkbox>} label={<Typography fontWeight={showHectos ? 'bold' : 'normal'} color={showHectos ? 'primary' : 'action'}>Kolmice</Typography>} />
                    <div aria-disabled={!showPerpendicular} style={{ marginBottom: '20px', marginLeft: '32px' }}>
                        <div style={{ display: 'flex' }}>
                            <div>Šířka kolmice</div>
                            <input type='number' style={{ border: '0px solid white', borderBottom: '1px solid #777777', width: '32px', textAlign: 'right', marginLeft: '16px' }} disabled={!showPerpendicular} placeholder='Šířka kolmice' value={perpendicularWidth} onChange={(e) => { setPerpendicularWidth(parseInt(e.currentTarget.value)) }} />
                        </div>
                    </div>

                    {/* <Button onClick={(e) => { setActHeight('1200px'); }}>change size</Button> */}
                </div>}
            </div>
            {!showLayers && <div style={{ background: 'white', maxWidth: '40px', zIndex: 100002, border: '1px solid #C3C3C3', borderRadius: '16px', boxShadow: 'rgb(0 0 0 / 20%) 0px 4px 4px', marginTop: '12px' }}>
                <div style={{ position: 'relative', right: '0px' }}><IconButton onClick={() => { setShowLayers(!showLayers) }}><LayersOutlined /></IconButton></div>
            </div>}
            {showLayers && <div style={{ background: 'white', zIndex: 100002, border: '1px solid #C3C3C3', borderRadius: '16px', boxShadow: 'rgb(0 0 0 / 20%) 0px 4px 4px', marginTop: '12px' }}>

                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', fontSize: '14px', fontWeight: 'bold', lineHeight: '40px'}}><div style={{marginLeft:'20px',color:'#777777'}}>VRSTVY</div><IconButton onClick={() => { setShowLayers(!showLayers) }}><LayersOutlined /></IconButton></div>
                <div style={{ marginTop: '12px', marginLeft: '20px', width: '200px', marginRight: '20px', display: 'flex', flexDirection: 'column' }}>
                    <FormControlLabel control={<Checkbox onChange={(e, ch) => { setShowOrto(ch) }} checked={showOrto}></Checkbox>} label={<Typography fontWeight={showOrto ? 'bold' : 'normal'} color={showOrto ? 'primary' : 'action'}>ORTO</Typography>} />
                    <FormControlLabel control={<Checkbox onChange={(e, ch) => { setShowZtm(ch) }} checked={showZtm}></Checkbox>} label={<Typography fontWeight={showZtm ? 'bold' : 'normal'} color={showZtm ? 'primary' : 'action'}>ZTM</Typography>} />
                    <FormControlLabel control={<Checkbox onChange={(e, ch) => { setShowKatastr(ch) }} checked={showKatastr}></Checkbox>} label={<Typography fontWeight={showKatastr ? 'bold' : 'normal'} color={showKatastr ? 'primary' : 'action'}>Katastrální</Typography>} />
                    {/* <TextField type='number' label='Šířka' value={lineWidth} onChange={(e) => { setLineWidth(parseInt(e.currentTarget.value)) }} /> */}
                    {/* <Button onClick={(e) => { setActHeight('1200px'); }}>change size</Button> */}
                </div>
            </div>}
        </div>
    )
}
