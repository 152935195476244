import { useMsal } from '@azure/msal-react';
import { Alert, Button, CircularProgress, Snackbar, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { APIUserClient } from '../Api/ApiUserClient';
import { loginRequest } from '../MsAuth/MsAuthConfig';
import { GetSignupUserDataResponse } from '../Api/ApiServer';

interface UserSignupSceneProps {
}
export default function UserSignupScene(props: UserSignupSceneProps) {
    const [ buttonDisabled, setButtonDisabled] = useState(false);
    const [ infoOpen, setInfoOpen] = useState(false);
    const [token, setToken] = useState<string>();
    const [ localLogin, setLocalLogin] = useState<boolean | undefined>(undefined);
    const [ userData, setUserData] = useState<GetSignupUserDataResponse>();
    const [accessTokenMS, setAccessTokenMS] = React.useState<string>();
    
    const [newPass, setNewPass] = useState<string>('');
    const [newPassCheck, setNewPassCheck] = useState<string>('');

    const location = useLocation();
    const { instance } = useMsal();
    const navigate = useNavigate();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        let tok = queryParams.get('token');
        setToken(tok !== null ? tok : undefined);
      }, [location.search]);

    useEffect(() => {
        if (token) {
            new APIUserClient().GetSignupUserData(token).then((res) => {
                if (res.localLogin != true && res.msLogin === true){
                    localStorage.setItem('ms-state', 'user-signup-microsoft');
                    localStorage.setItem('ms-signup-userid', res.userId!.toString());
                    localStorage.setItem('ms-signup-token', token);
                    navigate('/auth-callback/validators');
                    /* instance.loginPopup(loginRequest).then((data) => {
                        setAccessTokenMS(data.accessToken);
                        setUserData(res);
                    })
                    .catch(e => {
                        console.log(e);
                    }); */
                }
                else setUserData(res);
            });
        }
        else setUserData(undefined);
    }, [token]);

    useEffect(() => {
        /* if (accessTokenMS && userData && userData.userId && token) {
            new APIUserClient().SignupUserMicrosoft(userData?.userId, accessTokenMS, token).then((res) => {
                if (res && res.ok)
                    navigate('/');
                else
                    setInfoOpen(true);
            });
        } */
    }, [accessTokenMS, userData]);

    function changePassword()
    {
        if (newPass.length !== 0 && newPassCheck.length !== 0 && newPass === newPassCheck && userData && userData.userId && token)
        {
            setButtonDisabled(true);
            new APIUserClient().SetUserPassword(userData.userId, newPass, token).then((d) => {   
                setButtonDisabled(false);                
                if (d.ok === true)
                    navigate('/?email=' + d.email);
                else 
                    setInfoOpen(true);
            }); 
        }  
    }
    
    if (userData && !accessTokenMS)
        return (
            <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                {userData.localLogin && userData.msLogin && localLogin === undefined && <div>
                    <div>
                        <div style={{  }}>
                            <Button
                                //disabled={password === "" || userName === ""}
                                variant={'contained'}
                                size='large'
                                style={{ width: '88%', height: '52px', marginBottom: '12px' }}
                                onClick={() => { setLocalLogin(true) }}>
                                <Typography sx={{ marginLeft: '12px', marginTop: '2px', fontWeight: 'bold' }}>Login validátor</Typography>
                            </Button>
                            <Typography
                                sx={{ marginLeft: '12px', marginTop: '6px', marginBottom: '14px' }}
                                color='secondary'
                                fontSize={13}>nebo využijte</Typography>

                            <div >
                                <Button variant='outlined' style={{ width: '88%', height: '52px' }} onClick={() => {
                                    localStorage.setItem('ms-state', 'login');
                                    navigate('/auth-callback/validators');
                                    /* instance.loginPopup(loginRequest).then((data) => {
                                        setAccessTokenMS(data.accessToken);
                                    })
                                    .catch(e => {
                                        console.log(e);
                                    }); */
                                }}><div style={{ display: 'flex' }}>
                                        <img width={32} height={32} src={'https://upload.wikimedia.org/wikipedia/commons/thumb/4/44/Microsoft_logo.svg/2048px-Microsoft_logo.svg.png'} />
                                        <Typography sx={{ marginLeft: '12px', marginTop: '4px', fontWeight: 'bold' }}>Microsoft přihlášení</Typography>
                                    </div></Button>
                            </div>
                        </div>

                    </div>
                </div>}
                {(localLogin === true || (userData.localLogin === true && userData.msLogin !== true)) && <div>
                    <div>
                        <div>
                            <div>
                                <TextField sx={{ margin: '12px', marginLeft: '24px', width: '350px' }} label={'Nové heslo'}
                                    type={"password"}
                                    size='small'
                                    name='new-pass'
                                    variant='outlined'
                                    value={newPass}
                                    onChange={(e) => { setNewPass(e.currentTarget.value); }} />
                            </div>
                            <div>
                                <TextField sx={{ margin: '12px', marginLeft: '24px', width: '350px' }} label={'Nové heslo znovu'}
                                    type={"password"}
                                    size='small'
                                    name='new-pass-check'
                                    variant='outlined'
                                    value={newPassCheck}
                                    onChange={(e) => { setNewPassCheck(e.currentTarget.value); }} />
                            </div>
                        </div>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
                            <Button variant='contained' sx={{ margin: 'auto' }} onClick={changePassword}
                            disabled={newPass.length === 0 || newPassCheck.length === 0 || newPass !== newPassCheck || !userData || !userData.userId || !token || buttonDisabled}
                            >ZMĚNIT HESLO</Button>
                        </div>
                    </div>
                </div>}              
            </div>
        )
    else 
        return <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <CircularProgress/>
            
            <Snackbar open={infoOpen} autoHideDuration={3000} onClose={() => setInfoOpen(false)}>

                <Alert onClose={() => setInfoOpen(false)} severity="error" sx={{ width: '100%' }}>
                    Chyba registrace, prosím kontaktujte podporu
                </Alert>

            </Snackbar>
        </div>
}
