import { GridToolbarContainer, GridToolbarFilterButton, GridToolbarDensitySelector, GridToolbarColumnsButton,useGridApiContext } from '@mui/x-data-grid-premium';
import { Button } from '@mui/material';
import { saveAs } from 'file-saver';
import { Document, Packer, Paragraph, Table, TableCell, TableRow } from 'docx';
import { Receipt, TableChartOutlined } from '@mui/icons-material';

const CustomToolbar = ({ rows, columns, isProject }) => {
    const exportToWord = () => {
        const tableRows = rows.map(row => {
            if (isProject) {
                const cells = columns.map(col => new TableCell({
                    children: [new Paragraph((row.properties[col.field.replace('properties.', '')] ?? '').toString())],
                }));
                return new TableRow({ children: cells });
            }
            else {
                const cells = columns.map(col => new TableCell({
                    children: [new Paragraph((row[col.field] ?? '').toString())],
                }));
                return new TableRow({ children: cells });
            }
        });

        const table = new Table({
            rows: [
                new TableRow({
                    children: columns.map(col => new TableCell({
                        children: [new Paragraph(col.headerName)],
                    })),
                }),
                ...tableRows,
            ],
        });

        const doc = new Document({
            sections: [{
                children: [table],
            }],
        });

        Packer.toBlob(doc).then(blob => {
            saveAs(blob, 'Export_CDE_SŽ.docx');
        });
  };
  const apiRef = useGridApiContext();
  return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            <Button onClick={()=>{apiRef.current.exportDataAsExcel({fileName: 'Export_CDE_SŽ.xlsx'});}} startIcon={<TableChartOutlined />} variant="text" size='small' color="primary" style={{ marginLeft: 8 }}>
                Export do Excelu
            </Button>
            <Button onClick={exportToWord} startIcon={<Receipt />} variant="text" size='small' color="primary" style={{ marginLeft: 8 }}>
                Export do Wordu
            </Button>
        </GridToolbarContainer>
    );
};

export default CustomToolbar;