import { ApiBaseClient } from "./ApiBaseClient";
import { AuthLocalRequest, AuthMicrosoftRequest, AuthResponse, ChangeUserTenantRequest, GetUserInfoResponse, GetUserTenantsResponse, RefreshTokenRequest, SignupMicrosoftRequest, SignupResponse } from "./ApiServer";

export class APIAuthClient extends ApiBaseClient {


    public async LoginMicrosoft(token: string, tenantId?: number): Promise<AuthResponse> {
        let tokens = (await this.GetClientToAuth().loginMicrosoft(new AuthMicrosoftRequest({ token: token, tenantId: tenantId })));
        if (tokens.token !== undefined && tokens.tenantId) //throw new Error('token is undefined');
        {
            localStorage.setItem('token', tokens.token);
            localStorage.setItem('token_expires', tokens.tokenExpiration?.toString()!);
            localStorage.setItem('refresh_token', tokens.refreshToken!);
        }
        // localStorage.setItem('tenant_id', tokens.token);
        return tokens;
        // return false;
    }
    public async Login(username: string, password: string, tenantId?: number): Promise<AuthResponse> {
        let tokens = (await this.GetClientToAuth().loginLocal(new AuthLocalRequest({ username: username, password: password, tenantId: tenantId })));
        if (tokens.token !== undefined && tokens.tenantId) //throw new Error('token is undefined');
        {
            localStorage.setItem('token', tokens.token);
            localStorage.setItem('token_expires', tokens.tokenExpiration?.toString()!);
            localStorage.setItem('refresh_token', tokens.refreshToken!);
        }
        // localStorage.setItem('tenant_id', tokens.token);
        return tokens;
        // return false;
    }

    public async SignupMicrosoft(tenantName: string, token: string): Promise<SignupResponse> {
        let tokens = (await this.GetClientToAuth().signupMicrosoft(new SignupMicrosoftRequest({ tenant: tenantName, token: token })));
        if (tokens.token === undefined) throw new Error('token is undefined');
        localStorage.setItem('token', tokens.token);
        localStorage.setItem('token_expires', tokens.tokenExpiration?.toString()!);
        localStorage.setItem('refresh_token', tokens.refreshToken!);
        // localStorage.setItem('tenant_id', tokens.token);
        return tokens;
        // return false;
    }
    
    public async ChangeUserTenant(tenantId: number):Promise<boolean>{
        let tokens =  (await this.GetAdressedAuthRefreshableClient().changeUserTenant(new ChangeUserTenantRequest({tenantId: tenantId})));

        if (tokens.token === undefined) throw new Error('token is undefined');
        localStorage.setItem('token', tokens.token);
        localStorage.setItem('token_expires', tokens.tokenExpiration?.toString()!);
        localStorage.setItem('refresh_token', tokens.refreshToken!);

        return true;
    }
    
    public async GetLogoBase64():Promise<string | undefined>{
        return (await this.GetClientToAuth().getAppLogo()).base64;
    }
    public async GetFileLink(uri:string):Promise<string>{
        return process.env.REACT_APP_API_URL + '/FileHelper/GetDownloadLinkFromCorsServer/'+encodeURIComponent(uri);
    }
    public async GetUserInfo():Promise<GetUserInfoResponse>{
        return (await this.GetAdressedAuthRefreshableClient().getUserInfo());
    }
    public async GetUserTenants(userId: number):Promise<GetUserTenantsResponse>{
        return (await this.GetAdressedAuthRefreshableClient().getUserTenants(userId));
    }
    public async IsAuthed():Promise<boolean>{
        
        return (await this.GetIsAuthedClient().isAuthed());
    }
}