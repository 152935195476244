import { Alert, AlertTitle, Button, Checkbox, Dialog, DialogContent, FormControlLabel, Slide, Snackbar, Switch, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import MuiDialogHeader from '../Shared/MuiDialogHeader';
import { APIUserClient } from '../Api/ApiUserClient';
import { isEmail } from '../Shared/IsEmail';
import InfoDialog from '../Shared/InfoDialog';

interface SendResetPasswordDialogProps {
    isOpen: boolean;
    onClose: () => void;
}
export default function SendResetPasswordDialog(props: SendResetPasswordDialogProps) {
    const { isOpen, onClose } = props;
    const [ buttonDisabled, setButtonDisabled] = useState(false);
    const [ email, setEmail] = useState('');
    const [ infoOpen, setInfoOpen] = useState(false);
    const [infoProps, setInfoProps] = useState<{label: string, text: string}>();


    useEffect(() => {
        setEmail('');
    }, []);

    useEffect(() => {
        setEmail('');
    }, [isOpen]);
    
    return (
        <Dialog onClose={() => onClose()} open={isOpen}>
            <MuiDialogHeader showCloseText title='RESET HESLA' onClose={() => onClose()}/>
            <DialogContent>
                <div>
                    <div>
                        <TextField
                            sx={{ margin: '12px', marginLeft: '24px', width: '350px' }}
                            label={'Email'}
                            size='small'
                            variant='outlined'
                            value={email}
                            onChange={(e) => { setEmail(e.target.value); }} />
                    </div>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
                        <Button variant='contained' sx={{ margin: 'auto' }} disabled={!isEmail(email) || buttonDisabled} onClick={() => {
                            setButtonDisabled(true);
                            new APIUserClient().ResetUserPasswordSendMail(email).then((e) => {
                                if (e.ok) {
                                    setInfoProps({label: 'Info', text: 'Email s odkazem na reset hesla byl odeslán na zadaný email.'});
                                    setInfoOpen(true);
                                }
                                else {
                                    setInfoProps({label: 'Chyba', text: e.msLogin ? 'Uživatel je přihlášen přes MS identitu, nelze resetovat heslo' : 'Chyba resetu hesla. Kontaktujte podporu.'});
                                    setInfoOpen(true);
                                }
                                setButtonDisabled(false);
                            });
                        }}>RESET</Button>
                    </div>
                   {infoProps && <InfoDialog onClose={() => {
                        setInfoOpen(false);
                        onClose();
                    } } open={infoOpen} label={infoProps.label} text={infoProps.text} />}                   
                </div>
            </DialogContent>
        </Dialog>
    )
}
