import { Alert, Box, Button, Checkbox, Divider, FormControlLabel, FormGroup, Grid, IconButton, Snackbar, TextField, Tooltip, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import './AdminScene.css';
import { AccountBoxOutlined, AccountCircleOutlined, AddCircle, AddCircleOutlineOutlined, AdminPanelSettingsOutlined, CheckBox, Close, ContentCopy, DeleteOutline, EditOutlined, Logout, Microsoft, People, SettingsSystemDaydreamOutlined } from '@mui/icons-material';
import { group } from 'console';
import { useUserInfoStore } from '../Stores/UserInfoStore';
import { TenantDTO, TenantUserDTO } from '../Api/ApiServer';
import { APIUserClient } from '../Api/ApiUserClient';
import NoDataPlaceholder from '../Shared/NoDataPlaceHolder';
import DeleteDialog from '../Shared/DeleteDialog';
import CreateTenantDialog from './CreateTenantDialog';
import AddUserToTenantDialog from './AddUserToTenantDialog';
import NewPasswordDialog from './NewPasswordDialog';
import CreateUserDialog from './CreateUserDialog';
import InfoDialog from '../Shared/InfoDialog';
interface AdminSceneProps {
    isActualTenant: boolean;
}

export default function AdminScene(props: AdminSceneProps) {
    const navigate = useNavigate();
    const { isActualTenant }  = props;
    const { isAdmin, canCreateUsers, canCreateTenants, userId, tenantId, initUserStore } = useUserInfoStore();
    const theme = useTheme();    
    const [tenants, setTenants] = useState<TenantDTO[]>();
    const [selectedTenant, setSelectedTenant] = useState<TenantDTO>();
    const [selectedUser, setSelectedUser] = useState<Partial<TenantUserDTO>>();

    const [tenantDialogOpen, setTenantDialogOpen] = useState(false);
    const [userDialogOpen, setUserDialogOpen] = useState(false);
    const [infoOpen, setInfoOpen] = useState(false);
    const [userCreateDialogOpen, setUserCreateDialogOpen] = useState(false);
    const [newPassDialogOpen, setNewPassDialogOpen] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [buttonResetDisabled, setButtonResetDisabled] = useState(false);
    const [saveConfirm, setSaveConfirm] = useState(false);
    
    const [newPass, setNewPass] = useState<string>('');
    const [emailCreate, setEmailCreate] = useState<string | undefined>(undefined);
    const [deletingTenant, setDeletingTenant] = useState<TenantDTO | null>(null);
    const [updatingTenant, setUpdatingTenant] = useState<TenantDTO | undefined>(undefined);
    const [removingUser, setRemovingUser] = useState<TenantUserDTO | null>(null);

    const [infoProps, setInfoProps] = useState<{label: string, text: string}>();
    
    useEffect(() => {
        refreshData(undefined, undefined);
        if (canCreateTenants ||userId == selectedUser?.id)
            setButtonDisabled(false);
        if (canCreateTenants)
            setButtonResetDisabled(false);

        if (userId === 0)
            initUserStore();
    }, []);

    useEffect(() => {
        refreshData(undefined, undefined);
        if (canCreateTenants ||userId == selectedUser?.id)
            setButtonDisabled(false);
        if (canCreateTenants)
            setButtonResetDisabled(false);
    }, [canCreateUsers, canCreateTenants, userId, tenantId]);

    function refreshData(tenId: number | undefined, userId: number | undefined)
    {
        if (isActualTenant) {
            new APIUserClient().GetUsersTenant(tenantId).then((d) => {
                if (d.tenant)
                {
                    setTenants([d.tenant]);
                    setSelectedTenant(d.tenant);
                    if (userId && d.tenant.users)
                        setSelectedUser(d.tenant.users.find(u => u.id === userId));
                }
            }); 
        }
        else {
            if (canCreateTenants === true)
                new APIUserClient().GetTenantsAdmin().then((d) => {
                    setTenants(d.tenants);
                    if (tenId && d.tenants)
                    {
                        let tenant = d.tenants.find(t => t.id === tenId);
                        setSelectedTenant(tenant);
                        if (userId && tenant && tenant.users)
                            setSelectedUser(tenant.users.find(u => u.id === userId));
                    }
                });   
            else if (canCreateUsers === true)  
                new APIUserClient().GetTenantsUser().then((d) => {
                    setTenants(d.tenants);
                    if (tenId && d.tenants)
                    {
                        let tenant = d.tenants.find(t => t.id === tenId);
                        setSelectedTenant(tenant);
                        if (userId && tenant && tenant.users)
                            setSelectedUser(tenant.users.find(u => u.id === userId));
                    }
                }); 
        }   
    }

    function selectTenant(tenant: TenantDTO | undefined)
    {
        if (tenant === undefined)
        {
            setSelectedUser(undefined); 
            setSelectedTenant(undefined); 
        }
        else if (selectedTenant !== tenant) 
        { 
            setSelectedTenant(tenant); 
            if (tenant.users && selectedUser && tenant.users.some(u => u.id === selectedUser.id))
            {
                setSelectedUser(tenant.users.find(u => u.id === selectedUser.id));
            }
            else
                setSelectedUser(undefined); 
        }
    }

    function saveUser()
    {
        if (selectedUser)
        {
            setButtonDisabled(true);        
            new APIUserClient().SaveUserData(new TenantUserDTO({ id: selectedUser.id, name: selectedUser.name, surname: selectedUser.surname, isAdmin: selectedUser.isAdmin, canCreateUsers: selectedUser.canCreateUsers, canCreateTenants: selectedUser.canCreateTenants })).then((d) => {
                refreshData(selectedTenant ? selectedTenant.id : undefined, selectedUser.id);
                setButtonDisabled(false); 
                setSaveConfirm(true);              
            });   
        }
    }

    function resetPassword()
    {
        if (selectedUser && selectedUser.id)
        {
            setButtonResetDisabled(true);        
            new APIUserClient().ResetPasswordUser(selectedUser.id).then((d) => {
                //refreshData(selectedTenant ? selectedTenant.id : undefined, selectedUser.id);
                if (d.ok && d.newPassword) {
                    setNewPass(d.newPassword);
                    setNewPassDialogOpen(true);
                }
                setButtonResetDisabled(false);               
            });   
        }
    }

    return (
        <div >
            <div style={{ display: 'flex', position: 'fixed', top: 0, width: '100%', backgroundColor: '#F5F5F5', height: '64px', zIndex: 5000, boxShadow: 'rgb(0 0 0 / 40%) 1px 1px 10px' }}>
                <Grid container spacing={6}>
                    <Grid item xs={8}>
                        <div style={{ float: 'left', margin: '5px', marginTop: '8px', marginLeft: '30px' }}>
                            {isActualTenant && <Typography variant='h5' color='primary' sx={{ margin: '8px', fontWeight: 'bolder', color: 'black' }} >SPRÁVA TENANTU</Typography>}
                            {!isActualTenant && <Typography variant='h5' color='primary' sx={{ margin: '8px', fontWeight: 'bolder', color: 'black' }} >ADMINISTRÁTOR</Typography>}
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                        <div style={{ float: 'right', margin: '5px', marginTop: '8px', marginRight: '10px', display: 'flex' }}>
                            <div style={{
                                width: "140px",
                                height: "40px",
                                marginTop: '8px',
                                marginRight: '20px'
                            }}>
                                <Button onClick={() =>{ navigate('/projects'); }} style={{
                                    width: "140px",
                                    height: "40px"
                                }} >
                                    <div className="close-button-div" style={{
                                        display: 'flex'
                                        }}>
                                        <Typography variant='h1' color='primary'  lineHeight={'42px'} fontSize={16}>ZAVŘÍT</Typography>
                                        <Close color='primary' sx={{ marginTop: '9px'}}/> 
                                        {/* <Close sx={{
                                            width: "40px",
                                            height: "40px"
                                        }} />                                        
                                        <Typography variant='h6' color='primary' sx={{ margin: '4px' }} >ZAVŘÍT</Typography> */}
                                    </div>

                                    {/* {showCloseText && <Typography variant='h1' color='primary'  lineHeight={'42px'} fontSize={16}>ZAVŘÍT</Typography>}
                                    <IconButton  onClick={() => { onClose() }} >
                                        <Close color='primary' />
                                    </IconButton> */}
                                </Button>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <div className="flex-container" style={{ marginTop: '72px', height: '100vh' }}>
                {!isActualTenant && <div className="flex-item">
                    <div className="flex-container"  style={{ marginTop: '10px', marginLeft: '25px' }}>                        
                            <AccountCircleOutlined color='primary' sx={{ width: "30px", height: "30px" }}/>
                            <Typography color='primary' sx={{ margin: '4px', fontWeight: 'bolder', caretColor: 'transparent' }} >Přehled Tenantů</Typography>
                        </div>
                        <div style={{ marginTop: '10px', marginLeft: '-25px' }}>
                            <Button  variant="outlined" startIcon={<AddCircle />} color='primary' onClick={() =>{ setTenantDialogOpen(true); }} sx={{ height: "30px", fontSize: '10px', fontWeight: 'bold', boxShadow: 3, caretColor: 'transparent' }} disabled={canCreateTenants !== true}>
                                PŘIDAT TENANT
                            </Button>
                    </div>
                    
                    {tenants && <div style={{ marginTop: '20px', marginLeft: '25px', marginRight: '10px' }}>
                        {tenants.map((tenant) => {
                            return <div key={tenant.id}>
                                <div onClick={() => { selectTenant(tenant) }} style={{ display: 'flex', color: selectedTenant !== undefined && selectedTenant.id === tenant.id ? '#0086CA' : 'black', borderBottom: '1px', borderColor: 'black'  }}>
                                    <div style={{ lineHeight: '40px', margin: '0px 10px', caretColor: 'transparent', cursor: 'pointer' }}>{tenant.name}</div>
                                    <div style={{ display: 'flex', marginLeft: 'auto', marginRight: '0px' }}>
                                        <Tooltip title='Upravit tenant'>
                                            <IconButton color='primary' onClick={() => {
                                                setUpdatingTenant(tenant);
                                                setTenantDialogOpen(true);
                                            }}><EditOutlined /></IconButton>
                                        </Tooltip>
                                        <Tooltip title='Smazat tenant'>
                                            <IconButton color='primary' onClick={() => {
                                                setDeletingTenant(tenant);
                                            }}><DeleteOutline /></IconButton>
                                        </Tooltip>
                                    </div>
                                </div>
                               {/*  <div onClick={() => { selectTenant(tenant) }} style={{ display: 'flex', color: selectedTenant !== undefined && selectedTenant.id === tenant.id ? '#0086CA' : 'black', borderBottom: '1px', borderColor: 'black'  }}>
                                    <div style={{ cursor: 'pointer', margin: '6px', alignContent: 'center', caretColor: 'transparent', borderBottom: '1px' }}>
                                        {tenant.name}
                                    </div>
                                </div>
                                <hr></hr> */}
                            </div>;
                            })
                        }
                        </div>
                    }
                    </div>
                }
                {selectedTenant && <div className="flex-item">
                    <div className="flex-container"  style={{ marginTop: '10px', marginLeft: '25px' }}>                        
                        <People color='primary' sx={{ width: "30px", height: "30px" }}/>
                        <Typography color='primary' sx={{ margin: '4px', fontWeight: 'bolder', caretColor: 'transparent' }} >Přehled Uživatelů</Typography>
                    </div>
                    <div style={{ marginTop: '10px', marginLeft: '-25px' }}>
                        <Button disabled={canCreateUsers !== true} variant="outlined" startIcon={<AddCircle />} color='primary' onClick={() =>{ setUserDialogOpen(true); }} sx={{ height: "30px", fontSize: '10px', fontWeight: 'bold', boxShadow: 3, caretColor: 'transparent' }} >
                            PŘIDAT UŽIVATELE
                        </Button>
                    </div>
                    {/* <div style={{ marginTop: '10px', marginLeft: '-25px' }}>
                        <Button disabled={canCreateUsers !== true && isAdmin !== true} variant="outlined" startIcon={<AddCircle />} color='primary' onClick={() =>{ setUserCreateDialogOpen(true); }} sx={{ height: "30px", fontSize: '10px', fontWeight: 'bold', boxShadow: 3, caretColor: 'transparent' }} >
                            ZALOŽIT UŽIVATELE
                        </Button>
                    </div> */}
                    {selectedTenant.users && <div style={{ marginTop: '20px', marginLeft: '25px', marginRight: '10px' }}>
                        {selectedTenant.users.map((user) => {
                                return <div key={user.id} onClick={() => { setSelectedUser(user); }} style={{ display: 'flex', color: selectedUser !== undefined && selectedUser.id === user.id ? '#0086CA' : 'black', background: selectedUser !== undefined && selectedUser.id === user.id ? '#F5F5F5' : 'white' , borderBottom: '1px', borderColor: 'black', marginTop: '6px'  }}>
                                        <div className="circle">
                                            <p className="circle-inner">
                                                {user.name ? user.name.substring(0,1) : '' }{user.surname ? user.surname.substring(0,1) : ''}
                                            </p>
                                        </div>
                                        <div style={{ cursor: 'pointer', margin: '8px', alignContent: 'center', caretColor: 'transparent', borderBottom: '1px' }}>
                                            {user.name} {user.surname}
                                        </div> <div style={{ display: 'flex', marginLeft: 'auto', marginRight: '0px' }}>
                                            <Tooltip title='Smazat tenant'>
                                                <IconButton color='primary' onClick={() => {
                                                    setRemovingUser(user);
                                                }}><DeleteOutline /></IconButton>
                                            </Tooltip>
                                        </div>
                                    </div>;
                                })
                            }
                        </div> 
                    }
                </div>
                }
                {!selectedTenant && !isActualTenant && <div style={{ margin: '64px', marginLeft: '96px'}}><NoDataPlaceholder title='Vyberte tenant' paragraph='' /></div>}
                {selectedTenant && selectedUser && <div className="flex-item-no-border">
                    <div className="circle-big">
                        <p className="circle-big-inner" >
                            {selectedUser.name ? selectedUser.name.substring(0,1) : '' }{selectedUser.surname ? selectedUser.surname.substring(0,1) : ''}
                        </p>
                    </div>
                    <div>
                        <div className="flex-container" >
                            <div>
                                <TextField sx={{ margin: '12px', marginLeft: '24px', width: '350px' }} label={'Jméno'}
                                    size='small'
                                    name='userName'
                                    variant='outlined'
                                    value={selectedUser.name}
                                    onChange={(e) => { setSelectedUser({ ...selectedUser, name: e.currentTarget.value }); }} />
                            </div>
                            <div>
                                <TextField
                                    sx={{ margin: '12px', width: '350px' }}
                                    label={'Příjmení'}
                                    size='small'
                                    variant='outlined'
                                    value={selectedUser.surname}
                                    onChange={(e) => { setSelectedUser({ ...selectedUser, surname: e.currentTarget.value }); }} />
                            </div>
                        </div>
                        <div>
                            <TextField
                                disabled
                                sx={{ margin: '12px', marginLeft: '24px', width: '350px' }}
                                label={'Email'}
                                size='small'
                                variant='outlined'
                                value={selectedUser.email}
                                onChange={(e) => { setSelectedUser({ ...selectedUser, email: e.currentTarget.value }); }} />
                        </div>
                        <div className="flex-container">
                            <div>
                                <FormGroup sx={{ margin: '12px', marginLeft: '24px' }}>
                                    <FormControlLabel disabled={canCreateTenants !== true} control={<Checkbox checked={selectedUser.isAdmin} onChange={(e) => { setSelectedUser({ ...selectedUser, isAdmin: e.target.checked }); }} />} label="Administrátor" />
                                </FormGroup>
                            </div>
                            {selectedUser.identityProvider === 'Microsoft' && <div style={{ marginLeft: '32px', marginTop: '18px'}}>
                                <Tooltip title={'Přihlášení pomocí Microsoft identity'}>
                                    <Microsoft color='primary'/>
                                </Tooltip>
                            </div>}
                            {/* {selectedUser.identityProvider === 'Local' && <div>
                               
                            </div>} */}                            
                        </div>
                        <div className="flex-container" >
                            <div >
                                <FormGroup sx={{ margin: '12px', marginLeft: '24px', width: '250px' }}>
                                    <FormControlLabel disabled={canCreateTenants !== true}  control={<Checkbox checked={selectedUser.canCreateTenants} onChange={(e) => { setSelectedUser({ ...selectedUser, canCreateTenants: e.target.checked }); }} />} label="Zakládání tenantů" />
                                </FormGroup>
                            </div>
                            <div >
                                <FormGroup sx={{ margin: '12px', marginLeft: '24px', width: '250px' }}>
                                    <FormControlLabel disabled={canCreateTenants !== true} control={<Checkbox checked={selectedUser.canCreateUsers} onChange={(e) => { setSelectedUser({ ...selectedUser, canCreateUsers: e.target.checked }); }} />} label="Zakládání uživatelů" />
                                </FormGroup>
                            </div>
                        </div>
                        <div className="flex-container" style={{ marginTop: '24px'}}>
                        {/* <div style={{ width: '100%', display: 'flex-container', justifyContent: 'center', marginTop: '16px' }}> */}
                            <div>
                                <Button variant='contained' sx={{ margin: 'auto', marginLeft: '24px' }} disabled={buttonDisabled} onClick={saveUser}>ULOŽIT</Button>
                            </div>                            
                            <div>
                                <Button variant='contained' sx={{ margin: 'auto', marginLeft: '24px', width: '150px' }} disabled={buttonResetDisabled || canCreateTenants !== true} onClick={resetPassword}>RESET HESLA</Button>
                            </div>
                        </div>
                    </div>
                </div>  
                }                
                {selectedTenant && !selectedUser && <div style={{ margin: '64px', marginLeft: '96px'}}><NoDataPlaceholder title='Vyberte uživatele' paragraph='' /></div>}
            <Snackbar open={saveConfirm} autoHideDuration={3000} onClose={() => setSaveConfirm(false)}>
                <Alert onClose={() => setSaveConfirm(false)} severity="success" sx={{ width: '100%' }}>
                    Uloženo
                </Alert>
            </Snackbar>             
            </div>
            <CreateTenantDialog tenant={updatingTenant} isOpen={tenantDialogOpen} onClose={(refresh, id) => {
                setTenantDialogOpen(false);
                if (refresh)
                {
                    refreshData(id, selectedUser? selectedUser.id : undefined);
                }
            } } />
            <CreateUserDialog emailDef={emailCreate} tenantId={selectedTenant ? selectedTenant.id : undefined} isOpen={userCreateDialogOpen} onClose={(refresh) => {
                setUserCreateDialogOpen(false);
                setEmailCreate(undefined);
                if (refresh /* && pass */)
                {
                    /* setNewPass(pass);
                    setNewPassDialogOpen(true); */
                    refreshData(selectedTenant ? selectedTenant.id : undefined, selectedUser ? selectedUser.id : undefined);
                }
            } } />
            <AddUserToTenantDialog tenantId={selectedTenant ? selectedTenant.id : undefined} isOpen={userDialogOpen} onClose={(refresh, id, exists, email, already) => {
                setUserDialogOpen(false);
                if (refresh) {
                    setInfoProps({label: 'Přidání do tenantu', text: already ? 'Uživatel již je v tenantu' : 'Uživatel již existuje a byl přidán do tenantu.'});
                    setInfoOpen(true);
                    refreshData(selectedTenant ? selectedTenant.id : undefined, id);
                }
                else if (exists === false) {
                    setEmailCreate(email);
                    setUserCreateDialogOpen(true);
                }
            } } />
            <InfoDialog label={infoProps ? infoProps.label : ''} onClose={() => setInfoOpen(false)} open={infoOpen} text={infoProps ? infoProps.text : ''} />
            <NewPasswordDialog isOpen={newPassDialogOpen} onClose={() => { setNewPass(''); setNewPassDialogOpen(false); } } newPassword={newPass}/>
            <DeleteDialog open={deletingTenant !== null} onClose={() => { setDeletingTenant(null) }} data={deletingTenant} onDeleteAgree={
                        (data) => {
                            if (data.id)
                                new APIUserClient().DeleteTenant(data.id).then((res) => {
                                    if (res.ok)
                                    {
                                        setDeletingTenant(null);
                                        refreshData(selectedTenant ? selectedTenant.id : undefined, selectedUser ? selectedUser.id : undefined);
                                    }
                                });
                        }
                    } />
            <DeleteDialog open={removingUser !== null} onClose={() => { setRemovingUser(null) }} data={removingUser} onDeleteAgree={
                        (data) => {
                            if (data.id && selectedTenant && selectedTenant.id)
                                new APIUserClient().RemoveUserFromTenant(data.id, selectedTenant.id).then((res) => {
                                    if (res.ok)
                                    {
                                        setRemovingUser(null);
                                        refreshData(selectedTenant ? selectedTenant.id : undefined, selectedUser ? selectedUser.id : undefined);
                                    }
                                });
                        }
                    } />
        </div >
    )
}
