import { LatLng } from "leaflet";
import { ApiBaseClient } from "../Api/ApiBaseClient";
import { ApiDataClient } from "../Api/ApiDataClient";
import { TestVideoValidationSlopeRequest, ValidationSlopeState } from "../Api/ApiServer";
import { convertToGPS } from "../Shared/LeafletMap/GpsSJTSKConverter";
import { parseHectos, parseLines } from "./ParseLines";

export const fetchSlopeValidationData = async (id: number | null) => {
    try {
        const response = id === null
            ? await new ApiBaseClient().GetClientToAuth().validateTiltVideo(new TestVideoValidationSlopeRequest())
            : await new ApiDataClient().ValidateVersion(Number(id));

        console.log(response);

        const q = convertToGPS(response.polyLine?.tiltValidationLineParts![0].startPoint!.y!, response.polyLine?.tiltValidationLineParts![0].startPoint!.x!);
        console.log(q);
        const position = new LatLng(q[1], q[0]);

        const actLines = response.polyLine?.tiltValidationLineParts!.map(x => ({
            points: [{ X: x.startPoint?.x!, Y: x.startPoint?.y! }, { X: x.endPoint?.x!, Y: x.endPoint?.y! }],
            type: x.entityType
        }));

        const perpendicularLines = response.polyLine?.perpendiculars?.map(x => ({
            points: [{ X: x.startPoint?.x!, Y: x.startPoint?.y! }, { X: x.endPoint?.x!, Y: x.endPoint?.y! }],
            type: x.entityType,
            crossId: x.perpendicularCrossId
        }));

        console.log(perpendicularLines);

        const leftErrors = getLeftErrors(response.gridLines!);
        const rightErrors = getRightErrors(response.gridLines!);
        const leftWarnings = getLeftWarnings(response.gridLines!);
        const rightWarnings = getRightWarnings(response.gridLines!);

        const totalErrors = leftErrors + rightErrors;
        const totalWarnings = leftWarnings + rightWarnings;

        // const totalWarnings = response.gridLines?.filter(x =>

        const parsedLines = parseLines(actLines);
        // console.log(parsedLines);

        const lines = [{ lines: parsedLines }, { lines: parseLines(perpendicularLines) }];
        const hectos = parseHectos(response.hectos!);
        const rows = response.gridLines!;
        let projectData = {projectName: response.projectName, projectId: response.projectId, tiltName: response.tiltName, tiltId: response.tiltId}

        return { position, totalErrors, totalWarnings, leftErrors, leftWarnings, rightErrors, rightWarnings, lines, hectos, rows, projectData };
    } catch (error) {
        throw error;
    }
};
// this.spminLeftCorrectState = _data["spminLeftCorrectState"];
// this.spminLeftTooltip = _data["spminLeftTooltip"];
// this.spminRightCorrectState = _data["spminRightCorrectState"];
// this.spminRightTooltip = _data["spminRightTooltip"];
// this.deltaSLeftCorrectState = _data["deltaSLeftCorrectState"];
// this.deltaSLeftTooltip = _data["deltaSLeftTooltip"];
// this.deltaSRightCorrectState = _data["deltaSRightCorrectState"];
// this.deltaSRightTooltip = _data["deltaSRightTooltip"];
const getLeftErrors = (rows: any[]) => {
    return rows.filter(x =>
        x.finalSlopeLeftCorrectState === ValidationSlopeState.Error
    ).length +
    rows.filter(x =>
        x.spminLeftCorrectState === ValidationSlopeState.Error
    ).length +
    rows.filter(x =>
        x.deltaSLeftCorrectState === ValidationSlopeState.Error
    ).length +
    rows.filter(x =>
        x.isCrossSlopeLeftInCurveCorrect === false
    ).length;
}
const getLeftWarnings = (rows: any[]) => {
    return rows.filter(x =>
        x.finalSlopeLeftCorrectState === ValidationSlopeState.Warning
    ).length + 
    rows.filter(x =>
        x.spminLeftCorrectState === ValidationSlopeState.Warning
    ).length + 
    rows.filter(x =>
        x.deltaSLeftCorrectState === ValidationSlopeState.Warning
    ).length;
}
const getRightErrors = (rows: any[]) => {
    return rows.filter(x =>
        x.finalSlopeRightCorrectState === ValidationSlopeState.Error
    ).length + 
    rows.filter(x =>
        x.isCrossSlopeRightInCurveCorrect === false
    ).length + 
    rows.filter(x =>
        x.spminRightCorrectState === ValidationSlopeState.Error
    ).length + 
    rows.filter(x =>
        x.deltaSRightCorrectState === ValidationSlopeState.Error
    ).length;
}
const getRightWarnings = (rows: any[]) => {
    return rows.filter(x =>
        x.finalSlopeRightCorrectState === ValidationSlopeState.Warning 
    ).length + 
    rows.filter(x =>
        x.spminRightCorrectState === ValidationSlopeState.Warning
    ).length + 
    rows.filter(x =>
        x.deltaSRightCorrectState === ValidationSlopeState.Warning
    ).length;
}