import { Button, Container, IconButton, TextField, ToggleButton, ToggleButtonGroup, Typography, styled } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Oblouk } from './Models/ObloukModelAsociative';
import SolverField from './SolverField';
import { GetColorForState } from './Helpers/GetColorForStateHelper';
import { Close, Info, PreviewSharp } from '@mui/icons-material';
import delay from '../Shared/delay';
import CurveSolverMainDetail from './CurveSolverMainDetail';
import CurveSolverVytycovaciDetail from './CurveSolverVytycovaciDetail';
import openrail from './Assets/openrail.png';
import upperpanel from './Assets/upperpanel.png';
import table from './Assets/table.png';
import detail from './Assets/detail.png';
import { useCompanyLogoStore } from '../Stores/CompanyLogoStore';

interface CurveSolverHelpSceneProps {
}
export default function CurveSolverHelpScene(props: CurveSolverHelpSceneProps) {
    const { initLogoStore, logoBase64 } = useCompanyLogoStore();
    useEffect(() => {
        if (!logoBase64) {
            initLogoStore();
        }
    }, []);
    return (
        <div>
            <div style={{ width: '100%', background: '#F5F5F5', position: 'fixed', height: '52px', top: '0', textAlign: 'center', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}>
                <div style={{ display: 'flex', textAlign: 'center', justifyContent: 'center', alignItems: 'center' }}>
                    <img src={logoBase64} style={{ height: '32px', width: 'auto', minWidth: '92px', maxWidth: '260px', marginLeft: '28px', marginRight: '28px' }} />
                    <Typography variant='h5' fontWeight='bold' color='primary' margin={1}>GPK</Typography>
                    <Typography variant='h5' fontWeight='bold' >Validátor</Typography>
                    <Typography variant='h6' fontWeight='bold' sx={{ marginLeft: '6px'}}>{' - nápověda'}</Typography>
                </div>
            </div>
            <Container maxWidth="md">            
                <Typography variant="h3" gutterBottom style={{ paddingTop: '100px' }}>
                    GPK Validátor
                </Typography>

                <Typography variant="body1" paragraph textAlign='left'>
                    GPK Validátor je interaktivní klient-server webová aplikace s příslušnými pluginy do projekčních nástrojů umožňující v reálném čase kontrolovat vybrané geometrické parametry trasy koleje. Vychází z normy ČSN 73 6360-1 - Konstrukční a geometrické uspořádání koleje železničních drah a její prostorová poloha – Část 1.
                </Typography>

                <Typography variant="body1" paragraph textAlign='left'>
                    V zásadě umožňuje 2 režimy ovládání. První režim probíhá manuálním zadáváním jednotlivých oblouků do webového prostředí validátoru. Druhou možností je pak přímé propojení a export hodnot pro validaci z geometrie trasy. 
                </Typography>

                <Typography variant="body1" paragraph textAlign='left'>
                    Webová část je rozdělena na dvě poloviny. Horní polovina obsahuje přehlednou tabulku s řádky oblouků na celé trase a spodní polovina detailně zobrazuje hlavní a vytyčovací parametry pro vybraný řádek oblouku z horní tabulky.
                </Typography>

                <Typography variant="h5" gutterBottom style={{ paddingTop: '24px' }}>
                    Ovládací panel s funkcemi
                </Typography>

                <Typography variant="body1" paragraph textAlign='left'>
                    <div>Pod navigací projektu s vybranou trasou jsou tlačítka s funkcemi umožňující její obsluhu a editaci</div>
                    <ul>
                        <li><strong>Uložit:</strong> Uloží vyplněné hodnoty k příslušné trase na server</li>
                        <li><strong>Přidat OBL:</strong> Přidá řádek s obloukem do tabulky</li>
                        <li><strong>Přidat / smazat MZL:</strong> Přidá/odstraní mezilehlou přechodnici (viz. poznámky níže)</li>
                        <li><strong>Kopírovat:</strong> Zkopíruje řádek s hodnotami oblouku</li>
                        <li><strong>Smazat:</strong> Odstraní řádek s hodnotami oblouku</li>
                        <li><strong>Nastavení:</strong> Ovládá uživatelské prostředí aplikace</li>
                    </ul>
                    
                    <div>
                        <img style={{ height: '100%', maxWidth: '100%', objectFit: 'contain'}} src={upperpanel}/>
                        <Typography sx={{ textAlign: 'center'}} variant="body2">Obr. 1 - Ovládací tlačítka</Typography>
                    </div>
                </Typography>

                <Typography variant="h5" gutterBottom style={{ paddingTop: '24px' }}>
                    Horní tabulka se sloupci parametrů
                </Typography>

                <Typography variant="body1" paragraph textAlign='left'>
                    <div>Pod navigací projektu s vybranou trasou jsou tlačítka s funkcemi umožňující její obsluhu a editaci</div>
                    <div>•	Jeden řádek tvoří vždy jeden oblouk (včetně přechodnic), případně mezilehlá přechodnice.</div>
                    <div>•	Lze počítat geometrii pro oblouky bez / s klotoidickými přechodnicemi a stejně dlouhými vzestupnicemi</div>
                    <ul>
                        <li>Systém zatím nehlídá vztah vůči okolí (náhlé změny nedostatku převýšení, dodatečné podmínky pro přejezdy, výhybky apod., délky mezipřímých, vztah směrového a výškového řešení.</li>
                    </ul>
                    <div>•	Ručně se vyplňují dvěma tmavšími odstíny zelené podbarvené buňky (tučně vyznačené hodnoty), ostatní se dopočítávají samy. Jednotlivá čísla lze šipkami nahoru a dolu postupně zvyšovat či snižovat.</div>

                    <div>
                        <img style={{ height: '100%', maxWidth: '100%', objectFit: 'contain'}} src={table}/>                    
                        <Typography sx={{ textAlign: 'center'}} variant="body2">Obr. 2 - Tabulka se sloupci</Typography>
                    </div>
                </Typography>

                <Typography variant="h5" gutterBottom style={{ paddingTop: '24px' }}>
                    Hladiny hodnot
                </Typography>

                <Typography variant="body1" paragraph textAlign='left'>
                    Návrhové hodnoty parametrů konstrukčního a geometrického uspořádání koleje jsou stanoveny s ohledem na příslušné rychlosti na dané trati. Podle konkrétních parametrů je nutno dále zohlednit skladbu a druh provozované dopravy. Návrhové hodnoty parametrů jsou podle jejich významu definovány v těchto hladinách:               
                </Typography>

                <Typography variant="body1" paragraph textAlign='left'>   
                    <strong>•	Standardní hodnota (H<sub>n</sub>)</strong> - tato hodnota shora (nebo zdola) vymezuje interval doporučených hodnot k projektování. Pokud tomu nebrání místní nebo jiná omezení, mají být tyto hodnoty dodržovány. Použití těchto hodnot zaručuje splnění požadavků na komfort jízdy i vhodnou míru nákladů na údržbu trati.        
                </Typography>

                <Typography variant="body1" paragraph textAlign='left'>   
                    <strong>•	Mezní hodnota (H<sub>lim</sub>)</strong> - tato hodnota nemá být překračována. Překročení těchto hodnot je možné například z důvodu omezení nebo vyloučení místního snížení projektované rychlosti. Při překročení těchto hodnot dochází k omezení komfortu cestujících a/nebo ke zvýšení nákladů na údržbu trati. Použití vyšších/nižších než mezních hodnot musí být odsouhlaseno vlastníkem dráhy a může být v některých případech omezeno dalšími podmínkami (například podmínka provozu schválených vozidel, jejichž konstrukce odpovídá dotčeným hodnotám).    
                </Typography>

                <Typography variant="body1" paragraph textAlign='left'>   
                    <strong>•	Maximální (minimální) hodnota (H<sub>max</sub> / H<sub>min</sub>)</strong> - tato hodnota nesmí být překročena. Maximální nebo minimální hodnoty veličin se použijí jen v nezbytných případech, kdy není možné nalézt jiné řešení.    
                </Typography>

                <Typography variant="body1" paragraph textAlign='left'>     
                    Podle významu jednotlivých parametrů se použijí výše uvedené hladiny hodnot, v jednotlivých případech mohou být hladiny sloučeny nebo nevyužity. Není-li u jednotlivých parametrů předepsána hodnota standardní, uvažuje se shodná s hodnotou mezní. Není-li u jednotlivých parametrů předepsána hodnota mezní, uvažuje se shodná s hodnotou maximální (minimální).        
                </Typography>

                <Typography variant="body1" paragraph textAlign='left'>     
                    <p><strong>Barevné podbarvení buňky znamená:</strong></p>
                    <ul>
                        <li><span style={{color: 'darkgreen'}}><strong>Tmavě zelená:</strong></span> Vstupní pole pro zadání hodnot uživatelem</li>
                        <li><span style={{color: 'lightgreen'}}><strong>Světle zelená:</strong></span> Doporučený interval standardních hodnot nebo lepších (N)</li>
                        <li><span style={{color: 'lightcoral'}}><strong>Světle oranžová:</strong></span> Interval mezi standardními a mezními hodnotami (N → LIM)</li>
                        <li><span style={{color: 'darkorange'}}><strong>Tmavě oranžová:</strong></span> Interval mezi mezními a maximálními/minimálními hodnotami (LIM → MIN/MAX)</li>
                        <li><span style={{color: 'red'}}><strong>Červená:</strong></span> Hodnoty přesahující maximální / minimální veličiny</li>
                        <li><strong>Šrafované kombinace:</strong> 3 různé barevné kombinace, kdy hodnoty nabývají zvláštních podmínek s komentářem</li>
                    </ul>
                </Typography>

                <Typography variant="body1" paragraph textAlign='left' style={{ textDecoration: 'underline'}}>
                    Poznámky k jednotlivým segmentům tabulky:            
                </Typography>

                <Typography variant="body1" paragraph textAlign='left'>
                    <ul>
                        <li><strong>Převýšení:</strong></li>
                        <ul>
                            <li>Počáteční interval se pohybuje v maximálních hodnotách</li>
                        </ul>                        
                    </ul>    
                </Typography>

                <Typography variant="body1" paragraph textAlign='left'>
                    <ul>
                        <li><strong>Rychlosti:</strong></li>
                        <ul>
                            <li>4 rychlostní profily jsou nezbytné</li>
                            <li>Počáteční hodnota je maximální</li>
                            <li>V nejvyšších rychlostech nemají profily V<sub>130</sub>, V<sub>150</sub> a V<sub>k</sub> opodstatnění, ale pro snazší práci s tabulkou jsou definovány tak, že pro tyto vysoké rychlosti se uvažuje chování vždy jako pro nejbližší nižší ještě povolený profil</li>
                        </ul>                        
                    </ul>    
                </Typography>

                <Typography variant="body1" paragraph textAlign='left'>
                    <ul>
                        <li><strong>Nedostatek / přebytek převýšení:</strong></li>
                        <ul>
                            <li>Výpočty respektují normou definované požadavky na zaokrouhlování</li>
                            <li>Při překročení nuly jsou doplňovány předpony "E=" nebo "I="</li>
                        </ul>                       
                    </ul>    
                </Typography>

                <Typography variant="body1" paragraph textAlign='left'>
                    <ul>
                        <li><strong>Přechodnice + vzestupnice:</strong></li>
                        <ul>
                            <li>Návrh délek přechodnic je uveden pro rychlost V, pro ostatní rychlosti je uvedeno pouze posouzení návrhu (hodnoty N/LIM/MIN)</li>
                            <li>n = součinitel sklonu vzestupnice</li>
                            <li>n<sub>I</sub> = součinitel nedostatku převýšení</li>
                            <li>Výchozí minimální navrhované délky přechodnic pro hladiny</li>
                            <ul>
                                <li>Minimální</li>
                                <li>Mezní</li>
                                <li>Standardní</li>
                            </ul>
                            <li>Inflexní přechodnice – systém umí při importu z geometrie trasy rozpoznat inflexní motiv a posoudit totožný sklon obou vzestupnic</li>
                        </ul>                     
                    </ul>    
                </Typography>

                <Typography variant="body1" paragraph textAlign='left'>
                    <ul>
                        <li><strong>Mezilehlá přechodnice:</strong></li>
                        <ul>
                            <li>V řádku uvedená mezilehlá přechodnice se vztahuje vždy k předchozímu a následujícímu řádku s oblouky.</li>
                        </ul>                   
                    </ul>    
                </Typography>            

                <Typography variant="h5" gutterBottom style={{ paddingTop: '24px' }}>
                    Spodní tabulka s detailem vybraného řádku
                </Typography>

                <Typography variant="body1" paragraph textAlign='left'>
                    <ul>
                        <li>Záložka Hlavní obsahuje všechny parametry z řádku vybraného oblouku horní tabulky seskupené dle typu hodnoty</li>   
                        <li>Záložka Vytyčovací obsahuje další vytyčovací prvky a tečny zaoblení</li>   
                        <li>Tečny zaoblení vzestupnic – jde o informativní údaj pro trasování</li>                 
                    </ul> 
                    <div>
                        <img style={{ height: '100%', maxWidth: '100%', objectFit: 'contain'}} src={detail}/>  
                        <Typography sx={{ textAlign: 'center'}} variant="body2">Obr. 3 - Detail řádku</Typography>
                    </div> 
                </Typography>           

                <Typography variant="h5" gutterBottom style={{ paddingTop: '24px' }}>
                    Bentley OpenRail GPK Validátor
                </Typography>

                <Typography variant="body1" paragraph textAlign='left' style={{ paddingBottom: '150px'}}>
                    Součástí nástroje SG Open Tools je speciální funkce GPK Validátor, která po vyvolání přihlašovacího dialogu do webového GPK Validátoru umožňuje založit do vybraného projektu novou trasu / zapsat do stávající a do té pak exportovat geometrické parametry se zachováním živé vazby na geometrii trasy. Z trasy ve výkresu le kromě geometrických parametrů exportovat i jednotlivé rychlosti a hodnotu navrženého převýšení.  
                    <div>
                        <img style={{ height: '100%', maxWidth: '100%', objectFit: 'contain'}} src={openrail}/> 
                        <Typography sx={{ textAlign: 'center'}} variant="body2">Obr. 4 - OpenRail</Typography>
                    </div>
                </Typography> 

        </Container>
       </div>
    );
};
