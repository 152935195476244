import { ArrowBack, ArrowLeft } from '@mui/icons-material';
import { AppBar, Avatar, Box, Button, IconButton, Stack, Toolbar, Tooltip, Typography } from '@mui/material';
import React from 'react'
import MenuIcon from '@mui/icons-material/Menu';
import { } from '@mui/material/colors';
import { usePwUser } from './Context/PWUserDataProvider';

interface ProjectWiseUpperMenuProps {
    drawerOpen: boolean;
    onOpenDrawer: () => void;
}

export default function ProjectWiseUpperMenu(props: Readonly<ProjectWiseUpperMenuProps>) {
    const { drawerOpen, onOpenDrawer } = props; // #FF5200
    const { user } = usePwUser();
    const getInitials = (firstName?: string, lastName?: string) => {
        if (firstName && lastName)
            return firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase();
        else
            return '';
    }
    return (
        <Box display='flex' boxShadow={2} alignItems='center' px={2} sx={{ width: '100%', maxHeight: '40px', minHeight: '40px', background: '#0E457D', zIndex: 999999 }}>
            <Box sx={{ maxHeight: '40px' }} alignItems='center' display='flex' justifyContent='space-between' width='calc(100% - 40px)' >
                <Box alignItems='center' display='flex' justifyContent='left'>
                    <img src={process.env.PUBLIC_URL + "/sz_logo.svg"} style={{ height: '20px' }} alt="logo" />
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, ml: 2, color: 'white' }}>
                        CDE SŽ
                    </Typography>
                </Box>
                <Box display='flex' alignItems='center'>
                    <Avatar sx={{ boxShadow: 2, height: '30px', width: '30px', fontSize: '16px', bgcolor:'#FF5200' }}>{getInitials(user?.firstName, user?.lastName)}</Avatar>
                </Box>
            </Box>
        </Box>)
}
