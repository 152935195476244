import React from 'react'
import CurveSolverHeaderCell from './CurveSolverHeaderCell'

export default function CurveSolverTableHeader() {
    return (
        <div style={{ display: 'flex', marginBottom: '4px' }}>
            <CurveSolverHeaderCell isFirst width={'54px'}  mainName="ID" subNames={['']} />
            <CurveSolverHeaderCell width={'68px'} paddingLeftItem='30px' itemSize='46px' mainName="Poloměr" subNames={['R']} />
            <CurveSolverHeaderCell width={'98px'}  mainName="Převýšení" subNames={['D', 'D<sub>N</sub>']} />
            <CurveSolverHeaderCell width={'180px'} mainName="Rychlosti" itemSize='46px' subNames={['V', 'V<sub>130</sub>', 'V<sub>150</sub>', 'V<sub>k</sub>']} />
            <CurveSolverHeaderCell width={'201px'} mainName="Nedostatek/přebytek převýšení" itemSize='50px' subNames={['I', 'I<sub>130</sub>', 'I<sub>150</sub>', 'I<sub>k</sub>']} />
            <CurveSolverHeaderCell width={'288px'} mainName="V (přechodníce + vzestupnice)" itemSize='48px' subNamesWithOverflow={[{ name: 'L<sub>k,1</sub>' }, {name:'INF'},{ names: ['n<sub>1</sub>', 'n<sub>I,1</sub>'] }, { name: 'L<sub>k,2</sub>' }, {name:'INF'}, { names: ['n<sub>2</sub>', 'n<sub>I,2</sub>'] }]} />
            <CurveSolverHeaderCell width={'220px'} mainName="V<sub>130</sub> (přechodníce + vzestupnice)" itemSize='56px' subNamesWithOverflow={[{ name: 'L<sub>k,1,130</sub>' }, { names: ['n<sub>1,130</sub>', 'n<sub>I,1,130</sub>'] }, { name: 'L<sub>k,2,130</sub>' }, { names: ['n<sub>2,130</sub>', 'n<sub>I,2,130</sub>'] }]} />
            <CurveSolverHeaderCell width={'220px'} mainName="V<sub>150</sub> (přechodníce + vzestupnice)" itemSize='56px' subNamesWithOverflow={[{ name: 'L<sub>k,1,150</sub>' }, { names: ['n<sub>1,150</sub>', 'n<sub>I,1,150</sub>'] }, { name: 'L<sub>k,2,150</sub>' }, { names: ['n<sub>2,150</sub>', 'n<sub>I,2,150</sub>'] }]} />
            <CurveSolverHeaderCell isLast width={'208px'} mainName="V<sub>k</sub> (přechodníce + vzestupnice)" itemSize='56px' subNames={['L<sub>k,1,k</sub>', 'n<sub>1,k</sub>', 'L<sub>k,2,k</sub>', 'n<sub>2,k</sub>']} />
        </div>
    )
}
