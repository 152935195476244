import { ObloukItem } from "../Models/ObloukItem";

export function calculateDN(items: { [key: string]: ObloukItem }): void {
    if (items['V'].numericValue <= 120) {
      items['DN'].setValueByNumericValue(Math.round((7.1 * Math.pow(items['V'].numericValue, 2) / items['R'].numericValue)));
    } else if (items['V'].numericValue <= 160) {
      items['DN'].setValueByNumericValue(Math.round((6.5 * Math.pow(items['V'].numericValue, 2) / items['R'].numericValue)));
    } else if (items['V'].numericValue <= 200) {
      items['DN'].setValueByNumericValue(Math.round((5.9 * Math.pow(items['V'].numericValue, 2) / items['R'].numericValue)));
    } else {
      items['DN'].setValueByNumericValue(0);
    }
  }