
import React, { createContext, PropsWithChildren, useContext, useState } from 'react';

// Define the shape of the PwUser object
interface PwUser {
    id: string;
    email: string;
    displayName: string;
    firstName?: string;
    lastName?: string;
}
interface PwUserProviderProps {
    user: PwUser | null;
    setUser: (user: PwUser) => void | null;
}

// Create a context for PwUser
const PwUserContext = createContext<PwUserProviderProps | null>(null);

// Create a provider component for PwUser
const PwUserProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
    const [user, setUser] = useState<PwUser | null>(null);

    return (
        <PwUserContext.Provider value={{ user: user, setUser: setUser }}>
            {children}
        </PwUserContext.Provider>
    );
};

// Create a custom hook to access the PwUser context
const usePwUser = () => {
    const user = useContext(PwUserContext);

    if (user === null) {
        throw new Error('usePwUser must be used within a PwUserProvider');
    }

    const setUser = (newUser: PwUser) => {
        // You can perform any additional logic here before setting the user
        setUser(newUser);
    };

    return user;
};

export { PwUserProvider, usePwUser };