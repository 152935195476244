import { ObloukItemState } from "../Enums/ObloukItemState";
import { ObloukItem } from "./ObloukItem";

export function initOblouk(items: { [key: string]: ObloukItem }) {
    items['R'] = new ObloukItem(false, "");
    items['Dstav'] = new ObloukItem(false, "0");
    items['D'] = new ObloukItem(false, "");
    items['DN'] = new ObloukItem(true, "");
    items['Dmax'] = new ObloukItem(true, "0");
    items['Dx'] = new ObloukItem(true, "0");
    items['V'] = new ObloukItem(false, "");
    items['V130'] = new ObloukItem(false, "");
    items['V150'] = new ObloukItem(false, "");
    items['Vk'] = new ObloukItem(false, "");
    items['Vnakl'] = new ObloukItem(false, "0");
    items['I'] = new ObloukItem(true, "");
    items['I130'] = new ObloukItem(true, "");
    items['I150'] = new ObloukItem(true, "");
    items['Ik'] = new ObloukItem(true, "");
    items['Enakl'] = new ObloukItem(true, "");
    items['I_E'] = new ObloukItem(true, "");
    items['Inn'] = new ObloukItem(true, "");
    items['Ilim'] = new ObloukItem(true, "");
    items['Imax'] = new ObloukItem(true, "");
    items['I_E130'] = new ObloukItem(true, "");
    items['Inn130'] = new ObloukItem(true, "");
    items['Ilim130'] = new ObloukItem(true, "");
    items['Imax130'] = new ObloukItem(true, "");
    items['I_E150'] = new ObloukItem(true, "");
    items['Inn150'] = new ObloukItem(true, "");
    items['Ilim150'] = new ObloukItem(true, "");
    items['Imax150'] = new ObloukItem(true, "");
    items['I_Ek'] = new ObloukItem(true, "");
    items['Innk'] = new ObloukItem(true, "");
    items['Ilimk'] = new ObloukItem(true, "");
    items['Imaxk'] = new ObloukItem(true, "");
    items['I_E1k'] = new ObloukItem(true, "");
    items['Inn1k'] = new ObloukItem(true, "");
    items['Ilim1k'] = new ObloukItem(true, "");
    items['Imax1k'] = new ObloukItem(true, "");
    items['I_E2k'] = new ObloukItem(true, "");
    items['Inn2k'] = new ObloukItem(true, "");
    items['Ilim2k'] = new ObloukItem(true, "");
    items['Imax2k'] = new ObloukItem(true, "");

    //odsud se určitě používají
    items['nn'] = new ObloukItem(true, "");
    items['nlim'] = new ObloukItem(true, "");
    items['nmin'] = new ObloukItem(true, "");
    items['nIn'] = new ObloukItem(true, "");
    items['nIlim'] = new ObloukItem(true, "");
    items['nImin'] = new ObloukItem(true, "");
    items['Ldn'] = new ObloukItem(true, "");
    items['Ldlim'] = new ObloukItem(true, "");
    items['Lkn'] = new ObloukItem(true, "");
    items['Lklim'] = new ObloukItem(true, "");
    items['Ldmin'] = new ObloukItem(true, "");
    items['Lkmin'] = new ObloukItem(true, "");
    items['Lklim07R'] = new ObloukItem(true, "");
    items['Ln'] = new ObloukItem(true, "");
    items['Lklim20'] = new ObloukItem(true, "");
    items['Llim'] = new ObloukItem(true, "");
    items['Lmin'] = new ObloukItem(true, "");
    items['Lk1'] = new ObloukItem(false, "");
    items['Lk2'] = new ObloukItem(false, "");
    items['Lk1k'] = new ObloukItem(true, "");
    items['Lk2k'] = new ObloukItem(true, "");

    items['nn130'] = new ObloukItem(true, "");
    items['nlim130'] = new ObloukItem(true, "");
    items['nmin130'] = new ObloukItem(true, "");
    items['nIn130'] = new ObloukItem(true, "");
    items['nIlim130'] = new ObloukItem(true, "");
    items['nImin130'] = new ObloukItem(true, "");
    items['Ldn130'] = new ObloukItem(true, "");
    items['Ldlim130'] = new ObloukItem(true, "");
    items['Ldmin130'] = new ObloukItem(true, "");
    items['Ln130'] = new ObloukItem(true, "");
    items['Llim130'] = new ObloukItem(true, "");
    items['Lmin130'] = new ObloukItem(true, "");
    items['Lkn130'] = new ObloukItem(true, "");
    items['Lklim20130'] = new ObloukItem(true, "");
    items['Lklim130'] = new ObloukItem(true, "");
    items['Lkmin130'] = new ObloukItem(true, "");

    items['nn150'] = new ObloukItem(true, "");
    items['nlim150'] = new ObloukItem(true, "");
    items['nmin150'] = new ObloukItem(true, "");
    items['nIn150'] = new ObloukItem(true, "");
    items['nIlim150'] = new ObloukItem(true, "");
    items['nImin150'] = new ObloukItem(true, "");
    items['Ldn150'] = new ObloukItem(true, "");
    items['Ldlim150'] = new ObloukItem(true, "");
    items['Ldmin150'] = new ObloukItem(true, "");
    items['Ln150'] = new ObloukItem(true, "");
    items['Llim150'] = new ObloukItem(true, "");
    items['Lmin150'] = new ObloukItem(true, "");
    items['Lkn150'] = new ObloukItem(true, "");
    items['Lklim20150'] = new ObloukItem(true, "");
    items['Lklim150'] = new ObloukItem(true, "");
    items['Lkmin150'] = new ObloukItem(true, "");

    items['Ikmax'] = new ObloukItem(true, "");
    items['Iklim'] = new ObloukItem(true, "");

    items['nnk'] = new ObloukItem(true, "");
    items['nmink'] = new ObloukItem(true, "");
    items['Ldnk'] = new ObloukItem(true, "");
    items['Ldmink'] = new ObloukItem(true, "");
    items['Lklim20k'] = new ObloukItem(true, "");
    items['LkI1'] = new ObloukItem(true, "");
    items['LkI2'] = new ObloukItem(true, "");
    items['Lnk'] = new ObloukItem(true, "");
    items['Llimk'] = new ObloukItem(true, "");
    items['Lmink'] = new ObloukItem(true, "");

    items['n1130'] = new ObloukItem(true, "");
    items['nI1130'] = new ObloukItem(true, "");
    items['Lk1130'] = new ObloukItem(true, "");
    items['n1150'] = new ObloukItem(true, "");
    items['nI1150'] = new ObloukItem(true, "");
    items['Lk1150'] = new ObloukItem(true, "");
    items['n1k'] = new ObloukItem(true, "");
    items['n1'] = new ObloukItem(true, "");
    items['n2'] = new ObloukItem(true, "");
    items['nI1'] = new ObloukItem(true, "");
    items['nI2'] = new ObloukItem(true, "");
    items['nlimk'] = new ObloukItem(true, "");
    items['n2130'] = new ObloukItem(true, "");
    items['nI2130'] = new ObloukItem(true, "");
    items['Lk2130'] = new ObloukItem(true, "");
    items['n2150'] = new ObloukItem(true, "");
    items['nI2150'] = new ObloukItem(true, "");
    items['Lk2150'] = new ObloukItem(true, "");
    items['n2k'] = new ObloukItem(true, "");
    items['Ikn'] = new ObloukItem(true, "");

    items['alfa'] = new ObloukItem(false, "");
    items['kolej'] = new ObloukItem(false, "");
    items['ω'] = new ObloukItem(false, "");
    //  "A1","A2","tauk1","tauk2","Yk1","Yk2","Xk1","Xk2","m1","m2"
    //co je tohle za pole?? Nikde nejsou vidět..
    items['A1'] = new ObloukItem(true, "");
    items['A2'] = new ObloukItem(true, "");
    items['tauk1'] = new ObloukItem(true, "");
    items['tauk2'] = new ObloukItem(true, "");
    items['Yk1'] = new ObloukItem(true, "");
    items['Yk2'] = new ObloukItem(true, "");
    items['Xk1'] = new ObloukItem(true, "");
    items['Xk2'] = new ObloukItem(true, "");
    items['m1'] = new ObloukItem(true, "");
    items['m2'] = new ObloukItem(true, "");
    items['tz1'] = new ObloukItem(true, "");
    items['tz2'] = new ObloukItem(true, "");
    // "deltaD","deltaI", "Vmezi","Lkm"
    items['deltaD'] = new ObloukItem(true, "");
    items['deltaI'] = new ObloukItem(true, "");
    items['Vmezi'] = new ObloukItem(true, "");
    items['Lkm'] = new ObloukItem(false, "");
    //dLdn,dLdlim,dLdmin,dLn,dLlim,dLmin,dLkn,dLklim,dLkmin
    items['dLdn'] = new ObloukItem(true, "");
    items['dLdlim'] = new ObloukItem(true, "");
    items['dLdmin'] = new ObloukItem(true, "");
    items['dLn'] = new ObloukItem(true, "");
    items['dLlim'] = new ObloukItem(true, "");
    items['dLmin'] = new ObloukItem(true, "");
    items['dLkn'] = new ObloukItem(true, "");
    items['dLklim'] = new ObloukItem(true, "");
    items['dLkmin'] = new ObloukItem(true, "");
    //"dLklim07R", "dLklim20"
    items['dLklim07R'] = new ObloukItem(true, "");
    items['dLklim20'] = new ObloukItem(true, "");
    // "dLdn130", "dLdlim130", "dLdmin130", "dLn130", "dLlim130", "dLmin130", "dLkn130", "dLklim130", "dLkmin130", "dLklim20130",
    // "dLdn150", "dLdlim150", "dLdmin150", "dLn150", "dLlim150", "dLmin150", "dLkn150", "dLklim150", "dLkmin150", "dLklim20150",
    // "dLdnk", "dLdlimk", "dLdmink", "dLknk", "dLklimk", "dLklim20k", "dLkI1", "dLkI2", "dLk1130", "dLk2130", "dLk1150", "dLk2150"
    items['dLdn130'] = new ObloukItem(true, "");
    items['dLdlim130'] = new ObloukItem(true, "");
    items['dLdmin130'] = new ObloukItem(true, "");
    items['dLn130'] = new ObloukItem(true, "");
    items['dLlim130'] = new ObloukItem(true, "");
    items['dLmin130'] = new ObloukItem(true, "");
    items['dLkn130'] = new ObloukItem(true, "");
    items['dLklim130'] = new ObloukItem(true, "");
    items['dLkmin130'] = new ObloukItem(true, "");
    items['dLklim20130'] = new ObloukItem(true, "");
    items['dLdn150'] = new ObloukItem(true, "");
    items['dLdlim150'] = new ObloukItem(true, "");
    items['dLdmin150'] = new ObloukItem(true, "");
    items['dLn150'] = new ObloukItem(true, "");
    items['dLlim150'] = new ObloukItem(true, "");
    items['dLmin150'] = new ObloukItem(true, "");
    items['dLkn150'] = new ObloukItem(true, "");
    items['dLklim150'] = new ObloukItem(true, "");
    items['dLkmin150'] = new ObloukItem(true, "");
    items['dLklim20150'] = new ObloukItem(true, "");
    items['dLdnk'] = new ObloukItem(true, "");
    items['dLdlimk'] = new ObloukItem(true, "");
    items['dLdmink'] = new ObloukItem(true, "");
    items['dLknk'] = new ObloukItem(true, "");
    items['dLklimk'] = new ObloukItem(true, "");
    items['dLklim20k'] = new ObloukItem(true, "");

    // items['dRx'].setValueByNumericValue(items['R'].numericValue * itemsUp['R'].numericValue / Math.abs(items['R'].numericValue - itemsUp['R'].numericValue))
    // items['dR1'].setValueByNumericValue(Math.max(items['R'].numericValue, itemsUp['R'].numericValue));
    // items['dR2'].setValueByNumericValue(Math.min(items['R'].numericValue, itemsUp['R'].numericValue));
    // items['dA'].setValueByNumericValue(Math.pow(items['Lkm'].numericValue * items['dRx'].numericValue, 0.5));
    // items['dL1'].setValueByNumericValue(Math.pow(items['dA'].numericValue, 2) / items['dR1'].numericValue);
    // items['dL2'].setValueByNumericValue(Math.pow(items['dA'].numericValue, 2) / items['dR2'].numericValue);
    // items['dXs1'].setValueByNumericValue(items['dL1'].numericValue / 2 - Math.pow(items['dL1'].numericValue, 3) / (240 * Math.pow(items['dR1'].numericValue, 2)));
    // items['dXs2'].setValueByNumericValue(items['dL2'].numericValue / 2 - Math.pow(items['dL2'].numericValue, 3) / (240 * Math.pow(items['dR2'].numericValue, 2)));
    // items['dYs1'].setValueByNumericValue(items['dR1'].numericValue + Math.pow(items['dL1'].numericValue, 2) / (24 * items['dR1'].numericValue) - Math.pow(items['dL1'].numericValue, 4) / (720 * Math.pow(items['dR1'].numericValue, 3)));
    // items['dYs2'].setValueByNumericValue(items['dR2'].numericValue + Math.pow(items['dL2'].numericValue, 2) / (24 * items['dR2'].numericValue) - Math.pow(items['dL2'].numericValue, 4) / (720 * Math.pow(items['dR2'].numericValue, 3)));

    // items['omega'].setValueByNumericValue(Math.atan((items['dXs2'].numericValue - items['dXs1'].numericValue) / (items['dYs1'].numericValue - items['dYs2'].numericValue)) * 200 / Math.PI);
    // items['tau1'].setValueByNumericValue(items['dL1'].numericValue / (2 * items['dR1'].numericValue));
    // items['tau2'].setValueByNumericValue(items['dL2'].numericValue / (2 * items['dR2'].numericValue));
    // items['taum1'].setValueByNumericValue(items['omega'].numericValue - items['tau1'].numericValue);
    // items['taum2'].setValueByNumericValue(items['omega'].numericValue - items['tau2'].numericValue);
    // items['ddn'].setValueByNumericValue((items['dXs2'].numericValue - items['dXs1'].numericValue) / Math.sin(items['omega'].numericValue * Math.PI / 200));
    // items['dmm'].setValueByNumericValue(items['dR1'].numericValue - items['dR2'].numericValue - items['ddn'].numericValue);
    items['dRx'] = new ObloukItem(true, "");
    items['dR1'] = new ObloukItem(true, "");
    items['dR2'] = new ObloukItem(true, "");
    items['dA'] = new ObloukItem(true, "");
    items['dL1'] = new ObloukItem(true, "");
    items['dL2'] = new ObloukItem(true, "");
    items['dXs1'] = new ObloukItem(true, "");
    items['dXs2'] = new ObloukItem(true, "");
    items['dYs1'] = new ObloukItem(true, "");
    items['dYs2'] = new ObloukItem(true, "");
    items['omega'] = new ObloukItem(true, "");
    items['tau1'] = new ObloukItem(true, "");
    items['tau2'] = new ObloukItem(true, "");
    items['taum1'] = new ObloukItem(true, "");
    items['taum2'] = new ObloukItem(true, "");
    items['ddn'] = new ObloukItem(true, "");
    items['dmm'] = new ObloukItem(true, "");



    items['nm'] = new ObloukItem(true, "");
    items['nIm'] = new ObloukItem(true, "");
    items['Lkm130'] = new ObloukItem(true, "");
    items['V130m'] = new ObloukItem(true, "");
    items['nm130'] = new ObloukItem(true, "");
    items['nIm130'] = new ObloukItem(true, "");

    items['Lkm150'] = new ObloukItem(true, "");
    items['V150m'] = new ObloukItem(true, "");
    items['nm150'] = new ObloukItem(true, "");
    items['nIm150'] = new ObloukItem(true, "");

    items['Lkm150'] = new ObloukItem(true, "");
    items['V150m'] = new ObloukItem(true, "");
    items['nm150'] = new ObloukItem(true, "");
    items['nIm150'] = new ObloukItem(true, "");
    // "deltaI130", "deltaI150"
    items['deltaI130'] = new ObloukItem(true, "");
    items['deltaI150'] = new ObloukItem(true, "");
    items['deltaIk'] = new ObloukItem(true, "");
    items['Vkm'] = new ObloukItem(true, "");
    // "vIk1","dIk","vD2","dLkx1","dLkx2","dLkx3","dLkx4","dLkx5","vIk2","nmk","VkdeltaD"
    items['vIk1'] = new ObloukItem(true, "");
    items['dIk'] = new ObloukItem(true, "");
    items['vD2'] = new ObloukItem(true, "");
    items['dLkx1'] = new ObloukItem(true, "");
    items['dLkx2'] = new ObloukItem(true, "");
    items['dLkx3'] = new ObloukItem(true, "");
    items['dLkx4'] = new ObloukItem(true, "");
    items['dLkx5'] = new ObloukItem(true, "");
    items['vIk2'] = new ObloukItem(true, "");
    items['nmk'] = new ObloukItem(true, "");
    items['VkdeltaD'] = new ObloukItem(true, "");
    // "dLnk","dLlimk","dLmink"
    items['dLnk'] = new ObloukItem(true, "");
    items['dLlimk'] = new ObloukItem(true, "");
    items['dLmink'] = new ObloukItem(true, "");
    items['LkmVk'] = new ObloukItem(true, "");

    items['tzm'] = new ObloukItem(true, "");
    
    

}