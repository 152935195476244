import { NotificationImportantOutlined } from '@mui/icons-material'
import { Typography } from '@mui/material';
interface NoDataPlaceholderProps {
    title: string;
    paragraph: string;
}

export default function NoDataPlaceholder(props: NoDataPlaceholderProps) {
    return (
        <div style={{textAlign:'center'}}>
            <NotificationImportantOutlined color='action' style={{ width: '80px', height: '120px', margin:'40px 20px' }} />
            <Typography fontWeight={'bold'} color='primary' fontSize={22}>{props.title}</Typography>
            <Typography fontSize={16} sx={{marginTop:'16px'}} color='action'>{props.paragraph}</Typography>
        </div>
    )
}
