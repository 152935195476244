import { ApiBaseClient } from "./ApiBaseClient";
import { AddUserToTenantRequest, AddUserToTenantResponse, ChangeUserPasswordRequest, ChangeUserPasswordResponse, ChangeUserTenantRequest, CreateTenantRequest, CreateTenantResponse, CreateUserRequest, CreateUserResponse, CreateUserSendSignupMailRequest, CreateUserSendSignupMailResponse, DeleteTenantResponse, GetResetPasswordUserDataResponse, GetSignupUserDataResponse, GetTenantsAdminResponse, GetTenantsUserResponse, GetUsersTenantResponse, RemoveUserFromTenantRequest, RemoveUserFromTenantResponse, RenameTenantRequest, RenameTenantResponse, ResetPasswordUserRequest, ResetPasswordUserResponse, ResetUserPasswordSendMailResponse, SaveUserDataRequest, SetUserPasswordResponse, SignupUserMicrosoftRequest, SignupUserMicrosoftResponse, TenantUserDTO } from "./ApiServer";

export class APIUserClient extends ApiBaseClient {
    
    public async GetTenantsAdmin():Promise<GetTenantsAdminResponse>{
        return (await this.GetAdressedAuthRefreshableClient().getTenantsAdmin());
    }

    public async GetTenantsUser():Promise<GetTenantsUserResponse>{
        return (await this.GetAdressedAuthRefreshableClient().getTenantsUser());
    }
    
    public async CreateTenant(name: string):Promise<CreateTenantResponse>{
        return (await this.GetAdressedAuthRefreshableClient().createTenant(new CreateTenantRequest({name: name})));
    }
    
    public async RenameTenant(tenantId: number,name: string):Promise<RenameTenantResponse>{
        return (await this.GetAdressedAuthRefreshableClient().renameTenant(new RenameTenantRequest({tenantId: tenantId,name: name})));
    }
    
    public async AddUserToTenant(email: string, tenantId?: number):Promise<AddUserToTenantResponse>{
        return (await this.GetAdressedAuthRefreshableClient().addUserToTenant(new AddUserToTenantRequest({email: email, tenantId: tenantId})));
    }
    
    public async ChangeUserTenant(tenantId: number):Promise<boolean>{
        let tokens =  (await this.GetAdressedAuthRefreshableClient().changeUserTenant(new ChangeUserTenantRequest({tenantId: tenantId})));

        if (tokens.token === undefined) throw new Error('token is undefined');
        localStorage.setItem('token', tokens.token);
        localStorage.setItem('token_expires', tokens.tokenExpiration?.toString()!);
        localStorage.setItem('refresh_token', tokens.refreshToken!);

        return true;
    }
    
    public async SaveUserData(user: TenantUserDTO):Promise<boolean>{
        console.log(user);
        return (await this.GetAdressedAuthRefreshableClient().saveUserData(new SaveUserDataRequest({id: user.id, name: user.name, surname: user.surname, isAdmin: user.isAdmin, canCreateTenant: user.canCreateTenants, canCreateUsers: user.canCreateUsers}))).ok!;
    }
    
    public async ResetPasswordUser(userId: number):Promise<ResetPasswordUserResponse>{
        return (await this.GetAdressedAuthRefreshableClient().resetPasswordUser(new ResetPasswordUserRequest({id: userId })));
    }
    
    public async ChangePasswordUser(oldPass: string, newPass: string):Promise<ChangeUserPasswordResponse>{
        return (await this.GetAdressedAuthRefreshableClient().changeUserPassword(new ChangeUserPasswordRequest({ oldPassword: oldPass, newPassword: newPass })));
    }
    
    public async CreateUser(name: string, surname: string, email: string, tenantId?: number):Promise<CreateUserResponse>{
        return (await this.GetAdressedAuthRefreshableClient().createUser(new CreateUserRequest({ name: name, surname: surname, email: email, tenantId: tenantId })));
    }

    public async GetUsersTenant(tenantId: number):Promise<GetUsersTenantResponse>{
        return (await this.GetAdressedAuthRefreshableClient().getUsersTenant(tenantId));
    }

    public async DeleteTenant(tenantId: number):Promise<DeleteTenantResponse>{
        return (await this.GetAdressedAuthRefreshableClient().deleteTenant(tenantId));
    }

    public async RemoveUserFromTenant(userId: number, tenantId: number):Promise<RemoveUserFromTenantResponse>{
        return (await this.GetAdressedAuthRefreshableClient().removeUserFromTenant(new RemoveUserFromTenantRequest({userId: userId, tenantId: tenantId})));
    } 

    public async GetSignupUserData(token: string):Promise<GetSignupUserDataResponse>{
        return (await this.GetAdressedAuthRefreshableClient().getSignupUserData(token));
    } 

    public async SignupUserMicrosoft(id: number, token: string, signupToken: string):Promise<SignupUserMicrosoftResponse>{
        return (await this.GetAdressedAuthRefreshableClient().signupUserMicrosoft(new SignupUserMicrosoftRequest({userId: id, msToken: token, signupToken: signupToken})));
    }

    public async SetUserPassword(id: number, pass: string, signupToken: string):Promise<SetUserPasswordResponse>{
        return (await this.GetAdressedAuthRefreshableClient().setUserPassword(id, pass, signupToken));
    }
    
    public async CreateUserSendSignupMail(name: string, surname: string, email: string, localLogin: boolean, msLogin: boolean, tenantId?: number):Promise<CreateUserSendSignupMailResponse>{
        return (await this.GetAdressedAuthRefreshableClient().createUserSendSignupMail(new CreateUserSendSignupMailRequest({ name: name, surname: surname, email: email, tenantId: tenantId, localLogin: localLogin, msLogin: msLogin})));
    } 

    public async GetResetPasswordUserData(token: string):Promise<GetResetPasswordUserDataResponse>{
        return (await this.GetAdressedAuthRefreshableClient().getResetPasswordUserData(token));
    } 

    public async ResetUserPasswordSendMail(email: string):Promise<ResetUserPasswordSendMailResponse>{
        return (await this.GetAdressedAuthRefreshableClient().resetUserPasswordSendMail(email));
    }
}