import { Button, Divider, Typography, useTheme } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router';
import { useUserInfoStore } from '../Stores/UserInfoStore';
import './UsersPopup.css';
import { AccountBoxOutlined, AdminPanelSettingsOutlined, Logout, SettingsSystemDaydreamOutlined } from '@mui/icons-material';
interface UsersPopupProps {
    showChangeTenantDialog: () => void;
}

export default function UsersPopup(props: UsersPopupProps) {
    const { showChangeTenantDialog } = props;
    const navigate = useNavigate();
    const { initials, userName, userSurname, tenantName, userEmail, isAdmin, canCreateUsers, canCreateTenants } = useUserInfoStore();
    const theme = useTheme();

    const logout = () => {
        localStorage.removeItem('token');
        navigate('/');
    }
    return (
        <div style={{ minWidth: '320px' }}>
            <div style={{ margin: '10px', background: 'white', borderRadius:'8px' }}>
                <div style={{ display: 'flex' }}>
                    <div style={{ width: '30%' }}>
                        <div className='user-circle' >{initials}</div>

                    </div>
                    <div style={{ width: '70%' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', margin: '32px 12px 12px 12px' }}>
                            <Typography fontWeight={'bold'}>{userName}{' '}{userSurname}</Typography>
                            <Typography fontSize={14} color='action'>{userEmail}</Typography>
                            <Typography fontWeight='bold' color='primary'>{tenantName}</Typography>
                            <Button variant='outlined' sx={{margin:'20px', fontWeight:'bold'}} onClick={() => { navigate('/user'); }}>Správa účtu</Button>
                        </div>
                    </div>
                </div>
                <Divider sx={{ bgcolor: "primary.main" }} />
                <div>
                    <Button disabled={!canCreateTenants} color="secondary" startIcon={<AdminPanelSettingsOutlined/>} sx={{ paddingRight: "12px", justifyContent: "flex-start", width: '100%', fontWeight: 'bold', padding:'32px' }} onClick={() => {
                        navigate('/admin');
                    }}>
                        ADMINISTRÁTOR
                    </Button>
                </div>
                <Divider sx={{ bgcolor: "primary.main" }} />
                <div>
                    <Button disabled={!isAdmin} color="secondary" startIcon={<SettingsSystemDaydreamOutlined/>}  sx={{ width: "100%", paddingRight: "12px", justifyContent: "flex-start", fontWeight: 'bold', padding:'32px' }} onClick={() => { 
                            navigate('/tenant');
                        }}>
                        SPRÁVA TENANTU
                    </Button>
                </div>
                <Divider sx={{ bgcolor: "primary.main" }} />
                <div>
                    <Button color="secondary" startIcon={<SettingsSystemDaydreamOutlined/>}  sx={{ width: "100%", paddingRight: "12px", justifyContent: "flex-start", fontWeight: 'bold', padding:'32px' }} onClick={showChangeTenantDialog}>
                        ZMĚNA TENANTU
                    </Button>
                </div>


            </div>
            <Button color="secondary" startIcon={<Logout/>} onClick={logout} sx={{ width: "100%", paddingRight: "12px", fontWeight:'bold', padding:'16px' }} >
                Odhlásit
            </Button>
        </div>
    )
}
