import { useMsal } from '@azure/msal-react';
import { Alert, Button, CircularProgress, Snackbar, TextField, Typography, useTheme } from '@mui/material';
import React, { useEffect } from 'react'
import { useMediaPredicate } from 'react-media-hook';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { FileParameter, OutputRowDTO, RowType } from '../Api/ApiServer';
import './EtcsDataTableScene.css'

interface EtcsDataTableSceneProps {
    data: OutputRowDTO[];
}

export default function EtcsDataTableScene(props: EtcsDataTableSceneProps) {
    const { data } = props;
    const params = useParams();
    
    const theme = useTheme();
    const [snackBarText, setSnackBarText] = React.useState<string>();
    const [severenity, setSeverenity] = React.useState<'error' | 'info'>('info');

    useEffect(() => {
    }, []);    

    const getBackground = (type: RowType | undefined): string => {
        switch (type)
        {
            case RowType.Curve:
                return 'white';
            case RowType.Inflexion:
                return '#ddebf7';
            case RowType.Intermediate:
                return '#d0cece';
            default:
                return 'white';
        }
    }

    return (
        <div className={'container-table'} style={{ marginTop: '80px'}}> 
            <table className={'tableEtcs'}>
                <thead>
                    <tr>
                        <th className={'thEtcs freeze'}>č. Oblouku</th>
                        <th className={'thEtcs'}>ZP</th>
                        <th className={'thEtcs'}>ZO</th>
                        <th className={'thEtcs'}>KO</th>
                        <th className={'thEtcs'}>KP</th>
                        <th className={'thEtcs'}>ZO ZP</th>
                        <th className={'thEtcs'}>KO KP </th>
                        <th className={'thEtcs'}>R</th>
                        {/* <th className={'thEtcs'}>L<sub>i</sub></th>
                        <th className={'thEtcs'}>D</th> */}
                        <th className={'thEtcs'}>L<sub>vz1</sub></th>
                        <th className={'thEtcs'}>L<sub>vzm</sub></th>
                        <th className={'thEtcs'}>L<sub>vz2</sub></th>
                        <th className={'thEtcsEmpty'}></th>

                        <th className={'thEtcs100'}>V<sub>100</sub>max</th>
                        <th className={'thEtcs100'}>V<sub>100</sub></th>
                        <th className={'thEtcs100'}>I<sub>100</sub></th>
                        <th className={'thEtcs100'}>V<sub>100</sub> n<sub>1</sub></th>
                        <th className={'thEtcs100'}>V<sub>100</sub> n<sub>2</sub></th>
                        <th className={'thEtcsEmpty'}></th>

                        <th className={'thEtcs130'}>V<sub>130</sub>max</th>
                        <th className={'thEtcs130'}>V<sub>130</sub></th>
                        <th className={'thEtcs130'}>I<sub>130</sub></th>
                        <th className={'thEtcs130'}>V<sub>130</sub> n<sub>1</sub></th>
                        <th className={'thEtcs130'}>V<sub>130</sub> n<sub>2</sub></th>
                        <th className={'thEtcsEmpty'}></th>

                        <th className={'thEtcsK'}>V<sub>k</sub>max</th>
                        <th className={'thEtcsK'}>V<sub>k</sub></th>
                        <th className={'thEtcsK'}>I<sub>k</sub></th>
                        <th className={'thEtcsK'}>V<sub>k</sub> n<sub>1</sub></th>
                        <th className={'thEtcsK'}>V<sub>k</sub> n<sub>2</sub></th>
                        <th className={'thEtcsEmpty'}></th>

                        <th className={'thEtcs150'}>V<sub>150</sub>max</th>
                        <th className={'thEtcs150'}>V<sub>150</sub></th>
                        <th className={'thEtcs150'}>I<sub>150</sub></th>
                        <th className={'thEtcs150'}>V<sub>150</sub> n<sub>1</sub></th>
                        <th className={'thEtcs150'}>V<sub>150</sub> n<sub>2</sub></th>
                        <th className={'thEtcs150'}>V<sub>150</sub> n<sub>I</sub></th>
                        <th className={'thEtcs150'}>V<sub>150</sub>I</th>
                        <th className={'thEtcs150'}>V<sub>150</sub>&Delta;I</th>
                    </tr>
                </thead>  
                <tbody>  
                    {data.map((p, i) => {
                        return <tr key={i} style={{ background: getBackground(p.rowType)}}>
                            <td className={'tdEtcs freeze'}>{p.order}</td>
                            <td className={'tdEtcs'}>{p.zp !== undefined && p.zp !== null ? p.zp.toFixed(6) : ""}</td>
                            <td className={'tdEtcs'}>{p.zo !== undefined && p.zo !== null ? p.zo.toFixed(6) : ""}</td>
                            <td className={'tdEtcs'}>{p.ko !== undefined && p.ko !== null ? p.ko.toFixed(6) : ""}</td>
                            <td className={'tdEtcs'}>{p.kp !== undefined && p.kp !== null ? p.kp.toFixed(6) : ""}</td>
                            <td className={'tdEtcs'}>{p.zO_ZP !== undefined && p.zO_ZP !== null ? p.zO_ZP.toFixed(6) : ""}</td>
                            <td className={'tdEtcs'}>{p.kO_KP !== undefined && p.kO_KP !== null ? p.kO_KP.toFixed(6) : ""}</td>
                            <td className={'tdEtcs'}>{p.r !== undefined && p.r !== null ? p.r.toFixed(3) : ""}</td>
                            {/* <td className={'tdEtcs'}>{p.li !== undefined && p.li !== null ? p.li.toFixed(3) : ""}</td>
                            <td className={'tdEtcs'}>{p.d !== undefined && p.d !== null ? p.d.toFixed(0) : ""}</td> */}
                            <td className={'tdEtcs'}>{p.lvz1 !== undefined && p.lvz1 !== null ? p.lvz1.toFixed(3) : ""}</td>
                            <td className={'tdEtcs'}>{p.lvzm !== undefined && p.lvzm !== null ? p.lvzm.toFixed(3) : ""}</td>
                            <td className={'tdEtcs'}>{p.lvz2 !== undefined && p.lvz2 !== null ? p.lvz2.toFixed(3) : ""}</td>
                            <td className={'tdEtcs'}></td>

                            <td className={'tdEtcs'}>{p.v100max !== undefined && p.v100max !== null ? p.v100max.toFixed(0) : ""}</td>
                            <td className={'tdEtcs'}>{p.v100 !== undefined && p.v100 !== null ? p.v100.toFixed(0) : ""}</td>
                            <td className={'tdEtcs'}>{p.l100 !== undefined && p.l100 !== null ? p.l100.toFixed(0) : ""}</td>
                            <td className={'tdEtcs'}>{p.v100n1 !== undefined && p.v100n1 !== null ? p.v100n1.toFixed(0) : "bez vstupnice"}</td>
                            <td className={'tdEtcs'}>{p.v100n2 !== undefined && p.v100n2 !== null ? p.v100n2.toFixed(0) : "bez vstupnice"}</td>
                            <td className={'tdEtcs'}></td>

                            <td className={'tdEtcs'}>{p.v130max !== undefined && p.v130max !== null ? p.v130max.toFixed(0) : ""}</td>
                            <td className={'tdEtcs'}>{p.v130 !== undefined && p.v130 !== null ? p.v130.toFixed(0) : ""}</td>
                            <td className={'tdEtcs'}>{p.l130 !== undefined && p.l130 !== null ? p.l130.toFixed(0) : ""}</td>
                            <td className={'tdEtcs'}>{p.v130n1 !== undefined && p.v130n1 !== null ? p.v130n1.toFixed(0) : "bez vstupnice"}</td>
                            <td className={'tdEtcs'}>{p.v130n2 !== undefined && p.v130n2 !== null ? p.v130n2.toFixed(0) : "bez vstupnice"}</td>
                            <td className={'tdEtcs'}></td>
                            
                            <td className={'tdEtcs'}>{p.vkmax !== undefined && p.vkmax !== null ? p.vkmax.toFixed(0) : ""}</td>
                            <td className={'tdEtcs'}>{p.vk !== undefined && p.vk !== null ? p.vk.toFixed(0) : ""}</td>
                            <td className={'tdEtcs'}>{p.lk !== undefined && p.lk !== null ? p.lk.toFixed(0) : ""}</td>
                            <td className={'tdEtcs'}>{p.vkn1 !== undefined && p.vkn1 !== null ? p.vkn1.toFixed(0) : "bez vstupnice"}</td>
                            <td className={'tdEtcs'}>{p.vkn2 !== undefined && p.vkn2 !== null ? p.vkn2.toFixed(0) : "bez vstupnice"}</td>
                            <td className={'tdEtcs'}></td>

                            <td className={'tdEtcs'}>{p.v150max !== undefined && p.v150max !== null ? p.v150max.toFixed(0) : ""}</td>
                            <td className={'tdEtcs'}>{p.v150 !== undefined && p.v150 !== null ? p.v150.toFixed(0) : ""}</td>
                            <td className={'tdEtcs'}>{p.l150 !== undefined && p.l150 !== null ? p.l150.toFixed(0) : ""}</td>
                            <td className={'tdEtcs'}>{p.v150n1 !== undefined && p.v150n1 !== null ? p.v150n1.toFixed(0) : "bez vstupnice"}</td>
                            <td className={'tdEtcs'}>{p.v150n2 !== undefined && p.v150n2 !== null ? p.v150n2.toFixed(0) : "bez vstupnice"}</td>
                            <td className={'tdEtcs'}>{p.v150ni !== undefined && p.v150ni !== null ? p.v150ni.toFixed(0) : "bez vstupnice"}</td>
                            <td className={'tdEtcs'}>{p.v150i !== undefined && p.v150i !== null ? p.v150i.toFixed(0) : ""}</td>
                            <td className={'tdEtcs'}>{p.v150deltai !== undefined && p.v150deltai !== null ? p.v150deltai.toFixed(0) : ""}</td>
                        </tr>;
                    })}  
                </tbody>              
            </table>   
            <Snackbar open={snackBarText !== undefined} autoHideDuration={3000} onClose={() => setSnackBarText(undefined)}>

                <Alert onClose={() => setSnackBarText(undefined)} severity={severenity} sx={{ width: '100%' }}>
                    {snackBarText}
                </Alert>

            </Snackbar>
        </div>)
}
