import { ApiBaseClient } from "../Api/ApiBaseClient";
import { BentleyProductType, CreateNewUpdateRequest, FileParameter, GetUploaderUriResponse, SGProductType, SaveBlobToUpdateRequest } from "../Api/ApiServer";

export class ApiUpdaterClient extends ApiBaseClient {

    public async GetUploadUri(version: string, minimumVersion: string, product: SGProductType, productPlugin: BentleyProductType, password: string): Promise<GetUploaderUriResponse> {
        return (await this.GetClientToAuth().getUploaderUri(new CreateNewUpdateRequest({
            password: password,
            product: product,
            productPlugin: productPlugin,
            version: version,
            minimumUpdateVersion: minimumVersion
        })));
    }
    public async GetUploadUriToUpdater(file: FileParameter, password: string): Promise<GetUploaderUriResponse> {
        return (await this.GetClientToAuth().getUploaderUriToUpdater(file, password));
    }
    public async FinishUploadUpdater(uploadEntityId: number): Promise<void> {
        return (await this.GetClientToAuth().saveBlobToUpdate(new SaveBlobToUpdateRequest({ password: 'BIMCONUpdaterUpload6265446466', updateId: uploadEntityId })));
    }
}