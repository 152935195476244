import { ceilWithMinus } from "../../Shared/ceilWithMinus";
import { ObloukItemState } from "../Enums/ObloukItemState";
import { ObloukItem } from "../Models/ObloukItem";

export function calculateI130(items: { [key: string]: ObloukItem }): void {
    if (items['V130'].numericValue > 0) {
      if (items['V130'].numericValue <= 230) {
        items['Inn130'].setValueByNumericValue(80);
      } else if (items['V130'].numericValue <= 300) {
        items['Inn130'].setValueByNumericValue(70);
      } else {
        items['Inn130'].setValueByNumericValue(60);
      }

      if (items['V130'].numericValue <= 250) {
        items['Ilim130'].setValueByNumericValue(100);
        items['Imax130'].setValueByNumericValue(130);

      } else if (items['V130'].numericValue <= 300) {
        items['Ilim130'].setValueByNumericValue(80);
        items['Imax130'].setValueByNumericValue(100);
      } else {
        items['Inn130'].setValueByNumericValue(65);
        items['Imax130'].setValueByNumericValue(90);
      }


      items['I130'].setValueByNumericValue(11.8 * Math.pow(items['V130'].numericValue, 2) / items['R'].numericValue - items['D'].numericValue);
      items['I130'].setValueByNumericValue(ceilWithMinus(items['I130'].numericValue));
      items['I_E130'].setValueByNumericValue(Math.abs(items['I130'].numericValue));

      if (items['I130'].numericValue < -110) items['I130'].okState = ObloukItemState.Error;
      else if (items['I130'].numericValue < -80) items['I130'].okState = ObloukItemState.Zlute;
      else if (items['I130'].numericValue > items['Imax130'].numericValue) items['I130'].okState = ObloukItemState.Error;
      else if (items['I130'].numericValue > items['Ilim130'].numericValue) {
        if (items['V130'].numericValue < 80 && items['R'].numericValue < 250) {
          items['I130'].okState = ObloukItemState.OranzoveSrafovane;
          items['I130'].toolTip = "Lze pouze pro vozidla s hmotností na nápravu do 18 t.";
        }
        else if (items['V130'].numericValue > 300 && items['I130'].numericValue > 80) {
          items['I130'].okState = ObloukItemState.OranzoveSrafovane;
          items['I130'].toolTip = "Lze jen při návrhu PJD.";
        }
        else items['I130'].okState = ObloukItemState.Oranzove;
      }
      else if (items['I130'].numericValue > items['Inn130'].numericValue) items['I130'].okState = ObloukItemState.Zlute;
      //rewrite bellow to ts
      if (items['I130'].numericValue >= 0) items['I130'].setValueByNumericValue(Math.round(items['I130'].numericValue));

      //co tohle znamená??
      else items['I130'].value = "E=" + Math.abs(Math.round(items['I130'].numericValue));
    }
    //     If V130 Then
    //     If V130 <= 230 Then
    //         Inn130 = 80
    //     Elseif V130 <= 300 Then
    //         Inn130 = 70
    //     Else
    //         Inn130 = 60
    //     End If
    //     If V130 <= 250 Then
    //         Ilim130 = 100
    //     Elseif V130 <= 300 Then
    //         Ilim130 = 80
    //     Else
    //         Inn130 = 65
    //     End If

    //     If V130 <= 250 Then
    //         Imax130 = 130
    //     Elseif V130 <= 300 Then
    //         Imax130 = 100
    //     Else
    //         Imax130 = 90
    //     End If

    //     I130 = 11.8 * V130 ^ 2 / R - D
    //     If I130 >= 0 Then
    //         I130 = Application.WorksheetFunction.RoundUp(I130, 0)
    //     Else
    //         'I130 = Application.WorksheetFunction.RoundDown(I130, 0)
    //         I130 = Application.WorksheetFunction.RoundUp(I130, 0)
    //     End If
    //     I_E130 = Abs(I130)
    //     Select Case I130
    //      Case Is < -110
    //         BunkaFormat "K" & lRadek, 99
    //      Case Is < -80
    //         BunkaFormat "K" & lRadek, 3
    //      Case Is > Imax130
    //         BunkaFormat "K" & lRadek, 99
    //      Case Is > Ilim130
    //         If V130 < 80 And R < 250 Then
    //             BunkaFormat "K" & lRadek, 5
    //             vBublina = "Lze pouze pro vozidla s hmotností na nápravu Do 18 t."
    //             Set rng = Range("K" & lRadek)
    //             rng.AddComment vBublina
    //         Elseif V130 > 300 And I130 > 80 Then
    //             BunkaFormat "K" & lRadek, 5
    //             vBublina = "Lze jen při návrhu PJD."
    //             Set rng = Range("K" & lRadek)
    //             rng.AddComment vBublina
    //         Else
    //             BunkaFormat "K" & lRadek, 4
    //         End If
    //      Case Is > Inn130
    //         BunkaFormat "K" & lRadek, 3
    //     End Select
    //     If I130 >= 0 Then
    //         Range("K" & lRadek).Value = Round(I130, 0)
    //     Else
    //         Range("K" & lRadek).Value = "E=" & Abs(Round(I130, 0))
    //     End If
    // End If
  }