import { ceilWithMinus } from "../../Shared/ceilWithMinus";
import { ObloukItemState } from "../Enums/ObloukItemState";
import { ObloukItem } from "../Models/ObloukItem";

export function calculateI(items: { [key: string]: ObloukItem }): void {

  items['I'].setValueByNumericValue(11.8 * Math.pow(items['V'].numericValue, 2) / items['R'].numericValue - items['D'].numericValue);

  items['I'].setValueByNumericValue(ceilWithMinus(items['I'].numericValue));
  items['I_E'].setValueByNumericValue(Math.abs(items['I'].numericValue));

  //     If V <= 230 Then
  //     Inn = 80
  // Elseif V <= 300 Then
  //     Inn = 70
  // Else
  //     Inn = 60
  // End If

  if (items['V'].numericValue <= 230) {
    items['Inn'].setValueByNumericValue(80);
  } else if (items['V'].numericValue <= 300) {
    items['Inn'].setValueByNumericValue(70);
  } else {
    items['Inn'].setValueByNumericValue(60);
  }
  if (items['V'].numericValue <= 230) {
    items['Ilim'].setValueByNumericValue(100);
  } else if (items['V'].numericValue <= 300) {
    items['Ilim'].setValueByNumericValue(80);
  } else {
    items['Ilim'].setValueByNumericValue(65);
  }

  if (items['V'].numericValue <= 300) {
    items['Imax'].setValueByNumericValue(100);
  } else {
    items['Imax'].setValueByNumericValue(90);
  }
  if (items['I'].numericValue < -110) items['I'].okState = ObloukItemState.Error;
  else if (items['I'].numericValue < -80) items['I'].okState = ObloukItemState.Zlute;
  else if (items['I'].numericValue > items['Imax'].numericValue) items['I'].okState = ObloukItemState.Error;
  else if (items['I'].numericValue > items['Ilim'].numericValue) {
    if (items['V'].numericValue > 300 && items['I'].numericValue > 80) {
      items['I'].okState = ObloukItemState.OranzoveSrafovane;
      items['I'].toolTip = "Lze jen při návrhu PJD.";
    }
    else items['I'].okState = ObloukItemState.Oranzove;
  }
  else if (items['I'].numericValue > items['Inn'].numericValue) items['I'].okState = ObloukItemState.Zlute;
  //rewrite bellow to ts
  if (items['I'].numericValue >= 0) items['I'].setValueByNumericValue(Math.round(items['I'].numericValue));

  //co tohle znamená??
  else items['I'].value = "E=" + Math.abs(Math.round(items['I'].numericValue));

}