import { ObloukItemState } from "../Enums/ObloukItemState";
import { ObloukItem } from "../Models/ObloukItem";

export function setSpeedVStates(items: { [key: string]: ObloukItem }): void {
    if (items['V'].numericValue > 360) {
      items['V'].okState = ObloukItemState.Error;
    }
    if (items['V130'].numericValue > 360) {
      items['V130'].okState = ObloukItemState.Error;
    }
    if (items['V150'].numericValue > 360) {
      items['V150'].okState = ObloukItemState.Error;
    }
    if (items['Vk'].numericValue > 360) {
      items['Vk'].okState = ObloukItemState.Error;
    }
    if (items['Vk'].numericValue > 0 && items['Vk'].numericValue < 70) {
      items['Vk'].okState = ObloukItemState.Error;
    }
    if (items['D'].numericValue < 30 && items['Vk'].numericValue !== 0) {
      items['Vk'].okState = ObloukItemState.Error;
    }
    if (items['V'].numericValue < 70 && items['Vk'].numericValue !== 0) {
      items['Vk'].okState = ObloukItemState.Error;
    }
    if (items['Vnakl'].numericValue > 360) {
      items['Vnakl'].okState = ObloukItemState.Error;
    }

  }