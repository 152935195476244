import proj4 from "proj4";

export const convertToGPS = (latitude: number, longitude: number) => {
    // Define your GPS coordinates in latitude and longitude format
    // const latitude = 50.123456; // Replace with your actual latitude
    // const longitude = 14.987654; // Replace with your actual longitude

    // Define the WGS84 projection definition
    const wgs84Projection = '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs';

    // Define the S-JTSK projection definition
    const sjtskProjection = '+proj=krovak +lat_0=49.5 +lon_0=24.83333333333333 +alpha=30.28813975277778 +k=0.9999 +x_0=0 +y_0=0 +ellps=bessel +towgs84=570.8,85.7,462.8,4.998,1.587,5.261,3.56 +pm=bessel +units=m +no_defs';

    // Perform the reverse coordinate conversion
    const gpsCoordinated = proj4(sjtskProjection, wgs84Projection, [longitude, latitude]);

    // console.log('S-JTSK:', latitude, longitude);
    // console.log('GPS:', gpsCoordinated);
    return gpsCoordinated;
};
export const convertToSJTSK = (latitude: number, longitude: number) => {
    // Define your GPS coordinates in latitude and longitude format
    // const latitude = 50.123456; // Replace with your actual latitude
    // const longitude = 14.987654; // Replace with your actual longitude

    // Define the WGS84 projection definition
    const wgs84Projection = '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs';

    // Define the S-JTSK projection definition
    const sjtskProjection = '+proj=krovak +lat_0=49.5 +lon_0=24.83333333333333 +alpha=30.28813975277778 +k=0.9999 +x_0=0 +y_0=0 +ellps=bessel +towgs84=570.8,85.7,462.8,4.998,1.587,5.261,3.56 +pm=bessel +units=m +no_defs';

    // Perform the reverse coordinate conversion
    const sjtskCoordinates = proj4(wgs84Projection, sjtskProjection, [longitude, latitude]);

    console.log('GPS:', latitude, longitude);
    console.log('S-JTSK:', sjtskCoordinates);
    return sjtskCoordinates;
};