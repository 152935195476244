import React, { useEffect, useRef } from "react";
import { Polyline } from "react-leaflet";
import "leaflet-arrowheads";

export default function ArrowHeadsPolyline(props) {
  const polylineRef = useRef(null);

  useEffect(() => {
    if (props.arrowheads) {
      const polyline = polylineRef.current;
      polyline.arrowheads(props.arrowheads);
      polyline._update();
    }

    return () => {
      if (props.arrowheads) {
        const polyline = polylineRef.current;
        if (polyline && polyline.deleteArrowheads) polyline.deleteArrowheads();
        if (polyline && polyline._update) polyline._update();
        if(polyline && polyline.remove) polyline.remove();
      }
    };
  }, []);

  return <Polyline {...props} ref={polylineRef} />;
}
