import { ceilWithMinus } from "../../Shared/ceilWithMinus";
import { roundNumber } from "../../Shared/roundNumber";
import { ObloukItem } from "../Models/ObloukItem";

const vmaxTemp = 360;
export function whispering(items: { [key: string]: ObloukItem }): void {
    let naseptavaniD = false;
    let dtemp = 0;
    if (items['D'].value !== '') dtemp = items['D'].numericValue;
    if (items['R'].numericValue > 0) {
        if (items['Lk1'].value === '' && items['V'].value !== '' && items['D'].value !== '')
            items['Lk1'].whisperTexts = [roundNumber(items['Lmin'].numericValue, 3).toLocaleString("cs"), ceilWithMinus(items['Llim'].numericValue).toString(), ceilWithMinus(items['Ln'].numericValue).toString()];
        if (items['Lk2'].value === '' && items['V'].value !== '' && items['D'].value !== '')
            items['Lk2'].whisperTexts = [roundNumber(items['Lmin'].numericValue, 3).toLocaleString("cs"), ceilWithMinus(items['Llim'].numericValue).toString(), ceilWithMinus(items['Ln'].numericValue).toString()];



        if (items['D'].value === '') {
            naseptavaniD = true;
            items['Dmax'].setValueByNumericValue(160);
            if (items['R'].numericValue < 290) {
                items['Dmax'].setValueByNumericValue(Math.floor((items['R'].numericValue - 50) / 1.5));

            }
            if (items['V'].value === '' && items['V130'].value === '' && items['V150'].value === '' && items['Vk'].value === '') {
                items['D'].whisperTexts = [items['Dmax'].numericValue.toString()];
                dtemp = items['Dmax'].numericValue;
            }
            else {
                //     If Not IsEmpty(V) Then
                //     D = WorksheetFunction.RoundUp(11.8 * V ^ 2 / R - Imax, 0)
                //rewrite whole upper comment to typescript

                let ikTemp = 0;
                //priorita operátorů??
                if (items['V'].value !== '') {
                    dtemp = ceilWithMinus(11.8 * Math.pow(items['V'].numericValue, 2) / items['R'].numericValue - items['Imax'].numericValue);
                }
                else if (items['V130'].value !== '') {
                    dtemp = ceilWithMinus(11.8 * Math.pow(items['V130'].numericValue, 2) / items['R'].numericValue - items['Imax130'].numericValue);
                }
                else if (items['V150'].value !== '') {
                    dtemp = ceilWithMinus(11.8 * Math.pow(items['V150'].numericValue, 2) / items['R'].numericValue - items['Imax150'].numericValue);
                }
                else if (items['Vk'].value !== '') {
                    dtemp = ceilWithMinus((11.8 * Math.pow(items['Vk'].numericValue, 2)) / (items['R'].numericValue - items['Ikmax'].numericValue));
                    // ikTemp = 0;
                    if (dtemp < 30) {
                        dtemp = 30;
                    }
                }
                if (dtemp < 0) {
                    dtemp = 0;
                }

                if (dtemp > items['Dmax'].numericValue) {
                    dtemp = 999;

                }
                if (dtemp !== items['Dmax'].numericValue && dtemp !== 999) {
                    // "' " & CStr(D) & "-" & CStr(Dmax)
                    items['D'].whisperTexts = [dtemp.toString(), items['Dmax'].value];
                }
                else if (dtemp === 999) {
                    items['D'].whisperTexts = ["NELZE"];
                }
                else {
                    items['D'].whisperTexts = [dtemp.toString()];
                }
            }


        }
        whisperForSpeed(items, dtemp, naseptavaniD);
        whisperForSpeed130(items, dtemp, naseptavaniD);
        whisperForSpeed150(items, dtemp, naseptavaniD);
        whisperForSpeedVk(items, dtemp, naseptavaniD);

    }
}
function whisperForSpeed(items: { [key: string]: ObloukItem }, dTemp: number, naseptavaniD: boolean) {

    let Vbtemp = 0;
    if (items['V'].value === '') {
        if (dTemp !== 999) {
            let iTemp = 100;
            let vTemp = (items['R'].numericValue * (iTemp + dTemp) / 11.8) ** 0.5;
            vTemp = Math.floor(vTemp) - (Math.floor(vTemp) % 5);
            if (vTemp > 300) {
                iTemp = 90;
                vTemp = (items['R'].numericValue * (iTemp + dTemp) / 11.8) ** 0.5;
                vTemp = Math.floor(vTemp) - (Math.floor(vTemp) % 5);
            }
            if (naseptavaniD) {
                // I = 100
                // Vb = (R * (I + Dmax) / 11.8) ^ 0.5
                // Vb = WorksheetFunction.RoundDown(Vb, 0) - WorksheetFunction.RoundDown(Vb, 0) Mod 5
                // If Vb > 300 Then
                //     I = 90
                //     Vb = (R * (I + Dmax) / 11.8) ^ 0.5
                //     Vb = WorksheetFunction.RoundDown(Vb, 0) - WorksheetFunction.RoundDown(Vb, 0) Mod 5
                // End If
                iTemp = 100;
                Vbtemp = (items['R'].numericValue * (iTemp + items['Dmax'].numericValue) / 11.8) ** 0.5;
                Vbtemp = Math.floor(Vbtemp) - (Math.floor(Vbtemp) % 5);
                if (Vbtemp > 300) {
                    iTemp = 90;
                    Vbtemp = (items['R'].numericValue * (iTemp + items['Dmax'].numericValue) / 11.8) ** 0.5;
                    Vbtemp = Math.floor(Vbtemp) - (Math.floor(Vbtemp) % 5);
                }
            }
            if (vTemp > vmaxTemp) vTemp = vmaxTemp;
            if (Vbtemp > vmaxTemp) Vbtemp = vmaxTemp;
            //     If Vb > V Then
            //     Range("E" & lRadek).Value = "' " & CStr(V) & "-" & CStr(Vb)
            // Else
            //     Range("E" & lRadek).Value = "' " & CStr(V)
            // End If
            if (Vbtemp > vTemp)
                items['V'].whisperTexts = [vTemp.toString(), Vbtemp.toString()];
            else
                items['V'].whisperTexts = [vTemp.toString()];


        }
    }

}
function whisperForSpeed130(items: { [key: string]: ObloukItem }, dTemp: number, naseptavaniD: boolean) {
    let I130temp = 130;
    // let Vtemp130 = 0;
    let Vbtemp130 = 0;
    if (items['V130'].value === '') {
        if (dTemp !== 999) {
            let iTemp = 100;
            let Vtemp130 = (items['R'].numericValue * (I130temp + dTemp) / 11.8) ** 0.5;
            Vtemp130 = Math.floor(Vtemp130) - (Math.floor(Vtemp130) % 5);
            if (Vtemp130 > 250) {
                I130temp = 100;
                Vtemp130 = (items['R'].numericValue * (I130temp + dTemp) / 11.8) ** 0.5;
                Vtemp130 = Math.floor(Vtemp130) - (Math.floor(Vtemp130) % 5);
            }
            if (Vtemp130 > 300) {
                I130temp = 90;
                Vtemp130 = (items['R'].numericValue * (I130temp + dTemp) / 11.8) ** 0.5;
                Vtemp130 = Math.floor(Vtemp130) - (Math.floor(Vtemp130) % 5);
            }
            if (naseptavaniD) {
                Vbtemp130 = (items['R'].numericValue * (I130temp + items['Dmax'].numericValue) / 11.8) ** 0.5;
                Vbtemp130 = Math.floor(Vbtemp130) - (Math.floor(Vbtemp130) % 5);
            }

            if (Vtemp130 > vmaxTemp) Vtemp130 = vmaxTemp;
            if (Vbtemp130 > vmaxTemp) Vbtemp130 = vmaxTemp;

            if (Vbtemp130 > Vtemp130) items['V130'].whisperTexts = [Vtemp130.toString(), Vbtemp130.toString()];
            else items['V130'].whisperTexts = [Vtemp130.toString()];


        }
    }
    else {
        if (naseptavaniD) {
            if (items['V130'].numericValue > 250) {
                I130temp = 100;
            }
            if (items['V130'].numericValue > 300) {
                I130temp = 90;
            }
            let Dx = ceilWithMinus(11.8 * Math.pow(items['V130'].numericValue, 2) / items['R'].numericValue - I130temp);
            if (Dx > dTemp && dTemp < 999) {
                dTemp = Dx;
                if (dTemp > items['Dmax'].numericValue) dTemp = 999;
                if (dTemp !== items['Dmax'].numericValue && dTemp !== 999) {
                    items['D'].whisperTexts = [dTemp.toString(), items['Dmax'].numericValue.toString()];
                }
                else if (dTemp === 999) {
                    items['D'].whisperTexts = ['NELZE'];
                }
                else items['D'].whisperTexts = [dTemp.toString()];
            }

        }
    }
}

function whisperForSpeed150(items: { [key: string]: ObloukItem }, dTemp: number, naseptavaniD: boolean) {
    let I150temp = 150;
    // let Vtemp150 = 0;
    let Vbtemp150 = 0;
    if (items['V150'].value === '') {
        if (dTemp !== 999) {
            let Vtemp150 = (items['R'].numericValue * (I150temp + dTemp) / 11.8) ** 0.5;
            Vtemp150 = Math.floor(Vtemp150) - (Math.floor(Vtemp150) % 5);
            if (Vtemp150 < 80 || Vtemp150 > 230) {
                I150temp = 130;
                Vtemp150 = (items['R'].numericValue * (I150temp + dTemp) / 11.8) ** 0.5;
                Vtemp150 = Math.floor(Vtemp150) - (Math.floor(Vtemp150) % 5);
            }
            if (Vtemp150 > 250) {
                I150temp = 100;
                Vtemp150 = (items['R'].numericValue * (I150temp + dTemp) / 11.8) ** 0.5;
                Vtemp150 = Math.floor(Vtemp150) - (Math.floor(Vtemp150) % 5);
            }
            if (Vtemp150 > 300) {
                I150temp = 90;
                Vtemp150 = (items['R'].numericValue * (I150temp + dTemp) / 11.8) ** 0.5;
                Vtemp150 = Math.floor(Vtemp150) - (Math.floor(Vtemp150) % 5);
            }
            if (naseptavaniD) {
                I150temp = 150;
                Vbtemp150 = (items['R'].numericValue * (I150temp + items['Dmax'].numericValue) / 11.8) ** 0.5;
                Vbtemp150 = Math.floor(Vbtemp150) - (Math.floor(Vbtemp150) % 5);
                if (Vbtemp150 < 80 || Vbtemp150 > 230) {
                    I150temp = 130;
                    Vbtemp150 = (items['R'].numericValue * (I150temp + items['Dmax'].numericValue) / 11.8) ** 0.5;
                    Vbtemp150 = Math.floor(Vbtemp150) - (Math.floor(Vbtemp150) % 5);
                }
                if (Vbtemp150 > 250) {
                    I150temp = 100;
                    Vbtemp150 = (items['R'].numericValue * (I150temp + items['Dmax'].numericValue) / 11.8) ** 0.5;
                    Vbtemp150 = Math.floor(Vbtemp150) - (Math.floor(Vbtemp150) % 5);
                }
                if (Vbtemp150 > 300) {
                    I150temp = 90;
                    Vbtemp150 = (items['R'].numericValue * (I150temp + items['Dmax'].numericValue) / 11.8) ** 0.5;
                    Vbtemp150 = Math.floor(Vbtemp150) - (Math.floor(Vbtemp150) % 5);
                }
            }
            if (naseptavaniD) {

            }
            if (Vtemp150 > vmaxTemp) Vtemp150 = vmaxTemp;
            if (Vbtemp150 > vmaxTemp) Vbtemp150 = vmaxTemp;

            if (Vbtemp150 > Vtemp150) items['V150'].whisperTexts = [Vtemp150.toString(), Vbtemp150.toString()];
            else items['V150'].whisperTexts = [Vtemp150.toString()];


        }
    }
    else {
        if (naseptavaniD) {
            if (items['V150'].numericValue < 80 || items['V150'].numericValue > 230) {
                I150temp = 100;
            }
            if (items['V150'].numericValue > 250) {
                I150temp = 100;
            }
            if (items['V150'].numericValue > 300) {
                I150temp = 90;
            }
            let Dx = ceilWithMinus(11.8 * Math.pow(items['V150'].numericValue, 2) / items['R'].numericValue - I150temp);
            if (Dx > dTemp && dTemp < 999) {
                dTemp = Dx;
                if (dTemp > items['Dmax'].numericValue) dTemp = 999;
                if (dTemp !== items['Dmax'].numericValue && dTemp !== 999) {
                    items['D'].whisperTexts = [dTemp.toString(), items['Dmax'].numericValue.toString()];
                }
                else if (dTemp === 999) {
                    items['D'].whisperTexts = ['NELZE'];
                }
                else items['D'].whisperTexts = [dTemp.toString()];
            }

        }
    }
}
function whisperForSpeedVk(items: { [key: string]: ObloukItem }, dTemp: number, naseptavaniD: boolean) {

    let IKtemp = 0;
    let VKtemp = 0;
    let VKtempB = 0;
    if (items['R'].numericValue >= 300)
        IKtemp = 270;
    else if (items['R'].numericValue >= 250) {
        IKtemp = 240;
    }

    if (items['Vk'].value === '') {
        if (dTemp !== 999) {
            if (IKtemp > 0) {
                VKtemp = (items['R'].numericValue * (IKtemp + dTemp) / 11.8) ** 0.5;
                VKtemp = Math.floor(VKtemp) - (Math.floor(VKtemp) % 5);
                if (VKtemp > 230) {
                    IKtemp = 130;
                    VKtemp = (items['R'].numericValue * (IKtemp + dTemp) / 11.8) ** 0.5;
                    VKtemp = Math.floor(VKtemp) - (Math.floor(VKtemp) % 5);
                }
                if (VKtemp > 250) {
                    IKtemp = 100;
                    VKtemp = (items['R'].numericValue * (IKtemp + dTemp) / 11.8) ** 0.5;
                    VKtemp = Math.floor(VKtemp) - (Math.floor(VKtemp) % 5);
                }
                if (VKtemp > 300) {
                    IKtemp = 90;
                    VKtemp = (items['R'].numericValue * (IKtemp + dTemp) / 11.8) ** 0.5;
                    VKtemp = Math.floor(VKtemp) - (Math.floor(VKtemp) % 5);
                }
                if (naseptavaniD) {
                    if (items['R'].numericValue >= 300)
                        IKtemp = 270;
                    else if (items['R'].numericValue >= 250) {
                        IKtemp = 240;
                    }
                    else IKtemp = 0;

                    VKtempB = (items['R'].numericValue * (IKtemp + items['Dmax'].numericValue) / 11.8) ** 0.5;
                    VKtempB = Math.floor(VKtempB) - (Math.floor(VKtempB) % 5);
                    if (VKtempB > 230) {
                        IKtemp = 130;
                        VKtempB = (items['R'].numericValue * (IKtemp + items['Dmax'].numericValue) / 11.8) ** 0.5;
                        VKtempB = Math.floor(VKtempB) - (Math.floor(VKtempB) % 5);
                    }
                    if (VKtempB > 250) {
                        IKtemp = 100;
                        VKtempB = (items['R'].numericValue * (IKtemp + items['Dmax'].numericValue) / 11.8) ** 0.5;
                        VKtempB = Math.floor(VKtempB) - (Math.floor(VKtempB) % 5);
                    }
                    if (VKtempB > 300) {
                        IKtemp = 90;
                        VKtempB = (items['R'].numericValue * (IKtemp + items['Dmax'].numericValue) / 11.8) ** 0.5;
                        VKtempB = Math.floor(VKtempB) - (Math.floor(VKtempB) % 5);
                    }
                }
                if (VKtemp > vmaxTemp) VKtemp = vmaxTemp;
                if (VKtempB > vmaxTemp) VKtempB = vmaxTemp;

                if (VKtempB > VKtemp) items['Vk'].whisperTexts = [VKtemp.toString(), VKtempB.toString()];
                else items['Vk'].whisperTexts = [VKtemp.toString()];
                //2354
            }
        }
    }
    else {
        if (naseptavaniD && IKtemp > 0) {
            if (items['Vk'].numericValue > 230) {
                IKtemp = 130;
            }
            if (items['Vk'].numericValue > 250) {
                IKtemp = 100;
            }
            if (items['Vk'].numericValue > 300) {
                IKtemp = 90;
            }
            let Dx = ceilWithMinus(11.8 * Math.pow(items['Vk'].numericValue, 2) / items['R'].numericValue - IKtemp);
            if (Dx > dTemp && dTemp < 999) {
                dTemp = Dx;
                if (dTemp > items['Dmax'].numericValue) dTemp = 999;
                if (dTemp !== items['Dmax'].numericValue && dTemp !== 999) {
                    items['D'].whisperTexts = [dTemp.toString(), items['Dmax'].numericValue.toString()];
                }
                else if (dTemp === 999) {
                    items['D'].whisperTexts = ['NELZE'];
                }
                else items['D'].whisperTexts = [dTemp.toString()];
            }

        }
    }

}

