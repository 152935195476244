import * as React from 'react';
import { } from '@itwin/core-frontend';
import ProjectWiseLayoutScene from './ProjectWiseLayoutScene';
import { usePwUser } from './Context/PWUserDataProvider';
import refreshBentleyToken from './Helpers/refreshToken';
import { useNavigate } from 'react-router';

export interface IPWMainSceneProps {
}

export default function PWMainScene(props: IPWMainSceneProps) {
    const { setUser } = usePwUser();
    const navigate = useNavigate();
    const getLoginData = (token) => {

        const axios = require('axios');

        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: 'https://api.bentley.com/users/me',
            headers: {
                'Authorization': 'Bearer ' + token
            }
        };

        axios.request(config)
            .then((response) => {
                setUser({ id: response.data.user.id, email: response.data.user.email, displayName: response.data.user.displayName, firstName: response.data.user.givenName, lastName: response.data.user.surname });

                const expires = localStorage.getItem('bentley_token_expires')!;
                console.log('expires', (Number(expires) * 1000) - 5000);
                window.setTimeout(() => {
                    refreshBentleyToken();
                }, (Number(expires) * 1000) - 5000);
            })
            .catch((error) => {
                refreshBentleyToken().catch((error) => {
                    navigate('/pwlogin');
                });
            });
    }

    React.useEffect(() => {
        const token = localStorage.getItem('bentley_access_token')!;
        getLoginData(token);
    }, []);

    return (
        <div>
            <ProjectWiseLayoutScene />
            {/* <ProjectWiseProjectScene /> */}
        </div>
    );
}
