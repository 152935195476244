import { ValidationDesignHectoServer } from "../Api/ApiServer";
import { convertToGPS } from "../Shared/LeafletMap/GpsSJTSKConverter";
import { Hectometer } from "../Shared/LeafletMap/LeafletMap";

export const parseHectos = (hectos: ValidationDesignHectoServer[]) => {
    let newHectos: Hectometer[] = [];
    hectos.forEach(hecto => {
        let convertedBP = convertToGPS(hecto.basePoint!.y!, hecto.basePoint!.x!);
        let convertedCP = convertToGPS(hecto.crossPoint!.y!, hecto.crossPoint!.x!);
        newHectos.push({ basePoint: ({ lat: convertedBP[1], lng: convertedBP[0] }), text: hecto.station !== undefined ? hecto.station.toFixed(1) : '', crossPoint: ({ lat: convertedCP[1], lng: convertedCP[0] }) });
    });
    return newHectos;
}
export const parseLines = (actLines?: any[]) => {
    let newLines: any[] = [];
    actLines!.forEach(line => {
        let converted = convertToGPS(line.points[0].Y, line.points[0].X);
        newLines.push({ lat: converted[1], lng: converted[0], type: line.type, crossId: line.crossId });

        let converted2 = convertToGPS(line.points[1].Y, line.points[1].X);
        newLines.push({ lat: converted2[1], lng: converted2[0], type: line.type, crossId: line.crossId });

    });
    let parsedLines = newLines.reduce((acc, curr, index, array) => {
        if (index % 2 === 0) {
            acc.push({ points: [{ lat: curr.lat, lng: curr.lng }, { lat: array[index + 1].lat, lng: array[index + 1].lng }], type: curr.type, crossId: curr.crossId });
        }
        return acc;
    }, []);
    return parsedLines;
}