import { ProjectWiseProjectInfoModel } from '../Models/ProjectWiseProjectInfoModel';
import { ProjectWiseProjectModel } from '../Models/ProjectWiseProjectModel';

const getComponentInstanceId = (selectedRowInstanceId: string, projectInfoData: ProjectWiseProjectInfoModel[]): string | undefined => {
    for (const project of projectInfoData) {
        if (project.properties.ComponentInstanceId.toString() === selectedRowInstanceId) {
            return project.properties.ConnectedProjectGuid;
        }
    }
    return undefined;
};

export const getActiveUserProjects = async (projectData: ProjectWiseProjectModel[], projectInfoData: ProjectWiseProjectInfoModel[]) => {
    try {
        const token = localStorage.getItem('bentley_access_token');
        const userProjects: ProjectWiseProjectModel[] = [];

        for (const project of projectData) {
            const projectId = getComponentInstanceId(project.instanceId, projectInfoData);

            if (!projectId)
                continue;

            const response = await fetch('https://pwproxy.azurewebsites.net/membersLive?id=' + projectId + '&token=' + token);
            if (response.ok) {
                userProjects.push(project);
            }
        }

        return userProjects;
    } catch (err: any) {
        // setError(err.message);
    } finally {
        // setLoading(false); // Stop the loading spinner
    }
};
