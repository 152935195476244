import { ProjectWiseIssuesModel, IssueItemWithProject } from '../Models/ProjectWiseIssuesModel';
import { ProjectWiseProjectInfoModel } from '../Models/ProjectWiseProjectInfoModel';
import { ProjectWiseProjectModel } from '../Models/ProjectWiseProjectModel';

export const getAllIssue = async (projectData: ProjectWiseProjectModel[], projectInfoData: ProjectWiseProjectInfoModel[]) => {
    try {
        const token = localStorage.getItem('bentley_access_token');
        const response = await fetch('https://pwproxy.azurewebsites.net/issuesLive?token=' + token); // Replace with your URL

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const jsonData: ProjectWiseIssuesModel[] = await response.json(); // Assuming the data is an array of ProjectWiseIssuesModel
        return jsonData.flatMap((issue) => convertProjectWiseIssuesModelToIssueItems(issue, projectData, projectInfoData));
    }
    catch (err: any) {
        // setError(err.message);
        console.log('err');
        console.log(err);
    } finally {
        // setLoading(false); // Stop the loading spinner
    }
};

function convertProjectWiseIssuesModelToIssueItems(
    model: ProjectWiseIssuesModel,
    projectData: ProjectWiseProjectModel[],
    projectInfoData: ProjectWiseProjectInfoModel[]
): IssueItemWithProject[] {
    const { id: projectId, stavba } = model;

    const projectName = projectInfoData
        .find((data) => data.properties.ConnectedProjectGuid === projectId)
        ?.properties.ComponentInstanceId
        ? projectData.find(
            (data) => data.instanceId === projectInfoData
                .find((data) => data.properties.ConnectedProjectGuid === projectId)
                ?.properties.ComponentInstanceId?.toString()
        )?.properties.PROJECT_PW_STAVBA_NAZEV
        : undefined;
    
    // Map each StavbaItem to IssueItemWithProject
    return stavba.map((item) => ({
        id: item.id,
        state: item.state,
        type: item.type,
        displayName: item.displayName,
        subject: item.subject,
        detail: item.detail,
        projectId, // Add projectId from the upper level
        projectName: projectName ?? '', // Add projectName from the upper level
    }));
}