import { Dashboard, Group,  } from '@mui/icons-material';
import { Avatar, Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import React, { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { Drawer } from './SideMenuStyles';
import { ProjectWiseModules } from './ProjectWiseModules';

interface ProjectWiseSideMenuProps {
  isDrawerOpen: boolean;
  setDrawerOpen: (open: boolean) => void;
  onModuleChange?: (module: ProjectWiseModules) => void;
  selectedModule: ProjectWiseModules;
}

export default function ProjectWiseSideMenu(props: Readonly<ProjectWiseSideMenuProps>) {
  const { isDrawerOpen, setDrawerOpen, onModuleChange, selectedModule } = props;
  // const [selectedModule, setSelectedModule] = useState<StavarioModule>(Stava);
  const location = useLocation();
  useEffect(() => {
    const path = location.pathname;

    /* if (path.toLowerCase().includes('projects')) {
      onModuleChange?.(StavarioModule.ProjectList);
    }
    else if (path.toLowerCase().includes('projectdetail')) {
      onModuleChange?.(StavarioModule.ProjectDetail);
    } else if (path.toLowerCase().includes('diaryvov') && !path.toLowerCase().includes('diaryvovdetail')) {
      onModuleChange?.(StavarioModule.DiaryRecordsList);
    }
    else if (path.toLowerCase().includes('diaryvovdetail')) {
      onModuleChange?.(StavarioModule.DiaryRecordDetail);
    }
    else if (path.toLowerCase().includes('diaries')) {
      onModuleChange?.(StavarioModule.DiariesList);
    }
    else if (path.toLowerCase().includes('diarydetail')) {
      onModuleChange?.(StavarioModule.DiaryDetail);
    }
    else if (path.toLowerCase().includes('wealthdetail')) {
      onModuleChange?.(StavarioModule.WealthDetail);
    }
    else if (path.toLowerCase().includes('wealths')) {
      onModuleChange?.(StavarioModule.WealthList);
    } */
  }, [location.pathname, onModuleChange]);
  
  const navigate = useNavigate();
  const params = useParams();
  const DrawerList = (
    <Box display='flex' flexDirection='column' justifyContent='space-between' height='calc(100% - 20px)'>
      <Box>
        <List>
          <ListItem key={'projects'} disablePadding sx={{ background: selectedModule === ProjectWiseModules.Projects ? 'lightgray' : 'white'}}>
            <Tooltip title='Projekty' placement='right'>
                <ListItemButton onClick={() => {
                onModuleChange?.(ProjectWiseModules.Projects);
                }}>
                <ListItemIcon>
                    {<Dashboard />}
                </ListItemIcon>
                <ListItemText primary={'Projekty'} />
                </ListItemButton>
            </Tooltip>
          </ListItem>
          <ListItem key={'tasks'} disablePadding sx={{ background: selectedModule === ProjectWiseModules.Tasks ? 'lightgray' : 'white'}}>
            <Tooltip title='Úkoly' placement='right'>
                <ListItemButton onClick={() => {
                onModuleChange?.(ProjectWiseModules.Tasks);
                }}>
                <ListItemIcon>
                    {<Group />}
                </ListItemIcon>
                <ListItemText primary={'Dashboard'} />
                </ListItemButton>
                </Tooltip>
            </ListItem>
        </List>
      </Box>
    </Box>
  );


  return (

    <Drawer variant='permanent' PaperProps={{ sx: { marginTop: '40px' } }} open={isDrawerOpen} onClose={() => setDrawerOpen(false)}>
      {/* <DrawerHeader >
        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ lineHeight: '40px', fontWeight: 'bold', marginLeft: '10px' }}>NABÍDKA</div>
       
          <IconButton onClick={() => setDrawerOpen?.(false)}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
      </DrawerHeader> */}
      {DrawerList}
    </Drawer>
  );

}
