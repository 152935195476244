import { Alert, AlertTitle, Button, Checkbox, Dialog, DialogContent, FormControlLabel, Slide, Snackbar, Switch, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import MuiDialogHeader from '../Shared/MuiDialogHeader';
import { APIUserClient } from '../Api/ApiUserClient';
import { isEmail } from '../Shared/IsEmail';
import InfoDialog from '../Shared/InfoDialog';

interface CreateUserDialogProps {
    tenantId?: number;
    isOpen: boolean;
    emailDef?: string;
    onClose: (refresh: boolean) => void;
}
export default function CreateUserDialog(props: CreateUserDialogProps) {
    const { isOpen, onClose, emailDef, tenantId } = props;
    const [ buttonDisabled, setButtonDisabled] = useState(false);
    const [ refresh, setRefresh] = useState(false);
    const [ email, setEmail] = useState('');
    const [ name, setName] = useState('');
    const [ surname, setSurname] = useState('');
    const [ openCreateError, setOpenCreateError] = useState(false);
    const [ infoOpen, setInfoOpen] = useState(false);
    const [ msLogin, setMsLogin] = useState(false);
    const [ localLogin, setLocalLogin] = useState(false);
    const [infoProps, setInfoProps] = useState<{label: string, text: string}>();


    useEffect(() => {
        if (emailDef)
            setEmail(emailDef);
        else
            setEmail('');
        setName('');
        setSurname('');
        setButtonDisabled(false);
        setRefresh(false);
    }, []);

    useEffect(() => {
        if (emailDef)
            setEmail(emailDef);
        else
            setEmail('');
        setName('');
        setSurname('');
        setButtonDisabled(false);
        setRefresh(false);
    }, [isOpen]);
    
    useEffect(() => {
        if (emailDef)
            setEmail(emailDef);
        else
        setEmail('');

    }, [emailDef]);

    function TransitionLeft(props: any) {
        return <Slide {...props} direction='left' />;
    }

    const handleClose = (event: any) => {
        setOpenCreateError(false);
    }
    
    return (
        <Dialog onClose={() => onClose(refresh)} open={isOpen}>
            <MuiDialogHeader showCloseText title='ZALOŽENÍ UŽIVATELE' onClose={() => onClose(refresh)}/>
            <DialogContent>
                <div>
                    <div>
                        <TextField
                            sx={{ margin: '12px', marginLeft: '24px', width: '350px' }}
                            label={'Jméno'}
                            size='small'
                            variant='outlined'
                            value={name}
                            onChange={(e) => { setName(e.target.value); }} />
                    </div>
                    <div>
                        <TextField
                            sx={{ margin: '12px', marginLeft: '24px', width: '350px' }}
                            label={'Příjmení'}
                            size='small'
                            variant='outlined'
                            value={surname}
                            onChange={(e) => { setSurname(e.target.value); }} />
                    </div>
                    <div>
                        <TextField
                            sx={{ margin: '12px', marginLeft: '24px', width: '350px' }}
                            label={'Email'}
                            size='small'
                            variant='outlined'
                            value={email}
                            disabled
                            onChange={(e) => { setEmail(e.target.value); }} />
                    </div>
                    <div /* style={{ display: 'flex', justifyContent: 'space-evenly'}} */>
                        <FormControlLabel
                            sx={{ marginLeft: '16px'}}
                            control={
                            <Checkbox checked={localLogin} onChange={(e) => setLocalLogin(e.target.checked)}/>
                            }
                            label="Lokální přihlášení"
                        />  
                    </div>
                    <div>                      
                        <FormControlLabel
                            sx={{ marginLeft: '16px'}}
                            control={
                            <Checkbox checked={msLogin} onChange={(e) => setMsLogin(e.target.checked)} />
                            }
                            label="Přihlášení MS identitou"
                        />
                    </div>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
                        <Button variant='contained' sx={{ margin: 'auto' }} disabled={name.length === 0 || surname.length === 0 || email.length === 0 || !isEmail(email) || buttonDisabled} onClick={() => {
                            setButtonDisabled(true);
                            new APIUserClient().CreateUserSendSignupMail(name, surname, email, localLogin, msLogin, tenantId).then((e) => {
                                if (e.ok) {
                                    setRefresh(true);
                                    setInfoProps({label: 'Info', text: 'Založení uživatele proběhlo v pořádku. Novému uživateli byl odeslán email s odkazem k dokončení registrace.'});
                                    setInfoOpen(true);
                                }
                                else {
                                    setOpenCreateError(true);
                                }
                                setButtonDisabled(false);
                            });
                        }}>ZALOŽIT</Button>
                    </div>
                   {infoProps && <InfoDialog onClose={() => {
                        setInfoOpen(false);
                        onClose(true);
                    } } open={infoOpen} label={infoProps.label} text={infoProps.text} />}
                    <div className="create-form-popup">
                        <Snackbar
                            TransitionComponent={TransitionLeft}
                            open={openCreateError}
                            autoHideDuration={3000}
                            onClose={handleClose}
                            anchorOrigin={{ vertical: "top", horizontal: "right" }} >
                            <Alert onClose={handleClose} severity="warning">
                                <AlertTitle>{'Chyba zakládání'}</AlertTitle>
                                {'Uživatel s tímto emailem již existuje'}
                            </Alert>
                        </Snackbar>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}
