import { Alert, Box, Button, Checkbox, Divider, FormControlLabel, FormGroup, Grid, Snackbar, TextField, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import './AdminScene.css';
import { Close, Logout, People, SettingsSystemDaydreamOutlined } from '@mui/icons-material';
import ChangePasswordDialog from './ChangePasswordScene';
import { TenantUserDTO } from '../Api/ApiServer';
import { useUserInfoStore } from '../Stores/UserInfoStore';
import { APIUserClient } from '../Api/ApiUserClient';

export default function UserScene() {
    const navigate = useNavigate();
    const { isAdmin, userId, userName, userSurname, userEmail, canCreateUsers, canCreateTenants, initUserStore } = useUserInfoStore();
    const theme = useTheme();    
    const [selectedUser, setSelectedUser] = useState<Partial<TenantUserDTO>>();

    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [changePasswordOpen, setChangePasswordOpen] = useState(false);
    const [saveConfirm, setSaveConfirm] = useState(false);
    
    useEffect(() => {
        if (isAdmin ||userId == selectedUser?.id)
            setButtonDisabled(false);

        if (userId !== 0)
            setSelectedUser(new TenantUserDTO({ id: userId, name: userName, surname: userSurname, email: userEmail, isAdmin: isAdmin, canCreateUsers: canCreateUsers, canCreateTenants: canCreateTenants }));
        else
            initUserStore();
    }, []);

    useEffect(() => {
        setSelectedUser(new TenantUserDTO({ id: userId, name: userName, surname: userSurname, email: userEmail, isAdmin: isAdmin, canCreateUsers: canCreateUsers, canCreateTenants: canCreateTenants }));
    }, [userId]);

    function saveUser()
    {
        if (selectedUser)
        {
            setButtonDisabled(true);        
            new APIUserClient().SaveUserData(new TenantUserDTO({ id: selectedUser.id, name: selectedUser.name, surname: selectedUser.surname, isAdmin: selectedUser.isAdmin, canCreateUsers: selectedUser.canCreateUsers, canCreateTenants: selectedUser.canCreateTenants  })).then((d) => {
                if (d) {
                    initUserStore();
                    setSaveConfirm(true);
                }
                setButtonDisabled(false);               
            });   
        }
    }

    return (
        <div style={{ background: 'white', height: '100vh'}}>
            <div style={{ display: 'flex', position: 'fixed', top: 0, width: '100%', backgroundColor: '#F5F5F5', height: '64px', zIndex: 5000, boxShadow: 'rgb(0 0 0 / 40%) 1px 1px 10px' }}>
                <Grid container spacing={6}>
                    <Grid item xs={8}>
                        <div style={{ float: 'left', margin: '5px', marginTop: '8px', marginLeft: '30px' }}>
                            <Typography variant='h5' color='primary' sx={{ margin: '8px', fontWeight: 'bolder', color: 'black' }} >SPRÁVA ÚČTU</Typography>
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                        <div style={{ float: 'right', margin: '5px', marginTop: '8px', marginRight: '10px', display: 'flex' }}>
                            <div style={{
                                width: "140px",
                                height: "40px",
                                marginTop: '8px',
                                marginRight: '20px'
                            }}>
                                <Button onClick={() =>{ navigate('/projects'); }} style={{
                                    width: "140px",
                                    height: "40px"
                                }} >
                                    <div className="close-button-div" style={{
                                        display: 'flex'
                                        }}>
                                        <Typography variant='h1' color='primary'  lineHeight={'42px'} fontSize={16}>ZAVŘÍT</Typography>
                                        <Close color='primary' sx={{ marginTop: '9px'}}/> 
                                    </div>
                                </Button>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
            {selectedUser && <div style={{ marginTop: '60px', marginLeft: '24px'}} className="flex-item-no-border">
                <div className="circle-big">
                    <p className="circle-big-inner" >
                        {selectedUser.name ? selectedUser.name.substring(0,1) : '' }{selectedUser.surname ? selectedUser.surname.substring(0,1) : ''}
                    </p>
                </div>
                <div style={{ marginBottom: '24px'}}>
                    <div className="flex-container" >
                        <div>
                            <TextField sx={{ margin: '12px', marginLeft: '24px', width: '350px' }} label={'Jméno'}
                                size='small'
                                name='userName'
                                variant='outlined'
                                value={selectedUser.name}
                                onChange={(e) => { setSelectedUser({ ...selectedUser, name: e.currentTarget.value }); }} />
                        </div>
                        <div>
                            <TextField
                                sx={{ margin: '12px', width: '350px' }}
                                label={'Příjmení'}
                                size='small'
                                variant='outlined'
                                value={selectedUser.surname}
                                onChange={(e) => { setSelectedUser({ ...selectedUser, surname: e.currentTarget.value }); }} />
                        </div>
                    </div>
                    <div>
                        <TextField
                            disabled
                            sx={{ margin: '12px', marginLeft: '24px', width: '350px' }}
                            label={'Email'}
                            size='small'
                            variant='outlined'
                            value={selectedUser.email}
                            onChange={(e) => { setSelectedUser({ ...selectedUser, email: e.currentTarget.value }); }} />
                    </div>
                    <div >
                        <FormGroup sx={{ margin: '12px', marginLeft: '24px' }}>
                            <FormControlLabel disabled={isAdmin !== true} control={<Checkbox checked={selectedUser.isAdmin} />} label="Administrátor" />
                        </FormGroup>
                    </div>
                    <div className="flex-container" >
                        <div >
                            <FormGroup sx={{ margin: '12px', marginLeft: '24px', width: '250px' }}>
                                <FormControlLabel disabled={isAdmin !== true} control={<Checkbox checked={selectedUser.canCreateTenants} onChange={(e) => { setSelectedUser({ ...selectedUser, canCreateTenants: e.target.checked }); }} />} label="Zakládání tenantů" />
                            </FormGroup>
                        </div>
                        <div >
                            <FormGroup sx={{ margin: '12px', marginLeft: '24px', width: '250px' }}>
                                <FormControlLabel disabled={isAdmin !== true} control={<Checkbox checked={selectedUser.canCreateUsers} onChange={(e) => { setSelectedUser({ ...selectedUser, canCreateUsers: e.target.checked }); }} />} label="Zakládání uživatelů" />
                            </FormGroup>
                        </div>
                    </div>
                    {/* <div style={{ width: '100%', display: 'flex-container', justifyContent: 'center', marginTop: '16px' }}>
                        <div>
                            <Button variant='contained' sx={{ margin: 'auto' }} disabled={buttonDisabled} onClick={saveUser}>ULOŽIT</Button>
                        </div>
                    </div>   */}
                    <div style={{ marginLeft: '32px', marginTop: '24px'}}  className="flex-container">
                    {/* <div style={{ width: '100%', display: 'flex-container', justifyContent: 'center', marginTop: '16px' }}> */}
                        <div>
                            <Button variant='contained' sx={{ margin: 'auto' }} disabled={buttonDisabled} onClick={saveUser}>ULOŽIT</Button>
                        </div>                           
                        <div>
                            <Button variant='contained' sx={{ margin: 'auto', marginLeft: '24px', width: '150px' }} disabled={buttonDisabled} onClick={() => { setChangePasswordOpen(true);}}>ZMĚNA HESLA</Button>
                        </div> 
                    </div>
                </div>
            </div>  
            }   
                
            <Snackbar open={saveConfirm} autoHideDuration={3000} onClose={() => setSaveConfirm(false)}>
                <Alert onClose={() => setSaveConfirm(false)} severity="success" sx={{ width: '100%' }}>
                    Uloženo
                </Alert>
            </Snackbar>   
            <ChangePasswordDialog isOpen={changePasswordOpen} onClose={() => { setChangePasswordOpen(false); }}/>
        </div >
    )
}
