import * as React from 'react';
import { environment } from './AuthorizationService';
import { CircularProgress } from '@mui/material';
import { useLocation, useNavigate } from 'react-router';

export interface IPWLoginSceneProps {
}
function generateCodeVerifier() {
    // Generate a random 32-byte string
    const array = new Uint32Array(32);
    window.crypto.getRandomValues(array);
    return Array.from(array, dec => ('0' + dec.toString(16)).slice(-2)).join('');
}

function generateCodeChallenge(codeVerifier: string): Promise<string> {
    return window.crypto.subtle.digest('SHA-256', new TextEncoder().encode(codeVerifier))
        .then(buffer => {
            const bytes = new Uint8Array(buffer);
            // Convert Uint8Array to a number array
            const byteArray = Array.from(bytes);
            return btoa(String.fromCharCode.apply(null, byteArray))
                .replace(/\+/g, '-')
                .replace(/\//g, '_')
                .replace(/=+$/, '');
        });
}
export default function PWLoginScene(props: IPWLoginSceneProps) {
    const navigate = useNavigate();
    const [code, setCode] = React.useState<string>('');
    // const location = useLocation();
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    const query = useQuery();

    React.useEffect(() => {

    }, [query]);
    const signIn = async () => {
        const codeParam = query.get('code');
        if (codeParam) {
            getToken(codeParam, environment.authorization.clientId, '', environment.authorization.redirectUri, environment.authorization.scope)
                .then((token) => { });
        }
        else {
            const codeVerifier = generateCodeVerifier();
            const codeChallenge = await generateCodeChallenge(codeVerifier);
            // generateCodeChallenge(generateCodeVerifier())
            setCode(codeChallenge);
            // Save code_verifier in localStorage or sessionStorage for later use in token exchange
            sessionStorage.setItem('code_verifier', codeVerifier);

            window.location.href = `https://ims.bentley.com/connect/authorize?client_id=${environment.authorization.clientId}&redirect_uri=${encodeURIComponent(environment.authorization.redirectUri)}&response_type=code&scope=${environment.authorization.scope}&code_challenge=${codeChallenge}&code_challenge_method=S256&response_mode=query`;
        }
    }
    async function getToken(authorizationCode: string, clientId: string, clientSecret: string, redirectUri: string, scope: string) {
        const codeVerifier = sessionStorage.getItem('code_verifier'); // Retrieve the code_verifier
        if (!codeVerifier) return;
        const url = 'https://ims.bentley.com/connect/token';
        const params = new URLSearchParams();
        params.append('grant_type', 'authorization_code');
        params.append('code', authorizationCode);
        params.append('client_id', clientId);
        params.append('client_secret', 'BbHG3t4hu4RjeW+wtgMAQnW06NAQ5lyzOziQVHuKjzCUUrwbC5aYVwIaB+85HtubXNf65zYq9mIwbmj19F8ryw==');
        params.append('code_verifier', codeVerifier); // Include the code_verifier here
        params.append('redirect_uri', redirectUri);
        params.append('scope', scope);

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: params.toString()
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            localStorage.setItem('bentley_access_token', data.access_token);
            localStorage.setItem('bentley_token_expires', data.expires_in);
            localStorage.setItem('refresh_bentley_access_token', data.refresh_token);
           
            navigate('/pwmain');
            return data;
        } catch (error) {
            console.error('Error fetching token:', error);
        }
    }

    React.useEffect(() => { signIn() }, []);
    return (
        <CircularProgress />
    );
}
