import { Card, Grid, Box, CircularProgress } from '@mui/material';
import * as React from 'react';
import ProjectWiseTasksScene from './ProjectWiseTasksScene';
import { ProjectWiseProjectModel } from './Models/ProjectWiseProjectModel';
import { ProjectWiseProjectInfoModel } from './Models/ProjectWiseProjectInfoModel';
import ProjectWiseBuildingsScene from './ProjectWiseBuildingsScene';
import { getAllIssue } from './Helpers/getAllIssues';
import { getActiveUserProjects } from './Helpers/getActiveUserProjects';
import { IssueItemWithProject } from './Models/ProjectWiseIssuesModel';
import { usePwUser } from './Context/PWUserDataProvider';
import { handleCapture } from './Helpers/printPdf';
import delay from '../Shared/delay';
import { CardType } from './Enums/CardType';

export interface IProjectWiseDashboardSceneProps {
    projectData: ProjectWiseProjectModel[];
    projectInfoData: ProjectWiseProjectInfoModel[];
    print: boolean;
    isOnlyUser: boolean;
}

export default function ProjectWiseDashboardScene(props: IProjectWiseDashboardSceneProps) {
    const { projectData, projectInfoData, print, isOnlyUser } = props;
    const [data, setData] = React.useState<IssueItemWithProject[]>([]);
    const [selectedRowIndex, setSelectedRowIndex] = React.useState(null);
    const [task, setTask] = React.useState<IssueItemWithProject[] | null>(null);
    const [processes, setProcesses] = React.useState<IssueItemWithProject[] | null>(null);
    const [lastActivity, setLastActivity] = React.useState<IssueItemWithProject[] | null>(null);
    const [projectDataOnlyUser, setProjectDataOnlyUser] = React.useState<ProjectWiseProjectModel[] | null>(null);
    const [loading, setLoading] = React.useState(false);
    const [loadingUserProject, setLoadingUserProject] = React.useState(false);
    const [printing, setPrinting] = React.useState(false);
    const captureRef = React.useRef<HTMLDivElement>(null);
    const { user } = usePwUser();
    
    React.useEffect(() => { if (printing && captureRef.current) handleCapture(captureRef.current); }, [printing]);
    React.useEffect(() => { if (print && captureRef.current) setPrinting(true); delay(2000).then(() => setPrinting(false)) }, [print]);

    React.useEffect(() => {
        handleRowClick(null);
    }, [data]);

    React.useEffect(() => {
        unselectBuilding();
    }, [isOnlyUser]);

    React.useEffect(() => {
        if (projectData && projectInfoData) {
            setLoading(true); 
            getAllIssue(projectData, projectInfoData).then((issues) => {
                setLoading(false);
                if (issues) {
                    setData(issues);
                }
            })
            
        }
    }, [projectData, projectInfoData]); 

    React.useEffect(() => {
        if (projectData && projectInfoData) {
            setLoadingUserProject(true); 
            getActiveUserProjects(projectData, projectInfoData).then((projects) => {
                setLoadingUserProject(false);
                setProjectDataOnlyUser(projects ?? []);
            })
        }
    }, [projectData, projectInfoData]);

    const handleRowClickWithHighlight = (row, rowIndex) => {
        if (selectedRowIndex === rowIndex) {
          setSelectedRowIndex(null); // Unselect if the same row is clicked
          handleRowClick(null);
        } else {
          setSelectedRowIndex(rowIndex); // Select the new row
          handleRowClick(row);
        }
    };

    const unselectBuilding = () => {
        setSelectedRowIndex(null);
        handleRowClick(null);
    };

    const handleRowClick = (params: any) => {
        if (params) {
            const selectedRow = isOnlyUser ? (projectDataOnlyUser ? projectDataOnlyUser[params.id] : null) :  projectData[params.id];
            if (selectedRow) {
                const componentInstanceId = getComponentInstanceId(selectedRow.instanceId);
        
                setTask(data.filter((item) => item.projectId === componentInstanceId && item.type === 'Úkoly' && (!isOnlyUser || item.detail.assignee.id === user?.id || item.detail.assignees?.some((assignee) => assignee.id === user?.id))));
                setProcesses(data.filter((item) => item.projectId === componentInstanceId && item.type !== 'Úkoly' && (!isOnlyUser || item.detail.assignee.id === user?.id || item.detail.assignees?.some((assignee) => assignee.id === user?.id))));
                setLastActivity(data.filter((item) => item.projectId === componentInstanceId && (!isOnlyUser || item.detail.assignee.id === user?.id || item.detail.assignees?.some((assignee) => assignee.id === user?.id))).sort((a, b) => new Date(b.detail.lastModifiedDateTime).getTime() - new Date(a.detail.lastModifiedDateTime).getTime()));
            }
        }
        else {
            setTask(data.filter((item) => item.type === 'Úkoly' && (!isOnlyUser || item.detail.assignee.id === user?.id || item.detail.assignees?.some((assignee) => assignee.id === user?.id))));
            setProcesses(data.filter((item) => item.type !== 'Úkoly' && (!isOnlyUser || item.detail.assignee.id === user?.id || item.detail.assignees?.some((assignee) => assignee.id === user?.id))));
            setLastActivity(data.filter((item) => (!isOnlyUser || item.detail.assignee.id === user?.id ||item.detail.assignees?.some((assignee) => assignee.id === user?.id))).sort((a, b) => new Date(b.detail.lastModifiedDateTime).getTime() - new Date(a.detail.lastModifiedDateTime).getTime()));
        }
    };

    const getComponentInstanceId = (selectedRowInstanceId: string): string | undefined => {
        for (const project of projectInfoData) {
            if (project.properties.ComponentInstanceId.toString() === selectedRowInstanceId) {
                return project.properties.ConnectedProjectGuid;
            }
        }
        return undefined;
    };

    return (
        <Box ref={captureRef} pr={8}>
            {(loading || loadingUserProject) && <CircularProgress />}
            {!loading && !loadingUserProject && 
                <Grid container spacing={2} m={2} sx={{ marginLeft: '20px', marginTop: '-1px' }}>
                    <Grid item md={12} sm={12} lg={6} sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Card sx={{ height: '450px' }}>
                            <ProjectWiseBuildingsScene projectData={isOnlyUser ? (projectDataOnlyUser ?? []) : projectData} selectedRowIndex={selectedRowIndex} isDialog={false} handleRowClickWithHighlight={handleRowClickWithHighlight} unselectBuilding={unselectBuilding}/>
                        </Card>
                    </Grid>
                    <Grid item md={12} sm={12} lg={6} style={{ display: 'flex', flexDirection: 'column' }}>
                        <Card sx={{ height: '450px' }}>
                            <ProjectWiseTasksScene data={task ?? []} cardType={CardType.TASKS} isDialog={false} />
                        </Card>
                    </Grid>
                    <Grid item md={12} sm={12} lg={6} style={{ display: 'flex', flexDirection: 'column' }}>
                        <Card sx={{ height: '450px' }}>
                            <ProjectWiseTasksScene data={lastActivity ?? []} cardType={CardType.LAST_ACTIVITY} isDialog={false} />
                        </Card>
                    </Grid>
                    <Grid item md={12} sm={12} lg={6} sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Card sx={{ height: '450px' }}>
                            <ProjectWiseTasksScene data={processes ?? []} cardType={CardType.PROCESSES} isDialog={false} />
                        </Card>
                    </Grid>
                </Grid>
            }
        </Box>
    );
}
