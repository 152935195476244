import react, { useEffect, useRef, useState } from 'react';
import 'leaflet/dist/leaflet.css';

// import L, { TileLayer } from 'leaflet';
import { MapContainer, Marker, Polyline, Popup, TileLayer, WMSTileLayer, Polygon, SVGOverlay, ScaleControl, useMap } from 'react-leaflet';
// import { DivIcon, LatLng } from 'leaflet';
import proj4 from 'proj4';
import { convertToGPS } from './GpsSJTSKConverter';
import { LatLng, polygon, divIcon, LatLngBounds } from 'leaflet';
import { Button, Checkbox, FormControlLabel, IconButton, TextField } from '@mui/material';
import { ValidationDesignEntityType, ValidationDesignHectoServer, ValidationSlopeState } from '../../Api/ApiServer';
import delay from '../delay';
import ArrowHeadsPolyline from './ArrowHeadsPolyline';
import { LayersOutlined, Settings, SettingsOutlined } from '@mui/icons-material';
import LeafletMapMenu from './LeafletMapMenu';
interface Point {
    lat: number;
    lng: number;
}
export interface Lines {
    lines: Line[];
}
export interface Hectometer {
    basePoint: Point;
    crossPoint: Point;
    text: string;
}
interface Line {
    points: Point[];
    type: ValidationDesignEntityType;
    crossId: string;
    state?: ValidationSlopeState;
}

interface LeafletMapProps {
    linesInput?: Lines[];
    inputCenter?: LatLng;
    height?: string;
    hectosInput?: Hectometer[];
    inputSelectedLinesId?: any[];
    lineClicked: (line: Line) => void;
}
function calculateAngleFromXAxis(point1: Point, point2: Point): number {
    // Calculate the slope
    const m: number = (point2.lng - point1.lng) / (point2.lat - point1.lat);

    // Calculate the angle in radians
    const angleRadians: number = Math.atan(m);

    // Convert the angle to degrees
    const angleDegrees: number = (angleRadians * 180) / Math.PI;

    if (angleDegrees < 0)
        return (angleDegrees + 10);
    else return -(angleDegrees + 10);

}
const PolygonWithText = (props: any) => {
    // const center = polygon(props.coords).getCenter();
    const text = divIcon({ html: '<svg overflow="visible"><text x="0" y="0" transform="rotate(' + calculateAngleFromXAxis(props.center, props.basePoint) + ')"  stroke="#FF4060">' + props.text + '</text></svg>', className: 'textIcon' });

    // return (
    //     <Polygon color="blue" positions={props.coords}>
    //         <Marker position={center} icon={text} />
    //     </Polygon>
    // );
    return (
        <Polygon color="blue" positions={props.coords}>
            <Marker position={props.center} icon={text} />
        </Polygon>
    );
}



const LeafletMap: React.FC<LeafletMapProps> = ({ linesInput, inputCenter, height, hectosInput, lineClicked, inputSelectedLinesId }) => {
    // const map = useMap();
    const [position, setPosition] = useState<LatLng | undefined>();
    const [lines, setLines] = useState<Lines[]>([]);
    const [hectos, setHectos] = useState<Hectometer[]>([]);
    const [showOrto, setShowOrto] = useState(false);
    const [showZtm, setShowZtm] = useState(false);
    const [showKatastr, setShowKatastr] = useState(false);
    const [lineWidth, setLineWidth] = useState(5);
    const [actHeight, setActHeight] = useState('0');
    const [showPerpendicular, setShowPerpendicular] = useState(true);
    const [showArrows, setShowArrows] = useState(true);
    const [showHectos, setShowHectos] = useState(true);
    const [perpendicularWidth, setPerpendicularWidth] = useState(3);

    useEffect(() => {
        console.log(height);
        if (height !== undefined)
            setActHeight(height);
        // delay(400).then(() => {

        //     map.current.invalidateSize();
        // });
        // map.current.
    }, [height]);
    useEffect(() => {
        console.log(inputSelectedLinesId);


        // delay(400).then(() => {

        //     map.current.invalidateSize();
        // });
        // map.current.
    }, [inputSelectedLinesId]);
    useEffect(() => {
        if (hectosInput !== undefined && hectosInput.length > 0)
            setHectos(hectosInput);
    }, [hectosInput]);
    useEffect(() => {
        // console.log(height);
        // setActHeight(height);
        delay(400).then(() => {
            if (map.current)
                map.current.invalidateSize();
        });
        // map.current.
    }, [actHeight]);

    useEffect(() => {
        if (linesInput !== undefined && linesInput.length > 0) {
            setLines(linesInput);
        }
    }, [linesInput]);

    useEffect(() => {
        if (inputCenter !== undefined)
            setPosition(inputCenter);
    }, [inputCenter]);
    const getColorForTypeAndState = (line: Line) => {
        if (line.state === undefined) {
            switch (line.type) {
                case ValidationDesignEntityType.None:
                    return 'black';
                case ValidationDesignEntityType.Curve:
                    return 'blue';
                case ValidationDesignEntityType.Spiral:
                    return 'green';
                case ValidationDesignEntityType.Tangent:
                    return 'red';
                case ValidationDesignEntityType.Perpendicular:
                    if(inputSelectedLinesId){
                        if(inputSelectedLinesId.some(x=>x === line.crossId))
                            return 'red';
                        else return 'gray';
                    }
                    else return 'gray';
                    
                    // return 'purple';
                default:
                    return 'purple';
            }
        }
        else {
            switch (line.state) {
                case ValidationSlopeState.Correct:
                    return 'black';
                case ValidationSlopeState.Error:
                    return 'red';
                case ValidationSlopeState.Warning:
                    return 'orange';
                default:
                    return 'gray';
            }
        }
    }
    const radiusInMeters = 5;

    function calculateRotatedRectangleBounds(center: LatLng, angle: number) {
        // Convert the angle from degrees to radians
        const radians = (angle * Math.PI) / 180;

        // Calculate the half-lengths of the rectangle sides
        const halfA = radiusInMeters / 5 / 2;
        const halfB = radiusInMeters / 5 / 2;

        // Calculate the coordinates of the rotated corners
        const cosAngle = Math.cos(radians);
        const sinAngle = Math.sin(radians);

        const southwest = {
            lat: center.lat - halfA * cosAngle - halfB * sinAngle,
            lng: center.lng + halfA * sinAngle - halfB * cosAngle,
        };

        const northeast = {
            lat: center.lat + halfA * cosAngle + halfB * sinAngle,
            lng: center.lng - halfA * sinAngle + halfB * cosAngle,
        };

        return { southwest, northeast };
    }

    const maxZoom = 19.9;
    // const [zoom, setZoom] = useState(14);

    function LayoutListener({ height }) {
        const map = useMap();
        useEffect(() => {
            map?.invalidateSize();
        }, [map, height]);
        return null;
    }
    
    const map = useRef<any | null>();
    return <div /* style={{ display: 'flex' }} */>
        <div style={{ width: '100%', minWidth: '600px', height: actHeight }}>
            {position && <MapContainer style={{ width: '100%', height: '100%' }} ref={map} center={position} zoom={16} maxZoom={maxZoom} scrollWheelZoom={true}>
                <LayoutListener height={actHeight} />
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    maxNativeZoom={maxZoom}
                    maxZoom={maxZoom}
                />
                {showZtm && <WMSTileLayer url={'https://ags.cuzk.cz/arcgis1/services/ZTM/MapServer/WMSServer?'}
                    format='image/png'
                    transparent
                    opacity={1}
                    version='1.0.0'
                    className='cuzkZTM'
                    maxNativeZoom={maxZoom}
                    maxZoom={maxZoom}
                    layers='0'  ></WMSTileLayer>}
                {showOrto && <WMSTileLayer url={'https://ags.cuzk.cz/arcgis1/services/ORTOFOTO/MapServer/WMSServer?'}
                    format='image/png'
                    maxNativeZoom={maxZoom}
                    maxZoom={maxZoom}
                    transparent
                    opacity={1}
                    version='1.0.0'
                    className='cuzkORTO'
                    layers='0'  ></WMSTileLayer>}
                {showKatastr && <WMSTileLayer url={'http://services.cuzk.cz/wms/local-km-wms.asp?'}
                    format='image/png'
                    transparent
                    maxNativeZoom={maxZoom}
                    maxZoom={maxZoom}
                    opacity={1}
                    version='1.3.0'
                    className='cuzk'
                    layers='KN' ></WMSTileLayer>}
                <ScaleControl imperial={false} metric />
                {lines && lines.map(liness => liness.lines.map((line, ind) => {
                    if (line.type !== ValidationDesignEntityType.Perpendicular || showPerpendicular)
                        if (showArrows)
                            return <ArrowHeadsPolyline
                                id={ind}
                                arrowheads={((line.type !== ValidationDesignEntityType.Perpendicular) && (ind === 1 || ind === liness.lines.length - 2)) ? { size: '6m', frequency: 'allvertices' } : undefined}
                                key={'ricpic' + ind}
                                eventHandlers={{
                                    mouseover: (e: any) => { console.log(e); e.target.setStyle({ color: 'red' }) },
                                    mouseout: (e: any) => { e.target.setStyle({ color: getColorForTypeAndState(line) }) },
                                    click: (e: any) => { lineClicked(line); }
                                }}
                                interactive
                                pathOptions={{ color: getColorForTypeAndState(line), weight: line.type === ValidationDesignEntityType.Perpendicular ? perpendicularWidth : lineWidth }} positions={line.points} ></ArrowHeadsPolyline>
                        else return <Polyline

                            key={'ricpic' + ind}
                            eventHandlers={{
                                mouseover: (e: any) => { console.log(e); e.target.setStyle({ color: 'red' }) },
                                mouseout: (e: any) => { e.target.setStyle({ color: getColorForTypeAndState(line) }) },
                                click: (e: any) => { lineClicked(line); }
                            }}
                            interactive
                            pathOptions={{ color: getColorForTypeAndState(line), weight: line.type === ValidationDesignEntityType.Perpendicular ? perpendicularWidth : lineWidth }} positions={line.points} ></Polyline>
                }))}
                {hectos && showHectos && hectos.map((hecto, ind) => {
                    // return <SVGOverlay bounds={getBounds(hecto.crossPoint)}>
                    //     <text x="50%" y="50%" stroke="black">
                    //         {hecto.text}
                    //     </text></SVGOverlay>
                    return <PolygonWithText key={ind} basePoint={hecto.basePoint} center={hecto.crossPoint}
                        coords={calculateRotatedRectangleBounds(new LatLng(hecto.crossPoint.lat, hecto.crossPoint.lng), 120)} text={hecto.text}></PolygonWithText>
                })}
                <LeafletMapMenu
                    lineWidth={lineWidth}
                    perpendicularWidth={perpendicularWidth}
                    showArrows={showArrows}
                    showHectos={showHectos}
                    showPerpendicular={showPerpendicular}
                    setPerpendicularWidth={setPerpendicularWidth}
                    setShowArrows={setShowArrows}
                    setShowHectos={setShowHectos}
                    setShowPerpendicular={setShowPerpendicular}
                    setLineWidth={setLineWidth}
                    showOrto={showOrto}
                    showZtm={showZtm}
                    showKatastr={showKatastr}
                    setShowOrto={setShowOrto}
                    setShowZtm={setShowZtm}
                    setShowKatastr={setShowKatastr}
                />
            </MapContainer>}</div>


    </div >;
};

export default LeafletMap;