import React from 'react'
interface subName {
    name?: string;
    names?: string[];
}
interface CurveSolverHeaderCellProps {
    mainName: string;
    subNames?: string[];
    subNamesWithOverflow?: subName[];
    width?: number | string;
    itemSize?: string;
    isFirst?: boolean;
    isLast?: boolean;
    paddingLeftItem?: string;
}
export default function CurveSolverHeaderCell(props: CurveSolverHeaderCellProps) {
    const { mainName, subNames, width, itemSize, subNamesWithOverflow, isFirst, isLast, paddingLeftItem } = props;
    return (
        <div style={{ minWidth: width, maxWidth: width, background: '#C3C3C3', margin: '2px' }}>
            <div style={{ margin: '1px', background: 'white', height: 'calc(100% - 2px)' }}>
                <div style={{ padding: '8px', borderBottom: '1px solid #C3C3C3', fontWeight: 'bold', fontSize: '12px', color: '#777777' }} dangerouslySetInnerHTML={{ __html: mainName }}>{/* mainName */}</div>
                {subNames !== undefined && <div style={{ padding: '8px', display: 'flex', fontSize: '11px', color: '#777777', marginTop: '4px', paddingLeft: paddingLeftItem ? paddingLeftItem : '10px' }}>{subNames.map((subname, i) => <div key={i} style={{ minWidth: itemSize ?? '54px', textAlign: 'start' }} dangerouslySetInnerHTML={{ __html: subname }}>{/* {subname} */}</div>)}</div>}
                {subNamesWithOverflow !== undefined && <div style={{ padding: '0px', display: 'flex', fontSize: '12px', color: '#777777', paddingLeft: '10px' }}>{subNamesWithOverflow.map((subname, i) => {
                    if (subname.names !== undefined)
                        return <div key={i} style={{ minWidth: itemSize ?? '54px', maxWidth: itemSize ?? '54px', textAlign: 'start', flexDirection: 'column', display: 'flex', marginTop: '4px' }}>
                            <div style={{ padding: '1px', fontSize: '10px' }} dangerouslySetInnerHTML={{ __html: subname.names[0] }}>{/* {subname.names[0]} */}</div>
                            <div style={{ height: '1px', width: '60%', background: '#C3C3C3' }} />
                            <div style={{ paddingLeft: '1px', fontSize: '10px' }} dangerouslySetInnerHTML={{ __html: subname.names[1] }}>{/* {subname.names[1]} */}</div>

                        </div>
                    else return <div key={i} style={{ minWidth: itemSize ?? '54px', maxWidth: itemSize ?? '54px', textAlign: 'start', marginTop: '10px' }} dangerouslySetInnerHTML={{ __html: subname.name! }}>{/* {subname.name} */}</div>
                })}</div>}
            </div>
        </div >
    )
}
