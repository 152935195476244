import { Close, CompareArrows, Folder, Info } from '@mui/icons-material';
import { Box, Button, Dialog, DialogContent, DialogTitle, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import React, { useEffect } from 'react';
import { ProjectWiseProjectModel } from './Models/ProjectWiseProjectModel';
import { axisClasses, BarChart } from '@mui/x-charts';
import { ProjectWiseProjectInfoModel } from './Models/ProjectWiseProjectInfoModel';

interface ProjectWiseProjectDetailProps {
    open: boolean;
    onClose: () => void;
    project: ProjectWiseProjectModel;
    projectInfoData: ProjectWiseProjectInfoModel[];
}
export default function ProjectWiseProjectDetail(props: ProjectWiseProjectDetailProps) {
    const { open, onClose, project, projectInfoData } = props;

    const getSoD = () => {
        return Number(project?.properties.PROJECT_PW_STAVBA_PLANNAKLADY_1.substring(0, project?.properties.PROJECT_PW_STAVBA_PLANNAKLADY_1.lastIndexOf(' ')).replace(/\s+/g, ''));
    }

    const getCerpano = () => {
        return Number(project?.properties.PROJECT_PW_STAVBA_NAKLADY_CERPANI.substring(0, project?.properties.PROJECT_PW_STAVBA_NAKLADY_CERPANI.lastIndexOf(' ')).replace(/\s+/g, ''));
    }

    const getZbv = () => {
        return Number(project?.properties.PROJECT_PW_STAVBA.substring(0, project?.properties.PROJECT_PW_STAVBA.lastIndexOf(' ')).replace(/\s+/g, '')) - Number(project?.properties.PROJECT_PW_STAVBA_PLANNAKLADY_1.substring(0, project?.properties.PROJECT_PW_STAVBA_PLANNAKLADY_1.lastIndexOf(' ')).replace(/\s+/g, ''));
    }
    // const getProjectId = () => {
    //     if(projectInfoData.)
    //         return project.instanceId;
    // };

    return (
        <Dialog
            open={open}
            onClose={() => { onClose() }}
            fullWidth
        >
            <DialogTitle sx={{ background: '#F5F5F5' }}>
                <div  >
                    <Typography variant='h5' style={{ marginRight: '20px', minWidth: '200px', color: '#0E457D', fontWeight: 'bold' }}>Vybrané údaje o stavbě</Typography>
                    <IconButton sx={{ width: '30px', height: '30px', right: '20px', top: '18px', float: 'right', position: 'absolute' }}
                        onClick={() => { onClose(); }}><Close /></IconButton>
                </div>
            </DialogTitle>
            <DialogContent>
                <Grid container>

                    <Grid item xs={4}>
                        <Button variant='contained'
                            onClick={() => { window.open('https://connect.bentley.com/project/index?projectId=' +  projectInfoData.find(x => x.properties.ComponentInstanceId.toString() === project.instanceId)?.properties.ConnectedProjectGuid, '_blank') }}
                            sx={{ margin: '12px', background: '#0E457D', minWidth: '150px' }}
                            startIcon={<Info />} title='PŘEHLED'>
                            PŘEHLED
                        </Button>
                    </Grid>
                    <Grid item xs={4}>
                        <Button variant='contained' sx={{ margin: '12px', background: '#0E457D', minWidth: '150px' }} onClick={() => { window.open('https://connect-projectwisewac.bentley.com/context/' + projectInfoData.find(x => x.properties.ComponentInstanceId.toString() === project.instanceId)?.properties.ConnectedProjectGuid + '/connection', '_blank') }} startIcon={<Folder />} title='DOKUMENTY'>
                            DOKUMENTY
                        </Button>
                    </Grid>
                    <Grid item xs={4}>
                        <Button
                            onClick={() => { window.open('https://connect-projectforms.bentley.com/#/' + projectInfoData.find(x => x.properties.ComponentInstanceId.toString() === project.instanceId)?.properties.ConnectedProjectGuid + '/type/%C3%9Akoly?discipline=issue', '_blank') }}
                            variant='contained' sx={{ margin: '12px', background: '#0E457D', minWidth: '150px' }} startIcon={<CompareArrows />} title='PROCESY'>
                            ÚKOLY
                        </Button>
                    </Grid>

                    <Grid item xs={12} style={{ paddingRight: '22px' }}>
                        <TextField sx={{ margin: '12px', width: '100%' }} label={'Název stavby'}
                            size='small'
                            name='name'
                            variant='filled'
                            value={project.properties.PROJECT_PW_STAVBA_NAZEV}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField sx={{ margin: '12px' }} label={'Označení'}
                            size='small'
                            name='znacka'
                            variant='filled'
                            value={project.properties.PROJECT_PW_STAVBA_SKOD}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField sx={{ margin: '12px' }} label={'Aktuální status'}
                            size='small'
                            name='status'
                            variant='filled'
                            value={project.properties.PROJECT_PW_STAVBA_STATUS}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField sx={{ margin: '12px' }} label={'Aktuální stádium'}
                            size='small'
                            name='stadium'
                            variant='filled'
                            value={project.properties.PROJECT_PW_STAVBA_STADIUM}
                        />
                    </Grid>

                    <Grid item xs={12} style={{ paddingRight: '22px' }}>
                        <TextField sx={{ margin: '12px', width: '100%' }} label={'Popis stavby'}
                            size='small'
                            multiline
                            name='popis'
                            variant='filled'
                            value={project.properties.PROJECT_PW_STAVBA_POPIS}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <TextField sx={{ margin: '12px' }} label={'HIS'}
                            size='small'
                            name='znacka'
                            variant='filled'
                            value={project.properties.PROJECT_PW_STAVBA_HIS}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField sx={{ margin: '12px' }} label={'Oblastní správce'}
                            size='small'
                            name='status'
                            variant='filled'
                            value={project.properties.PROJECT_PW_STAVBA_KRAJ}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField sx={{ margin: '12px' }} label={'TU'}
                            size='small'
                            name='stadium'
                            variant='filled'
                            value={project.properties.PROJECT_PW_STAVBA_TU}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Box>
                            <BarChart
                                borderRadius={5}
                                layout="horizontal"
                                yAxis={[{
                                    scaleType: 'band', data: ['SoD', 'Čerpáno', 'ZBV'], colorMap: {
                                        colors: ['#2E7D32', '#FF5200', '#0E457D'],
                                        type: 'ordinal'
                                    }
                                }]}
                                margin={{ top: 20, right: 60, bottom: 20, left: 80 }}
                                xAxis={[
                                    {
                                        valueFormatter: (value) => `${(value / 1000000).toLocaleString()} mil. kč`
                                    },
                                ]}
                                series={[{ data: [getSoD(), getCerpano(), getZbv()] }]}
                                sx={{
                                    [`.${axisClasses.left} .${axisClasses.label}`]: {
                                        // Move the y-axis label with CSS
                                        visibility: 'hidden',
                                    },
                                }
                                }
                                width={550}
                                height={150}
                            />
                        </Box>
                    </Grid>

                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
                        <Button variant='contained' sx={{ margin: 'auto', background: '#0E457D' }} onClick={() => {
                            onClose();
                        }}>Zavřít</Button>
                    </div>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}
