import React from 'react'
import { RouteDTO } from '../../Api/ApiServer';
import { Autocomplete, TextField } from '@mui/material';

export interface RoutesSelectProps {
    selectedRoute: RouteDTO | null;
    // selectedRouteId?: number | null;
    onChange: (event: RouteDTO | null) => void;
    routesInput: RouteDTO[];

}
export default function RoutesSelect(props: RoutesSelectProps) {
    const { routesInput, onChange, selectedRoute } = props;
    // if(routes)
    return (
        <div>

            <Autocomplete
                disablePortal
                id="combo-box-demo"
                disabled={(routesInput === undefined || routesInput.length === 0)}
                size={'small'}
                onChange={(e, v) => {
                    // setSelectedGroup(v);
                    onChange(v);
                }}
                getOptionLabel={o => o !== null ? o!.name! : ""}
                value={selectedRoute}
                options={routesInput}

                // fullWidth={biggerThan700}
                sx={{ minWidth: 288, margin:'20px' }}
                renderInput={(params) => <TextField {...params} label={selectedRoute === null ? 'Vyberte trasu' : 'Trasa'}
                // renderInput={(params) => <TextField error={isMandatory === true && selectedGroup === null}  {...params} label={'Skupina šablon'}

                />}
            />
            {/* {selectedGroup === null && isMandatory && <p style={{ marginBottom: '0px', marginLeft: '-10px', marginTop: '4px', color: theme.palette.error.main }}>{'Vlastnost je povinná'}</p>} */}
        </div>
    );
}
