import { Checkbox } from '@mui/material'
import React from 'react'

interface CurveSolverCheckboxCellProps {
    isChecked: boolean;
    onCheckedChange: (newChecked: boolean) => void;
    isCheckboxVisible: boolean;
}

export default function CurveSolverCheckboxCell(props: CurveSolverCheckboxCellProps) {
    const { isChecked, onCheckedChange, isCheckboxVisible } = props;
    return (
        <div style={{minWidth:'32px'}}>
            {isCheckboxVisible && <Checkbox sx={{ margin: '-6px' }} checked={isChecked} onChange={(e, ch) => { onCheckedChange(ch) }} />}
        </div>
    )
}
