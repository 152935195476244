import React, { useEffect } from 'react';
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from '../MsAuth/MsAuthConfig';
import { APIAuthClient } from '../Api/ApiAuthClient';
import { useNavigate, useParams } from 'react-router';
import { ApiException } from '../Api/ApiServer';
import ChooseTenantDialog from './ChooseTenantDialog';
import { Snackbar, Alert, CircularProgress } from '@mui/material';
import { APIUserClient } from '../Api/ApiUserClient';

const RedirectHandler = () => {
    const { instance, accounts, inProgress } = useMsal();
    const navigate = useNavigate();
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [userId, setUserId] = React.useState<number>(0);
    const [state, setState] = React.useState<{ name: string, tenant: string } | undefined>();
    const [loginError, setLoginError] = React.useState("");
    const [accessTokenMS, setAccessTokenMS] = React.useState<string>();
    const params = useParams();
    const isAuth = useIsAuthenticated();
    
    const handleRedirect = async () => {
        if (isAuth) {
            if (accounts !== null && accounts.length > 0  && inProgress === 'none') {
                // User is logged in, you can now acquire tokens
                const accessTokenResponse = await instance.acquireTokenSilent({
                    scopes: loginRequest.scopes, // Add the scopes you need here
                    account: accounts[0] === null ? undefined : accounts[0]
                });
                // console.log(accessTokenResponse);
                setAccessTokenMS(accessTokenResponse.accessToken);
                let redirectPath = localStorage.getItem('ms-redirect');
                if (redirectPath !== null) {
                    loginMS(accessTokenResponse.accessToken, undefined, redirectPath ? redirectPath : undefined);
                    // localStorage.removeItem('ms-redirect');
                    // navigate(redirectPath);
                }
                else navigate('/login')
                // Now you have the access token, you can use it for API calls
            }
        }


        if (window.location.hash === '') {

            if (params['hash'] !== undefined) {
                const url = window.location.href;
                const baseUrl = url.substring(0, url.lastIndexOf('/'));
                localStorage.setItem('ms-redirect', '/' + params['hash']);
                window.location.href = baseUrl;
            }
            else {
                if (inProgress === 'none' && !isAuth) {
                    // await msal.instance.initialize();
                    instance.loginRedirect({ scopes: loginRequest.scopes });
                }
            }
        }

    };
    useEffect(() => {
        handleRedirect();
    }, [isAuth, instance, inProgress]);

    const loginMS = async (accessToken: string, tenantId: number | undefined, state: string | undefined) => {

        var type = localStorage.getItem('ms-state');
        if (type === 'login') {
            new APIAuthClient().LoginMicrosoft(accessToken, tenantId).then((v) => {
                if (v.id !== undefined && v.tenantId !== undefined && state) {
                    navigate(state);
                }
                // else if (v.id !== undefined) {
                //     setState(state ? JSON.parse(state) : undefined);
                //     setUserId(v.id);
                //     setDialogOpen(true);
                // }
            }).catch((e: ApiException) => {
                //@ts-ignore
                setLoginError(e.response.Reason);
            });
        }
        else if (type === 'user-signup-microsoft') {
            let userId = parseInt(localStorage.getItem('ms-signup-userid')!);
            let signupToken = localStorage.getItem('ms-signup-token');
            new APIUserClient().SignupUserMicrosoft(userId, accessToken, signupToken!).then((res) => {
                if (res && res.ok){
                    localStorage.removeItem('ms-signup-userid');
                    localStorage.removeItem('ms-signup-token');
                    navigate('/');
                }
                else
                    setLoginError('Chyba, kontaktujte podporu');
            });
        }
        else if (type !== null) {
            new APIAuthClient().SignupMicrosoft(type, accessToken).then((v) => {
                if (v.microsoftId !== undefined && state) {
                    navigate(state);
                    //navigate('/projects');
                }
            }).catch((e) => { setLoginError('Chyba'); });
        }
    }

    return (
        <div>
            <div>
                <CircularProgress />
            </div>

            <ChooseTenantDialog userId={userId} isOpen={dialogOpen} onClose={(r) => { setDialogOpen(false); if (r) { loginMS(accessTokenMS!, r, state ? state.name : undefined); } }} />

            <Snackbar open={loginError !== ""} autoHideDuration={3000} onClose={() => setLoginError("")}>

                <Alert onClose={() => setLoginError("")} severity="error" sx={{ width: '100%' }}>
                    Přihlášení se nezdařilo: {loginError}
                </Alert>

            </Snackbar>
        </div>

    );
};

export default RedirectHandler;