import { useMsal } from '@azure/msal-react';
import { Alert, Button, CircularProgress, Snackbar, TextField, Typography, useTheme } from '@mui/material';
import React, { useEffect } from 'react'
import { useMediaPredicate } from 'react-media-hook';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { FileParameter, OutputRowDTO } from '../Api/ApiServer';
import { ApiDataClient } from '../Api/ApiDataClient';
import { UploadFile } from '@mui/icons-material';
//import { importEtcsExcel } from './ParseEtscsExcel';

interface EtcsDataUploadSceneProps {
    //onLogin?: (accessToken: string, refreshToken: string, expiresAt: Date) => void;
    // redirectPath?: string;
    dataUploaded: (data: OutputRowDTO[]) => void;
}

export default function EtcsDataUploadScene(props: EtcsDataUploadSceneProps) {
    const { dataUploaded } = props;
    const params = useParams();
    
    const theme = useTheme();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [snackBarText, setSnackBarText] = React.useState<string>();
    const [severenity, setSeverenity] = React.useState<'error' | 'info'>('info');
    const [xmls, setXmls] = React.useState<FileParameter[]>();
    const [excelFile, setExcelFile] = React.useState<FileParameter>();
    //const [excelData, setExcelData] = React.useState<RawDataDTO[]>();
    const [excel, setExcel] = React.useState<FileList | null>();

    useEffect(() => {
    }, []);

    const excelOnLoad = async (e) => {
        const file = e && e.target && e.target.files;
        if (file) {
            let exc: FileParameter = { data: e.target.files[0], fileName: e.target.files[0].fileName};
            setExcelFile(exc);
            //setLoading(true);
            setExcel(e.target.files);
            /* importEtcsExcel(e.target.files, (data) =>{
                setExcelData(data);
                setLoading(false);
            }); */
        }
        else
            setExcel(null);
    };

    const xmlsOnLoad = async (e) => {
        const file = e && e.target && e.target.files;
        if (file) {            
            let xmls_list: FileParameter[] = [];
            if (e.target && e.target.files){            
                for (let i = 0; i < e.target.files.length; i++) {
                    xmls_list.push({ data: e.target.files[i], fileName: e.target.files[i].fileName});
                }
            }

            setXmls(xmls_list);
        }
    };

    const onShow = async (e) => {
        if (excelFile) {
            setLoading(true);
            //importEtcsExcel(excel, (data) =>{
                new ApiDataClient().GetInitData(excelFile, xmls ? xmls : []).then((res) => {
                    setLoading(false);
                    if (res.generatedData)
                        dataUploaded(res.generatedData);
                    else
                        setSeverenity('error');
                        setSnackBarText('Chyba zpracování excelu');

                }).catch((e) => {
                    setSeverenity('error');
                    setSnackBarText('Chyba zpracování excelu');
                    setLoading(false);
                });
                //setExcelData(data);
                //setLoading(false);
            //});
        }
    };

    return (
        <div> 
            {!loading && <div /* style={{ display: 'flex', flexDirection: 'column' }} */>
                <div style={{ border: '1px solid #C3C3C3', borderRadius: '16px', padding: '12px' }}>
                    <div style={{ fontWeight: 'bold' }}>{'Vyberte excel k importu'}</div>
                    <input type='file'
                        style={{ margin: '16px', width: 300 }}
                        accept=".xlsx"
                        onChange={(e) => {
                            excelOnLoad(e);

                        }}></input>

                </div>
                <div style={{ border: '1px solid #C3C3C3', borderRadius: '16px', padding: '12px', marginTop: '20px' }}>
                    <div style={{ fontWeight: 'bold' }}>{'Vyberte xml s rychlostmi'}</div>
                    <input type='file'
                        style={{ margin: '16px', width: 300 }}
                        accept=".xml"
                        multiple
                        // placeholder=
                        // style={{alignSelf:'center'}}
                        onChange={(e) => {
                            xmlsOnLoad(e);
                        }}></input>

                </div>                
                <Button sx={{ marginTop: '16px'}} variant='contained'
                    disabled={excel === null}
                    onClick={onShow}
                >Zobrazit</Button>
            </div>}
            {loading && <div><CircularProgress/></div>}
            <Snackbar open={snackBarText !== undefined} autoHideDuration={3000} onClose={() => setSnackBarText(undefined)}>

                <Alert onClose={() => setSnackBarText(undefined)} severity={severenity} sx={{ width: '100%' }}>
                    {snackBarText}
                </Alert>

            </Snackbar>
        </div>)
}
