import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import { PublicClientApplication,InteractionType } from '@azure/msal-browser';
import { msalConfig } from './MsAuth/MsAuthConfig';
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';
const msalInstance = new PublicClientApplication(msalConfig);
/* if (window.location.hash !== ''){
  console.log("hash found" + window.location.hash);
}
else { */
  ReactDOM.render(
    <React.StrictMode>
      <MsalProvider instance={msalInstance}>
        <App/>
      </MsalProvider>
    </React.StrictMode>,
    document.getElementById('root')
  );
//}