import { ceilWithMinus } from "../../Shared/ceilWithMinus";
import { roundNumber, truncateNumber } from "../../Shared/roundNumber";
import { ObloukItemState } from "../Enums/ObloukItemState";
import { ObloukItem } from "../Models/ObloukItem";

export function calculateMezilehla(itemsUp: { [key: string]: ObloukItem }, items: { [key: string]: ObloukItem }) {
    calculateMezilehlaStart(itemsUp, items);
    calculateV130M(itemsUp, items);
    calculateV150M(itemsUp, items);
    calculateVkM(itemsUp, items);
    calculateMezilehleVytycovaci(itemsUp, items);
    calculateTecnaZaobleni(itemsUp, items);
}

function calculateMezilehlaStart(itemsUp: { [key: string]: ObloukItem }, items: { [key: string]: ObloukItem }) {
    if (itemsUp['V'].numericValue === items['V'].numericValue && items['V'].numericValue > 0 && itemsUp['R'].numericValue > 0) {
        items['Vmezi'].okState = ObloukItemState.OkReadOnly;
        items['Vmezi'].setValueByNumericValue(items['V'].numericValue);
        items['deltaD'].setValueByNumericValue(Math.abs(items['D'].numericValue - itemsUp['D'].numericValue));
        //co tohle znamená??
        // If Iminus1 >= 0 Then
        // Iminus1 = Application.WorksheetFunction.RoundUp(Iminus1, 0)
        // Else
        // 'Iminus1 = Application.WorksheetFunction.RoundDown(Iminus1, 0)
        // Iminus1 = Application.WorksheetFunction.RoundUp(Iminus1, 0)
        //         End If
        //priorita operátorů??
        let Iminus1 = ceilWithMinus(11.8 * itemsUp['V'].numericValue ** 2 / itemsUp['R'].numericValue - itemsUp['D'].numericValue);
        items['deltaI'].setValueByNumericValue(Math.abs(items['I'].numericValue - Iminus1));



        items['dLdn'].setValueByNumericValue(items['nn'].numericValue * items['V'].numericValue * items['deltaD'].numericValue / 1000);
        items['dLdlim'].setValueByNumericValue(Math.max(500, items['nlim'].numericValue * items['V'].numericValue) * items['deltaD'].numericValue / 1000);
        items['dLdmin'].setValueByNumericValue(Math.max(400, items['nmin'].numericValue * items['V'].numericValue) * items['deltaD'].numericValue / 1000);
        if (items['deltaI'].numericValue > 0) {
            items['dLkn'].setValueByNumericValue(items['nIn'].numericValue * items['V'].numericValue * items['deltaI'].numericValue / 1000);
            items['dLklim'].setValueByNumericValue(items['nIlim'].numericValue * items['V'].numericValue * items['deltaI'].numericValue / 1000);
            items['dLkmin'].setValueByNumericValue(items['nImin'].numericValue * items['V'].numericValue * items['deltaI'].numericValue / 1000);
        }
        //1478
        if (items['deltaD'].numericValue > 0) {
            items['dLklim07R'].setValueByNumericValue(0.7 * Math.pow(items['R'].numericValue, 0.5));
        }
        if (items['V'].numericValue >= 60) {
            items['dLklim20'].setValueByNumericValue(20);
        }
        else if (items['V'].numericValue >= 200) {
            items['dLklim20'].setValueByNumericValue(60);
        }
        items['dLn'].setValueByNumericValue(Math.max(items['dLdn'].numericValue, items['dLkn'].numericValue, items['dLklim07R'].numericValue, items['dLklim20'].numericValue));
        items['dLlim'].setValueByNumericValue(Math.max(items['dLdlim'].numericValue, items['dLklim'].numericValue, items['dLklim07R'].numericValue, items['dLklim20'].numericValue));
        items['dLmin'].setValueByNumericValue(Math.max(items['dLdmin'].numericValue, items['dLkmin'].numericValue));

        if (items['deltaD'].numericValue > 0) {
            //pořadí operátorů??
            items['nm'].setValueByNumericValue(roundNumber(items['Lkm'].numericValue / items['deltaD'].numericValue * 1000 / items['V'].numericValue, 3));
        }
        else {
            items['nm'].value = '';
        }

        if (items['deltaI'].numericValue > 0) {
            items['nIm'].setValueByNumericValue(roundNumber(items['Lkm'].numericValue / items['deltaI'].numericValue * 1000 / items['V'].numericValue, 3));
        }
        else {
            items['nIm'].value = '';
        }
        //1586
        if (items['Lkm'].numericValue < items['dLmin'].numericValue)
            items['Lkm'].okState = ObloukItemState.Error;
        else if (items['Lkm'].numericValue < items['dLlim'].numericValue)
            items['Lkm'].okState = ObloukItemState.Oranzove;
        else if (items['Lkm'].numericValue < items['dLn'].numericValue)
            items['Lkm'].okState = ObloukItemState.Zlute;
        if (items['nm'].value !== '') {
            if (items['nm'].numericValue === 0 || items['nm'].numericValue < items['nmin'].numericValue) {
                items['nm'].okState = ObloukItemState.Error;
            }
            else if (items['nm'].numericValue < items['nlim'].numericValue) {
                items['nm'].okState = ObloukItemState.Oranzove;
            }
            else if (items['nm'].numericValue < items['nn'].numericValue) {
                items['nm'].okState = ObloukItemState.Zlute;
            }
        }

        if (items['nIm'].numericValue < items['nImin'].numericValue) {
            items['nIm'].okState = ObloukItemState.Error;
        }
        else if (items['nIm'].numericValue < items['nIlim'].numericValue) {
            items['nIm'].okState = ObloukItemState.Oranzove;
        }
        else if (items['nIm'].numericValue < items['nIn'].numericValue) {
            items['nIm'].okState = ObloukItemState.Zlute;
        }



    }
    else {
        items['Vmezi'].okState = ObloukItemState.Error;
        items['Vmezi'].value = 'RŮZNÉ';
    }
}
function calculateV130M(itemsUp: { [key: string]: ObloukItem }, items: { [key: string]: ObloukItem }) {
    if (items['V130'].numericValue > 0) {
        if (itemsUp['V130'].numericValue === items['V130'].numericValue) {
            items['V130m'].okState = ObloukItemState.OkReadOnly;
            let Iminus1 = ceilWithMinus(11.8 * (itemsUp['V130'].numericValue ** 2) / itemsUp['R'].numericValue - itemsUp['D'].numericValue);
            items['deltaI130'].setValueByNumericValue(Math.abs(items['I130'].numericValue - Iminus1));
            //tady je zas v originálu strašně divnej kod??
            items['dLdn130'].setValueByNumericValue(items['nn130'].numericValue * items['V130'].numericValue * items['deltaD'].numericValue / 1000);
            items['dLdlim130'].setValueByNumericValue(Math.max(500, items['nlim130'].numericValue * items['V130'].numericValue) * items['deltaD'].numericValue / 1000);
            items['dLdmin130'].setValueByNumericValue(Math.max(400, items['nmin130'].numericValue * items['V130'].numericValue) * items['deltaD'].numericValue / 1000);
            if (items['deltaI130'].numericValue > 0) {
                items['dLkn130'].setValueByNumericValue(items['nIn130'].numericValue * items['V130'].numericValue * items['deltaI130'].numericValue / 1000);
                items['dLklim130'].setValueByNumericValue(items['nIlim130'].numericValue * items['V130'].numericValue * items['deltaI130'].numericValue / 1000);
                items['dLkmin130'].setValueByNumericValue(items['nImin130'].numericValue * items['V130'].numericValue * items['deltaI130'].numericValue / 1000);
            }
            if (items['V130'].numericValue >= 60) {
                items['dLklim20130'].setValueByNumericValue(20);
            }
            else if (items['V130'].numericValue >= 200) {
                items['dLklim20130'].setValueByNumericValue(60);
            }
            else {
                items['dLklim20130'].setValueByNumericValue(0);
            }

            items['dLn130'].setValueByNumericValue(Math.max(items['dLdn130'].numericValue, items['dLkn130'].numericValue, items['dLklim130'].numericValue));
            items['dLlim130'].setValueByNumericValue(Math.max(items['dLdlim130'].numericValue, items['dLklim130'].numericValue, items['dLklim20130'].numericValue));
            items['dLmin130'].setValueByNumericValue(Math.max(items['dLdmin130'].numericValue, items['dLkmin130'].numericValue));

            if (items['deltaD'].numericValue > 0) {
                items['nm130'].setValueByNumericValue(roundNumber(items['Lkm'].numericValue / items['deltaD'].numericValue * 1000 / items['V130'].numericValue, 3));
            }
            else {
                items['nm130'].value = '';
            }
            if (items['deltaI130'].numericValue > 0) {
                items['nIm130'].setValueByNumericValue(roundNumber(items['Lkm'].numericValue / items['deltaI130'].numericValue * 1000 / items['V130'].numericValue, 3));
            }
            else {
                items['nIm130'].value = '';
            }
            if (items['Lkm'].numericValue < items['dLmin130'].numericValue) {
                items['Lkm130'].okState = ObloukItemState.Error;
                items['Lkm130'].value = 'XXX';
            }
            else if (items['Lkm'].numericValue < items['dLlim130'].numericValue) {
                items['Lkm130'].okState = ObloukItemState.Oranzove;
                items['Lkm130'].value = 'MIN';
            }
            else if (items['Lkm'].numericValue < items['dLn130'].numericValue) {
                items['Lkm130'].okState = ObloukItemState.Zlute;
                items['Lkm130'].value = 'LIM';
            }
            else {
                items['Lkm130'].okState = ObloukItemState.OkReadOnly;
                items['Lkm130'].value = 'N';
            }
            items['V130m'].setValueByNumericValue(items['V130'].numericValue);

            if (items['nm130'].value !== '') {
                if (items['nm130'].numericValue === 0 || items['nm130'].numericValue < items['nmin130'].numericValue) {
                    items['nm130'].okState = ObloukItemState.Error;
                }
                else if (items['nm130'].numericValue < items['nlim130'].numericValue) {
                    items['nm130'].okState = ObloukItemState.Oranzove;
                }
                else if (items['nm130'].numericValue < items['nn130'].numericValue) {
                    items['nm130'].okState = ObloukItemState.Zlute;
                }
            }

            if (items['nIm130'].numericValue < items['nImin130'].numericValue) {
                items['nIm130'].okState = ObloukItemState.Error;
            }
            else if (items['nIm130'].numericValue < items['nIlim130'].numericValue) {
                items['nIm130'].okState = ObloukItemState.Oranzove;
            }
            else if (items['nIm130'].numericValue < items['nIn130'].numericValue) {
                items['nIm130'].okState = ObloukItemState.Zlute;
            }

        }
        else {
            items['V130m'].okState = ObloukItemState.Error;
            items['V130m'].value = 'RŮZNÉ';
        }

    }


}
function calculateV150M(itemsUp: { [key: string]: ObloukItem }, items: { [key: string]: ObloukItem }) {
    if (items['V150'].numericValue > 0) {
        if (itemsUp['V150'].numericValue === items['V150'].numericValue) {
            items['V150m'].okState = ObloukItemState.OkReadOnly;
            let Iminus1 = ceilWithMinus(11.8 * (itemsUp['V150'].numericValue ** 2) / itemsUp['R'].numericValue - itemsUp['D'].numericValue);
            items['deltaI150'].setValueByNumericValue(Math.abs(items['I150'].numericValue - Iminus1));
            //tady je zas v originálu strašně divnej kod??
            items['dLdn150'].setValueByNumericValue(items['nn150'].numericValue * items['V150'].numericValue * items['deltaD'].numericValue / 1000);
            items['dLdlim150'].setValueByNumericValue(Math.max(500, items['nlim150'].numericValue * items['V150'].numericValue) * items['deltaD'].numericValue / 1000);
            items['dLdmin150'].setValueByNumericValue(Math.max(400, items['nmin150'].numericValue * items['V150'].numericValue) * items['deltaD'].numericValue / 1000);
            if (items['deltaI150'].numericValue > 0) {
                items['dLkn150'].setValueByNumericValue(items['nIn150'].numericValue * items['V150'].numericValue * items['deltaI150'].numericValue / 1000);
                items['dLklim150'].setValueByNumericValue(items['nIlim150'].numericValue * items['V150'].numericValue * items['deltaI150'].numericValue / 1000);
                items['dLkmin150'].setValueByNumericValue(items['nImin150'].numericValue * items['V150'].numericValue * items['deltaI150'].numericValue / 1000);
            }
            if (items['V150'].numericValue >= 60) {
                items['dLklim20150'].setValueByNumericValue(20);
            }
            else if (items['V150'].numericValue >= 200) {
                items['dLklim20150'].setValueByNumericValue(60);
            }
            else {
                items['dLklim20150'].setValueByNumericValue(0);
            }

            items['dLn150'].setValueByNumericValue(Math.max(items['dLdn150'].numericValue, items['dLkn150'].numericValue, items['dLklim150'].numericValue));
            items['dLlim150'].setValueByNumericValue(Math.max(items['dLdlim150'].numericValue, items['dLklim150'].numericValue, items['dLklim20150'].numericValue));
            items['dLmin150'].setValueByNumericValue(Math.max(items['dLdmin150'].numericValue, items['dLkmin150'].numericValue));

            if (items['deltaD'].numericValue > 0) {
                items['nm150'].setValueByNumericValue(roundNumber(items['Lkm'].numericValue / items['deltaD'].numericValue * 1000 / items['V150'].numericValue, 3));
            }
            else {
                items['nm150'].value = '';
            }
            if (items['deltaI150'].numericValue > 0) {
                items['nIm150'].setValueByNumericValue(roundNumber(items['Lkm'].numericValue / items['deltaI150'].numericValue * 1000 / items['V150'].numericValue, 3));
            }
            else {
                items['nIm150'].value = '';
            }
            if (items['Lkm'].numericValue < items['dLmin150'].numericValue) {
                items['Lkm150'].okState = ObloukItemState.Error;
                items['Lkm150'].value = 'XXX';
            }
            else if (items['Lkm'].numericValue < items['dLlim150'].numericValue) {
                items['Lkm150'].okState = ObloukItemState.Oranzove;
                items['Lkm150'].value = 'MIN';
            }
            else if (items['Lkm'].numericValue < items['dLn150'].numericValue) {
                items['Lkm150'].okState = ObloukItemState.Zlute;
                items['Lkm150'].value = 'LIM';
            }
            else {
                items['Lkm150'].okState = ObloukItemState.OkReadOnly;
                items['Lkm150'].value = 'N';
            }
            items['V150m'].setValueByNumericValue(items['V150'].numericValue);

            if (items['nm150'].value !== '') {
                if (items['nm150'].numericValue === 0 || items['nm150'].numericValue < items['nmin150'].numericValue) {
                    items['nm150'].okState = ObloukItemState.Error;
                }
                else if (items['nm150'].numericValue < items['nlim150'].numericValue) {
                    items['nm150'].okState = ObloukItemState.Oranzove;
                }
                else if (items['nm150'].numericValue < items['nn150'].numericValue) {
                    items['nm150'].okState = ObloukItemState.Zlute;
                }
            }

            if (items['nIm150'].numericValue < items['nImin150'].numericValue) {
                items['nIm150'].okState = ObloukItemState.Error;
            }
            else if (items['nIm150'].numericValue < items['nIlim150'].numericValue) {
                items['nIm150'].okState = ObloukItemState.Oranzove;
            }
            else if (items['nIm150'].numericValue < items['nIn150'].numericValue) {
                items['nIm150'].okState = ObloukItemState.Zlute;
            }

        }
        else {
            items['V150m'].okState = ObloukItemState.Error;
            items['V150m'].value = 'RŮZNÉ';
        }

    }

}
function calculateVkM(itemsUp: { [key: string]: ObloukItem }, items: { [key: string]: ObloukItem }) {
    if (items['Vk'].numericValue > 0) {
        if (items['Vk'].numericValue === itemsUp['Vk'].numericValue) {
            items['Vkm'].okState = ObloukItemState.OkReadOnly;
            //1721
            let Iminus1 = ceilWithMinus(11.8 * (itemsUp['Vk'].numericValue ** 2) / itemsUp['R'].numericValue - itemsUp['D'].numericValue);
            // if (Iminus1 >= 0) Iminus1 = ceilWithMinus(Iminus1);
            // else Iminus1 = Math.floor(Iminus1);
            items['deltaIk'].setValueByNumericValue(Math.abs(items['Ik'].numericValue - Iminus1));
            //1733
            items['dLdnk'].setValueByNumericValue(items['nnk'].numericValue * items['Vk'].numericValue * items['deltaD'].numericValue / 1000);
            items['dLdlimk'].setValueByNumericValue(items['nmink'].numericValue * items['Vk'].numericValue * items['deltaD'].numericValue / 1000);
            items['dLdmink'].setValueByNumericValue(Math.max(400, items['nmink'].numericValue * items['Vk'].numericValue) * items['deltaD'].numericValue / 1000);
            items['dLklim20k'].setValueByNumericValue(20);
            //1739

            if ((items['D'].numericValue === 0 && items['deltaD'].numericValue >= 30) || (items['D'].numericValue === items['deltaD'].numericValue && items['deltaD'].numericValue >= 30)) {
                if (items['D'].numericValue === 0) {
                    items['vD2'].setValueByNumericValue(items['deltaD'].numericValue);
                    items['vIk1'].setValueByNumericValue(items['Ik'].numericValue);
                    items['vIk2'].setValueByNumericValue(Iminus1);
                    if (items['vIk2'].numericValue >= 0) items['vIk2'].setValueByNumericValue(ceilWithMinus(items['vIk2'].numericValue));
                    else items['vIk2'].setValueByNumericValue(Math.floor(items['vIk2'].numericValue));
                }
                else {
                    items['vD2'].setValueByNumericValue(items['deltaD'].numericValue);
                    items['vIk1'].setValueByNumericValue(Iminus1);
                    // items['vIk2'].setValueByNumericValue(Iminus1);
                    if (items['vIk1'].numericValue >= 0) items['vIk1'].setValueByNumericValue(ceilWithMinus(items['vIk1'].numericValue));
                    else items['vIk1'].setValueByNumericValue(Math.floor(items['vIk1'].numericValue));
                }

            }
            let vPodm1 = '';
            let vPodm2 = '';
            let vPodm3 = '';
            let vPodm4 = '';
            let vPodm5 = '';
            let vPodmA = '';
            let vPodmB = '';
            let vIkPom = items['vIk1'].numericValue + 13 * items['dIk'].numericValue / items['vD2'].numericValue;
            if ((vIkPom) > 120) {
                vPodm1 = 'XXX';
            }
            else if (vIkPom > 100) {
                vPodm1 = 'MIN';
            }
            else vPodm1 = 'N';

            if (items['dIk'].numericValue <= (3 * items['vD2'].numericValue) && items['vD2'].numericValue < 112) {
                items['dLkx1'].setValueByNumericValue(2.2 * items['Vk'].numericValue * items['vIk2'].numericValue * items['vD2'].numericValue / (items['vD2'].numericValue - 13));
            }

            if (items['dIk'].numericValue > (3 * items['vD2'].numericValue) && items['vD2'].numericValue < 90) {
                items['dLkx2'].setValueByNumericValue(3.2 * items['Vk'].numericValue * items['dIk'].numericValue / 1000);
            }
            //1780
            // If (dD > 0 And D >= 30 And NacteniCisla("C" & lRadek - 1) >= 30) Then
            //             'a)
            //             If dIk <= 3 * dD And dD < 112 Then
            //                 dLkx3 = 2.2 * Vk * dIk * dD / (1000 * (dD - 13))
            //             End If

            //             'b)
            //             If dIk > 3 * dD And dD < 90 Then
            //                 dLkx4 = 3.2 * Vk * dIk / 1000
            //             End If

            //         End If
            //priorita??
            if (items['deltaD'].numericValue > 0 && items['D'].numericValue >= 300 && itemsUp['D'].numericValue >= 30) {
                if (items['dIk'].numericValue <= 3 * items['deltaD'].numericValue && items['deltaD'].numericValue < 112) {
                    items['dLkx3'].setValueByNumericValue(2.2 * items['Vk'].numericValue * items['dIk'].numericValue * items['deltaD'].numericValue / (1000 * (items['deltaD'].numericValue - 13)));
                }
                if (items['dIk'].numericValue > 3 * items['deltaD'].numericValue && items['deltaD'].numericValue < 90) {
                    items['dLkx4'].setValueByNumericValue(3.2 * items['Vk'].numericValue * items['dIk'].numericValue / 1000);
                }
            }

            if (items['deltaD'].numericValue === 0) {
                items['dLkx5'].setValueByNumericValue(4 * items['Vk'].numericValue * items['dIk'].numericValue / 1000);
                if (items['dIk'].numericValue > 80) {
                    vPodm2 = 'LIM';
                }
                else {
                    vPodm2 = 'N';
                }
            }
            if (items['deltaD'].numericValue > 0) {
                if (items['R'].numericValue > itemsUp['R'].numericValue) {
                    if (items['D'].numericValue < itemsUp['D'].numericValue) {
                        vPodm3 = 'N';
                    }
                    else {
                        vPodm3 = 'XXX';
                    }
                }
                else {
                    if (items['D'].numericValue > itemsUp['D'].numericValue) {
                        vPodm3 = 'N';
                    }
                    else {
                        vPodm3 = 'XXX';
                    }
                }
            }

            //1822

            if (items['deltaD'].numericValue > 0) {
                items['vIk2'].setValueByNumericValue(11.8 * Math.pow(itemsUp['Vk'].numericValue, 2) / itemsUp['R'].numericValue - itemsUp['D'].numericValue);
                if (items['vIk2'].numericValue >= 0) {
                    items['vIk2'].setValueByNumericValue(ceilWithMinus(items['vIk2'].numericValue));
                }
                else {
                    items['vIk2'].setValueByNumericValue(Math.floor(items['vIk2'].numericValue));
                }
                if (items['D'].numericValue > itemsUp['D'].numericValue) {
                    if (items['Ik'].numericValue >= items['vIk2'].numericValue) {
                        vPodm4 = 'N';
                    }
                    else {
                        vPodm4 = 'MIN';
                    }
                }
                else {
                    if (items['dIk'].numericValue <= items['vIk2'].numericValue) {
                        vPodm4 = 'N';
                    }
                    else {
                        vPodm4 = 'MIN';
                    }
                }
            }
            //1848

            //     If dD > 0 Then
            //     If dD >= 40 Then
            //         vPodm5 = "N"
            //     Elseif dD >= 30 Then
            //         vPodm5 = "LIM"
            //     Else
            //         vPodm5 = "XXX"
            //     End If
            // End If

            if (items['deltaD'].numericValue > 0) {
                if (items['deltaD'].numericValue >= 40) {
                    vPodm5 = 'N';
                }
                else if (items['deltaD'].numericValue >= 30) {
                    vPodm5 = 'LIM';
                }
                else {
                    vPodm5 = 'XXX';
                }
            }

            // dLnk = WorksheetFunction.Max(dLdnk, dLklim20k, dLkx1, dLkx2, dLkx3, dLkx4, dLkx5)
            // dLlimk = WorksheetFunction.Max(dLdmink, dLklim20k, dLkx1, dLkx2, dLkx3, dLkx4, dLkx5)
            // dLmink = WorksheetFunction.Max(dLdmink, dLkx1, dLkx2, dLkx3, dLkx4, dLkx5)
            items['dLnk'].setValueByNumericValue(Math.max(items['dLdnk'].numericValue, items['dLklim20k'].numericValue, items['dLkx1'].numericValue, items['dLkx2'].numericValue, items['dLkx3'].numericValue, items['dLkx4'].numericValue, items['dLkx5'].numericValue));
            items['dLlimk'].setValueByNumericValue(Math.max(items['dLdmink'].numericValue, items['dLklim20k'].numericValue, items['dLkx1'].numericValue, items['dLkx2'].numericValue, items['dLkx3'].numericValue, items['dLkx4'].numericValue, items['dLkx5'].numericValue));
            items['dLmink'].setValueByNumericValue(Math.max(items['dLdmink'].numericValue, items['dLkx1'].numericValue, items['dLkx2'].numericValue, items['dLkx3'].numericValue, items['dLkx4'].numericValue, items['dLkx5'].numericValue));

            //     If vPodm1 = "XXX" Or vPodm2 = "XXX" Or vPodm3 = "XXX" Or vPodm4 = "XXX" Or vPodm5 = "XXX" Then
            //     vPodm = "XXX"
            // Elseif vPodm1 = "MIN" Or vPodm2 = "MIN" Or vPodm3 = "MIN" Or vPodm4 = "MIN" Or vPodm5 = "MIN" Then
            //     vPodm = "MIN"
            // Elseif vPodm1 = "LIM" Or vPodm2 = "LIM" Or vPodm3 = "LIM" Or vPodm4 = "LIM" Or vPodm5 = "LIM" Then
            //     vPodm = "LIM"
            // Else
            //     vPodm = "N"
            // End If

            if (vPodm1 === 'XXX' || vPodm2 === 'XXX' || vPodm3 === 'XXX' || vPodm4 === 'XXX' || vPodm5 === 'XXX') {
                vPodmA = 'XXX';
            }
            else if (vPodm1 === 'MIN' || vPodm2 === 'MIN' || vPodm3 === 'MIN' || vPodm4 === 'MIN' || vPodm5 === 'MIN') {
                vPodmA = 'MIN';
            }
            else if (vPodm1 === 'LIM' || vPodm2 === 'LIM' || vPodm3 === 'LIM' || vPodm4 === 'LIM' || vPodm5 === 'LIM') {
                vPodmA = 'LIM';
            }
            else {
                vPodmA = 'N';
            }

            //     If Lkm < dLmink Then
            //     vPodm = "XXX"
            // Elseif Lkm < dLlimk And (vPodm = "LIM" Or vPodm = "N") Then
            //     vPodm = "MIN"
            // Elseif Lkm < dLnk And vPodm = "N" Then
            //     vPodm = "LIM"
            // End If
            if (items['Lkm'].numericValue < items['dLmink'].numericValue) {
                vPodmA = 'XXX';
            }
            else if (items['Lkm'].numericValue < items['dLlimk'].numericValue && (vPodmA === 'LIM' || vPodmA === 'N')) {
                vPodmA = 'MIN';
            }
            else if (items['Lkm'].numericValue < items['dLnk'].numericValue && vPodmA === 'N') {
                vPodmA = 'LIM';
            }
            let lkmTooltip = 'Podmínky v ČSN:';
            if (vPodm5 !== '') {
                lkmTooltip += '\nD.2: ' + vPodm5;
            }
            if (vPodm4 !== '') {
                lkmTooltip += '\nD.5.1: ' + vPodm4;
            }
            if (vPodm3 !== '') {
                lkmTooltip += '\nD.5.1 d): ' + vPodm3;
            }
            if (vPodm1 !== '') {
                lkmTooltip += '\nD.5.3.2: ' + vPodm1;
            }
            if (vPodm2 !== '') {
                lkmTooltip += '\nD.5.3.3: ' + vPodm2;
            }
            items['LkmVk'].toolTip = lkmTooltip;


            // If dD > 0 Then
            //             nmk = Round(Lkm / dD * 1000 / Vk, 3)
            //             Range("CG" & lRadek).Value = nmk
            //         Else
            //             nmk = 0
            //             Range("CG" & lRadek).Value = vbNullString
            //         End If
            if (items['deltaD'].numericValue > 0) {
                items['nmk'].setValueByNumericValue(roundNumber(items['Lkm'].numericValue / items['deltaD'].numericValue * 1000 / items['Vk'].numericValue, 3));
            }
            else {
                items['nmk'].value = '';
            }


            //     Select Case vPodm
            //     Case Is = "XXX"
            //        BunkaFormat "CF" & lRadek, 99
            //     Case Is = "MIN"
            //        BunkaFormat "CF" & lRadek, 4
            //     Case Is = "LIM"
            //        BunkaFormat "CF" & lRadek, 3
            //     Case Else
            //    End Select
            //     Range("CF" & lRadek).Value = vPodm
            //     Range("CC" & lRadek).Value = Vk
            if (vPodmA === 'XXX') items['LkmVk'].okState = ObloukItemState.Error;
            else if (vPodmA === 'MIN') items['LkmVk'].okState = ObloukItemState.Oranzove;
            else if (vPodmA === 'LIM') items['LkmVk'].okState = ObloukItemState.Zlute;
            items['LkmVk'].value = vPodmA;
            items['Vkm'].setValueByNumericValue(items['Vk'].numericValue);


            if (vPodm2 === 'XXX' || vPodm5 === 'XXX') {
                vPodmB = 'XXX';
            }
            else if (vPodm2 === 'MIN' || vPodm5 === 'MIN') {
                vPodmB = 'MIN';
            }
            else if (vPodm2 === 'LIM' || vPodm5 === 'LIM') {
                vPodmB = 'LIM';
            }
            else {
                vPodmB = 'N';
            }

            //     Select Case vPodmB
            //     Case Is = "XXX"
            //        BunkaFormat "CE" & lRadek, 99
            //     Case Is = "MIN"
            //        BunkaFormat "CE" & lRadek, 4
            //     Case Is = "LIM"
            //        BunkaFormat "CE" & lRadek, 3
            //    End Select
            //    Range("CE" & lRadek).Value = vPodmB
            if (vPodmB === 'XXX') items['VkdeltaD'].okState = ObloukItemState.Error;
            else if (vPodmB === 'MIN') items['VkdeltaD'].okState = ObloukItemState.Oranzove;
            else if (vPodmB === 'LIM') items['VkdeltaD'].okState = ObloukItemState.Zlute;
            items['VkdeltaD'].value = vPodmB;

            //     Select Case nmk
            //     Case Is = 0
            //     Case Is < nmink
            //        BunkaFormat "CG" & lRadek, 99
            //     Case Is < nlimk
            //        BunkaFormat "CG" & lRadek, 4
            //     Case Is < nnk
            //        BunkaFormat "CG" & lRadek, 3
            //    End Select
            if (items['nmk'].value !== '') {
                if (items['nmk'].numericValue === 0 || items['nmk'].numericValue < items['nmink'].numericValue) {
                    items['nmk'].okState = ObloukItemState.Error;
                }
                else if (items['nmk'].numericValue < items['nlimk'].numericValue) {
                    items['nmk'].okState = ObloukItemState.Oranzove;
                }
                else if (items['nmk'].numericValue < items['nnk'].numericValue) {
                    items['nmk'].okState = ObloukItemState.Zlute;
                }
            }


        }
        else {
            items['Vkm'].okState = ObloukItemState.Error;
            items['Vkm'].value = 'RŮZNÉ';
        }
    }



}
function calculateMezilehleVytycovaci(itemsUp: { [key: string]: ObloukItem }, items: { [key: string]: ObloukItem }) {
    items['dRx'].setValueByNumericValue(items['R'].numericValue * itemsUp['R'].numericValue / Math.abs(items['R'].numericValue - itemsUp['R'].numericValue))


    items['dR1'].setValueByNumericValue(Math.max(items['R'].numericValue, itemsUp['R'].numericValue));
    items['dR1'].setValueByNumericValue(roundNumber(items['dR1'].numericValue, 3));

    items['dR2'].setValueByNumericValue(Math.min(items['R'].numericValue, itemsUp['R'].numericValue));
    items['dR2'].setValueByNumericValue(roundNumber(items['dR2'].numericValue, 3));

    items['dA'].setValueByNumericValue(Math.pow(items['Lkm'].numericValue * items['dRx'].numericValue, 0.5));
    items['dA'].setValueByNumericValue(roundNumber(items['dA'].numericValue, 3));

    items['dL1'].setValueByNumericValue(Math.pow(items['dA'].numericValue, 2) / items['dR1'].numericValue);
    items['dL1'].setValueByNumericValue(roundNumber(items['dL1'].numericValue, 3));

    items['dL2'].setValueByNumericValue(Math.pow(items['dA'].numericValue, 2) / items['dR2'].numericValue);
    items['dL2'].setValueByNumericValue(roundNumber(items['dL2'].numericValue, 3));

    items['dXs1'].setValueByNumericValue(items['dL1'].numericValue / 2 - Math.pow(items['dL1'].numericValue, 3) / (240 * Math.pow(items['dR1'].numericValue, 2)));


    items['dXs2'].setValueByNumericValue(items['dL2'].numericValue / 2 - Math.pow(items['dL2'].numericValue, 3) / (240 * Math.pow(items['dR2'].numericValue, 2)));
    // items['dXs2'].setValueByNumericValue(roundNumber(items['dXs2'].numericValue, 3));

    items['dYs1'].setValueByNumericValue(items['dR1'].numericValue + Math.pow(items['dL1'].numericValue, 2) / (24 * items['dR1'].numericValue) - Math.pow(items['dL1'].numericValue, 4) / (2688 * Math.pow(items['dR1'].numericValue, 3)));
    items['dYs1'].setValueByNumericValue(roundNumber(items['dYs1'].numericValue, 3));
    items['dYs2'].setValueByNumericValue(items['dR2'].numericValue + Math.pow(items['dL2'].numericValue, 2) / (24 * items['dR2'].numericValue) - Math.pow(items['dL2'].numericValue, 4) / (2688 * Math.pow(items['dR2'].numericValue, 3)));
    items['dYs2'].setValueByNumericValue(roundNumber(items['dYs2'].numericValue, 3));

    items['omega'].setValueByNumericValue(Math.atan((items['dXs2'].numericValue - items['dXs1'].numericValue) / (items['dYs1'].numericValue - items['dYs2'].numericValue)) * 200 / Math.PI);
    // items['omega'].setValueByNumericValue(roundNumber(items['omega'].numericValue, 3));
    // items['tau1'].setValueByNumericValue(items['dL1'].numericValue / (2 * items['dR1'].numericValue));

    // items['tau2'].setValueByNumericValue(items['dL2'].numericValue / (2 * items['dR2'].numericValue));
    // items['taum1'].setValueByNumericValue(items['omega'].numericValue - items['tau1'].numericValue);
    // items['taum2'].setValueByNumericValue(items['omega'].numericValue - items['tau2'].numericValue);
    // let tmp = items['dL2'].numericValue / 2 - Math.pow(items['dL2'].numericValue, 3) / (240 * Math.pow(items['dR2'].numericValue, 2)) - items['dL1'].numericValue / 2 - Math.pow(items['dL1'].numericValue, 3) / (240 * Math.pow(items['dR1'].numericValue, 2));
    // let tmp2 = roundNumber(tmp, 3);
    // let tmp3 = roundNumber(items['omega'].numericValue, 3);
    // let tmp4 = roundNumber(tmp3 * Math.PI / 200, 3);
    items['ddn'].setValueByNumericValue((items['dXs2'].numericValue - items['dXs1'].numericValue) / Math.sin(items['omega'].numericValue * Math.PI / 200));


    //strašně divný
    items['ddn'].setValueByNumericValue(truncateNumber(items['ddn'].numericValue, 3));
    items['omega'].setValueByNumericValue(roundNumber(items['omega'].numericValue, 3));
    items['dXs2'].setValueByNumericValue(roundNumber(items['dXs2'].numericValue, 3));

    items['dXs1'].setValueByNumericValue(roundNumber(items['dXs1'].numericValue, 3));
    items['dRx'].setValueByNumericValue(roundNumber(items['dRx'].numericValue, 3));


    items['dmm'].setValueByNumericValue(items['dR1'].numericValue - items['dR2'].numericValue - items['ddn'].numericValue);
    items['dmm'].setValueByNumericValue(roundNumber(items['dmm'].numericValue, 3));

    // omega = Atn((dXs2 - dXs1) / (dYs1 - dYs2)) * 200 / WorksheetFunction.Pi
    // tau1 = dL1 / (2 * dR1)
    // tau2 = dL2 / (2 * dR2)
    // taum1 = omega - tau1
    // taum2 = omega - tau2
    // ddn = (dXs2 - dXs1) / Sin(omega * WorksheetFunction.Pi / 200)
    // dmm = dR1 - dR2 - ddn
}
function calculateTecnaZaobleni(itemsUp: { [key: string]: ObloukItem }, items: { [key: string]: ObloukItem }) {
    if (items['Lkm'].numericValue > 0 && items['deltaD'].numericValue !== 0) {
        let RvLN = 0.5 * Math.pow(Math.max(items['V'].numericValue, items['V130'].numericValue, items['V150'].numericValue, items['Vk'].numericValue), 2);
        RvLN = 100 * Math.ceil(RvLN / 100);
        let sklonM = items['deltaD'].numericValue / items['Lkm'].numericValue;
        let tzm = 0;
        if (Math.max(items['V'].numericValue, items['V130'].numericValue, items['V150'].numericValue, items['Vk'].numericValue) > 200) {
            tzm = 20;
        } else {
            tzm = RvLN / 2 * sklonM / 1000;
        }
        items['tzm'].setValueByNumericValue(roundNumber(tzm, 3));
        // RvLN = 0.5 * WorksheetFunction.Max(V, V130, V150, Vk) ^ 2
        // 'MsgBox CStr(RvLN)
        // RvLN = 100 * WorksheetFunction.RoundUp(RvLN / 100, 0)
        // 'MsgBox CStr(RvLN)
        // 'MsgBox Lk1
        // sklonM = dD / Lkm
        // If WorksheetFunction.Max(V, V130, V150, Vk) > 200 Then
        //     tzm = 20
        // Else
        //     tzm = RvLN / 2 * sklonM / 1000
        // End If
        // Range("CW" & lRadek).Value = WorksheetFunction.Round(tzm, 3)
    }
}
function calculateMezilehleVytycovaciNotRound(itemsUp: { [key: string]: ObloukItem }, items: { [key: string]: ObloukItem }) {
    items['dRx'].setValueByNumericValue(items['R'].numericValue * itemsUp['R'].numericValue / Math.abs(items['R'].numericValue - itemsUp['R'].numericValue))
    items['dR1'].setValueByNumericValue(Math.max(items['R'].numericValue, itemsUp['R'].numericValue));
    items['dR2'].setValueByNumericValue(Math.min(items['R'].numericValue, itemsUp['R'].numericValue));
    items['dA'].setValueByNumericValue(Math.pow(items['Lkm'].numericValue * items['dRx'].numericValue, 0.5));
    items['dL1'].setValueByNumericValue(Math.pow(items['dA'].numericValue, 2) / items['dR1'].numericValue);
    items['dL2'].setValueByNumericValue(Math.pow(items['dA'].numericValue, 2) / items['dR2'].numericValue);
    items['dXs1'].setValueByNumericValue(items['dL1'].numericValue / 2 - Math.pow(items['dL1'].numericValue, 3) / (240 * Math.pow(items['dR1'].numericValue, 2)));
    items['dXs2'].setValueByNumericValue(items['dL2'].numericValue / 2 - Math.pow(items['dL2'].numericValue, 3) / (240 * Math.pow(items['dR2'].numericValue, 2)));


    items['dYs1'].setValueByNumericValue(items['dR1'].numericValue + Math.pow(items['dL1'].numericValue, 2) / (24 * items['dR1'].numericValue) - Math.pow(items['dL1'].numericValue, 4) / (2688 * Math.pow(items['dR1'].numericValue, 3)));
    items['dYs2'].setValueByNumericValue(items['dR2'].numericValue + Math.pow(items['dL2'].numericValue, 2) / (24 * items['dR2'].numericValue) - Math.pow(items['dL2'].numericValue, 4) / (2688 * Math.pow(items['dR2'].numericValue, 3)));

    items['omega'].setValueByNumericValue(Math.atan((items['dXs2'].numericValue - items['dXs1'].numericValue) / (items['dYs1'].numericValue - items['dYs2'].numericValue)) * 200 / Math.PI);
    // items['tau1'].setValueByNumericValue(items['dL1'].numericValue / (2 * items['dR1'].numericValue));
    // items['tau2'].setValueByNumericValue(items['dL2'].numericValue / (2 * items['dR2'].numericValue));
    // items['taum1'].setValueByNumericValue(items['omega'].numericValue - items['tau1'].numericValue);
    // items['taum2'].setValueByNumericValue(items['omega'].numericValue - items['tau2'].numericValue);
    items['ddn'].setValueByNumericValue((items['dXs2'].numericValue - items['dXs1'].numericValue) / Math.sin(items['omega'].numericValue * Math.PI / 200));
    items['dmm'].setValueByNumericValue(items['dR1'].numericValue - items['dR2'].numericValue - items['ddn'].numericValue);

}