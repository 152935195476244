import { Checkbox, Dialog, DialogContent, FormControlLabel, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import MuiDialogHeader from '../Shared/MuiDialogHeader'
import { cantOpenDetail } from './Stores/LocalStorageKeys';
import { useSettingsStore } from './Stores/SettingsStore';
interface CurveSolverBaseSettingsDialogProps {
    onClose: () => void;
    isOpen: boolean;
}

export default function CurveSolverBaseSettingsDialog(props: CurveSolverBaseSettingsDialogProps) {
    const { initSettings, cantShowDetail, showHideDetail } = useSettingsStore();
    // const [showDetail, setShowDetail] = React.useState<boolean>(false);
    useEffect(() => {
        initSettings();
    }, []);
    // const showDetailLocalStorageManipulate = (checked: boolean) => {
    //     if (checked) {
    //         localStorage.removeItem(cantOpenDetail);
    //     }
    //     else {
    //         localStorage.setItem('cantOpenDetail', 'true');
    //     }
    // }
    return (
        <Dialog maxWidth='lg' PaperProps={{ sx: { background:'white',minHeight: '400px', height: '70%', minWidth: '520px' } }} onClose={props.onClose} open={props.isOpen}>
            <MuiDialogHeader title='Nastavení' onClose={props.onClose} />
            <DialogContent  style={{}}>
                <div>
                    <FormControlLabel
                        value="end"
                        sx={{ marginLeft: '-12x', marginTop: '16px' }}
                        control={<Checkbox checked={!cantShowDetail} onChange={(event, checked) => { if (showHideDetail) showHideDetail(checked); }} />}
                        label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazovat detail</Typography>}
                        labelPlacement="end"
                    />
                </div>
            </DialogContent>
        </Dialog>
    )
}
