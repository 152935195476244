import { createTheme, Theme, ThemeOptions } from "@mui/material";
const themeOptions: ThemeOptions = {
    palette: {
        mode: 'light',
        primary: {
            main: '#0086CA',
            light: '#0BD1E0',
            dark: '##0B27D6',
        },
        warning:{
            main: '#FFB905',
        },
        secondary: {
            main: '#404040',
        },
        background: {
            default: '#F5F5F5',
        },
        text: {
            primary: '#404040',
            secondary: '#777777',
        },
        error: {
            main: '#DD403A',
        },
        success: {
            main: '#85CB33',
        },
        divider: '#E6E6E6',
    },
    typography: {
        fontFamily: 'Mulish',
        h1: {
            fontFamily: 'Mulish',
        },
        h2: {
            fontFamily: 'Mulish',
        },
        h3: {
            fontFamily: 'Mulish',
        },
        h4: {
            fontFamily: 'Mulish',
        },
        h5: {
            fontFamily: 'Mulish',
        },
        h6: {
            fontFamily: 'Mulish',
        },
    }, 
    shape: {
        borderRadius: 4,
    },
    components: {
        MuiButton: {
            styleOverrides: {
            root: {
                fontWeight: 'bold', // Set the font weight to 'bold' for all buttons
            },
            },
        },
    },
};
export default function CreateValidatorTheme(): Theme {
    return createTheme(themeOptions)
}