import { Close } from '@mui/icons-material';
import { Button, Dialog, DialogContent, DialogTitle, FormControl, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { ApiDataClient } from '../../Api/ApiDataClient';

interface SaveProjectDialogProps {
    open: boolean;
    onClose: (save: boolean) => void;
}
export default function SaveProjectDialog(props: SaveProjectDialogProps) {
    const { open, onClose } = props;

    const [buttonDisabled, setButtonDisabled] = React.useState<boolean>(false);

    useEffect(() => {
    }, []);

    return (
        <Dialog
            open={open}
            onClose={() => { onClose(false) }}
            aria-labelledby="save-dialog-title"
            aria-describedby="save-dialog-description"
        >
            <DialogTitle id="save-project-dialog-title" sx={{ background: '#F5F5F5'}}>
                <div  >
                    <div style={{ marginRight: '20px', minWidth: '200px' }}>Založení projektu</div>
                    <IconButton sx={{ width: '30px', height: '30px', right: '20px', top: '18px', float: 'right', position: 'absolute' }}
                        onClick={() => { onClose(false); }}><Close /></IconButton>
                </div>
            </DialogTitle>
            <DialogContent>
                    <div>
                        <Typography variant='h5' fontWeight='bold' >Data změněna. Chcete změny uložit?</Typography>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
                            <Button variant='contained' sx={{ margin: 'auto' }} disabled={buttonDisabled} onClick={() => {
                                onClose(true);
                            }}>ANO</Button>
                            <Button variant='contained' sx={{ margin: 'auto' }} disabled={buttonDisabled} onClick={() => {
                                onClose(false);
                            }}>NE</Button>
                        </div>
                    </div>
            </DialogContent>
        </Dialog>
    )
}
