import { create } from "zustand";

interface PluginStoreState {
    fromPlugin?: boolean;
    // load: (perms: DataStandardDTO[]) => void
    // initValidator: () => void;
    setPlugin: (fromPlugin: boolean) => void;
}


export const usePluginStore = create<PluginStoreState>()((set) => ({
    fromPlugin: false,
    setPlugin: (from: boolean) => {
        set((state) => ({ fromPlugin: from }))
    }
    // validator: ValidatorsEnum.None,
    // // load: (by) => set((state) => ({ standardsData: by })),
    // initValidator: () => {
    //     let cant = localStorage.getItem(selectedValidator);
    //     if (cant !== null)
    //         set((state) => ({ validator: parseInt(cant!) }));

    // },
    // setValidator: (vali: ValidatorsEnum) => {
    //     localStorage.setItem(selectedValidator, vali.toString());
    //     set((state) => ({ validator: vali }));
    // }
}))