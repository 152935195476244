import axios from 'axios';



const blockSize = 4 * 1024 * 1024;
export const handleBlobFileUpload = async (event: any, sasUrl: string) => {
    const file = event.target.files[0];
    // 4MB block size (adjust as needed)
    const totalBlocks = Math.ceil(file.size / blockSize);
    const blockIds: string[] = [];

    for (let i = 0; i < totalBlocks; i++) {
        // if (totalBlocks <= 10) {
        //     const blockId = btoa(`${i}`);
        //     blockIds.push(blockId);
        // }
        // else {
            const blockId = btoa(`${i + 10000}`);
            blockIds.push(blockId);
        // }
    }

    // const sasUrl =
    //   "https://testvideos.blob.core.windows.net/testvideos/user_125/video_125/test.mp4?se=2023-05-14T11%3A02%3A59Z&sp=rc&sv=2022-11-02&sr=b&sig=7o8tNK508ekXy9JpahWBsfdfsfPjdtjWwN6etNk%3D";

    try {
        await uploadBlocks(file, sasUrl, blockIds);
        return await commitBlockList(sasUrl, blockIds);
        // console.log("File upload completed successfully.");
    } catch (error: any) {
        console.error("File upload failed:", error);

        // Handle the specific error when the request is unauthorized
        if (error.message === "Failed to commit block list") {
            console.error("Failed to commit block list. Please make sure the SAS URL is properly authorized and the value of the Authorization header is formed correctly, including the signature.");
            throw (error);
            // Handle the error appropriately (e.g., display an error message to the user)
        } else {
            console.error("Unexpected error:", error);
            throw (error);
        }
    }
};






const uploadBlocks = async (file, sasUrl, blockIds) => {
    // const blockSize = 1 * 1024 * 1024; // 4MB block size (adjust as needed)

    const totalBlocks = Math.ceil(file.size / blockSize);
    const promises: Promise<any>[] = [];

    console.log("Uploading blocks...");

    for (let i = 0; i < totalBlocks; i++) {
        const blockId = blockIds[i];
        console.log(blockId);
        const start = i * blockSize;
        const end = Math.min(start + blockSize, file.size);
        const blockContent = file.slice(start, end);

        const promise = axios.put(`${sasUrl}&comp=block&blockid=${blockId}`, blockContent, {
            headers: {
                "Content-Type": file.type,
                "x-ms-blob-type": "BlockBlob",
                // "Content-Range": `bytes ${start}-${end - 1}/${file.size}`,
            },
        });

        promises.push(promise);
    }
    for (let prom of promises)
        try {
            await Promise.resolve(prom);
        }
        catch (e) {
            console.log(e);
        }
    // await Promise.all(promises);

    console.log("Blocks uploaded successfully.");
};

const commitBlockList = async (sasUrl, blockIds) => {
    const xmlPayload = `
      <BlockList>
        ${blockIds.map((blockId) => `<Latest>${blockId}</Latest>`).join("\n")}
      </BlockList>
    `;

    console.log("Committing block list...");

    const response = await axios.put(`${sasUrl}&comp=blocklist`, xmlPayload, {
        headers: {
            "Content-Type": "application/xml",
            //   "x-ms-blob-type": "BlockBlob",
        },
    });

    if (response.status === 201) {
        console.log("Block list committed successfully.", response);
        return response.config.url as string;

    } else {
        throw new Error("Failed to commit block list");
    }

};
