import { ObloukItemState } from "../Enums/ObloukItemState";
import { ObloukItem } from "../Models/ObloukItem";

export function calculateSpeeds(items: { [key: string]: ObloukItem }): void {
    if (items['V'].numericValue > 0) {
        calculateSpeedStartup(items);
        if (items['V130'].numericValue > 0) calculateV130(items);
        if (items['V150'].numericValue > 0) calculateV150(items);
        if (items['Vk'].numericValue > 0) calculateVk(items);
        if (items['Lk1'].numericValue > 0) calculateLk1(items);
        if (items['Lk2'].numericValue > 0) calculateLk2(items);
    }
}
function calculateSpeedStartup(items: { [key: string]: ObloukItem }): void {
    if (items['V'].numericValue <= 200) items['nn'].setValueByNumericValue(10);
    else items['nn'].setValueByNumericValue(7)

    if (items['V'].numericValue <= 80) items['nlim'].setValueByNumericValue(6);
    else if (items['V'].numericValue <= 120) items['nlim'].setValueByNumericValue(7);
    else if (items['V'].numericValue <= 200) items['nlim'].setValueByNumericValue(8);
    else items['nlim'].setValueByNumericValue(6);
    items['nlim'].setValueByNumericValue(Math.max(500 / items['V'].numericValue, items['nlim'].numericValue));

    if (items['V'].numericValue <= 120) items['nmin'].setValueByNumericValue(6);
    else if (items['V'].numericValue <= 200) items['nmin'].setValueByNumericValue(7);
    else items['nmin'].setValueByNumericValue(5);
    items['nmin'].setValueByNumericValue(Math.max(400 / items['V'].numericValue, items['nmin'].numericValue));

    items['nIn'].setValueByNumericValue(10);

    if (items['V'].numericValue <= 160) items['nIlim'].setValueByNumericValue(4);
    else if (items['V'].numericValue <= 200) items['nIlim'].setValueByNumericValue(8);
    else items['nIlim'].setValueByNumericValue(9);

    if (items['V'].numericValue <= 160) items['nImin'].setValueByNumericValue(4);
    else items['nImin'].setValueByNumericValue(6);

    items['Ldn'].setValueByNumericValue(items['nn'].numericValue * items['V'].numericValue * items['D'].numericValue / 1000);


    items['Ldlim'].setValueByNumericValue(Math.max(500, items['nlim'].numericValue * items['V'].numericValue) * items['D'].numericValue / 1000);
    items['Ldmin'].setValueByNumericValue(Math.max(400, items['nmin'].numericValue * items['V'].numericValue) * items['D'].numericValue / 1000);

    if (items['I'].numericValue > 0) {
        items['Lkn'].setValueByNumericValue(items['nIn'].numericValue * items['V'].numericValue * items['I'].numericValue / 1000);
        items['Lklim'].setValueByNumericValue(items['nIlim'].numericValue * items['V'].numericValue * items['I'].numericValue / 1000);
        items['Lkmin'].setValueByNumericValue(items['nImin'].numericValue * items['V'].numericValue * items['I'].numericValue / 1000);
    }

    if (items['D'].numericValue > 0) {
        items['Lklim07R'].setValueByNumericValue(0.7 * Math.sqrt(items['R'].numericValue));

    }
    if (items['V'].numericValue >= 60) {
        items['Lklim20'].setValueByNumericValue(20);
    }
    else if (items['V'].numericValue >= 200) {
        items['Lklim20'].setValueByNumericValue(60);
    }

    items['Ln'].setValueByNumericValue(Math.max(items['Ldn'].numericValue, items['Lkn'].numericValue, items['Lklim07R'].numericValue, items['Lklim20'].numericValue));
    items['Llim'].setValueByNumericValue(Math.max(items['Ldlim'].numericValue, items['Lklim'].numericValue, items['Lklim07R'].numericValue, items['Lklim20'].numericValue));
    items['Lmin'].setValueByNumericValue(Math.max(items['Ldmin'].numericValue, items['Lkmin'].numericValue));
}
function calculateV130(items: { [key: string]: ObloukItem }): void {


    if (items['V130'].numericValue <= 200) items['nn130'].setValueByNumericValue(10);
    else items['nn130'].setValueByNumericValue(7);

    if (items['V130'].numericValue <= 80) items['nlim130'].setValueByNumericValue(6);
    else if (items['V130'].numericValue <= 120) items['nlim130'].setValueByNumericValue(7);
    else if (items['V130'].numericValue <= 200) items['nlim130'].setValueByNumericValue(8);
    else items['nlim130'].setValueByNumericValue(6);

    items['nlim130'].setValueByNumericValue(Math.max(500 / items['V130'].numericValue, items['nlim130'].numericValue));

    if (items['V130'].numericValue <= 120) items['nmin130'].setValueByNumericValue(6);
    else if (items['V130'].numericValue <= 200) items['nmin130'].setValueByNumericValue(7);
    else items['nmin130'].setValueByNumericValue(5);

    items['nmin130'].setValueByNumericValue(Math.max(400 / items['V130'].numericValue, items['nmin130'].numericValue));
    items['nIn130'].setValueByNumericValue(10);

    if (items['V130'].numericValue <= 160) items['nIlim130'].setValueByNumericValue(4);
    else if (items['V130'].numericValue <= 200) items['nIlim130'].setValueByNumericValue(8);
    else items['nIlim130'].setValueByNumericValue(9);


    if (items['V130'].numericValue <= 160) items['nImin130'].setValueByNumericValue(4);
    else items['nImin130'].setValueByNumericValue(6);

    items['Ldn130'].setValueByNumericValue(items['nn130'].numericValue * items['V130'].numericValue * items['D'].numericValue / 1000);

    items['Ldlim130'].setValueByNumericValue(Math.max(500, items['nlim130'].numericValue * items['V130'].numericValue) * items['D'].numericValue / 1000);
    items['Ldmin130'].setValueByNumericValue(Math.max(400, items['nmin130'].numericValue * items['V130'].numericValue) * items['D'].numericValue / 1000);


    if (items['I130'].numericValue > 0) {
        items['Lkn130'].setValueByNumericValue(items['nIn130'].numericValue * items['V130'].numericValue * items['I130'].numericValue / 1000);
        items['Lklim130'].setValueByNumericValue(items['nIlim130'].numericValue * items['V130'].numericValue * items['I130'].numericValue / 1000);
        items['Lkmin130'].setValueByNumericValue(items['nImin130'].numericValue * items['V130'].numericValue * items['I130'].numericValue / 1000);
    }

    if (items['V130'].numericValue >= 60) {
        items['Lklim20130'].setValueByNumericValue(20);
    }
    else if (items['V130'].numericValue >= 200) {
        items['Lklim20130'].setValueByNumericValue(60);
    }



    items['Ln130'].setValueByNumericValue(Math.max(items['Ldn130'].numericValue, items['Lkn130'].numericValue, items['Lklim20130'].numericValue));
    items['Llim130'].setValueByNumericValue(Math.max(items['Ldlim130'].numericValue, items['Lklim130'].numericValue, items['Lklim20130'].numericValue));
    items['Lmin130'].setValueByNumericValue(Math.max(items['Ldmin130'].numericValue, items['Lkmin130'].numericValue));
}

function calculateV150(items: { [key: string]: ObloukItem }): void {

    if (items['V150'].numericValue <= 200) items['nn150'].setValueByNumericValue(10);
    else items['nn150'].setValueByNumericValue(7);

    if (items['V150'].numericValue <= 80) items['nlim150'].setValueByNumericValue(6);
    else if (items['V150'].numericValue <= 120) items['nlim150'].setValueByNumericValue(7);
    else if (items['V150'].numericValue <= 200) items['nlim150'].setValueByNumericValue(8);
    else items['nlim150'].setValueByNumericValue(6);

    items['nlim150'].setValueByNumericValue(Math.max(500 / items['V150'].numericValue, items['nlim150'].numericValue));

    if (items['V150'].numericValue <= 120) items['nmin150'].setValueByNumericValue(6);
    else if (items['V150'].numericValue <= 200) items['nmin150'].setValueByNumericValue(7);
    else items['nmin150'].setValueByNumericValue(5);

    items['nmin150'].setValueByNumericValue(Math.max(400 / items['V150'].numericValue, items['nmin150'].numericValue));

    items['nIn150'].setValueByNumericValue(10);

    if (items['V150'].numericValue <= 160) items['nIlim150'].setValueByNumericValue(4);
    else if (items['V150'].numericValue <= 200) items['nIlim150'].setValueByNumericValue(8);
    else items['nIlim150'].setValueByNumericValue(9);

    if (items['V150'].numericValue <= 160) items['nImin150'].setValueByNumericValue(4);
    else items['nImin150'].setValueByNumericValue(6);

    items['Ldn150'].setValueByNumericValue(items['nn150'].numericValue * items['V150'].numericValue * items['D'].numericValue / 1000);

    items['Ldlim150'].setValueByNumericValue(Math.max(500, items['nlim150'].numericValue * items['V150'].numericValue) * items['D'].numericValue / 1000);
    items['Ldmin150'].setValueByNumericValue(Math.max(400, items['nmin150'].numericValue * items['V150'].numericValue) * items['D'].numericValue / 1000);


    if (items['I150'].numericValue > 0) {
        items['Lkn150'].setValueByNumericValue(items['nIn150'].numericValue * items['V150'].numericValue * items['I150'].numericValue / 1000);
        items['Lklim150'].setValueByNumericValue(items['nIlim150'].numericValue * items['V150'].numericValue * items['I150'].numericValue / 1000);
        items['Lkmin150'].setValueByNumericValue(items['nImin150'].numericValue * items['V150'].numericValue * items['I150'].numericValue / 1000);
    }

    if (items['V150'].numericValue >= 60) {
        items['Lklim20150'].setValueByNumericValue(20);
    }
    else if (items['V150'].numericValue >= 200) {
        items['Lklim20150'].setValueByNumericValue(60);
    }

    items['Ln150'].setValueByNumericValue(Math.max(items['Ldn150'].numericValue, items['Lkn150'].numericValue, items['Lklim20150'].numericValue));
    items['Llim150'].setValueByNumericValue(Math.max(items['Ldlim150'].numericValue, items['Lklim150'].numericValue, items['Lklim20150'].numericValue));
    //tohle je opravdu dobře?
    items['Lmin150'].setValueByNumericValue(Math.max(items['Ldmin150'].numericValue, items['Lkmin150'].numericValue));
}

function calculateVk(items: { [key: string]: ObloukItem }): void {
    items['nnk'].setValueByNumericValue(8);
    items['nmink'].setValueByNumericValue(6);
    items['Ldnk'].setValueByNumericValue(items['nnk'].numericValue * items['Vk'].numericValue * items['D'].numericValue / 1000);

    //nestačil by jen jeden řádek - ten druhý?
    //     Ldmink = nmink * Vk * D / 1000

    //     Ldmink = WorksheetFunction.Max(400, nmink * Vk) * D / 1000

    items['Ldmink'].setValueByNumericValue(items['nmink'].numericValue * items['Vk'].numericValue * items['D'].numericValue / 1000);
    items['Ldmink'].setValueByNumericValue(Math.max(400, items['nmink'].numericValue * items['Vk'].numericValue) * items['D'].numericValue / 1000);


    items['Lklim20k'].setValueByNumericValue(20);
    if (items['Vk'].numericValue > 200) items['Lklim20k'].setValueByNumericValue(60);

    items['LkI1'].setValueByNumericValue(0);
    items['LkI2'].setValueByNumericValue(0);


    if (items['Ik'].numericValue < (3 * items['D'].numericValue)) {
        if (items['D'].numericValue < 112) {
            items['LkI1'].setValueByNumericValue(2.2 * items['Vk'].numericValue * items['Ik'].numericValue * items['D'].numericValue / (1000 * (items['D'].numericValue - 13)));
        }
    }
    else {
        if (items['D'].numericValue < 90) {
            items['LkI2'].setValueByNumericValue(3.2 * items['Vk'].numericValue * items['Ik'].numericValue / 1000);
        }
    }


    items['Lnk'].setValueByNumericValue(Math.max(items['Ldnk'].numericValue, items['Lklim20k'].numericValue, items['LkI1'].numericValue, items['LkI2'].numericValue));
    items['Llimk'].setValueByNumericValue(Math.max(items['Ldmink'].numericValue, items['Lklim20k'].numericValue, items['LkI1'].numericValue, items['LkI2'].numericValue));
    items['Lmink'].setValueByNumericValue(Math.max(items['Ldmink'].numericValue, items['LkI1'].numericValue, items['LkI2'].numericValue));
}
function calculateLk1(items: { [key: string]: ObloukItem }): void {
    if (items['V'].numericValue > 0) calculatePrechodniceVzestupniceVLk1(items);
    if (items['V130'].numericValue > 0) calculatePrechodniceVzestupniceV130Lk1(items);
    if (items['V150'].numericValue > 0) calculatePrechodniceVzestupniceV150Lk1(items);
    if (items['Vk'].numericValue > 0 && items['D'].numericValue > 0) calculatePrechodniceVzestupniceVkLk1(items);
}
function calculateLk2(items: { [key: string]: ObloukItem }): void {
    if (items['V'].numericValue > 0) calculatePrechodniceVzestupniceVLk2(items);
    if (items['V130'].numericValue > 0) calculatePrechodniceVzestupniceV130Lk2(items);
    if (items['V150'].numericValue > 0) calculatePrechodniceVzestupniceV150Lk2(items);
    if (items['Vk'].numericValue > 0) calculatePrechodniceVzestupniceVkLk2(items);

}


function calculatePrechodniceVzestupniceVLk1(items: { [key: string]: ObloukItem }) {
    if (items['D'].numericValue > 0) items['n1'].setValueByNumericValue(Number((items['Lk1'].numericValue / items['D'].numericValue * 1000 / items['V'].numericValue).toFixed(3)));
    else items['n1'].value = '';

    if (items['I'].numericValue > 0) items['nI1'].setValueByNumericValue(Number((items['Lk1'].numericValue / items['I'].numericValue * 1000 / items['V'].numericValue).toFixed(3)));
    else items['nI1'].value = '';

    if (items['Lk1'].numericValue < items['Lmin'].numericValue) items['Lk1'].okState = ObloukItemState.Error;
    else if (items['Lk1'].numericValue < items['Llim'].numericValue) items['Lk1'].okState = ObloukItemState.Oranzove;
    else if (items['Lk1'].numericValue < items['Ln'].numericValue) items['Lk1'].okState = ObloukItemState.Zlute;
    else items['Lk1'].okState = ObloukItemState.OkInput; //možná spíš našeptávač




    if (items['n1'].numericValue === 0 || items['n1'].numericValue < items['nmin'].numericValue)
        items['n1'].okState = ObloukItemState.Error;
    else if (items['n1'].numericValue < items['nlim'].numericValue)
        items['n1'].okState = ObloukItemState.Oranzove;
    else if (items['n1'].numericValue < items['nn'].numericValue)
        items['n1'].okState = ObloukItemState.Zlute;
    else
        items['n1'].okState = ObloukItemState.OkReadOnly;


    //rewrite above to typescript
    if (items['nI1'].value !== '') {
        if (items['nI1'].numericValue < items['nImin'].numericValue)
            items['nI1'].okState = ObloukItemState.Error;
        else if (items['nI1'].numericValue < items['nIlim'].numericValue)
            items['nI1'].okState = ObloukItemState.Oranzove;
        else if (items['nI1'].numericValue < items['nIn'].numericValue)
            items['nI1'].okState = ObloukItemState.Zlute;
        else
            items['nI1'].okState = ObloukItemState.OkReadOnly;
    }
}
function calculatePrechodniceVzestupniceV130Lk1(items: { [key: string]: ObloukItem }): void {
    //     If V > 0 Then
    //     If D > 0 Then
    if (items['D'].numericValue > 0) items['n1130'].setValueByNumericValue(Number((items['Lk1'].numericValue / items['D'].numericValue * 1000 / items['V130'].numericValue).toFixed(3)));
    else items['n1130'].value = '';

    if (items['I130'].numericValue > 0) items['nI1130'].setValueByNumericValue(Number((items['Lk1'].numericValue / items['I130'].numericValue * 1000 / items['V130'].numericValue).toFixed(3)));
    else items['nI1130'].value = '';
    //tohle je dobře??
    if (items['Lk1'].numericValue < items['Lmin130'].numericValue) {
        items['Lk1130'].okState = ObloukItemState.Error;
        items['Lk1130'].value = 'XXX';
    }
    else if (items['Lk1'].numericValue < items['Llim130'].numericValue) {
        items['Lk1130'].okState = ObloukItemState.Oranzove;
        items['Lk1130'].value = 'MIN';
    }
    else if (items['Lk1'].numericValue < items['Ln130'].numericValue) {
        items['Lk1130'].okState = ObloukItemState.Zlute;
        items['Lk1130'].value = 'LIM';
    }
    else {
        items['Lk1130'].okState = ObloukItemState.OkReadOnly; //možná spíš našeptávač
        items['Lk1130'].value = 'N';
    }




    if (items['n1130'].numericValue === 0 || items['n1130'].numericValue < items['nmin130'].numericValue)
        items['n1130'].okState = ObloukItemState.Error;
    else if (items['n1130'].numericValue < items['nlim130'].numericValue)
        items['n1130'].okState = ObloukItemState.Oranzove;
    else if (items['n1130'].numericValue < items['nn130'].numericValue)
        items['n1130'].okState = ObloukItemState.Zlute;
    else
        items['n1130'].okState = ObloukItemState.OkReadOnly;


    //rewrite above to typescript 
    if (items['nI1130'].numericValue < items['nImin130'].numericValue)
        items['nI1130'].okState = ObloukItemState.Error;
    else if (items['nI1130'].numericValue < items['nIlim130'].numericValue)
        items['nI1130'].okState = ObloukItemState.Oranzove;
    else if (items['nI1130'].numericValue < items['nIn130'].numericValue)
        items['nI1130'].okState = ObloukItemState.Zlute;
    else
        items['nI1130'].okState = ObloukItemState.OkReadOnly;
}
function calculatePrechodniceVzestupniceV150Lk1(items: { [key: string]: ObloukItem }) {
    if (items['D'].numericValue > 0) items['n1150'].setValueByNumericValue(Number((items['Lk1'].numericValue / items['D'].numericValue * 1000 / items['V150'].numericValue).toFixed(3)));
    else items['n1'].value = '';

    if (items['I150'].numericValue > 0) items['nI1150'].setValueByNumericValue(Number((items['Lk1'].numericValue / items['I150'].numericValue * 1000 / items['V150'].numericValue).toFixed(3)));
    else items['nI1150'].value = '';
    //tohle je dobře??
    if (items['Lk1'].numericValue < items['Lmin150'].numericValue) {
        items['Lk1150'].okState = ObloukItemState.Error;
        items['Lk1150'].value = 'XXX';
    }
    else if (items['Lk1'].numericValue < items['Llim150'].numericValue) {
        items['Lk1150'].okState = ObloukItemState.Oranzove;
        items['Lk1150'].value = 'MIN';
    }
    else if (items['Lk1'].numericValue < items['Ln150'].numericValue) {
        items['Lk1150'].okState = ObloukItemState.Zlute;
        items['Lk1150'].value = 'LIM';
    }
    else {
        items['Lk1150'].okState = ObloukItemState.OkReadOnly; //možná spíš našeptávač
        items['Lk1150'].value = 'N';
    }




    if (items['n1150'].numericValue === 0 || items['n1150'].numericValue < items['nmin150'].numericValue)
        items['n1150'].okState = ObloukItemState.Error;
    else if (items['n1150'].numericValue < items['nlim150'].numericValue)
        items['n1150'].okState = ObloukItemState.Oranzove;
    else if (items['n1150'].numericValue < items['nn150'].numericValue)
        items['n1150'].okState = ObloukItemState.Zlute;
    else
        items['n1150'].okState = ObloukItemState.OkReadOnly;


    //rewrite above to typescript 
    if (items['nI1150'].numericValue < items['nImin150'].numericValue)
        items['nI1150'].okState = ObloukItemState.Error;
    else if (items['nI1150'].numericValue < items['nIlim150'].numericValue)
        items['nI1150'].okState = ObloukItemState.Oranzove;
    else if (items['nI1150'].numericValue < items['nIn150'].numericValue)
        items['nI1150'].okState = ObloukItemState.Zlute;
    else
        items['nI1150'].okState = ObloukItemState.OkReadOnly;
}
function calculatePrechodniceVzestupniceVkLk1(items: { [key: string]: ObloukItem }) {
    items['n1k'].setValueByNumericValue(Number((items['Lk1'].numericValue / items['D'].numericValue * 1000 / items['Vk'].numericValue).toFixed(3)));
    if (items['Lk1'].numericValue < items['Lmink'].numericValue) {
        items['Lk1k'].okState = ObloukItemState.Error;
        items['Lk1k'].value = 'XXX';
    }
    else if (items['Lk1'].numericValue < items['Llimk'].numericValue) {
        items['Lk1k'].okState = ObloukItemState.Oranzove;
        items['Lk1k'].value = 'MIN';
    }
    else if (items['Lk1'].numericValue < items['Lnk'].numericValue) {
        items['Lk1k'].okState = ObloukItemState.Zlute;
        items['Lk1k'].value = 'LIM';
    }
    else items['Lk1k'].value = 'N';

    if (items['n1k'].numericValue < items['nmink'].numericValue) items['n1k'].okState = ObloukItemState.Error;
    else if (items['n1k'].numericValue < items['nlimk'].numericValue) items['n1k'].okState = ObloukItemState.Oranzove;
    else if (items['n1k'].numericValue < items['nnk'].numericValue) items['n1k'].okState = ObloukItemState.Zlute;

}
function calculatePrechodniceVzestupniceVLk2(items: { [key: string]: ObloukItem }): void {
    if (items['D'].numericValue > 0) items['n2'].setValueByNumericValue(Number((items['Lk2'].numericValue / items['D'].numericValue * 1000 / items['V'].numericValue).toFixed(3)));
    else items['n2'].value = '';

    if (items['I'].numericValue > 0) items['nI2'].setValueByNumericValue(Number((items['Lk2'].numericValue / items['I'].numericValue * 1000 / items['V'].numericValue).toFixed(3)));
    else items['nI2'].value = '';

    if (items['Lk2'].numericValue < items['Lmin'].numericValue) items['Lk2'].okState = ObloukItemState.Error;
    else if (items['Lk2'].numericValue < items['Llim'].numericValue) items['Lk2'].okState = ObloukItemState.Oranzove;
    else if (items['Lk2'].numericValue < items['Ln'].numericValue) items['Lk2'].okState = ObloukItemState.Zlute;
    else items['Lk2'].okState = ObloukItemState.OkInput; //možná spíš našeptávač




    if (items['n2'].numericValue === 0 || items['n2'].numericValue < items['nmin'].numericValue)
        items['n2'].okState = ObloukItemState.Error;
    else if (items['n2'].numericValue < items['nlim'].numericValue)
        items['n2'].okState = ObloukItemState.Oranzove;
    else if (items['n2'].numericValue < items['nn'].numericValue)
        items['n2'].okState = ObloukItemState.Zlute;
    else
        items['n2'].okState = ObloukItemState.OkReadOnly;


    //rewrite above to typescript
    if (items['nI2'].value !== '') {
        if (items['nI2'].numericValue < items['nImin'].numericValue)
            items['nI2'].okState = ObloukItemState.Error;
        else if (items['nI2'].numericValue < items['nIlim'].numericValue)
            items['nI2'].okState = ObloukItemState.Oranzove;
        else if (items['nI2'].numericValue < items['nIn'].numericValue)
            items['nI2'].okState = ObloukItemState.Zlute;
        else
            items['nI2'].okState = ObloukItemState.OkReadOnly;
    }

}
function calculatePrechodniceVzestupniceV130Lk2(items: { [key: string]: ObloukItem }) {
    if (items['D'].numericValue > 0) items['n2130'].setValueByNumericValue(Number((items['Lk2'].numericValue / items['D'].numericValue * 1000 / items['V130'].numericValue).toFixed(3)));
    else items['n2130'].value = '';

    if (items['I130'].numericValue > 0) items['nI2130'].setValueByNumericValue(Number((items['Lk2'].numericValue / items['I130'].numericValue * 1000 / items['V130'].numericValue).toFixed(3)));
    else items['nI2130'].value = '';
    //tohle je dobře??
    if (items['Lk2'].numericValue < items['Lmin130'].numericValue) {
        items['Lk2130'].okState = ObloukItemState.Error;
        items['Lk2130'].value = 'XXX';
    }
    else if (items['Lk2'].numericValue < items['Llim130'].numericValue) {
        items['Lk2130'].okState = ObloukItemState.Oranzove;
        items['Lk2130'].value = 'MIN';
    }
    else if (items['Lk2'].numericValue < items['Ln130'].numericValue) {
        items['Lk2130'].okState = ObloukItemState.Zlute;
        items['Lk2130'].value = 'LIM';
    }
    else {
        items['Lk2130'].okState = ObloukItemState.OkReadOnly; //možná spíš našeptávač
        items['Lk2130'].value = 'N';
    }




    if (items['n2130'].numericValue === 0 || items['n2130'].numericValue < items['nmin130'].numericValue)
        items['n2130'].okState = ObloukItemState.Error;
    else if (items['n2130'].numericValue < items['nlim130'].numericValue)
        items['n2130'].okState = ObloukItemState.Oranzove;
    else if (items['n2130'].numericValue < items['nn130'].numericValue)
        items['n2130'].okState = ObloukItemState.Zlute;
    else
        items['n2130'].okState = ObloukItemState.OkReadOnly;


    //rewrite above to typescript 
    if (items['nI2130'].numericValue < items['nImin130'].numericValue)
        items['nI2130'].okState = ObloukItemState.Error;
    else if (items['nI2130'].numericValue < items['nIlim130'].numericValue)
        items['nI2130'].okState = ObloukItemState.Oranzove;
    else if (items['nI2130'].numericValue < items['nIn130'].numericValue)
        items['nI2130'].okState = ObloukItemState.Zlute;
    else
        items['nI2130'].okState = ObloukItemState.OkReadOnly;
}

function calculatePrechodniceVzestupniceV150Lk2(items: { [key: string]: ObloukItem }) {
    if (items['D'].numericValue > 0) items['n2150'].setValueByNumericValue(Number((items['Lk2'].numericValue / items['D'].numericValue * 1000 / items['V150'].numericValue).toFixed(3)));
    else items['n2150'].value = '';

    if (items['I150'].numericValue > 0) items['nI2150'].setValueByNumericValue(Number((items['Lk2'].numericValue / items['I150'].numericValue * 1000 / items['V150'].numericValue).toFixed(3)));
    else items['nI2150'].value = '';
    //tohle je dobře??
    if (items['Lk2'].numericValue < items['Lmin150'].numericValue) {
        items['Lk2150'].okState = ObloukItemState.Error;
        items['Lk2150'].value = 'XXX';
    }
    else if (items['Lk2'].numericValue < items['Llim150'].numericValue) {
        items['Lk2150'].okState = ObloukItemState.Oranzove;
        items['Lk2150'].value = 'MIN';
    }
    else if (items['Lk2'].numericValue < items['Ln150'].numericValue) {
        items['Lk2150'].okState = ObloukItemState.Zlute;
        items['Lk2150'].value = 'LIM';
    }
    else {
        items['Lk2150'].okState = ObloukItemState.OkReadOnly; //možná spíš našeptávač
        items['Lk2150'].value = 'N';
    }




    if (items['n2150'].numericValue === 0 || items['n2150'].numericValue < items['nmin150'].numericValue)
        items['n2150'].okState = ObloukItemState.Error;
    else if (items['n2150'].numericValue < items['nlim150'].numericValue)
        items['n2150'].okState = ObloukItemState.Oranzove;
    else if (items['n2150'].numericValue < items['nn150'].numericValue)
        items['n2150'].okState = ObloukItemState.Zlute;
    else
        items['n2150'].okState = ObloukItemState.OkReadOnly;


    //rewrite above to typescript 
    if (items['nI2150'].numericValue < items['nImin150'].numericValue)
        items['nI2150'].okState = ObloukItemState.Error;
    else if (items['nI2150'].numericValue < items['nIlim150'].numericValue)
        items['nI2150'].okState = ObloukItemState.Oranzove;
    else if (items['nI2150'].numericValue < items['nIn150'].numericValue)
        items['nI2150'].okState = ObloukItemState.Zlute;
    else
        items['nI2150'].okState = ObloukItemState.OkReadOnly;
}
function calculatePrechodniceVzestupniceVkLk2(items: { [key: string]: ObloukItem }) {
    items['n2k'].setValueByNumericValue(Number((items['Lk2'].numericValue / items['D'].numericValue * 1000 / items['Vk'].numericValue).toFixed(3)));
    if (items['Lk2'].numericValue < items['Lmink'].numericValue) {
        items['Lk2k'].okState = ObloukItemState.Error;
        items['Lk2k'].value = 'XXX';
    }
    else if (items['Lk2'].numericValue < items['Llimk'].numericValue) {
        items['Lk2k'].okState = ObloukItemState.Oranzove;
        items['Lk2k'].value = 'MIN';
    }
    else if (items['Lk2'].numericValue < items['Lnk'].numericValue) {
        items['Lk2k'].okState = ObloukItemState.Zlute;
        items['Lk2k'].value = 'LIM';
    }
    else items['Lk2k'].value = 'N';

    if (items['n2k'].numericValue < items['nmink'].numericValue) items['n2k'].okState = ObloukItemState.Error;
    else if (items['n2k'].numericValue < items['nlimk'].numericValue) items['n2k'].okState = ObloukItemState.Oranzove;
    else if (items['n2k'].numericValue < items['nnk'].numericValue) items['n2k'].okState = ObloukItemState.Zlute;
}
