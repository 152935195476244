import { useMsal } from '@azure/msal-react';
import { Button, Popover, Switch, useTheme } from '@mui/material';
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import CurveSolverUpperMenu from '../CurveSolver/CurveSolverUpperMenu';
import { UpperMenuType } from '../CurveSolver/Enums/UpperMenuTypes';
import { ProjectDTO } from '../Api/ApiServer';
import { ApiBaseClient } from '../Api/ApiBaseClient';
import { ApiDataClient } from '../Api/ApiDataClient';
import { MoreVert } from '@mui/icons-material';
import { useValidatorStore } from '../CurveSolver/Stores/ValidatorStore';
import { ValidatorsEnum } from '../CurveSolver/Enums/ValidatorsEnum';

interface ValidatorsMainSceneProps {

}

export default function ValidatorsMainScene(props: ValidatorsMainSceneProps) {
    const theme = useTheme();
    // console.log(theme);
    //const [validators, setValidators] = React.useState<ProjectDTO[]>();
    const { initValidator, setValidator, validator } = useValidatorStore();

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const openUserMenu = Boolean(anchorEl);
    const [open, setOpen] = React.useState(false);
    const id = open ? 'simple-popover' : undefined;

    const navigate = useNavigate();

    useEffect(() => {
        //reloadValidators();
        if (validator === undefined)
            initValidator();
    }, []);

    function reloadValidators() {
        /*  new ApiDataClient().GetValidators().then((v) => {
             setValidators(v.projects);
         }); */
    }

    const handleUserMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const redirectToBimValidator = () => {
        navigate('/bimvalidator/');
        // console.log('co to je?: ', process.env.REACT_APP_BIMVALIDATOR_URL + '/router&token=' + localStorage.getItem('token'));
        // /* window.location.replace(process.env.REACT_APP_BIMVALIDATOR_URL + '/router&token=' + localStorage.getItem('token') ) */
        // window.location.replace(process.env.REACT_APP_BIMVALIDATOR_URL + '/router/' + localStorage.getItem('token'))
    }

    const handleChange = () => {
        setValidator(ValidatorsEnum.None);
        navigate('/projects');
    }

    return (
        <div style={{ background: '#F5F5F5', display: 'flex', flexDirection: 'column', height: '100%' }}>
            <CurveSolverUpperMenu
            navigationEnabled
                showChangeTenantDialog={() => { }}
                onAddProject={() => { }}
                menuType={UpperMenuType.Validators} />
            <div style={{ position: 'relative', top: '80px', display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ color: theme.palette.primary.main, fontSize: '22px', paddingLeft: '32px', fontWeight: 'bold' }}>
                        Validátory
                    </div>
                    <div style={{ display: 'flex', color: theme.palette.primary.main, fontSize: '14px', paddingRight: '30px' }}>
                        <div style={{ marginTop: '6px', fontWeight: 'bold' }}>{'Validátory'}</div>
                        <Switch sx={{ marginTop: '5px' }} size='small' onChange={handleChange} />
                        <div style={{ color: 'black', marginTop: '6px' }}>{'Projekty'}</div>
                    </div>
                </div>
                <div style={{ marginTop: '-16px', display: 'flex', flexWrap: 'wrap', height: '100%', marginLeft: '16px', }}>
                    <div style={{ height: '250px', width: '340px', margin: 16, marginTop: '32px', marginBottom: 0, border: '0px solid gray', background: /* v.hasRight ? theme.palette.primary.main : */ 'white', borderRadius: '4px', boxShadow: 'rgb(0 0 0 / 20%) 0px 4px 4px' }}>
                        <div style={{ display: 'box', height: '100%', width: '100%', marginTop: '5px', cursor: 'pointer' }} onClick={() => { setValidator(ValidatorsEnum.CurveSolver); navigate('/projects') }}>
                            <div style={{ marginTop: '5px', marginBottom: '0px' }}>
                                <div style={{ display: 'block', textAlign: 'right' }}>
                                    <Button sx={{ display: 'box', height: '100%', width: '20px' }} size='small' variant='text' onClick={(e) => { e.preventDefault(); e.stopPropagation(); /* handleUserMenuClick(e);  */ }}><MoreVert /></Button>
                                </div>
                                <Popover
                                    id={id}
                                    sx={{ zIndex: 50002 }}
                                    open={openUserMenu}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                >
                                    <div className="popup-content-div" >
                                        {/* <div>
                                            <Button color="secondary" sx={{ width: "150px", paddingRight: "12px", justifyContent: "flex-start", fontSize: '10px' }} onClick={() => { if (project && project.id) { onUpdateProject(project.id!); handleClose(); } }}>
                                                Upravit projekt
                                            </Button>
                                        </div>
                                        <div>
                                            <Button color="secondary" sx={{ width: "150px", paddingRight: "12px", justifyContent: "flex-start", fontSize: '10px' }} onClick={() => { if (project && project.id) { onDeleteProject(project.id!); handleClose(); }}}>
                                                Smazat projekt
                                            </Button>
                                        </div> */}
                                        {/* <Divider sx={{ bgcolor: "primary.main" }} /> */}

                                        {/* <Button color="secondary" onClick={logout} sx={{ width: "120px", paddingRight: "12px", justifyContent: "flex-start" }} >
                                            {t('login.logout')}
                                        </Button> */}

                                    </div>
                                </Popover>
                            </div>
                            {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80%', fontSize: '36px'}}>Curve <br/> solver</div> */}
                            <img style={{ height: '70%', width: '90%', objectFit: 'contain' }} src={process.env.PUBLIC_URL + "/curve_icon.png"} />
                            <div style={{ color: theme.palette.primary.main, marginLeft: '8px', fontWeight: '750', fontSize: 20 }}>
                                GPK Validátor
                            </div>
                        </div>
                    </div>
                    <div style={{ height: '250px', width: '340px', margin: 16, marginTop: '32px', marginBottom: 0, border: '0px solid gray', background: /* v.hasRight ? theme.palette.primary.main : */ 'white', borderRadius: '4px', boxShadow: 'rgb(0 0 0 / 20%) 0px 4px 4px' }}>
                        <div style={{ display: 'box', height: '100%', width: '100%', marginTop: '5px', cursor: 'pointer' }} onClick={() => { setValidator(ValidatorsEnum.SlopeGuard); navigate('/projects') }}>
                            <div style={{ marginTop: '5px', marginBottom: '0px' }}>
                                <div style={{ display: 'block', textAlign: 'right' }}>
                                    <Button sx={{ display: 'box', height: '100%', width: '20px' }} size='small' variant='text' onClick={(e) => { e.preventDefault(); e.stopPropagation(); /* handleUserMenuClick(e); */ }}><MoreVert /></Button>
                                </div>
                                <Popover
                                    id={id}
                                    sx={{ zIndex: 50002 }}
                                    open={openUserMenu}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                >
                                    <div className="popup-content-div" >
                                        {/* <div>
                                            <Button color="secondary" sx={{ width: "150px", paddingRight: "12px", justifyContent: "flex-start", fontSize: '10px' }} onClick={() => { if (project && project.id) { onUpdateProject(project.id!); handleClose(); } }}>
                                                Upravit projekt
                                            </Button>
                                        </div>
                                        <div>
                                            <Button color="secondary" sx={{ width: "150px", paddingRight: "12px", justifyContent: "flex-start", fontSize: '10px' }} onClick={() => { if (project && project.id) { onDeleteProject(project.id!); handleClose(); }}}>
                                                Smazat projekt
                                            </Button>
                                        </div> */}
                                        {/* <Divider sx={{ bgcolor: "primary.main" }} /> */}

                                        {/* <Button color="secondary" onClick={logout} sx={{ width: "120px", paddingRight: "12px", justifyContent: "flex-start" }} >
                                            {t('login.logout')}
                                        </Button> */}

                                    </div>
                                </Popover>
                            </div>
                            {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80%', fontSize: '36px'}}>Tilt <br/> validátor</div> */}

                            <img style={{ height: '70%', width: '90%', objectFit: 'contain' }} src={process.env.PUBLIC_URL + "/slope_icon.png"} />
                            <div style={{ color: theme.palette.primary.main, marginLeft: '8px', fontWeight: '750', fontSize: 20 }}>
                                SlopeGuard
                            </div>
                        </div>
                    </div>
                    <div style={{ height: '250px', width: '340px', margin: 16, marginTop: '32px', marginBottom: 0, border: '0px solid gray', background: /* v.hasRight ? theme.palette.primary.main : */ 'white', borderRadius: '4px', boxShadow: 'rgb(0 0 0 / 20%) 0px 4px 4px' }}>
                        <div style={{ display: 'box', height: '100%', width: '100%', marginTop: '5px', cursor: 'pointer' }} onClick={() => { setValidator(ValidatorsEnum.BimValidator); redirectToBimValidator() }}>
                            <div style={{ marginTop: '5px', marginBottom: '0px' }}>
                                <div style={{ display: 'block', textAlign: 'right' }}>
                                    <Button sx={{ height: '100%', width: '10px' }} size='small' variant='text' onClick={(e) => { e.preventDefault(); e.stopPropagation(); /* handleUserMenuClick(e); */ }}><MoreVert /></Button>
                                </div>
                                <Popover
                                    id={id}
                                    sx={{ zIndex: 50002 }}
                                    open={openUserMenu}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                >
                                    <div className="popup-content-div" >
                                        {/* <div>
                                            <Button color="secondary" sx={{ width: "150px", paddingRight: "12px", justifyContent: "flex-start", fontSize: '10px' }} onClick={() => { if (project && project.id) { onUpdateProject(project.id!); handleClose(); } }}>
                                                Upravit projekt
                                            </Button>
                                        </div>
                                        <div>
                                            <Button color="secondary" sx={{ width: "150px", paddingRight: "12px", justifyContent: "flex-start", fontSize: '10px' }} onClick={() => { if (project && project.id) { onDeleteProject(project.id!); handleClose(); }}}>
                                                Smazat projekt
                                            </Button>
                                        </div> */}
                                        {/* <Divider sx={{ bgcolor: "primary.main" }} /> */}

                                        {/* <Button color="secondary" onClick={logout} sx={{ width: "120px", paddingRight: "12px", justifyContent: "flex-start" }} >
                                            {t('login.logout')}
                                        </Button> */}

                                    </div>
                                </Popover>
                            </div>
                            {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80%', fontSize: '36px'}}>BIM <br/> validátor</div> */}

                            <img style={{ height: '70%', width: '90%', objectFit: 'contain' }} src={process.env.PUBLIC_URL + "/dss_icon.png"} />
                            <div style={{ color: theme.palette.primary.main, marginLeft: '8px', fontWeight: '750', fontSize: 20 }}>
                                BIM Validátor
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
