import { whisperDAndSetStates } from "../Calculations/CalculateDAndSetStates";
import { calculateDN } from "../Calculations/CalculateDN";
import { calculateI } from "../Calculations/CalculateI";
import { calculateI130 } from "../Calculations/CalculateI130";
import { calculateI150 } from "../Calculations/CalculateI150";
import { calculateIk } from "../Calculations/CalculateIk";
import { calculateMezilehla } from "../Calculations/CalculateMezilehla";
import { calculateSpeeds } from "../Calculations/CalculateSpeed";
import { calculateVytycovaci } from "../Calculations/CalculateVytycovaci";
import { setSpeedVStates } from "../Calculations/SetSpeedVStates";
import { whispering } from "../Calculations/Whispering";
import { ObloukItemState } from "../Enums/ObloukItemState";
import { IObloukModel } from "./IObloukModel";
import { ObloukItem } from "./ObloukItem";
import { initOblouk } from "./ObloukModelInitializer";

export class Oblouk {
  private items: { [key: string]: ObloukItem } = {};
  private editableKeys = ['R', 'D', 'V', 'V130', 'V150', 'Vk', 'Lk1', 'Lk2', 'Lkm']
  private keys = ['R', 'Dstav', 'D', 'DN', 'Dmax', 'Dx', 'V', 'V130', 'V150', 'Vk',
    'Vnakl', 'I', 'I130', 'I150', 'Ik', 'Enakl', 'I_E', 'Inn', 'Ilim', 'Imax',
    'I_E130', 'Inn130', 'Ilim130', 'Imax130', 'I_E150', 'Inn150', 'Ilim150', 'Imax150',
    'I_Ek', 'Ikn', 'Iklim', 'Ikmax', 'IkmaxD', 'IklimD', 'Innnakl', 'Ilimnakl', 'Imaxnakl',
    'Lk1', 'n1', 'Lk2', 'n2', 'nI1', 'nI2', 'nI1130', 'nI2130', 'nI1150', 'nI2150', 'nIm', 'nIm130', 'nIm150',
    'Ln', 'Llim', 'Lmin', 'Ldn', 'Ldlim', 'Ldmin', 'Lkn', 'Lklim', 'Lkmin', 'Lklim07R', 'Lklim20', 'nn', 'nlim', 'nmin', 'nIn', 'nIlim', 'nImin',
    'Ln130', 'Llim130', 'Lmin130', 'Ldn130', 'Ldlim130', 'Ldmin130', 'Lkn130', 'Lklim130', 'Lkmin130', 'Lklim20130', 'nn130', 'nlim130', 'nmin130', 'nIn130', 'nIlim130', 'nImin130',
    'n1130', 'n2130',
    'Ln150', 'Llim150', 'Lmin150', 'Ldn150', 'Ldlim150', 'Ldmin150', 'Lkn150', 'Lklim150', 'Lkmin150', 'Lklim20150', 'nn150', 'nlim150', 'nmin150', 'nIn150', 'nIlim150', 'nImin150',
    'n1150', 'n2150',
    'Lnk', 'Llimk', 'Lmink', 'Ldnk', 'Ldmink', 'Lklim20k', 'LkI1', 'LkI2', 'nnk', 'nmink', 'nlimk', 'nInk',
    'n1k', 'nIk', 'n2k', 'nI2k', 'Lk1k', 'Lk2k', 'Lk1130', 'Lk2130', 'Lk1150', 'Lk2150'
    , 'alfa', 'kolej', 'ω', "A1", "A2", "tauk1", "tauk2", "Yk1", "Yk2", "Xk1", "Xk2", "m1", "m2", "tz1", "tz2",
    "Lkm",
    "deltaD", "deltaI", "Vmezi", "dLdn", "dLdlim", "dLdmin", "dLn", "dLlim", "dLmin", "dLkn", "dLklim", "dLkmin", "dLklim07R", "dLklim20",
    "dLdn130", "dLdlim130", "dLdmin130", "dLn130", "dLlim130", "dLmin130", "dLkn130", "dLklim130", "dLkmin130", "dLklim20130",
    "dLdn150", "dLdlim150", "dLdmin150", "dLn150", "dLlim150", "dLmin150", "dLkn150", "dLklim150", "dLkmin150", "dLklim20150",
    "dLdnk", "dLdlimk", "dLdmink", "dLknk", "dLklimk", "dLklim20k", "dLkI1", "dLkI2", "dLk1130", "dLk2130", "dLk1150", "dLk2150",
    "dRx", "dR1", "dR2", "dA", "dL1", "dL2", "dXs1", "dXs2", "dYs1", "dYs2", "omega", "tau1", "tau2", "taum1", "taum2", "ddn", "dmm",
    "nm", "nIm", "V130m", "Lkm130", "nm130", "V150m", "Lkm150", "nm150", "nIm130", "nIm150", "deltaI130", "deltaI150", "Vkm",
    "vIk1", "dIk", "vD2", "dLkx1", "dLkx2", "dLkx3", "dLkx4", "dLkx5", "vIk2", "nmk", "VkdeltaD", "dLnk", "dLlimk", "dLmink", "LkmVk",
    "tzm"
  ];




  // items['dRx'] = new ObloukItem(true, "");
  // items['dR1'] = new ObloukItem(true, "");
  // items['dR2'] = new ObloukItem(true, "");
  // items['dA'] = new ObloukItem(true, "");
  // items['dL1'] = new ObloukItem(true, "");
  // items['dL2'] = new ObloukItem(true, "");
  // items['dXs1'] = new ObloukItem(true, "");
  // items['dXs2'] = new ObloukItem(true, "");
  // items['dYs1'] = new ObloukItem(true, "");
  // items['dYs2'] = new ObloukItem(true, "");
  // items['omega'] = new ObloukItem(true, "");
  // items['tau1'] = new ObloukItem(true, "");
  // items['tau2'] = new ObloukItem(true, "");
  // items['taum1'] = new ObloukItem(true, "");
  // items['taum2'] = new ObloukItem(true, "");
  // items['ddn'] = new ObloukItem(true, "");
  // items['dmm'] = new ObloukItem(true, "");

  private id: number;
  private dbId: number;
  public hasMezilehla: boolean = false;
  public inflexWithNext: boolean = false;
  public inflexWithPrevious: boolean = false;
  // public horniOblouk: Oblouk | null;

  constructor(id: number, dbId: number) {
    this.id = id;
    this.dbId = dbId;
    // this.horniOblouk = null;
    this.reset();
  }
  public setDbId(dbId: number) { this.dbId = dbId; }
  public getDbId(): number { return this.dbId; }
  public getId(): number { return this.id };
  public setId(id: number) { this.id = id; }
  public getAllItemsAsString() {
    return this.keys.map(key => this.items[key] !== undefined ? ({ key: key, value: this.items[key].value, state: this.items[key].okState }) : null);
  }



  private reset() {
    initOblouk(this.items);

  }
  public calculateInflex(horniOblouk: Oblouk | null, spodniOblouk: Oblouk | null) {
    if (horniOblouk && this.inflexWithPrevious) {
      if (this.items['D'].numericValue !== 0 && horniOblouk.items['D'].numericValue !== 0) {
        let currentN1 = (this.items['Lk1'].numericValue * 1000) / this.items['D'].numericValue;
        let upperrN2 = (horniOblouk.items['Lk2'].numericValue * 1000) / horniOblouk.items['D'].numericValue;
        if (currentN1 !== upperrN2)
          this.items['n1'].okState = ObloukItemState.Error;
      }
    }

    if (spodniOblouk && this.inflexWithNext) {
      if (this.items['D'].numericValue !== 0 && spodniOblouk.items['D'].numericValue !== 0) {
        let currentN2 = (this.items['Lk2'].numericValue * 1000) / this.items['D'].numericValue;
        let downerN1 = (spodniOblouk.items['Lk1'].numericValue * 1000) / spodniOblouk.items['D'].numericValue;
        if (currentN2 !== downerN1)
          this.items['n2'].okState = ObloukItemState.Error;
      }
    }
  }
  public calculate(horniOblouk: Oblouk | null, spodniOblouk: Oblouk | null) {

    // this.clearAllReadonlyFields(this);
    if (this.items['R'].numericValue > 0 && this.items['V'].numericValue > 0) {

      calculateDN(this.items);
      whisperDAndSetStates(this.items);
      setSpeedVStates(this.items);
      calculateI(this.items);
      calculateI130(this.items);
      calculateI150(this.items);
      calculateIk(this.items);
      calculateSpeeds(this.items);
      calculateVytycovaci(this.items);
      if (horniOblouk !== undefined && horniOblouk !== null)
        calculateMezilehla(horniOblouk.items, this.items);

      if (spodniOblouk !== null)
        calculateMezilehla(this.items, spodniOblouk.items);
    }
    else this.clearFieldsToNoValid();
    whispering(this.items);
    this.calculateInflex(horniOblouk, spodniOblouk);
  }
  private clearFieldsToNoValid(): void {

  }
  private clearAllReadonlyFields(ob: Oblouk): void {
    for (const key of this.keys) {
      const item = ob.getItem(key);
      if (item !== undefined && !this.editableKeys.some(x => x === key)) {
        this.items[key] = new ObloukItem(item.readOnly, '');
      }
    }
  }


  isSame(ob: Oblouk): boolean {
    if (ob === undefined) return false;
    if (ob.getId() !== this.id) return false;
    for (const key of this.keys) {
      const item = ob.getItem(key);
      if (item !== undefined) {
        if (this.items[key] === undefined) return false;
        if (this.items[key].value !== item.value) return false;
      }
    }
    return true;
  }
  copyFrom(ob: Oblouk): void {
    this.id = ob.id;
    this.dbId = ob.dbId;
    this.hasMezilehla = ob.hasMezilehla;
    this.inflexWithNext = ob.inflexWithNext;
    this.inflexWithPrevious = ob.inflexWithPrevious;
    if (ob !== undefined) {
      for (const key of this.keys) {
        const item = ob.getItem(key);
        if (item !== undefined) {
          this.items[key] = new ObloukItem(item.readOnly, item.value);
        }
      }
    }
  }
  getItem(key: string): ObloukItem | undefined {
    return this.items[key];
  }
}