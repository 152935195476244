import { read } from "fs";
import { ObloukItemState } from "../Enums/ObloukItemState";

export class ObloukItem {
    public okState: ObloukItemState;
    public toolTip: string | undefined;
    public readOnly: boolean;
    public value: string;
    public whisperTexts: string[] | undefined;
    public numericValue: number;
    public setValueByNumericValue(value: number): void {
        this.numericValue = value;
        this.value = value.toString();
    }
    constructor(readonly: boolean, value: string, toolTip?: string) {
        this.okState = readonly ? ObloukItemState.OkReadOnly : ObloukItemState.OkInput;
        this.readOnly = readonly;
        this.value = value;
        this.toolTip = toolTip;
        let num = Number(value);
        this.numericValue = isNaN(num) ? 0 : num;
    }
}