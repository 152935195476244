import { useMsal } from '@azure/msal-react';
import { Accordion, AccordionDetails, AccordionSummary, Button, Divider, IconButton, TextField, Tooltip, Typography, useTheme, TextareaAutosize, createTheme, ThemeProvider } from '@mui/material';
import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import CurveSolverUpperMenu from '../CurveSolver/CurveSolverUpperMenu';
import { UpperMenuType } from '../CurveSolver/Enums/UpperMenuTypes';
import { BaseLineDTO, CorridorDTO, GetRoutesForProjectResponse, ProjectDTO, ProjectDataDTO, ProjectType, RouteDTO, ValidateTiltTreeDataResponse } from '../Api/ApiServer';
import { ApiBaseClient } from '../Api/ApiBaseClient';
import { ApiDataClient } from '../Api/ApiDataClient';
import CreateProjectDialog from './Components/CreateProjectDialog';
import UpdateProjectDialog from '../Projects/Components/UpdateProjectDialog';
import { DataGridPremium, GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium';
import { Add, Delete, Edit, EditOutlined, ExpandMore, NavigateNext, Save } from '@mui/icons-material';
import NavigationPanel, { NavigationItem } from '../Shared/NavigationPanel';
import { useValidatorStore } from '../CurveSolver/Stores/ValidatorStore';
import { ValidatorsEnum } from '../CurveSolver/Enums/ValidatorsEnum';
import TreeView, { flattenTree, INode, ITreeViewOnNodeSelectProps, NodeId } from "react-accessible-treeview";
import CorridorScene from '../TiltValidator/CorridorScene';
import RoutesScene from '../Routes/RoutesScene';
import SaveProjectDialog from './Components/SaveProjectDialog';

interface ProjectDetailSceneProps {

}

enum TreeLevel {
    Corridor = 0,
    BaseLine = 1
}

export default function ProjectDetailScene(props: ProjectDetailSceneProps) {
    const { instance } = useMsal();
    const theme = useTheme();
    // console.log(theme);
    const [saveOpen, setSaveOpen] = React.useState(false);
    const [projectId, setProjectId] = React.useState<number>();
    const [routes, setRoutes] = React.useState<RouteDTO[]>();
    const [navigationItems, setNavigationItems] = React.useState<NavigationItem[]>();
    const [projectInfo, setProjectInfo] = React.useState<Partial<ProjectDataDTO>>();
    const [treeData, setTreeData] = React.useState<INode[] | null>(null);
    const [changed, setChanged] = React.useState(false);

    const navigate = useNavigate();
    const params = useParams();
    const { validator } = useValidatorStore();
    const textFieldTheme = createTheme({
        components: {
            MuiInput: {
                styleOverrides: {
                    underline: {
                        '&:before': {
                            borderBottom: '1px solid #F3F3F3', // Change this to your desired color
                        },
                        '&:hover:not(.Mui-disabled):before': {
                            borderBottom: '2px solid ' + theme.palette.primary.main, // Change this to your desired color for hover state
                        },
                    },
                },
            },
        },
    });
    useEffect(() => {
        if (validator === ValidatorsEnum.SlopeGuard)
            refreshTree();
        else
            reloadRoutes();

        setChanged(false);
    }, []);

    // useEffect(() => {
    //     if (validator === ValidatorsEnum.SlopeGuard)
    //         refreshTree();
    //     else
    //         reloadRoutes();
    // }, [params]);

    useEffect(() => {
        if (changed)
            window.addEventListener('beforeunload', beforeUnLoad);
        return () => {
            window.removeEventListener('beforeunload', beforeUnLoad);
        };
    }, [changed]);

    const beforeUnLoad = (e: any) => {
        if (changed) {
            setSaveOpen(true);
            return e.returnValue = 'Really want to leave?';
        }
    }

    const refreshTree = () => {
        getTreeData().then((data) => {
            if (data !== null && data.corridors != null && data.corridors.length > 0) {
                setTreeData(flattenTree(parseCorridors(data.corridors)));
            }
            else setTreeData(null);
        });
    }

    const parseCorridors = (corridors: CorridorDTO[]) => {
        let array: any[] = [];
        for (let cor of corridors) {
            array.push({ name: cor.name, children: parseBaselines(cor.baseLines!), metadata: { typ: TreeLevel.Corridor } });
        }

        return { name: "Koridory", children: array };
    };

    const parseBaselines = (data: BaseLineDTO[]) => {
        if (data.length !== undefined) {
            let array: any[] = [];
            for (let item of data) {
                array.push({
                    name: item.name,
                    metadata: { typ: TreeLevel.BaseLine, data: item.data }
                });
            }
            if (array.length === 0)
                return null;
            return array;
        }
        else {
            return [];
        }
    };

    const getTreeData = async () => {
        if (params['projectId']) {
            setProjectId(parseInt(params['projectId']));
            var data = await new ApiDataClient().ValidateTiltTreeData(parseInt(params['projectId']));

            setRoutes(undefined);
            setNavigationItems([new NavigationItem(data.projectId!, data.projectData!.projectName!, UpperMenuType.Projects)]);
            setProjectInfo(data.projectData!);
            return data;
        }
        else {
            setRoutes(undefined);
            setProjectId(undefined);
            // setProjectInfo(undefined);
            setNavigationItems(undefined);

            return null;
        }
    };

    function reloadRoutes() {
        if (params['projectId']) {
            setProjectId(parseInt(params['projectId']));
            new ApiDataClient().GetRoutes(parseInt(params['projectId'])).then((v) => {
                setRoutes(v.routes);
                setNavigationItems([new NavigationItem(v.projectId!, v.projectData!.projectName!, UpperMenuType.Projects)]);
                setProjectInfo(v.projectData!);
            });
        }
        else {
            setRoutes(undefined);
            setProjectId(undefined);
            // setProjectInfo(undefined);
            setNavigationItems(undefined);
        }
    }

    const saveProject = () => {
        if (projectInfo && projectId && projectInfo.projectName)
            new ApiDataClient().UpdateProjectData(projectInfo.projectName, projectId, projectInfo.description!, projectInfo.projectManager!, projectInfo.authorizedPersons!, projectInfo.otherFeatures!).then((v) => {
                setChanged(false);
            });
    }

    const [expanded, setExpanded] = React.useState<string[]>([]);

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            if (isExpanded)
                setExpanded([...expanded.filter(p => p !== panel), panel]);
            else
                setExpanded([...expanded.filter(p => p !== panel)]);
        };

    return (
        <div style={{ background: '#F5F5F5', /* display: 'flex', */ height: '100%' }}>
            <CurveSolverUpperMenu
                navigationEnabled
                showChangeTenantDialog={() => { }}
                onAddProject={() => { }}
                menuType={UpperMenuType.Routes}
                navigationItems={navigationItems} />
            <div style={{ background: '#F5F5F5', display: 'flex', height: '100%' }}>
                <div style={{ background: '#F5F5F5', width: '64%', marginTop: '60px' }}>
                    {projectInfo && <div style={{ background: '#F5F5F5', display: 'flex', width: '100%', marginTop: '15px', color: theme.palette.primary.main, fontSize: '22px', marginLeft: '32px', marginRight: 'auto', textAlign: 'left' }}>
                        <div>Podrobnosti</div>
                        <div style={{ marginLeft: '24px' }}>
                            {changed && <IconButton sx={{ width: '30px', height: '30px', marginRight: '24px' }}
                                onClick={() => { console.log('projektový data: ', projectInfo); saveProject(); }}><Save /></IconButton>}
                        </div>
                    </div>}
                    {projectInfo && <div style={{ display: 'flex', paddingLeft: '16px', marginTop: '-4px', justifyContent: 'space-between' }}>
                        <div style={{
                            height: 'auto', width: '450px', margin: '16px', marginBottom: 0, border: '0px solid gray', background: /* v.hasRight ? theme.palette.primary.main : */ 'white',
                            borderRadius: '4px', boxShadow: 'rgb(0 0 0 / 20%) 0px 4px 4px'
                        }}>
                            <div style={{ height: '100%', width: '100%', marginTop: '5px', cursor: validator === ValidatorsEnum.None ? 'default' : 'pointer' }} onClick={() => { if (validator !== ValidatorsEnum.None) navigate('/routes/' + projectId) }}>
                                <div style={{ display: 'flex', marginTop: '5px', marginBottom: '0px', justifyContent: 'space-between' }}>
                                    {/* <div style={{ color: theme.palette.primary.main, marginLeft: '16px', fontWeight: 'bold', marginTop: '8px', marginBottom: '-8px' }}> */}
                                    {/* {projectInfo.projectName} */}
                                    <ThemeProvider theme={textFieldTheme}>
                                        <TextField
                                            /* label={'Název projektu'} */
                                            inputProps={{
                                                style: {
                                                    paddingLeft: '20px',
                                                    minHeight: '32px'
                                                },

                                            }}
                                            InputProps={{
                                                endAdornment:
                                                    <EditOutlined sx={{ marginRight: '12px' }} color={'disabled'} />
                                            }}
                                            className="text-field"
                                            fullWidth
                                            sx={{ color: theme.palette.primary.main, fontWeight: 'bold', marginTop: '0px', marginBottom: '-8px' }}
                                            value={projectInfo.projectName}
                                            onChange={(e) => { setChanged(true); setProjectInfo(new ProjectDataDTO({ projectName: e.currentTarget.value, projectManager: projectInfo.projectManager, projectType: projectInfo.projectType, authorizedPersons: projectInfo.authorizedPersons, description: projectInfo.description, otherFeatures: projectInfo.otherFeatures })) }}
                                            variant="standard" />
                                    </ThemeProvider>
                                    {/* </div> */}
                                </div>
                                {/* <div style={{ marginTop: '7px', marginBottom: 'auto', height: '1px', background: '#777777' }} /> */}
                                <div style={{ height: 250, minWidth: '400px' }}>
                                    {projectInfo.projectType === ProjectType.Road && <img style={{ height: '100%', maxWidth: '100%', objectFit: 'contain' }} src={process.env.PUBLIC_URL + "/road.png"} />}
                                    {projectInfo.projectType === ProjectType.Railway && <img style={{ height: '100%', maxWidth: '100%', objectFit: 'contain' }} src={process.env.PUBLIC_URL + "/rail.png"} />}
                                    {projectInfo.projectType === ProjectType.RoadBridge && <img style={{ height: '100%', maxWidth: '100%', objectFit: 'contain' }} src={process.env.PUBLIC_URL + "/roadBridge.png"} />}
                                    {projectInfo.projectType === ProjectType.RailwayBridge && <img style={{ height: '100%', maxWidth: '100%', objectFit: 'contain' }} src={process.env.PUBLIC_URL + "/railBridge.png"} />}
                                    {projectInfo.projectType !== ProjectType.Road && projectInfo.projectType !== ProjectType.Railway && projectInfo.projectType !== ProjectType.RoadBridge && projectInfo.projectType !== ProjectType.RailwayBridge &&
                                        <img style={{ height: '100%', maxWidth: '100%', objectFit: 'contain' }} src={process.env.PUBLIC_URL + "/noProjectPicture.png"} />
                                    }
                                </div>
                            </div>
                        </div>
                        <div style={{
                            height: 'auto', width: '100%', margin: '16px', marginBottom: 0, border: '0px solid gray', background: /* v.hasRight ? theme.palette.primary.main : */ 'white',
                            borderRadius: '4px', boxShadow: 'rgb(0 0 0 / 20%) 0px 4px 4px'
                        }}>
                            <div style={{ display: 'box', /* height: '100%', width: '100%', */ marginTop: '5px', cursor: validator === ValidatorsEnum.None ? 'default' : 'pointer' }} onClick={() => { if (validator !== ValidatorsEnum.None) navigate('/routes/' + projectId) }}>
                                <div style={{ display: 'flex', marginTop: '5px', marginBottom: '0px', justifyContent: 'space-between' }}>
                                    <div style={{ color: theme.palette.primary.main, marginLeft: '16px', fontWeight: 'bold', marginTop: '8px', marginBottom: '8px' }}>
                                        Popis
                                    </div>
                                </div>
                                <div style={{ marginTop: '2px', marginBottom: 'auto', height: '1px', background: '#F3F3F3' }} />
                                {/* <Typography sx={{ textAlign: 'left', padding: '16px' }}>
                            Cílem stavby je zhotovení, tj. realizace stavby „Rekonstrukce nástupišť a zřízení bezbariérových přístupů v ŽST Roudnice n. L.", v režimu BIM. Stavba zahrnuje rekonstrukci stávajících nástupišť a zajištění bezbariérového přístupu na tato nástupiště a tím splnění požadavků na zajištění přístupu pro osoby se sníženou schopností pohybu a orientace.
                            </Typography> */}
                                <div style={{ marginRight: '24px' }}>
                                    <TextField
                                        multiline
                                        placeholder='Sem zadejte popis'
                                        InputProps={{ disableUnderline: true }}
                                        /* label={'HIP'} */
                                        className="text-field"
                                        sx={{ textAlign: 'left', maxHeight: '280px', margin: '12px', overflow: 'auto' }}

                                        value={projectInfo.description}
                                        onChange={(e) => { setChanged(true); setProjectInfo(new ProjectDataDTO({ projectName: projectInfo.projectName, projectManager: projectInfo.projectManager, projectType: projectInfo.projectType, authorizedPersons: projectInfo.authorizedPersons, description: e.currentTarget.value, otherFeatures: projectInfo.otherFeatures })) }}
                                        variant="standard" />
                                </div>
                            </div>
                        </div>
                    </div>}

                    {projectInfo && validator !== ValidatorsEnum.SlopeGuard && projectId && <RoutesScene projectId={projectId} />}

                </div>
                <div style={{ width: '33%', marginRight: '16px', marginLeft: '16px', paddingTop: '117px' }}>
                    <Accordion expanded={expanded.some(p => p === 'panel1')} onChange={handleChange('panel1')}>
                        <AccordionSummary
                            expandIcon={<ExpandMore />}
                            tabIndex={-1}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                                HIP
                            </Typography>
                        </AccordionSummary>
                        <div style={{ height: '1px', background: '#F3F3F3', marginTop: '-18px' }} />
                        <AccordionDetails>
                            <TextField
                                multiline
                                InputProps={{ disableUnderline: true }}
                                placeholder='Hlavní inženýr projektu'
                                /* label={'HIP'} */
                                className="text-field"
                                sx={{ textAlign: 'left', maxHeight: '230px', overflow: 'auto' }}
                                value={projectInfo?.projectManager}
                                onChange={(e) => { setChanged(true); setProjectInfo(new ProjectDataDTO({ projectName: projectInfo?.projectName, projectManager: e.currentTarget.value, projectType: projectInfo?.projectType, authorizedPersons: projectInfo?.authorizedPersons, description: projectInfo?.description, otherFeatures: projectInfo?.otherFeatures })) }}
                                variant="standard" />
                            {/* <Typography sx={{ textAlign: 'left' }}>
                           ing. Michal Manažer MBA.
                        </Typography> */}
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded.some(p => p === 'panel2')} onChange={handleChange('panel2')}>
                        <AccordionSummary
                            expandIcon={<ExpandMore />}
                            tabIndex={-1}
                            aria-controls="panel2bh-content"
                            id="panel2bh-header"
                        >
                            <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                                Oprávnění uživatelé
                            </Typography>
                        </AccordionSummary>
                        <div style={{ marginTop: '2px', marginBottom: 'auto', height: '1px', background: '#F3F3F3' }} />
                        <AccordionDetails>
                            <TextField
                                multiline
                                InputProps={{ disableUnderline: true }}
                                placeholder='Oprávnění uživatelé'
                                /* label={'HIP'} */
                                className="text-field"
                                sx={{ textAlign: 'left', maxHeight: '230px', overflow: 'auto' }}
                                value={projectInfo?.authorizedPersons}
                                onChange={(e) => { setChanged(true); setProjectInfo(new ProjectDataDTO({ projectName: projectInfo?.projectName, projectManager: projectInfo?.projectManager, projectType: projectInfo?.projectType, authorizedPersons: e.currentTarget.value, description: projectInfo?.description, otherFeatures: projectInfo?.otherFeatures })) }}
                                variant="standard" />
                            {/*  <Typography sx={{ textAlign: 'left' }}>
                            Karel Franta; 
                            Michal Omáčka;
                            Tomáš Čas
                        </Typography> */}
                        </AccordionDetails>
                    </Accordion>
                    {/* <Accordion expanded={expanded.some(p => p === 'panel3')} onChange={handleChange('panel3')}>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel3bh-content"
                        id="panel3bh-header"
                    >
                        <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                            Velikost
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography sx={{ textAlign: 'left' }}>
                            Nádraží o rozloze 1,5 ha
                        </Typography>
                    </AccordionDetails>
                </Accordion> */}
                    <Accordion expanded={expanded.some(p => p === 'panel4')} onChange={handleChange('panel4')}>
                        <AccordionSummary
                            expandIcon={<ExpandMore />}
                            tabIndex={-1}
                            aria-controls="panel4bh-content"
                            id="panel4bh-header"
                        >
                            <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                                Další vlastnosti
                            </Typography>
                        </AccordionSummary>
                        <div style={{ marginTop: '2px', marginBottom: 'auto', height: '1px', background: '#F3F3F3' }} />
                        <AccordionDetails>
                            <TextField
                                multiline
                                InputProps={{ disableUnderline: true }}
                                placeholder='Další vlastnosti'
                                /* label={'HIP'} */
                                className="text-field"
                                sx={{ textAlign: 'left', maxHeight: '230px', overflow: 'auto' }}
                                value={projectInfo?.otherFeatures}
                                onChange={(e) => { setChanged(true); setProjectInfo(new ProjectDataDTO({ projectName: projectInfo?.projectName, projectManager: projectInfo?.projectManager, projectType: projectInfo?.projectType, authorizedPersons: projectInfo?.authorizedPersons, description: projectInfo?.description, otherFeatures: e.currentTarget.value })) }}
                                variant="standard" />
                            {/* <Typography sx={{ textAlign: 'left' }}>
                            Bentley imported 22.9.2023
                        </Typography> */}
                        </AccordionDetails>
                    </Accordion>
                    <SaveProjectDialog open={saveOpen} onClose={(save) => {
                        setSaveOpen(false);
                        if (save)
                            saveProject();
                    }} />
                </div>
            </div>

            {projectInfo && validator === ValidatorsEnum.SlopeGuard && projectId && <CorridorScene projectId={projectId} />}
        </div>)
}
