import * as React from 'react';
import { Box, CircularProgress, Grid, Typography, useTheme } from '@mui/material';
import { ProjectWiseProjectModel } from './Models/ProjectWiseProjectModel';
import { BarChart } from '@mui/x-charts/BarChart';
import { PieChart } from '@mui/x-charts/PieChart';
import { LineChart } from '@mui/x-charts/LineChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';

export interface ProjectWiseProjectsUpperSceneProps {
    data: ProjectWiseProjectModel[];
    isColored: boolean;
}

export default function ProjectWiseProjectsUpperScene (props: ProjectWiseProjectsUpperSceneProps) {
    const { data, isColored } = props;
    const [loading, setLoading] = React.useState(true);
    const theme = useTheme();

    React.useEffect(() => { 
        setTimeout(() => {
            setLoading(false);
        }, 2000);
     }, []);
    // Call the function to fetch data

    const getCelkem = React.useCallback(() => {
        return data.length;
    }, [data]);

    const getPreparing = React.useCallback(() => {
        return data.filter(p => p.properties.PROJECT_PW_STAVBA_STATUS === 'projektová příprava').length;
    }, [data]);

    const getRealization = React.useCallback(() => {
        return data.filter(p => p.properties.PROJECT_PW_STAVBA_STATUS === 'realizace' || p.properties.PROJECT_PW_STAVBA_STATUS === 'realizace - výběrové řízení').length;
    }, [data]);

    const getCompleted = React.useCallback(() => {
        return data.filter(p => p.properties.PROJECT_PW_STAVBA_STATUS === 'ukončení realizace').length;
    }, [data]);


    const getCelkemSum = React.useCallback(() => {
        return data.filter(p => p.properties.PROJECT_PW_STAVBA).map(p => Number(p.properties.PROJECT_PW_STAVBA.substring(0, p.properties.PROJECT_PW_STAVBA.lastIndexOf(' ')).replace(/\s+/g, ''))).reduce((a, b) => a + b, 0);
    }, [data]);

    const getPreparingSum = React.useCallback(() => {
        return data.filter(p => p.properties.PROJECT_PW_STAVBA_STATUS === 'projektová příprava' && p.properties.PROJECT_PW_STAVBA).map(p => Number(p.properties.PROJECT_PW_STAVBA.substring(0, p.properties.PROJECT_PW_STAVBA.lastIndexOf(' ')).replace(/\s+/g, ''))).reduce((a, b) => a + b, 0);
    }, [data]);

    const getRealizationSum = React.useCallback(() => {
        return data.filter(p => (p.properties.PROJECT_PW_STAVBA_STATUS === 'realizace' || p.properties.PROJECT_PW_STAVBA_STATUS === 'realizace - výběrové řízení') && p.properties.PROJECT_PW_STAVBA).map(p => Number(p.properties.PROJECT_PW_STAVBA.substring(0, p.properties.PROJECT_PW_STAVBA.lastIndexOf(' ')).replace(/\s+/g, ''))).reduce((a, b) => a + b, 0);
    }, [data]);

    const getCompletedSum = React.useCallback(() => {
        return data.filter(p => p.properties.PROJECT_PW_STAVBA_STATUS === 'ukončení realizace' && p.properties.PROJECT_PW_STAVBA).map(p => Number(p.properties.PROJECT_PW_STAVBA.substring(0, p.properties.PROJECT_PW_STAVBA.lastIndexOf(' ')).replace(/\s+/g, ''))).reduce((a, b) => a + b, 0);
    }, [data]);

    return (
        <Box>
            {loading && <Box minHeight='150px'><CircularProgress /></Box>}
            {!loading && 
                <Grid container sx={{ px: '16px', marginTop: 2, mb:2 }}>
                    <Grid item lg={4} sm={12} md={6}>
                        <Box display="flex" flexDirection="column" height="100%">
                            <Box flex={1} display="flex" flexDirection="row">
                                <Box flex={1} display="flex" sx={{ border: '1px solid lightgray', borderRadius: '6px', margin: '8px 8px 8px 20px', boxShadow: isColored ? 3 : undefined, minWidth: '150px', width: '100%' }}>
                                    <Box alignContent='left' textAlign={'right'} margin={'4px'} width={'100%'}>
                                        <Typography textAlign={'left'} variant="h5" component="div" sx={{ height: '50%', flexGrow: 1, ml: 2, color: 'black' }}>
                                            Celkem
                                        </Typography>
                                        <Typography variant="h4" component="div" sx={{ height: '50%', flexGrow: 1, ml: 2, color: 'black', mr:'12px', mb: '12px' }}>
                                            {getCelkem()}
                                        </Typography>
                                    </Box>
                                </Box>         
                                <Box flex={1} display="flex" sx={{ border: '1px solid lightgray', borderRadius: '6px', margin: '8px 8px 8px 8px', boxShadow: isColored ? 3 : undefined, minWidth: '150px', width: '100%' }}>
                                    <Box alignContent='left' textAlign={'right'} margin={'4px'} width={'100%'}>
                                        <Typography textAlign={'left'} variant="h5" component="div" sx={{ height: '50%', flexGrow: 1, ml: 2, color: 'black' }}>
                                            V přípravě
                                        </Typography>
                                        <Typography variant="h4" component="div" sx={{ height: '50%', flexGrow: 1, ml: 2, color: 'black', mr:'12px', mb: '12px' }}>
                                            {getPreparing()}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>         
                            <Box flex={1} display="flex" flexDirection="row">
                                <Box flex={1} display="flex" sx={{ border: '1px solid lightgray', borderRadius: '6px', margin: '8px 8px 8px 20px', boxShadow: isColored ? 3 : undefined, minWidth: '150px', width: '100%' }}>
                                    <Box alignContent='left' textAlign={'right'} margin={'4px'} width={'100%'}>                                        
                                        <Typography textAlign={'left'} variant="h5" component="div" sx={{ height: '50%', flexGrow: 1, ml: 2, color: 'black' }}>
                                            Realizace
                                        </Typography>
                                        <Typography variant="h4" component="div" sx={{ height: '50%', flexGrow: 1, ml: 2, color: 'black', mr:'12px', mb: '12px' }}>
                                            {getRealization()}
                                        </Typography>
                                    </Box>
                                </Box>         
                                <Box flex={1} display="flex" sx={{ border: '1px solid lightgray', borderRadius: '6px', margin: '8px 8px 8px 8px', boxShadow: isColored ? 3 : undefined, minWidth: '150px', width: '100%' }}>
                                    <Box alignContent='left' textAlign={'right'} margin={'4px'} width={'100%'}>
                                        <Typography textAlign={'left'} variant="h5" component="div" sx={{ height: '50%', flexGrow: 1, ml: 2, color: 'black' }}>
                                            Dokončeno
                                        </Typography>
                                        <Typography variant="h4" component="div" sx={{ height: '50%', flexGrow: 1, ml: 2, color: 'black', mr:'12px', mb: '12px' }}>
                                            {getCompleted()}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box> 
                        </Box>
                    </Grid>
                    <Grid item lg={4} sm={12} md={6}>
                        <Box>
                            <PieChart
                                colors={[ '#FF5200', '#0E457D', '#31AFD4', '#2E7D32']}
                                series={[{ data: [                                   
                                    {id: 1, label: 'V přípravě', value: getPreparing()},                                    
                                    {id: 2, label: 'Realizace', value: getRealization()}                                    ,                                    
                                    {id: 3, label: 'Dokončeno', value: getCompleted()}
                                    ], innerRadius: 50, paddingAngle: 5, cornerRadius: 5}]}
                                width={400}
                                height={200}
                                />
                        </Box>
                    </Grid>
                    <Grid item lg={4} sm={12} md={6}>
                        <Box>
                            <BarChart
                                borderRadius={5}
                                xAxis={[{ scaleType: 'band', data: ['Celkem', 'V přípravě', 'Realizace', 'Dokončeno'], colorMap: {
                                    colors: ['#2E7D32', '#FF5200', '#0E457D', '#31AFD4'],
                                    type: 'ordinal'
                                } }]}
                                margin={{ top: 20, right: 20, bottom: 20, left: 120 }}
                                yAxis={[
                                    {
                                    valueFormatter: (value) => `${(value / 1000000).toLocaleString()} mil. kč`,
                                    label: 'passengers',
                                    },
                                ]}
                                series={[{ data: [getCelkemSum(), getPreparingSum(), getRealizationSum(), getCompletedSum()] }]}
                                sx={{
                                          [`.${axisClasses.left} .${axisClasses.label}`]: {
                                            // Move the y-axis label with CSS
                                            visibility: 'hidden',
                                          },
                                        }
                                  }
                                width={400}
                                height={200}
                                />
                        </Box>
                    </Grid>
                </Grid>}
        </Box>
    );
}
