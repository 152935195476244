import { Menu, MenuItem } from '@mui/material';
import React from 'react'
interface TableContextMenuProps {
    anchorEl: HTMLElement | null;
    onClose: () => void;
    open: boolean;
    menuItems: { label: string, onClick: () => void }[];
}
export default function TableContextMenu(props: TableContextMenuProps) {
    const { anchorEl, onClose, open, menuItems } = props;
  

    return (
        <Menu
            anchorEl={anchorEl}
            open={open}
            closeAfterTransition
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            {menuItems.map((item, index) => (
                <MenuItem key={index} onClick={() => { item.onClick(); onClose(); }}>
                    {item.label}
                </MenuItem>
            ))}
        </Menu>
    );
}
