import { create } from "zustand";
import { selectedValidator } from "./LocalStorageKeys";
import { ValidatorsEnum } from "../Enums/ValidatorsEnum";

interface ValidatorStoreState {
    validator?: ValidatorsEnum;
    // load: (perms: DataStandardDTO[]) => void
    initValidator: () => void;
    setValidator: (vali: ValidatorsEnum) => void;
}


export const useValidatorStore = create<ValidatorStoreState>()((set) => ({
    validator: ValidatorsEnum.None,
    // load: (by) => set((state) => ({ standardsData: by })),
    initValidator: () => {
        let cant = localStorage.getItem(selectedValidator);
        if (cant !== null)
            set((state) => ({ validator: parseInt(cant!) }));

    },
    setValidator: (vali: ValidatorsEnum) => {
        localStorage.setItem(selectedValidator, vali.toString());
        set((state) => ({ validator: vali }));
    }
}))