import { environment } from '../AuthorizationService';

export default async function refreshBentleyToken() {
    const refreshToken = localStorage.getItem('refresh_bentley_access_token');
    
    if (!refreshToken)  throw new Error('No refresh token present');

    const url = 'https://ims.bentley.com/connect/token';
    const params = new URLSearchParams();
    params.append('grant_type', 'refresh_token');
    params.append('refresh_token', refreshToken);
    params.append('client_id', environment.authorization.clientId);
    params.append('client_secret', 'BbHG3t4hu4RjeW+wtgMAQnW06NAQ5lyzOziQVHuKjzCUUrwbC5aYVwIaB+85HtubXNf65zYq9mIwbmj19F8ryw==');
    params.append('redirect_uri', environment.authorization.redirectUri);
    params.append('scope', environment.authorization.scope);

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: params.toString()
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        if(data.error) {
            throw new Error('Error refreshing token');
        }
        if(!data.access_token) {
            throw new Error('Error refreshing token');
        }
        localStorage.setItem('bentley_access_token', data.access_token);
        localStorage.setItem('refresh_bentley_access_token', data.refresh_token);

        const expires = localStorage.getItem('bentley_token_expires')!;
        window.setTimeout(() => {
            refreshBentleyToken();
        }, (Number(expires) * 1000) - 5000);

        return data;
    } catch (error) {
        console.error('Error refreshing token:', error);
        throw error;
    }
}
