import { GridColDef } from "@mui/x-data-grid-premium";
import { ValidationSlopeState } from "../Api/ApiServer";
import { Tooltip } from "@mui/material";
import { ErrorOutline, Warning } from "@mui/icons-material";

export const gridColumns: GridColDef[] =
    [
        {
            field: 'id',
            headerName: 'ID',
            minWidth: 0,
            maxWidth: 0,
            width: 0,
            // hide:true



        },
        {
            field: 'station',
            headerName: 'Staničení [km]',
            filterable: true,
            align: 'right',
            width: 110,
            headerClassName: 'tilt-grid-header',
            // renderCell: (params: any) => {
            //     return <div>{params.row.station.toFixed(5).toLocaleString('cs-CZ')}</div>;

            // },
            valueGetter: (params: any) => {
                return params.row.station.toFixed(6).replace('.', ',');
            }
        },
        {
            field: 'vertexName',
            headerName: 'Body geometrie',
            filterable: true,
            align: 'center',
            width: 110,
            headerClassName: 'tilt-grid-header',

            // renderCell: (params: any) => {
            //     return <div>{params.row.station.toFixed(5).toLocaleString('cs-CZ')}</div>;

            // },
            // valueGetter: (params: any) => {
            //     return params.row.station.toFixed(6).replace('.', ',');
            // }
        },
        {
            field: 'vn', headerName: 'Vn [km/h]', filterable: true,
            align: 'right',
            width: 80,
            valueGetter: (params: any) => {
                return params.row.vn !== null ? params.row.vn.toLocaleString('cs-CZ') : '';
            }
        },

        {
            field: 'empty1', headerName: '', filterable: false, width: 4, disableColumnMenu: true, aggregable: false, sortable: false, resizable: false, minWidth: 4, maxWidth: 4,
            renderCell: (params: any) => {
                return <div style={{ background: '#C3C3C3', width: '4px', height: '32px' }}></div>
            },
            renderHeader: (params: any) => {
                return <div style={{ background: '#C3C3C3', width: '4px', height: '38px' }}></div>
            },

            headerClassName: 'MuiDataGrid-columnHeader-mini',
            cellClassName: 'MuiDataGrid-cell-mini'
        },
        {
            field: 'longitudinalSlopeLeft', headerName: 'Podélný sklon L', filterable: true,
            width: 110,
            align: 'right',
            cellClassName: 'MuiDataGrid-cell-mini',
            renderCell: (params: any) => { return TwoStateCell(params.row.tooltipCrossSlopeLeftInCurve, params.row.isCrossSlopeLeftInCurveCorrect, params.row.longitudinalSlopeLeft) },
            // renderCell: (params: any) => {

            //     return <div>{params.row.longitudinalSlopeLeft !== null && params.row.longitudinalSlopeLeft.toFixed(2).replace(".", ",")}</div>
            // }
        },
        {
            field: 'crossSlopeLeft', headerName: 'Příčný sklon L', filterable: true,
            align: 'right',
            valueGetter: (params: any) => {
                return params.row.crossSlopeLeft !== null ? params.row.crossSlopeLeft.toFixed(2).toLocaleString('cs-CZ').replace(".", ",") + '%' : '';
            },
            width: 100
        },
        {
            field: 'finalSlopeLeft',
            headerName: 'Výsledný sklon L',
            filterable: true,
            width: 120,

            renderCell: (params: any) => {
                // return ThreeStateCell(params.row.tooltipFinalSlopeLeft, params.row.finalSlopeLeftCorrectState, params.row.finalSlopeLeft);
                if (params.row.tooltipFinalSlopeLeft !== null && params.row.tooltipFinalSlopeLeft !== '')
                    return ThreeStateTooltipCell(params.row.tooltipFinalSlopeLeft, params.row.finalSlopeLeftCorrectState, params.row.finalSlopeLeft);
                else return ThreeStateNoTooltipCell(params.row.finalSlopeLeftCorrectState, params.row.finalSlopeLeft);

            },
            cellClassName: 'MuiDataGrid-cell-mini'
        },

        {
            field: 'deltaSLeft', headerName: 'Δs L', filterable: true,
            headerAlign: 'center',
            width: 110, align: 'right',
            renderCell: (params: any) => {
                // return ThreeStateCell(params.row.deltaSLeftTooltip, params.row.deltaSLeftCorrectState, params.row.deltaSLeft);
                if (params.row.deltaSLeftTooltip)
                    return ThreeStateTooltipCell(params.row.deltaSLeftTooltip, params.row.deltaSLeftCorrectState, params.row.deltaSLeft);
                else return ThreeStateNoTooltipCell(params.row.deltaSLeftCorrectState, params.row.deltaSLeft);

            },
            cellClassName: 'MuiDataGrid-cell-mini',
            valueGetter: (params: any) => {
                return params.row.deltaSLeft !== null ? params.row.deltaSLeft.toFixed(2).toLocaleString('cs-CZ').replace(".", ",") + "%" : '';
            },
        },
        {
            field: 'aLeft', headerName: "a' L", filterable: true,
            width: 110, align: 'right', valueGetter: (params: any) => {
                return params.row.aLeft !== null ? params.row.aLeft.toFixed(3).toLocaleString('cs-CZ').replace(".", ",") + 'm' : '';
            },
        },
        {
            field: 'crossSlopeLeftInCurve',
            headerName: 'Sklon v oblouku L',
            filterable: true,
            width: 120,
            renderCell: (params: any) => { return TwoStateCell(params.row.tooltipCrossSlopeLeftInCurve, params.row.isCrossSlopeLeftInCurveCorrect, params.row.crossSlopeLeftInCurve) },
            cellClassName: 'MuiDataGrid-cell-mini'
        },
        {
            field: 'paveWidthLeft', headerName: 'Šířka zpevnění L', filterable: true,
            width: 110, align: 'right',
            valueGetter: (params: any) => {
                return params.row.paveWidthLeft !== null ? params.row.paveWidthLeft.toFixed(3).toLocaleString('cs-CZ').replace(".", ",") + 'm' : '';
            },
        },
        {
            field: 'spMinLeft', headerName: 's p,min L', filterable: true,
            width: 110, align: 'right',
            renderHeader: (params: any) => {
                // console.log(params);
                return <div style={{ width: '100%', marginLeft: '10px', fontWeight: 'bold' }}>s<sub>p,min</sub> L</div>
            },
            renderCell: (params: any) => {
                //console.log(params.row.spminLeftTooltip, params.row.spminLeftCorrectState, params.row.spMinLeft);
                // return ThreeStateCell(params.row.spminLeftTooltip, params.row.spminLeftCorrectState, params.row.spMinLeft);
                if (params.row.spminLeftTooltip !== null && params.row.spminLeftTooltip !== '')
                    return ThreeStateTooltipCell(params.row.spminLeftTooltip, params.row.spminLeftCorrectState, params.row.spMinLeft);
                else return ThreeStateNoTooltipCell(params.row.spminLeftCorrectState, params.row.spMinLeft);

            },
            cellClassName: 'MuiDataGrid-cell-mini'
            // renderCell: (params: any) => {
            //     return <div>{params.row.spMinLeft !== null && params.row.spMinLeft.toFixed(2).toLocaleString('cs-CZ').replace(".", ",") + "%"}</div>
            // }
        },
        {
            field: 'empty2', headerName: '', filterable: false, width: 4, disableColumnMenu: true, aggregable: false, sortable: false, resizable: false, minWidth: 4, maxWidth: 4,
            renderCell: (params: any) => {
                return <div style={{ background: '#C3C3C3', width: '4px', height: '32px' }}></div>
            },
            renderHeader: (params: any) => {
                return <div style={{ background: '#C3C3C3', width: '4px', height: '38px' }}></div>
            },
            headerClassName: 'MuiDataGrid-columnHeader-mini',
            cellClassName: 'MuiDataGrid-cell-mini'
        },
        {
            field: 'longitudinalSlopeRight', headerName: 'Podélný sklon P', filterable: true,
            width: 110, align: 'right',

            cellClassName: 'MuiDataGrid-cell-mini',
            renderCell: (params: any) => { return TwoStateCell(params.row.tooltipCrossSlopeRightInCurve, params.row.isCrossSlopeRightInCurveCorrect, params.row.longitudinalSlopeRight) },

        },
        {
            field: 'crossSlopeRight', headerName: 'Příčný sklon P', filterable: true,
            width: 100, align: 'right', valueGetter: (params: any) => {
                return params.row.crossSlopeRight !== null ? params.row.crossSlopeRight.toFixed(2).toLocaleString('cs-CZ').replace(".", ",") + '%' : '';
            },
        },

        {
            field: 'finalSlopeRight',

            headerName: 'Výsledný sklon P',
            filterable: true,
            width: 110,
            renderCell: (params: any) => {
                // return ThreeStateCell(params.row.tooltipFinalSlopeRight, params.row.finalSlopeRightCorrectState, params.row.finalSlopeRight);
                if (params.row.tooltipFinalSlopeRight)
                    return ThreeStateTooltipCell(params.row.tooltipFinalSlopeRight, params.row.finalSlopeRightCorrectState, params.row.finalSlopeRight);
                else return ThreeStateNoTooltipCell(params.row.finalSlopeRightCorrectState, params.row.finalSlopeRight);

            },
            cellClassName: 'MuiDataGrid-cell-mini'
        },
        // {
        //     field: 'limitFinalSlope', headerName: 'Výsledný konečný', filterable: true,
        //     width: 120, align: 'right', valueGetter: (params: any) => {
        //         return params.row.limitFinalSlope !== null ? params.row.limitFinalSlope.toFixed(2).toLocaleString('cs-CZ').replace(".",",") : '';
        //     },
        // },

        {
            field: 'deltaSRight', headerName: 'Δs P', filterable: true,
            headerAlign: 'center',
            renderCell: (params: any) => {
                // return ThreeStateCell(params.row.deltaSRightTooltip, params.row.deltaSRightCorrectState, params.row.deltaSRight);
                if (params.row.deltaSRightTooltip)
                    return ThreeStateTooltipCell(params.row.deltaSRightTooltip, params.row.deltaSRightCorrectState, params.row.deltaSRight);
                else return ThreeStateNoTooltipCell(params.row.deltaSRightCorrectState, params.row.deltaSRight);

            },
            cellClassName: 'MuiDataGrid-cell-mini',
            width: 110, align: 'right', valueGetter: (params: any) => {
                return params.row.deltaSRight !== null ? params.row.deltaSRight.toFixed(2).toLocaleString('cs-CZ').replace(".", ",") + '%' : '';
            },
        },
        {
            field: 'aRight', headerName: "a' P", filterable: true,
            width: 110, align: 'right', valueGetter: (params: any) => {
                return params.row.aRight !== null ? params.row.aRight.toFixed(3).toLocaleString('cs-CZ').replace(".", ",") + 'm' : '';
            },
        },
        {
            field: 'crossSlopeRightInCurve',
            headerName: 'Sklon v oblouku P',
            filterable: true,
            width: 120,
            cellClassName: 'MuiDataGrid-cell-mini',
            renderCell: (params: any) => { return TwoStateCell(params.row.tooltipCrossSlopeRightInCurve, params.row.isCrossSlopeRightInCurveCorrect, params.row.crossSlopeRightInCurve) },
            //     return <Tooltip title={params.row.tooltipCrossSlopeRightInCurve}><div style={{ color: params.row.isCrossSlopeRightInCurveCorrect === true ? 'black' : 'red' }}>{params.row.crossSlopeRightInCurve}</div></Tooltip>
            // }
        },
        {
            field: 'paveWidthRight', headerName: 'Šířka zpevnění P', filterable: true,
            width: 110, align: 'right', valueGetter: (params: any) => {
                return params.row.paveWidthRight !== null ? params.row.paveWidthRight.toFixed(3).toLocaleString('cs-CZ').replace(".", ",") + 'm' : '';
            },
        },
        {
            field: 'spMinRight', headerName: 's p,min P', filterable: true,
            width: 110, align: 'right',
            renderCell: (params: any) => {
                if (params.row.spminRightTooltip)
                    return ThreeStateTooltipCell(params.row.spminRightTooltip, params.row.spminRightCorrectState, params.row.spMinRight);
                else return ThreeStateNoTooltipCell(params.row.spminRightCorrectState, params.row.spMinRight);

            },
            cellClassName: 'MuiDataGrid-cell-mini',
            // renderCell: (params: any) => {
            //     return <div>{params.row.spMinRight !== null && params.row.spMinRight.toFixed(2).toLocaleString('cs-CZ').replace(".", ",") + '%'}</div>
            // },
            renderHeader: (params: any) => {
                // console.log(params);
                return <div style={{ width: '100%', marginLeft: '10px', fontWeight: 'bold' }}>s<sub>p,min</sub> P</div>
            },
        },
    ];

// const ThreeStateCell = (toolTipTitle: string, state: ValidationSlopeState, value: any) => {

//     return <Tooltip title={<div dangerouslySetInnerHTML={{ __html: toolTipTitle }}></div>}>
//         <div
//             style={{
//                 background: state === ValidationSlopeState.Correct ? undefined : (state === ValidationSlopeState.Warning ? 'orange' : 'red')
//                 , width: '100%',
//                 fontWeight: (state === ValidationSlopeState.Correct ? 'normal' : 'bold'),
//                 color: state === ValidationSlopeState.Correct ? 'black' : 'white',
//                 display: 'flex', justifyContent: 'space-between'
//             }}
//         >
//             <div>
//                 {state === ValidationSlopeState.Warning && <Warning style={{ color: 'white', width: '16px', height: '16px', marginLeft: '4px', marginTop: '10px' }} />}
//                 {state === ValidationSlopeState.Error && <ErrorOutline style={{ color: 'white', width: '16px', height: '16px', marginLeft: '4px', marginTop: '10px' }} />}
//             </div>
//             <div style={{ marginRight: '10px' }}>{value !== null ? value.toFixed(2).toLocaleString('cs-CZ').replace(".", ",") + '%' : ''}</div>

//         </div>
//     </Tooltip>
//     else
//         return <ThreeStateInnerCell state={state} value={value}></ThreeStateInnerCell>
// }

const ThreeStateTooltipCell = (toolTipTitle: string, state: ValidationSlopeState, value: any) => {
    // console.log(toolTipTitle);
    // if (toolTipTitle !== null && toolTipTitle !== '')
    return <Tooltip title={<div dangerouslySetInnerHTML={{ __html: toolTipTitle }}></div>}>
        <div
            style={{
                background: state === ValidationSlopeState.Correct ? undefined : (state === ValidationSlopeState.Warning ? 'orange' : 'red')
                , width: '100%',
                fontWeight: (state === ValidationSlopeState.Correct ? 'normal' : 'bold'),
                color: state === ValidationSlopeState.Correct ? 'black' : 'white',
                display: 'flex', justifyContent: 'space-between'
            }}
        >
            <div>
                {state === ValidationSlopeState.Warning && <Warning style={{ color: 'white', width: '16px', height: '16px', marginLeft: '4px', marginTop: '10px' }} />}
                {state === ValidationSlopeState.Error && <ErrorOutline style={{ color: 'white', width: '16px', height: '16px', marginLeft: '4px', marginTop: '10px' }} />}
            </div>
            <div style={{ marginRight: '10px' }}>{value !== null ? value.toFixed(2).toLocaleString('cs-CZ').replace(".", ",") + '%' : ''}</div>

        </div></Tooltip>
    // else
    //     return <Tooltip title={<div dangerouslySetInnerHTML={{ __html: toolTipTitle }}></div>}>
    //     <ThreeStateInnerCell state={state} value={value}></ThreeStateInnerCell></Tooltip>
}
const ThreeStateNoTooltipCell = (state: ValidationSlopeState, value: any) => {
    // console.log(toolTipTitle);
    // if (toolTipTitle !== null && toolTipTitle !== '')
    return <div
        style={{
            background: state === ValidationSlopeState.Correct ? undefined : (state === ValidationSlopeState.Warning ? 'orange' : 'red')
            , width: '100%',
            fontWeight: (state === ValidationSlopeState.Correct ? 'normal' : 'bold'),
            color: state === ValidationSlopeState.Correct ? 'black' : 'white',
            display: 'flex', justifyContent: 'space-between'
        }}
    >
        <div>
            {state === ValidationSlopeState.Warning && <Warning style={{ color: 'white', width: '16px', height: '16px', marginLeft: '4px', marginTop: '10px' }} />}
            {state === ValidationSlopeState.Error && <ErrorOutline style={{ color: 'white', width: '16px', height: '16px', marginLeft: '4px', marginTop: '10px' }} />}
        </div>
        <div style={{ marginRight: '10px' }}>{value !== null ? value.toFixed(2).toLocaleString('cs-CZ').replace(".", ",") + '%' : ''}</div>

    </div>
    // <Tooltip title={<div dangerouslySetInnerHTML={{ __html: toolTipTitle }}></div>}>

    // </Tooltip>
    // else
    //     return <Tooltip title={<div dangerouslySetInnerHTML={{ __html: toolTipTitle }}></div>}>
    //     <ThreeStateInnerCell state={state} value={value}></ThreeStateInnerCell></Tooltip>
}
const ThreeStateInnerCell = (props: { state: ValidationSlopeState, value: any }) => {
    return <div
        style={{
            background: props.state === ValidationSlopeState.Correct ? undefined : (props.state === ValidationSlopeState.Warning ? 'orange' : 'red')
            , width: '100%',
            fontWeight: (props.state === ValidationSlopeState.Correct ? 'normal' : 'bold'),
            color: props.state === ValidationSlopeState.Correct ? 'black' : 'white',
            display: 'flex', justifyContent: 'space-between'
        }}
    >
        <div>
            {props.state === ValidationSlopeState.Warning && <Warning style={{ color: 'white', width: '16px', height: '16px', marginLeft: '4px', marginTop: '10px' }} />}
            {props.state === ValidationSlopeState.Error && <ErrorOutline style={{ color: 'white', width: '16px', height: '16px', marginLeft: '4px', marginTop: '10px' }} />}
        </div>
        <div style={{ marginRight: '10px' }}>{props.value !== null ? props.value.toFixed(2).toLocaleString('cs-CZ').replace(".", ",") + '%' : ''}</div>

    </div>
}
const TwoStateCell = (toolTipTitle: string, state: boolean, value: any) => {
    return <Tooltip title={toolTipTitle}>
        <div style={{
            display: 'flex', justifyContent: 'space-between', width: '100%', height: '100%',
            background: state === true ? undefined : 'red',
            color: state === true ? 'black' : 'white',
            fontWeight: (state ? 'normal' : 'bold'),
        }}>
            <div>{state === false && <ErrorOutline style={{ color: 'white', width: '16px', height: '16px', marginLeft: '10px', marginTop: '10px' }} />}</div>
            <div style={{ marginRight: '10px' }}>{value !== null ? value.toFixed(2).toLocaleString('cs-CZ').replace(".", ",") + '%' : ''}</div>

        </div>
    </Tooltip>
}
