import { create } from "zustand";
import { cantOpenDetail } from "./LocalStorageKeys";

interface SettingsStoreState {
    cantShowDetail?: boolean;
    // load: (perms: DataStandardDTO[]) => void
    initSettings: () => void;
    showHideDetail: (show: boolean) => void;
}


export const useSettingsStore = create<SettingsStoreState>()((set) => ({
    cantShowDetail: false,
    // load: (by) => set((state) => ({ standardsData: by })),
    initSettings: () => {
        let cant = localStorage.getItem(cantOpenDetail);
        if (cant !== null)
            set((state) => ({ cantShowDetail: true }));
    },
    showHideDetail: (show: boolean) => {
        if (!show) {
            localStorage.setItem(cantOpenDetail, "true");
        }
        else
            localStorage.removeItem(cantOpenDetail);
        set((state) => ({ cantShowDetail: !show }));
    }
}))