import { Autocomplete, Button, Checkbox, CircularProgress, Dialog, DialogContent, FormControlLabel, Stack, TextField, Typography } from '@mui/material'

import React, { useEffect, useState } from 'react'
import MuiDialogHeader from '../Shared/MuiDialogHeader';
import { APIAuthClient } from '../Api/ApiAuthClient';

interface ChooseTenantDialogProps {
    onClose: (vyber: number | undefined) => void;
    isOpen: boolean;
    userId: number;
    selectedTenantId?: number
}

export default function ChooseTenantDialog(props: ChooseTenantDialogProps) {
    const { onClose, isOpen, userId, selectedTenantId } = props;
    const [selected, setSelected] = useState<number | undefined>(1);
    const [ buttonDisabled, setButtonDisabled] = useState(false);
    const [ options, setOptions] = useState<{ label:string, id:number }[]>([]);

    useEffect(() => {
        if (isOpen)
            new APIAuthClient().GetUserTenants(userId).then((res) => {
                let tenants: Array<{ label:string, id:number }> = [];
                if (res.tenants)
                    for (let key of res.tenants) {
                        let ten = { label: key.name!, id: key.id! };                
                        tenants.push(ten);
                    }
                setOptions(tenants);
            })
      }, [isOpen]);

    return (
        <Dialog maxWidth='lg' sx={{ minHeight: '300px'}} onClose={() => onClose(undefined)} open={isOpen}>
            <MuiDialogHeader title='Výběr tenantu' onClose={() => onClose(undefined)} />
            <DialogContent sx={{ background: 'white' }}>
                <div style={{ margin: '10px'}}>
                    <div style={{ minHeight: '300px'}}>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={options}
                            onChange={(e, v) => {
                                if (v)
                                    setSelected(v.id);
                                else
                                    setSelected(undefined);
                              }}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            value={selectedTenantId && options ? options.find(s => s.id === selectedTenantId) : undefined}
                            sx={{ width: 300 }}
                            renderInput={(params) => <TextField {...params} label="Výběr" />}
                            />
                    </div>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
                        <Button variant='contained' sx={{ margin: 'auto' }} disabled={!selected || buttonDisabled} onClick={() => {
                            setButtonDisabled(true);
                            if (selected)
                                onClose(selected);
                            setButtonDisabled(false);
                        }}>VYBRAT</Button>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}
