import { BIMDataValidatorClient, RefreshTokenRequest } from "./ApiServer";
import axios, { AxiosInstance } from "axios";
import delay from "../Shared/delay";
import { SingletonRefreshClient } from "./SingletonRefreshClient";
import { APIAuthClient } from "./ApiAuthClient";

export class ApiBaseClient {

    protected GetAdressedAuthRefreshableClient(): BIMDataValidatorClient {
        return new BIMDataValidatorClient(this.GetApiAddress(), this.GetAuthClient());
    }
    protected GetApiAddress(): string {
        // console.log(process.env.REACT_APP_API_URL);
        // if (process.env.REACT_APP_API_URL === 'NOT')
        //     return '';
        console.log(process.env.REACT_APP_API_URL);
        console.log(process.env.REACT_APP_BIMVALIDATOR_URL);
        return process.env.REACT_APP_API_URL!;
        // return 'https://localhost:7186';
    }
    // public GetApiAddressToPDFViewer(): string {
    //     if (process.env.REACT_APP_API_URL === 'NOT')
    //         return 'https://localhost:5001/todos/api/PDF';
    //     else return process.env.REACT_APP_API_URL! + '/todos/api/PDF';
    // }
    public GetClientToAuth(): BIMDataValidatorClient {
        const axiosApiInstance = axios.create();

        // Request interceptor for API calls
        axiosApiInstance.interceptors.request.use(
            async config => {
                // let culture = localStorage.getItem('i18nextLng');
                config.headers = {
                    // 'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'TimeZone': Intl.DateTimeFormat().resolvedOptions().timeZone
                }
                return config;
            },
            error => {
                Promise.reject(error)
            });
        axiosApiInstance.interceptors.response.use((response) => {
            return response
        }, async function (error) {
            const originalRequest = error.config;
            if (error.response.status === 401) {
                console.log('401.1', window.location.href);
                var items = window.location.href.split("/");
                window.location.href = window.location.origin + '/' + items[1] + '/' + items[2];
            }
            // if (error.response.status === 401 && !originalRequest._retry) {
            //     try {
            //         if (error.response.headers['is-token-expired'] === 'true') {
            //             console.log('refresh token', error);
            //             originalRequest._retry = true;
            //             await new APIUsersHelper().RefreshToken();
            //             axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('bearer');
            //             return axiosApiInstance(originalRequest);
            //         }
            //         else throw new Error('unathorized but refresh token not expires');
            //     }
            //     catch (e) {
            //         throw new Error('unable to refresh token');
            //     }
            // }
            return Promise.reject(error);
        });
        return new BIMDataValidatorClient(this.GetApiAddress(), axiosApiInstance);
    }


    protected GetAuthClient(): AxiosInstance {
        const axiosApiInstance = axios.create();

        // Request interceptor for API calls
        axiosApiInstance.interceptors.request.use(
            async config => {
                let token = localStorage.getItem('token');
                // let culture = localStorage.getItem('i18nextLng');
                // if (culture !== undefined && culture !== null) {
                if (token !== null) {
                    config.headers = {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'TimeZone': Intl.DateTimeFormat().resolvedOptions().timeZone
                    }
                }
                return config;

                //                 if (culture !== undefined && culture !== null)
                //                     config.headers = {
                //                         'Content-Type': 'application/json',
                //                         'Accept': 'application/json',
                //                         'accept-language': culture,
                //                         'TimeZone': Intl.DateTimeFormat().resolvedOptions().timeZone
                //                     }
                //                 else config.headers = {
                // set('Authorization', 'Bearer ' + token)
                //                 }

            },
            error => {
                Promise.reject(error)
            });
        axiosApiInstance.interceptors.response.use((response) => {
            return response
        }, async function (error) {
            const originalRequest = error.config;
            // if (error.response.status === 401) {
            //     console.log('401.1', window.location.href);
            //     var items = window.location.href.split("/");
            //     window.location.href = window.location.origin + '/' + items[3] + '/' + items[4];
            // }
            if (error.response.status === 401 && !originalRequest._retry) {
                console.log('tady');
                console.log(error);
                // console.log(response);
                try {
                    if (error.response.headers['is-token-expired'] === 'true') {
                        if (!SingletonRefreshClient.isRefreshing) {
                            SingletonRefreshClient.setRefresh();
                            originalRequest._retry = true;
                            try {
                                await SingletonRefreshClient.refreshToken();
                            }
                            catch (e) {

                                console.log(e);
                                window.location.href = window.location.origin;

                            }
                            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('bearer');
                            SingletonRefreshClient.completeRefresh();
                        }
                        else delay(400);

                        return axiosApiInstance(originalRequest);
                    }
                    else throw new Error('unauthorized but refresh token not expires');
                }
                catch (e) {
                    console.log('error refresh', e);
                    // throw new Error('unable to refresh token');
                }
            }
            return Promise.reject(error);
        });
        return axiosApiInstance;
    }
    protected GetIsAuthedClient(): BIMDataValidatorClient {
        return new BIMDataValidatorClient(this.GetApiAddress(), this.GetIsAuthedInstance());
    }
    protected GetIsAuthedInstance(): AxiosInstance {
        const axiosApiInstance = axios.create();

        // Request interceptor for API calls
        axiosApiInstance.interceptors.request.use(
            async config => {
                let token = localStorage.getItem('token');
                // let culture = localStorage.getItem('i18nextLng');
                // if (culture !== undefined && culture !== null) {
                if (token !== null) {
                    config.headers = {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'TimeZone': Intl.DateTimeFormat().resolvedOptions().timeZone
                    }
                }
                return config;

                //                 if (culture !== undefined && culture !== null)
                //                     config.headers = {
                //                         'Content-Type': 'application/json',
                //                         'Accept': 'application/json',
                //                         'accept-language': culture,
                //                         'TimeZone': Intl.DateTimeFormat().resolvedOptions().timeZone
                //                     }
                //                 else config.headers = {
                // set('Authorization', 'Bearer ' + token)
                //                 }

            },
            error => {
                Promise.reject(error)
            });
        axiosApiInstance.interceptors.response.use((response) => {
            return response
        }, async function (error) {
            const originalRequest = error.config;
            if (error.response.status === 401 && !originalRequest._retry) {
                console.log('tady');
                console.log(error);
                // console.log(response);
                try {
                    if (error.response.headers['is-token-expired'] === 'true') {
                        if (!SingletonRefreshClient.isRefreshing) {
                            SingletonRefreshClient.setRefresh();
                            originalRequest._retry = true;
                            try {
                                await SingletonRefreshClient.refreshToken();
                            }
                            catch (e) {
                                throw new Error('unauthorized but refresh token not expires');
                                // console.log(e);
                                // window.location.href = window.location.origin;

                            }
                            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('bearer');
                            SingletonRefreshClient.completeRefresh();
                        }
                        else delay(400);

                        return axiosApiInstance(originalRequest);
                    }
                    else throw new Error('unauthorized but refresh token not expires');
                }
                catch (e) {
                    console.log('error refresh', e);
                    // throw new Error('unable to refresh token');
                }
            }
            return Promise.reject(error);
            // if (error.response.status === 401 && !originalRequest._retry) {
            //     throw new Error('unauthorized but refresh token not expires');
            // }
            // return Promise.reject(error);
        });
        return axiosApiInstance;
    }

}