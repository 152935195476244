import { ContentCopy } from '@mui/icons-material';
import { Alert, AlertTitle, Button, Dialog, DialogContent, IconButton, Slide, Snackbar, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import MuiDialogHeader from '../Shared/MuiDialogHeader';

interface NewPasswordDialogProps {
    isOpen: boolean;
    onClose: () => void;
    newPassword: string;
}
export default function NewPasswordDialog(props: NewPasswordDialogProps) {
    const { isOpen, onClose, newPassword } = props;

    useEffect(() => {
    }, []);
    
    return (
        <Dialog onClose={() => onClose()} open={isOpen}>
            <MuiDialogHeader showCloseText title='NOVÉ HESLO' onClose={() => onClose()}/>
            <DialogContent>
                <div style={{ margin: '24px'}}>
                    <div>
                        <div style={{ margin: '10px', fontSize: '16px'}}>
                            <TextField
                            sx={{ margin: '12px', marginLeft: '24px', width: '250px' }}
                            size='small'
                            variant='outlined'
                            value={newPassword}
                            onChange={(e) => { }} 
                            InputProps={{
                                readOnly: true,
                              }}/>
                            {/* {newPassword} */}
                            <IconButton sx={{ margin: '12px', marginLeft: '10px'}} onClick={() => {navigator.clipboard.writeText(newPassword)}}>
                                <ContentCopy/>
                            </IconButton>
                        </div>
                        {/* <Typography variant='h5' fontWeight='bold' color='primary' margin={1}>{newPassword}</Typography> */}
                    </div>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
                        <Button variant='contained' sx={{ margin: 'auto' }} onClick={() => {
                                    onClose();
                            
                        }}>OK</Button>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}
