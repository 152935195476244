import React from 'react'
interface DetailGadgetProps {
    children: JSX.Element[] | JSX.Element;
    title: string;
}

export default function DetailGadget(props: DetailGadgetProps) {
    const { children, title } = props;
    const wrapChildrenInColumns = () => {
        const wrappedChildren:any[] = [];
        const itemCount = React.Children.count(children);
        const itemsPerColumn = 4;
    
        for (let i = 0; i < itemCount; i += itemsPerColumn) {
          const columnItems = React.Children.toArray(children).slice(i, i + itemsPerColumn);
          wrappedChildren.push(
            <div key={`column-${i}`} style={{ display: 'flex', flexDirection: 'column' }}>
              {columnItems}
            </div>
          );
        }
        return wrappedChildren;
      };
    return (
        <div style={{ border: '1px solid #C3C3C3', padding: '8px 16px', margin: '8px 0px 8px 16px', boxShadow: '0 0 10px rgba(100,100,100,0.5)', borderRadius:'4px' }}>
            {/* <div style={{ border: '1px solid black', padding: '8px', margin: '8px' }}> */}

            <div style={{marginBottom:'4px', marginLeft: '4px', fontWeight:'bold', color:'#404040', /* display: 'flex', */ textAlign: 'left'}} dangerouslySetInnerHTML={{ __html: title }}>{/* {title} */}</div>
            <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row' }}>
                {wrapChildrenInColumns()}
            </div>
        </div>
    )
}
