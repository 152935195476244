export function roundNumber(value: number, precision: number) {
    var precision = precision || 0,
        power = Math.pow(10, precision),
        absValue = Math.abs(Math.round(value * power)),
        result = (value < 0 ? '-' : '') + String(Math.floor(absValue / power));

    if (precision > 0) {
        var fraction = String(absValue % power),
            padding = new Array(Math.max(precision - fraction.length, 0) + 1).join('0');
        result += '.' + padding + fraction;
    }
    return Number(result);
}
export function truncateNumber(value: number, precision: number) {
    var power = Math.pow(10, precision),
        result = (value < 0 ? '-' : '') + String(Math.floor(value * power) / power);

    return Number(result);
}