import { Close } from '@mui/icons-material';
import { Button, Dialog, DialogContent, DialogTitle, FormControl, IconButton, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import React, { useEffect } from 'react'
import { ApiDataClient } from '../../Api/ApiDataClient';
import { RouteDTO } from '../../Api/ApiServer';

interface UpdateRouteDialogProps {
    open: boolean;
    route?: RouteDTO | undefined;
    onClose: () => void;
    onRefresh?: () => void;
}
export default function UpdateRouteDialog(props: UpdateRouteDialogProps) {
    const { open, route, onClose, onRefresh } = props;

    const [name, setName] = React.useState<string>();
    const [state, setState] = React.useState<string>('0');
    const [id, setId] = React.useState<number>();
    const [buttonDisabled, setButtonDisabled] = React.useState<boolean>(false);

    useEffect(() => {
        if (route){
            setName(route.name);
            setState(route.state ? route.state.toString() : '0');
            setId(route.id);
        }
        else {
            setName(undefined);
            setState('0');
            setId(undefined);
        }
    }, [route]);

    return (
        <Dialog
            open={open}
            onClose={() => { onClose() }}
            aria-labelledby="create-dialog-title"
            aria-describedby="create-dialog-description"
        >
            <DialogTitle id="create-project-dialog-title" sx={{ background: '#F5F5F5'}}>
                <div  >
                    <div style={{ marginRight: '20px', minWidth: '200px' }}>Úprava projektu</div>
                    <IconButton sx={{ width: '30px', height: '30px', right: '20px', top: '18px', float: 'right', position: 'absolute' }}
                        onClick={() => { setName(undefined); onClose(); }}><Close /></IconButton>
                </div>
            </DialogTitle>
            <DialogContent>
                    <div>
                        <TextField sx={{ margin: '12px', width: '526px' }} label={'Název projektu'}
                            size='small'
                            name='userName'
                            variant='outlined'
                            value={name}
                            error={!name}
                            onChange={(e) => { setName(e.target.value) }} />
                        <div style={{ margin: '12px', width: '526px' }}>
                            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                                <InputLabel id="demo-simple-select-standard-label">Stav</InputLabel>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={state}
                                    onChange={(v) => { if (v.target.value) setState(v.target.value) }  }
                                    label="Stav"
                                >
                                    <MenuItem value="0">Nový</MenuItem>
                                    <MenuItem value="1">Aktivní</MenuItem>
                                    <MenuItem value="2">K revizi</MenuItem>
                                    <MenuItem value="3">Dokončeno</MenuItem>
                                    <MenuItem value="4">Uzavřeno</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
                            <Button variant='contained' sx={{ margin: 'auto' }} disabled={name === undefined || buttonDisabled} onClick={() => {
                                if (name && state !== undefined && id) {
                                    setButtonDisabled(true);
                                    new ApiDataClient().UpdateRoute(name, parseInt(state), id).then((v) => {
                                        setButtonDisabled(false);

                                        if (onRefresh)
                                            onRefresh();

                                        onClose();
                                    });
                                }
                            }}>Uložit</Button>
                        </div>
                    </div>
            </DialogContent>
        </Dialog>
    )
}
