import { ceilWithMinus } from "../../Shared/ceilWithMinus";
import { ObloukItemState } from "../Enums/ObloukItemState";
import { ObloukItem } from "../Models/ObloukItem";

export function calculateIk(items: { [key: string]: ObloukItem }): void {
    if (items['Vk'].numericValue > 0) {
        items['Ik'].setValueByNumericValue(11.8 * Math.pow(items['Vk'].numericValue, 2) / items['R'].numericValue - items['D'].numericValue);
        items['Ik'].setValueByNumericValue(ceilWithMinus(items['Ik'].numericValue));
        items['I_Ek'].setValueByNumericValue(Math.abs(items['Ik'].numericValue));

        if (items['Vk'].numericValue >= 70 && items['D'].numericValue >= 30) {
            if (items['Vk'].numericValue > 230) {
                if (items['Vk'].numericValue <= 300) items['Ikn'].setValueByNumericValue(70);
                else items['Ikn'].setValueByNumericValue(60);


                if (items['Vk'].numericValue <= 250) items['Iklim'].setValueByNumericValue(100);
                else if (items['Vk'].numericValue <= 300) items['Iklim'].setValueByNumericValue(80);
                else items['Iklim'].setValueByNumericValue(65);

                if (items['Vk'].numericValue <= 250) items['Ikmax'].setValueByNumericValue(130);
                else if (items['Vk'].numericValue <= 300) items['Ikmax'].setValueByNumericValue(100);
                else items['Ikmax'].setValueByNumericValue(90);
            }


            else if (items['R'].numericValue >= 300) {
                items['Iklim'].setValueByNumericValue(270);
            }
            else if (items['R'].numericValue < 250) {
                items['Iklim'].setValueByNumericValue(0);
            }
            else {
                items['Iklim'].setValueByNumericValue(240);
            }
        }
        if (items['Ikmax'].value === '' || items['Ikmax'].value === '0') {
            items['Ikmax'].setValueByNumericValue(items['Iklim'].numericValue);
        }
        let IkmaxD = 3.5 * items['D'].numericValue;
        let IklimD = 3 * items['D'].numericValue;

        if (items['Ik'].numericValue < - 110) {
            items['Ik'].okState = ObloukItemState.Error;
        }
        else if (items['Ik'].numericValue < - 80) {
            items['Ik'].okState = ObloukItemState.Zlute;
        }
        else {
            if (items['Vk'].numericValue > 230) {
                if (items['Ik'].numericValue > items['Ikmax'].numericValue) {
                    items['Ik'].okState = ObloukItemState.Error;
                }
                else if (items['Ik'].numericValue > items['Iklim'].numericValue) {
                    if (items['Vk'].numericValue > 300 && items['Ik'].numericValue > 80) {
                        items['Ik'].okState = ObloukItemState.OranzoveSrafovane;
                        items['Ik'].toolTip = 'Lze jen při návrhu PJD.';

                    }
                    else items['Ik'].okState = ObloukItemState.Oranzove;
                }
                else if (items['Ik'].numericValue > items['If'].numericValue) {
                    items['Ik'].okState = ObloukItemState.Zlute;
                }
            }
            else {
                if (items['Ik'].numericValue > items['Ikmax'].numericValue) items['Ik'].okState = ObloukItemState.Error;
                else if (items['Ik'].numericValue > IkmaxD) items['Ik'].okState = ObloukItemState.Error;
                else if (items['V'].numericValue < 70 && items['Vk'].numericValue !== 0) items['Ik'].okState = ObloukItemState.Error;
                else if (items['Ik'].numericValue > IklimD) {
                    items['Ik'].okState = ObloukItemState.Oranzove;
                    if (items['Ik'].numericValue > 220) items['Ik'].toolTip = 'Lze jen s kolejnicemi 60 E1, 60 E2 a R65.';
                }
                else if (items['R'].numericValue < 400 && items['Ik'].numericValue > 240) {
                    items['Ik'].okState = ObloukItemState.ZeleneSeVzorkem;
                    items['Ik'].toolTip = 'Lze jen při rekonstrukci se souhlasem vlastníka dráhy. Lze jen s kolejnicemi 60 E1, 60 E2 a R65.';
                }
                else if (items['Ik'].numericValue > 220) {
                    items['Ik'].toolTip = 'Lze jen s kolejnicemi 60 E1, 60 E2 a R65.';
                }
            }
        }
        if (items['Ik'].numericValue >= 0) {
            items['Ik'].setValueByNumericValue(Math.round(items['Ik'].numericValue));
        }
        else {
            //co tohle znamená??
            items['Ik'].value = "E=" + Math.abs(Math.round(items['Ik'].numericValue));
        }
    }
    // if (items['V150'].numericValue > 0) {
    //     if (items['V150'].numericValue <= 230) {
    //       items['Inn150'].setValueByNumericValue(80);
    //     } else if (items['V150'].numericValue <= 300) {
    //       items['Inn150'].setValueByNumericValue(70);
    //     } else {
    //       items['Inn150'].setValueByNumericValue(60);
    //     }
    //     if (items['V150'].numericValue < 80) {
    //       items['Imax150'].setValueByNumericValue(130);

    //     }
    //     else if (items['V150'].numericValue <= 250) {
    //       items['Ilim150'].setValueByNumericValue(100);
    //       items['Imax150'].setValueByNumericValue(130);

    //     } else if (items['V130'].numericValue <= 300) {
    //       items['Ilim130'].setValueByNumericValue(80);
    //       items['Imax130'].setValueByNumericValue(100);
    //     } else {
    //       items['Inn130'].setValueByNumericValue(65);
    //       items['Imax130'].setValueByNumericValue(90);
    //     }

    //     items['I150'].setValueByNumericValue(11.8 * Math.pow(items['V150'].numericValue, 2) / items['R'].numericValue - items['D'].numericValue);
    //     items['I150'].setValueByNumericValue(Math.ceil(items['I150'].numericValue));
    //     items['I_E150'].setValueByNumericValue(Math.abs(items['I150'].numericValue));

    //     if (items['I150'].numericValue < -110) items['I150'].okState = ObloukItemState.Error;
    //     else if (items['I150'].numericValue < -80) items['I150'].okState = ObloukItemState.Zlute;
    //     else if (items['I150'].numericValue > items['Imax150'].numericValue) items['I150'].okState = ObloukItemState.Error;
    //     else if (items['I150'].numericValue > items['Ilim150'].numericValue) {
    //       if (items['V150'].numericValue < 80 && items['R'].numericValue < 250) {
    //         items['I150'].okState = ObloukItemState.OranzoveSrafovane;
    //         items['I150'].toolTip = "Lze pouze pro vozidla s hmotností na nápravu do 18 t.";
    //       }
    //       else if (items['V150'].numericValue >= 80 && items['V150'].numericValue <= 230 && items['I150'].numericValue > 130) {
    //         items['I150'].okState = ObloukItemState.OranzoveSrafovane;
    //         items['I150'].toolTip = "Lze pouze pro vozidla osobní dopravy.";
    //       }
    //       else if (items['V150'].numericValue > 300 && items['I150'].numericValue > 80) {
    //         items['I150'].okState = ObloukItemState.OranzoveSrafovane;
    //         items['I150'].toolTip = "Lze jen při návrhu PJD.";
    //       }
    //       else items['I150'].okState = ObloukItemState.Oranzove;
    //     }
    //     else if (items['I150'].numericValue > items['Inn150'].numericValue) items['I150'].okState = ObloukItemState.Zlute;
    //     //rewrite bellow to ts
    //     if (items['I150'].numericValue >= 0) items['I150'].setValueByNumericValue(Math.round(items['I150'].numericValue));

    //     //co tohle znamená??
    //     else items['I150'].value = "E=" + Math.abs(Math.round(items['I150'].numericValue));

    //   }
    // If Vk Then
    //         Ik = 11.8 * Vk ^ 2 / R - D
    //         If Ik >= 0 Then
    //             Ik = Application.WorksheetFunction.RoundUp(Ik, 0)
    //         Else
    //             'Ik = Application.WorksheetFunction.RoundDown(Ik, 0)
    //             Ik = Application.WorksheetFunction.RoundUp(Ik, 0)
    //         End If
    //         I_Ek = Abs(Ik)

    //         If Vk >= 70 And D >= 30 Then
    //             If Vk > 230 Then
    //                 If Vk <= 300 Then
    //                     Ikn = 70
    //                 Else
    //                     Ikn = 60
    //                 End If

    //                 If Vk <= 250 Then
    //                     Iklim = 100
    //                 Elseif Vk <= 300 Then
    //                     Iklim = 80
    //                 Else
    //                     Iklim = 65
    //                 End If

    //                 If Vk <= 250 Then
    //                     Ikmax = 130
    //                 Elseif Vk <= 300 Then
    //                     Ikmax = 100
    //                 Else
    //                     Ikmax = 90
    //                 End If
    //             Elseif R >= 300 Then
    //                 Iklim = 270
    //             Elseif R < 250 Then
    //                 Iklim = 0
    //             Else
    //                 Iklim = 240
    //             End If
    //         Else
    //             Iklim = 0
    //         End If

    //         If IsEmpty(Ikmax) Then
    //             Ikmax = Iklim
    //         End If

    //         IkmaxD = 3.5 * D
    //         IklimD = 3 * D

    //         Select Case Ik
    //          Case Is < -110
    //             BunkaFormat "M" & lRadek, 99
    //          Case Is < -80
    //             BunkaFormat "M" & lRadek, 3
    //          Case Else
    //             If Vk > 230 Then
    //                 Select Case Ik
    //                  Case Is > Ikmax
    //                     BunkaFormat "M" & lRadek, 99
    //                  Case Is > Iklim
    //                     If Vk > 300 And Ik > 80 Then
    //                         BunkaFormat "M" & lRadek, 5
    //                         vBublina = "Lze jen při návrhu PJD."
    //                         Set rng = Range("M" & lRadek)
    //                         rng.AddComment vBublina
    //                     Else
    //                         BunkaFormat "M" & lRadek, 4
    //                     End If
    //                  Case Is > Ikn
    //                     BunkaFormat "M" & lRadek, 3
    //                 End Select
    //             Else
    //                 If Ik > Ikmax Then
    //                     BunkaFormat "M" & lRadek, 99
    //                 Elseif Ik > IkmaxD Then
    //                     BunkaFormat "M" & lRadek, 99
    //                 Elseif V < 70 And Vk Then
    //                     BunkaFormat "M" & lRadek, 99
    //                 Elseif Ik > IklimD Then
    //                     BunkaFormat "M" & lRadek, 4
    //                     If Ik > 220 Then
    //                         vBublina = "Lze jen s kolejnicemi 60 E1, 60 E2 a R65."
    //                         Set rng = Range("M" & lRadek)
    //                         rng.AddComment vBublina
    //                     End If
    //                 Elseif R < 400 And Ik > 240 Then
    //                     BunkaFormat "M" & lRadek, 14
    //                     vBublina = "Lze jen při rekonstrukci se souhlasem vlastníka dráhy. Lze jen s kolejnicemi 60 E1, 60 E2 a R65."
    //                     Set rng = Range("M" & lRadek)
    //                     rng.AddComment vBublina
    //                 Elseif Ik > 220 Then
    //                     vBublina = "Lze jen s kolejnicemi 60 E1, 60 E2 a R65."
    //                     Set rng = Range("M" & lRadek)
    //                     rng.AddComment vBublina
    //                 End If
    //             End If
    //         End Select

    //         If Ik >= 0 Then
    //             Range("M" & lRadek).Value = Round(Ik, 0)
    //         Else
    //             Range("M" & lRadek).Value = "E=" & Abs(Round(Ik, 0))
    //         End If
    //     End If

;}