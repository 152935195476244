import { roundNumber } from "../../Shared/roundNumber";
import { ObloukItem } from "../Models/ObloukItem";

export function calculateVytycovaci(items: { [key: string]: ObloukItem }) {
    calculateInvisibleFieldsStart(items);
    //tyhle pole nejsou nikde vidět a proto jsem vůbec nepokračoval ifem mezi 1175 a 1422
    calculateWithAlfa(items);
    calculateTecnyZaobleni(items);
}
function calculateWithAlfa(items: { [key: string]: ObloukItem }) {
    if (items['alfa'].numericValue > 0) {
        //předpokládám, že celá tahle část slouží na sestavení textu, kterej se ale už nebude dál používat
    }
}
function calculateTecnyZaobleni(items: { [key: string]: ObloukItem }) {
    //1423-1447
    if ((items['Lk1'].value !== '' || items['Lk2'].value !== '') && items['D'].numericValue !== 0 && items['V'].numericValue > 0) {
        let rvln = 0.5 * (Math.max(items['V'].numericValue, items['V130'].numericValue, items['V150'].numericValue, items['Vk'].numericValue) ** 2);
        rvln = 100 * Math.ceil(rvln / 100);
        if (items['Lk1'].value !== '') {
            let sklon1 = items['D'].numericValue / items['Lk1'].numericValue;
            if (Math.max(items['V'].numericValue, items['V130'].numericValue, items['V150'].numericValue, items['Vk'].numericValue) > 200) {
                items['tz1'].setValueByNumericValue(20);
            }
            else {
                items['tz1'].setValueByNumericValue(roundNumber(rvln / 2 * sklon1 / 1000, 3));
            }
        }
        if (items['Lk2'].value !== '') {
            let sklon2 = items['D'].numericValue / items['Lk2'].numericValue;
            if (Math.max(items['V'].numericValue, items['V130'].numericValue, items['V150'].numericValue, items['Vk'].numericValue) > 200) {
                items['tz2'].setValueByNumericValue(20);
            }
            else {
                items['tz2'].setValueByNumericValue(roundNumber(rvln / 2 * sklon2 / 1000, 3));
            }
        }
    }
}
function calculateInvisibleFieldsStart(items: { [key: string]: ObloukItem }) {
    //řádky 1146-1173
    items['A1'].setValueByNumericValue((items['R'].numericValue * items['Lk1'].numericValue) ** 0.5);
    items['tauk1'].setValueByNumericValue(items['Lk1'].numericValue / 2 / items['R'].numericValue * 200 / Math.PI);
    items['Yk1'].setValueByNumericValue(Math.pow(items['Lk1'].numericValue, 2) / (6 * items['R'].numericValue)
        - Math.pow(items['Lk1'].numericValue, 4) / (336 * Math.pow(items['R'].numericValue, 3))
        + Math.pow(items['Lk1'].numericValue, 6) / (42240 * Math.pow(items['R'].numericValue, 5)));
    items['Xk1'].setValueByNumericValue(items['Lk1'].numericValue
        - Math.pow(items['Lk1'].numericValue, 3) / (40 * Math.pow(items['R'].numericValue, 2))
        + Math.pow(items['Lk1'].numericValue, 5) / (3456 * Math.pow(items['R'].numericValue, 4)));
    items['m1'].setValueByNumericValue(items['Yk1'].numericValue - items['R'].numericValue * (1 - Math.cos(items['tauk1'].numericValue * Math.PI / 200)));

    items['A2'].setValueByNumericValue((items['R'].numericValue * items['Lk2'].numericValue) ** 0.5);
    items['tauk2'].setValueByNumericValue(items['Lk2'].numericValue / 2 / items['R'].numericValue * 200 / Math.PI);
    items['Yk2'].setValueByNumericValue(Math.pow(items['Lk2'].numericValue, 2) / (6 * items['R'].numericValue)
        - Math.pow(items['Lk2'].numericValue, 4) / (336 * Math.pow(items['R'].numericValue, 3))
        + Math.pow(items['Lk2'].numericValue, 6) / (42240 * Math.pow(items['R'].numericValue, 5)));
    items['Xk2'].setValueByNumericValue(items['Lk2'].numericValue
        - Math.pow(items['Lk2'].numericValue, 3) / (40 * Math.pow(items['R'].numericValue, 2))
        + Math.pow(items['Lk2'].numericValue, 5) / (3456 * Math.pow(items['R'].numericValue, 4)));
    items['m2'].setValueByNumericValue(items['Yk2'].numericValue - items['R'].numericValue * (1 - Math.cos(items['tauk2'].numericValue * Math.PI / 200)));
}