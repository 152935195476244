import { APIAuthClient } from '../Api/ApiAuthClient';
import { create } from 'zustand'

interface CompanyLogoStoreState {
    logoBase64?: string;
    // load: (perms: DataStandardDTO[]) => void
    initLogoStore: () => void
}

export const useCompanyLogoStore = create<CompanyLogoStoreState>()((set) => ({
    logoBase64: '',
    // load: (by) => set((state) => ({ standardsData: by })),
    initLogoStore: () => {
        new APIAuthClient().GetLogoBase64().then((res) => {
            set((state) => ({ logoBase64: res }))
        });
    }
}))